export class Lesson {
  id: string | undefined
  job_id: string | undefined
  adi_id: string | undefined
  job_ref: string | undefined
  lesson_date: Date | null = null
  lesson_date_str: string | undefined
  duration: number = 1
  student_name: string | undefined
  is_prev: boolean | undefined
  adi_name: string | undefined
  confirmed_at?: Date | null = null
  rejected_at?: Date | null = null
  cancelled_at?: Date | null = null
  disabled_at?: Date | null = null
  remaining_hours?: number | null = null
  adi_cancellation_policy?: number | null = null
  status_id?: number | null = null
  cancellation_type?: number
  status?: string | null
  abilities?: {
    can_reject: boolean
    can_confirm: boolean
    can_dispute: boolean
    can_cancel: boolean
  }
}

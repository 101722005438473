import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useCourse} from '../../../providers/CourseProvider'
import {formatPriceIntoTwoDigits} from '../../../helpers/PriceHelper'
import {purchaseRequest} from '../../../services/addons.service'
import {
  handlePassProtect,
  freePaymentRequest,
  getAdditionalHoursCustomPricing,
} from '../../../services/booking.service'
import {getOffersForPostcode} from '../../../services/offers.service'
import {getLeadTimes} from '../../../services/product.service'
import {AdditionalHours} from '../AdditionalHours'
import {RootState} from '../../../../../setup'
import {getPaymentUrl} from '../../../helpers/PaymentHelper'
import {PassprotectWithRedemptionRemaining} from '../../../models/student/pass-protect-with-redemption-remaining'
import Modal from '../../shared/overlays/Modal'
import RadioInputGroup from '../../shared/forms/RadioInputGroup'
import {Skeleton} from '@mui/material'
import toast from 'react-hot-toast'

type Props = {
  show: any
  handleClose: any
  handleSubmit: any
  passProtectOption: PassprotectWithRedemptionRemaining | undefined
}

const UsePassProtectModal: FC<Props> = ({show, handleClose, handleSubmit, passProtectOption}) => {
  const intl = useIntl()
  const {Course} = useCourse()
  const [courseHours, setCourseHours] = useState<any>(0)
  const [selectedCourse, setSelectedCourse] = useState<any>({})
  const [additionalHoursInCart, setAdditionalHoursInCart] = useState<any>(0)
  const [total, setTotal] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true
  const [courseList, setCourseList] = useState<any>([])
  const [buttonText, setbuttonText] = useState<string>('Rebook Test')
  const [loading, setLoading] = useState(true)
  const [customPricing, setCustomPricing] = useState<any>()

  const handleSubmitPassProtect = async () => {
    setSubmitting(true)
    let leadTimeResponse = await getLeadTimes(
      Course.pickupAddress.postal_code,
      Course?.order?.transmission ?? 'manual'
    ).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get lead times')
      return
    })
    let ptProduct = leadTimeResponse?.find((a: any) => a.name == 'Practical Test Only')
    let leadTime = ptProduct ? ptProduct?.lead_time : 0
    const res = await handlePassProtect(passProtectOption?.create_rebooking_on, {
      lead_time: leadTime,
      hours: courseHours,
      extra_hours: additionalHoursInCart,
      source: 'Rebooking — Pass Protect',
    }).catch((error) => {
      setSubmitting(false)
      if (!error?.errorMessage) toast.error('Failed to handle passprotect')
    })
    if (total > 0) {
      await getRedirctURL(res?.cart?.token)
    } else {
      await freePaymentRequest(res?.cart?.token, null).catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to handle passprotect')
      })
      setSubmitting(false)
      handleClose()
      window.location.reload()
    }
  }
  useEffect(() => {
    setCourseHours(0)
    setTotal(0)
    setAdditionalHoursInCart(0)
    if (show && Course?.order?.id) {
      setLoading(true)
      ;(async () => {
        const response = await getAdditionalHoursCustomPricing(Course?.order?.id).catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get additional hours pricing')
        })
        if (response) {
          setCustomPricing(response)
          const offersResponse = await getOffersForPostcode(Course?.order?.postcode).catch(
            (error) => {
              if (!error?.errorMessage) toast.error('Failed to get offer for postcodes')
              setLoading(false)
            }
          )
          if (offersResponse) {
            setCourseList(offersResponse)
            setSelectedCourse(offersResponse[0])
          }
          setLoading(false)
        }
      })()
    }
  }, [show, Course?.order?.id])
  const getRedirctURL = async (token: any) => {
    let successUrl = process.env.REACT_APP_PAYMENT_REDIRECT_URL
    const response = await purchaseRequest(
      total,
      passProtectOption?.create_rebooking_on,
      token,
      successUrl ?? '',
      'Rebooking — Pass Protect'
    ).catch((e) => {
      if (!e?.errorMessage) toast.error('Something went wrong while sending purchase request')
    })
    if (response) {
      window.open(
        getPaymentUrl(response.uuid, 'Rebooking — Pass Protect'),
        isMobile ? '_blanck' : '_self'
      )
      handleClose()
    }
    setSubmitting(false)
  }
  const calculateTotal = () => {
    var total = 0

    if (courseHours > 0) {
      total = selectedCourse?.[Course?.order?.transmission]?.prices?.course_only ?? 0
      const perHourCost =
        selectedCourse?.[Course?.order?.transmission]?.prices?.course_only / courseHours
      total = total + perHourCost * additionalHoursInCart
    } else {
      let perHourCost = 0
      if (customPricing) {
        const keys = Object.keys(customPricing[Course?.order?.transmission]).map((key) =>
          parseInt(key)
        )
        if (additionalHoursInCart < keys[0]) {
          perHourCost = customPricing[Course?.order?.transmission][keys[0]]
        } else {
          keys.forEach((hour, i) => {
            if (additionalHoursInCart >= hour && additionalHoursInCart < keys[i + 1]) {
              perHourCost = customPricing[Course?.order?.transmission][hour]
            }
          })
        }
        total = total + perHourCost * additionalHoursInCart
      }
    }

    setTotal(total)
    setbuttonText(`Rebook Test ${total > 0 ? '£' + formatPriceIntoTwoDigits(total) : ''}`)
  }
  useEffect(() => {
    calculateTotal()
  }, [additionalHoursInCart, selectedCourse])

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        onSubmit={handleSubmitPassProtect}
        submitText={buttonText}
        title='PassProtect'
        disabled={submitting || loading}
      >
        <div className='P-5'>
          <h4 className='font-size-15'>{intl.formatMessage({id: 'BOOK_NEW_COURSE.DESC_1'})}</h4>

          <div className='flex flex-wrap justify-center mt-6'>
            {loading ? (
              <div className='grid-cols-3 mt-1 grid gap-4'>
                <Skeleton width={120} height={150} animation='wave'></Skeleton>
                <Skeleton width={120} height={150} animation='wave'></Skeleton>
                <Skeleton width={120} height={150} animation='wave'></Skeleton>
                <Skeleton width={400} height={200} animation='wave'></Skeleton>
              </div>
            ) : (
              <>
                <RadioInputGroup
                  options={courseList.map((item: any) => {
                    return {
                      value: item?.hours,
                      label:
                        item?.hours != 0 ? (
                          <>
                            <p className='font-bold text-sm flex'>{item?.hours} HOURS</p>
                            <div className='mt-1'>
                              <div className='block font-normal'>
                                £
                                {Course?.order?.transmission == 'manual'
                                  ? item?.manual?.prices?.course_only
                                  : item?.automatic?.prices?.course_only}
                              </div>
                              <div className='block mt-1 text-xs text-green-600'></div>
                              <div className='block mt-1 text-xs text-green-600'></div>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className='fw-600'>{item?.name}</p>
                          </>
                        ),
                    }
                  })}
                  disabled={submitting}
                  label={''}
                  name={''}
                  onChange={function (value: any): void {
                    setCourseHours(value)
                    setSelectedCourse(courseList.find((x: any) => x.hours === value))
                  }}
                  value={courseHours}
                ></RadioInputGroup>
                <div className='px-4 my-2 w-full'>
                  <AdditionalHours
                    cost={
                      Course?.order?.transmission == 'manual'
                        ? selectedCourse?.manual?.prices?.course_only / courseHours
                        : selectedCourse?.automatic?.prices?.course_only / courseHours
                    }
                    hours={0}
                    updateCart={() => {}}
                    updateHoursInCart={() => {}}
                    hasAdi={false}
                    hideAddButton={true}
                    hoursChanged={(val: any) => {
                      setAdditionalHoursInCart(val)
                    }}
                    min={0}
                    updateHourCost={() => {}}
                    firstStep={5}
                  ></AdditionalHours>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UsePassProtectModal

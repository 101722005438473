interface IProps {
  name: string
  options: Array<{value: any; label: string}>
  onChange: (e: any, data: any) => void
  values: Array<any>
  required?: boolean
  disabled: boolean
  error?: boolean
  errorMsg?: string
}

export default function HorizontalCheckboxGroup({
  name,
  values,
  options = [],
  onChange,
  required = false,
  disabled = false,
}: IProps) {
  const toggleSelected = (e: any, data: any) => {
    if (e.target.checked) {
      values = [...values, data]
    } else {
      values = values.filter((elem: any) => elem !== data)
    }

    onChange(e, data)
  }

  return (
    <ul className='grid w-full gap-2 grid-cols-5' key={name}>
      {options?.map((option, index) => {
        const uniqueId = `${name}-${index}`
        return (
          <li key={uniqueId} className='relative'>
            <input
              className='checked:accent-[#163a5e] peer absolute opacity-0 top-0 left-0 right-0 bottom-0'
              type='checkbox'
              name={uniqueId}
              id={uniqueId}
              checked={values.indexOf(option.value) !== -1}
              onChange={(e) => {
                toggleSelected(e, option.value)
              }}
              data-parsley-multiple={name}
              disabled={disabled}
              required={required}
            />
            <label
              htmlFor={uniqueId}
              className='inline-flex items-center justify-center py-1 w-full 
                        bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-darkBlue peer-checked:bg-darkBlue peer-checked:text-white
                        hover:bg-gray-50'
            >
              <div className='w-full text-sm font-semibold text-center'>{option.label}</div>
            </label>
          </li>
        )
      })}
    </ul>
  )
}

import {FC} from 'react'
import Modal from '../../shared/overlays/Modal'
import {useIntl} from 'react-intl'
import {Lesson} from '../../../models/lessons/lesson'
import {convertToTimeFormat} from '../../../helpers/DateHelper'
// import {ClickAwayListener, Tooltip} from '@mui/material'

type IProps = {
  show: boolean
  showLesson: Lesson
  onHide: any
}

const LessonDetailsModal: FC<IProps> = ({show, showLesson, onHide}) => {
  const intl = useIntl()
  // const [openDesc, setOpenDesc] = useState(false)
  return (
    <>
      <Modal
        open={show}
        title={intl.formatMessage({id: 'LESSONS.LESSON_DETAILS'})}
        description=''
        hideSubmit
        onClose={onHide}
      >
        <div className='d-flex justify-content-between pt-3 pb-5'>
          <div className=''>
            <div className='d-flex flex-grow-1 align-items-start  mb-3'>
              <strong>{intl.formatMessage({id: 'LESSONS.INSTRUCTOR_NAME'})} </strong>
              <span className='ms-5'>{showLesson?.adi_name ?? '-'}</span>
            </div>
            <div className='d-flex flex-grow-1 align-items-start  mb-3'>
              <strong>{intl.formatMessage({id: 'LESSONS.JOB_REF'})} </strong>
              <span className='ms-5'>{showLesson?.job_ref}</span>
            </div>
            <div className='d-flex flex-grow-1 align-items-start  mb-3'>
              <strong>{intl.formatMessage({id: 'LESSONS.LESSON_DATE'})} </strong>
              <span className='ms-9'>{showLesson?.lesson_date_str}</span>
            </div>
            <div className='d-flex flex-grow-1 align-items-start  mb-3'>
              <strong>{intl.formatMessage({id: 'LESSONS.DURATION'})} </strong>
              <span className='ms-16'>
                {convertToTimeFormat(parseFloat(showLesson?.duration.toString()) * 60)}
              </span>
            </div>
            {/* {!isUpcomingOnly && (
              <div className='d-flex flex-grow-1 align-items-start  mb-3'>
                <strong>Status: </strong>
                <li
                  className={
                    showLesson?.is_prev
                      ? 'warning-status-icon test ms-20'
                      : 'success-status-icon test ms-20'
                  }
                >
                  <ClickAwayListener onClickAway={() => setOpenDesc(false)}>
                    <Tooltip
                      title={
                        showLesson.is_prev
                          ? intl.formatMessage({id: 'LESSONS.PREVIOUS_LESSON'})
                          : intl.formatMessage({id: 'LESSONS.UPCOMING_LESSON'})
                      }
                      arrow
                      placement='top'
                      open={openDesc}
                      componentsProps={{
                        tooltip: {
                          style: {
                            fontSize: '10px',
                            lineHeight: '18px',
                            fontFamily: 'Poppins, sans-serif',
                            maxWidth: '250px',
                            marginRight: '6px',
                          },
                        },
                      }}
                    >
                      <i
                        className='text-sm ms-1 pmf-icon-info cursor-pointer'
                        onClick={() => setOpenDesc(!openDesc)}
                      ></i>
                    </Tooltip>
                  </ClickAwayListener>
                </li>
              </div>
            )} */}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LessonDetailsModal

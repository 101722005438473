import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import Skeleton from '@mui/material/Skeleton'
import { Link } from 'react-router-dom'

export interface WithImageBgProps extends React.HTMLAttributes<HTMLDivElement> {
  // data: {
  bgImg: string
  title: string
  description?: string
  navigateURL: string,
  children?: React.ReactNode
  loading?: boolean
}

export default function WithImageBg({
  bgImg,
  title,
  description,
  navigateURL,
  children,
  loading,
  ...props
}: WithImageBgProps) {
  return (
    <>
      <div className='group aspect-square  aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2'>
        <img
          src={toAbsoluteUrl(bgImg)}
          alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
          className='object-cover object-center group-hover:opacity-75'
        />
        <div aria-hidden='true' className='bg-gradient-to-b from-transparent to-black opacity-50' />
        <Link to={navigateURL} className='flex items-end p-6'>
          <div>
            <h3 className='font-bold text-2xl text-white'>
                <span className='absolute inset-0' />
                {title}
            </h3>
            <p aria-hidden='true' className='mt-1 text-sm text-white'>
              {description}
            </p>
          </div>
        </Link>
      </div>
    </>
  )
}

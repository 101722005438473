import {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import {getRedirectUrl} from '../../services/payment.service'
import {Skeleton, Step} from '@mui/material'
import Button from '../shared/elements/Button'
import Input from '../shared/forms/Input'
import Modal from '../shared/overlays/Modal'
import Alert from '../shared/overlays/Alert'
import {useFormik} from 'formik'
import * as Yup from 'yup'
type Props = {
  show: any
  onHide: any
  hasAdi: boolean
  remainingBalance: any
  adiData: any
  loading: any
}
const STEP = 25
const MIN = 25
const PaymentAmount: FC<Props> = ({show, onHide, hasAdi, remainingBalance, adiData, loading}) => {
  const intl = useIntl()
  const minPay = remainingBalance > MIN ? MIN : remainingBalance
  const [submitting, setSubmitting] = useState(false)
  const [amount, setAmount] = useState(+remainingBalance)
  const [errorMessage, setErrorMessage] = useState('')
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true

  const getPaymentUrl = async () => {
    setErrorMessage('')
    setSubmitting(true)
    const response = await getRedirectUrl(amount, remainingBalance)
      .catch((error) => {
        setSubmitting(false)
        if (error && error?.errorMessage) {
          setErrorMessage(error?.errorMessage)
        } else {
          setErrorMessage(intl.formatMessage({id: 'GENERAL.ERROR'}))
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
    window.open(response?.results, isMobile ? '_blanck' : '_self')
  }

  const initialValues = {
    paidAmount: remainingBalance,
  }
  const paymentSchema = Yup.object().shape({
    paidAmount: Yup.number()
      .required('Paid amount is required')
      .max(remainingBalance, `Maximum paid amount is ${remainingBalance}`)
      .min(minPay, `Minimum paid amount is ${minPay}`),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: paymentSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      getPaymentUrl()
    },
  })
  useEffect(() => {
    if (show) {
      setAmount(remainingBalance)
      formik.setFieldValue('paidAmount', remainingBalance)
    }
  }, [remainingBalance, show])

  return (
    <>
      <Modal
        title='Make a payment'
        onClose={onHide}
        hideClose={true}
        submitText='Next'
        open={show}
        onSubmit={formik.handleSubmit}
        disableSubmit={submitting}
      >
        <form>
          <div>
            <div className='flex justify-center'>
              {loading ? (
                <Skeleton width={150} />
              ) : (
                <h2 className='text-center text-base font-bold'>
                  {' '}
                  {intl.formatMessage({id: 'PAYMENT_MODAL.SUBTITLE'})}
                </h2>
              )}
            </div>
            <div className=' grid grid-cols-6 justify-center items-center justify-items-center  '>
              {loading ? (
                <Skeleton variant='circular' width={40} height={40} className='col-span-1' />
              ) : (
                <Button
                  colour='transparent'
                  className='col-span-1'
                  onClick={() => {
                    if (amount - STEP > minPay) {
                      setAmount(amount - STEP)
                      formik.setFieldValue('paidAmount', amount - STEP)
                    } else {
                      setAmount(minPay)
                      formik.setFieldValue('paidAmount', minPay)
                    }
                  }}
                >
                  <img loading="lazy" src='/assets/media/svg/minus.svg' />
                </Button>
              )}

              {loading ? (
                <Skeleton variant='rectangular' className='col-span-4' width='100%' height={60} />
              ) : (
                <div className='col-span-4 flex justify-center items-center justify-items-center '>
                  
                  <Input
                    type='text'
                    value={'£' + formik.values.paidAmount}
                    onChange={(e) => {
                      if (+(e?.target?.value ?? 0) >= 0 && e?.target?.value < remainingBalance) {
                        setAmount(+e?.target?.value)
                        formik.setFieldValue('paidAmount', e?.target?.value)
                      }
                    }}
                    error={formik.touched.paidAmount && formik.errors.paidAmount ? true : false}
                    errorMsg={formik?.errors?.paidAmount?.toString()}
                    disabled={formik.isSubmitting}
                    className=' text-5xl font-bold text-center border-none border-transparent focus:border-transparent focus:ring-0'
                  />
                </div>
              )}

              {loading ? (
                <Skeleton variant='circular' width={40} height={40} />
              ) : (
                <Button
                  colour='transparent'
                  className='col-span-1'
                  onClick={() => {
                    if (amount + STEP <= remainingBalance) {
                      setAmount(amount + STEP)
                      formik.setFieldValue('paidAmount', amount + STEP)
                    } else {
                      setAmount(remainingBalance)
                      formik.setFieldValue('paidAmount', remainingBalance)
                    }
                  }}
                >
                  <img loading="lazy" src='/assets/media/svg/plus.svg' />
                </Button>
              )}
            </div>
            <div>
              <div className=' flex justify-center py-7'>
                {loading ? (
                  <Skeleton width={150} className='py-2' />
                ) : (
                  <h2 className='text-center text-base'> Outstanding balance: <span className='font-bold'>£{remainingBalance} </span></h2>
                )}
              </div>
              <div>
                <h2 className='font-size-16 my-0 fw-bolder font-bold'>
                  Pay with card or PaySlow in monthly instalments
                </h2>
                <div className='grid grid-cols-7 gap-2'>
                  <img loading="lazy"
                    className='border border-gray-500'
                    width='50'
                    src={'/assets/media/svg/payment-cards/visa.svg'}
                    alt='Visa Brand Logo'
                  ></img>
                  <img loading="lazy"
                    className='border border-gray-500'
                    width='50'
                    src={'/assets/media/svg/payment-cards/mastercard.svg'}
                    alt='Mastercard Brand Logo'
                  ></img>
                  <img loading="lazy"
                    className='border border-gray-500'
                    width='50'
                    src={'/assets/media/svg/payment-cards/google_pay.svg'}
                    alt='Google Pay Brand Logo'
                  ></img>
                  <img loading="lazy"
                    className='border border-gray-500'
                    width='50'
                    src={'/assets/media/svg/payment-cards/apple_pay.svg'}
                    alt='Apple Pay Brand Logo'
                  ></img>
                  <img loading="lazy"
                    className='border border-gray-500'
                    width='50'
                    src={'/assets/media/svg/payment-cards/klarna-pink.svg'}
                    alt='Klarna Logo'
                  ></img>
                  <img loading="lazy"
                    className='p-1'
                    width='50'
                    src={'/assets/media/svg/payment-cards/payl8r.svg'}
                    alt='Payl8r Logo'
                  ></img>
                  <img loading="lazy"
                    className='p-1'
                    width='50'
                    src={'/assets/media/svg/payment-cards/amex.svg'}
                    alt='AMEX Brand Logo'
                  ></img>
                </div>
              </div>
            </div>
            <Alert colour='pink'>
              {hasAdi ? (
                moment(adiData?.allocation_date)
                  .add(7, 'days')
                  .isAfter(moment.now()) ? (
                  <span className='font-size-16 fw-600'>
                    {intl.formatMessage(
                      {id: 'DASHBOARD.PAYMENT.BALANCE_WARNING'},
                      {
                        date: moment(adiData?.allocation_date)
                          .add(7, 'days')
                          .format('DD/MM'),
                      }
                    )}
                  </span>
                ) : (
                  <span className='font-size-16 fw-600'>
                    {intl.formatMessage({id: 'DASHBOARD.PAYMENT.BALANCE_ADI_ASSIGNED_WARNING_1'})}
                    <b> {intl.formatMessage({id: 'DASHBOARD.PAYMENT.URGENTLY'})} </b>
                    {intl.formatMessage({id: 'DASHBOARD.PAYMENT.BALANCE_ADI_ASSIGNED_WARNING_2'})}
                  </span>
                )
              ) : (
                <span className='font-size-16 fw-600'>
                  {intl.formatMessage({id: 'DASHBOARD.PAYMENT.BALANCE_ADI_WARNING'})}
                </span>
              )}
            </Alert>
          </div>
        </form>
      </Modal>
    </>
  )
}

export {PaymentAmount}

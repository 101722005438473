import {FC} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import Button from '../../components/shared/elements/Button'
import WithImageBg from '../../components/shared/cards/WithImageBg'

const moreCards = [
  {
    title: 'Driving Lesson Videos',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: 'assets/media/more/driving-lesson-videos.png',
    pathURL: '/practical-driving-tips-videos',
  },
  {
    title: 'Refer a friend',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: 'assets/media/more/refer-friend.png',
    pathURL: '/refer-a-friend',
  },
  {
    title: 'GoShorty',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: 'assets/media/more/goShorty.png',
    pathURL: '/goShorty',
  },
]

const MoreWrapper: FC = () => {

  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='More' backToURL='/' isCenter />
      </div>
      <div className='px-5 mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:grid-rows-3 sm:gap-x-6 lg:gap-8'>
        {moreCards.map((item, idx) => (
          <WithImageBg className='mb-6' navigateURL={item.pathURL}
          bgImg={item.imgSrc} title={item.title} description={item.desc} key={idx} />
        ))}
      </div>
    </>
  )
}

export {MoreWrapper}

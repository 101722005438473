import {FC, useEffect, useState} from 'react'
import {SignOutButton} from './SignOutButton'
import ListGroup from '../shared/data/ListGroup'
import {User} from '../../models/auth/User'
import {Link, useNavigate} from 'react-router-dom'
import {
  ArrowLeftIcon,
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import {useCourse} from '../../providers/CourseProvider'
import {getOrder} from '../../services/booking.service'
import {StudentBooking} from '../../models/student/student-booking'
import {updateSelectedBooking} from '../../services/student.service'
import DisclosureItem from '../shared/elements/DisclosureItem'
import {CheckCircleIcon} from '@heroicons/react/20/solid'
import Button from '../shared/elements/Button'
import {useIntl} from 'react-intl'
import {Disclosure} from '@headlessui/react'
import {Skeleton} from '@mui/material'
import toast from 'react-hot-toast'

type Props = {
  personalData: User
}

const AccountSettings: React.FC<Props> = ({personalData}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const fullName = personalData?.name

  const [order, setOrder] = useState<any>()
  const {Course, getCourses, getCurrentCourse, updateCourse} = useCourse()

  const [bookingsList, setBookingsList] = useState<Array<StudentBooking>>([])
  const [currentDeal, setCurrentDeal] = useState<StudentBooking | null>()

  useEffect(() => {
    setBookingsList(Course.courses)
    if (Course.courses && Course.courses.length > 0) {
      let current = Course.courses.find((b: any) => !!b.selected_booking === true)
      setCurrentDeal(current ? current : null)
    }
  }, [Course.courses])

  const updateBookingSelected = async (selectedDealId: any) => {
    setLoading(true)
    const response = await updateSelectedBooking({deal_id: selectedDealId}).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to update selected course')
      setLoading(false)
    })
    if (response) {
      updateCourse('deal_id', selectedDealId)
      setLoading(false)
      getCourses()
      getCurrentCourse()
      navigate('/')
    }
  }

  useEffect(() => {
    setLoading(true)
    if (Course.deal_id) {
      ;(async () => {
        let res = await getOrder(Course.deal_id).catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get order')
          setLoading(false)
        })
        setOrder(res ?? [])
        setLoading(false)
      })()
    }
  }, [Course.deal_id])

  return (
    <>
    {/* start:: user header */}

    <div className='flex flex-col justify-between items-start p-5 pt-8 bg-white'>
      <Disclosure>
      {({open}) => (
        <>
          <Disclosure.Button className='flex w-full justify-between px-4 py-2 text-left text-sm font-medium'>
            <div className='flex justify-between items-center w-full'>
              <div className={'min-w-0 flex mb-0'}>
                <Link to='/' className='flex items-center'>
                  <ArrowLeftIcon className='w-5 h-5 me-[10px]' />
                </Link>
              </div>
              <div className='flex items-start justify-between space-x-1 grow'>
                <div className='flex-1 truncate me-6 text-right'>
                  <h3 className='truncate text-2xl font-normalf font-black bg-gradient-to-r bg-gradient-to-30 from-blue to-green  inline-block text-transparent bg-clip-text'>
                    {personalData?.name} {personalData?.sur_name}
                  </h3>
                  <h4 className='flex items-end justify-end text-base font-semibold'>
                    {order?.course_hours} Hours{' '}
                    <span className='capitalize ms-1 me-2'>({order?.transmission})</span>
                    {loading ? <>
                      <Skeleton variant="circular" width={20} height={20} />
                      </> :
                      <>
                      {(bookingsList.length > 1) && (
                        <ChevronDownIcon
                          className={`${open ? 'rotate-180 transform' : ''} h-4 w-4 mb-1 flex text-darkBlue`}
                        />
                      )}
                    </>}
                  </h4>
                </div>
                <div className='h-14 w-14 flex-shrink-0 flex items-center justify-center rounded-full bg-darkBlue'>
                  <UserIcon className='w-8 h-8 text-white' />
                </div>
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className='pb-2 pt-4 w-full'>
            <div className='scroll-y w-full px-4 border border-gray-300 rounded-xl'>
              {bookingsList.map((booking, index) => (
                <div
                  key={`alert${index}`}
                  className='flex w-full items-center py-4 hover-gray px-4 border-b-2 last:border-b-0 border-gray-500'
                  onClick={() => updateBookingSelected(booking.deal_id)}
                >
                  <div className='flex items-center grow'>
                    <div className='mb-0 me-2'>
                      <a href='#' className='text-base font-semibold'>
                        {booking.serial}
                      </a>
                      <div className=''>{booking.created_at}</div>
                    </div>
                  </div>

                  {!!booking.selected_booking === true ? (
                    <span className='badge badge-light fs-8 flex items-center'>
                      <CheckCircleIcon className='w-6 h-6 text-green' />
                      <p className='text-sm'>
                        {intl.formatMessage({id: 'COURSE_HISTORY.ACTIVE'})}
                      </p>
                    </span>
                  ) : (
                    <div>
                      <Button
                        size='icon'
                        onClick={() => updateBookingSelected(booking.deal_id)}
                        colour='gray'
                        fullWidth={false}
                      >
                        <span className='p-1 flex content-center'>
                          <ArrowPathIcon className='w-4 h-4' />
                        </span>
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
      </Disclosure>
    </div>

    <div className='bg-gradient-to-r bg-gradient-to-30 from-blue to-green h-[6px]'></div>
    {/* end:: user header */}

    <div className='flex flex-wrap px-5 pt-6 pb-4 p-lg-0'>
      <div className='pb-7'>
        <ListGroup
          data={[
            {
              name: 'About You',
              linkURL: '/profile/about-u',
              icon: 'icon-user',
            },
            {
              name: 'Change Email',
              linkURL: '/profile/change-email',
              icon: 'icon-mail',
            },
            {
              name: 'Licence Details',
              linkURL: '/profile/licence-details',
              icon: 'icon-user-card',
            },
            {
              name: 'Availability',
              linkURL: '/profile/availability',
              icon: 'icon-calendar-checklist',
            },
            {
              name: 'Medical Conditions',
              linkURL: '/profile/medical-conditions',
              icon: 'icon-medical',
            },
            {
              name: 'Cancel Booking',
              linkURL: '/cancel-course',
              icon: 'icon-times-circle',
            },
            {
              name: 'Tests',
              linkURL: '/tests',
              icon: 'icon-graduation-cap',
            },
            {
              name: 'Course History',
              linkURL: '/course-history',
              icon: 'icon-history',
            },
            {
              name: 'Payment History',
              linkURL: '/payment-history',
              icon: 'icon-wallet',
            },
            {
              name: 'Communication',
              linkURL: '/profile/communication',
              icon: 'icon-bubble-chat',
            },
            {
              name: 'Notifications',
              linkURL: '/profile/notification',
              icon: 'icon-addons',
              hide: true,
            },
          ].filter((a) => !a.hide)}
        />
      </div>
    </div>

    {/* begin::Sign out */}
    <SignOutButton />
    {/* end::Sign out */}

    {/* begin::Copyright */}
    <div className='w-full block mt-8 text-center'>
      <span className='text-gray-800 text-sm'>
        Copyright &copy; {new Date().getFullYear()} owned by Star Genie Limited
      </span>
    </div>
    {/* end::Copyright */}
  </>
  )
}

export {AccountSettings}

import {FC} from 'react'
import {useIntl} from 'react-intl'
import {CourseHistory} from '../../components/course-history/CourseHistory'
import PageHeader from '../../components/shared/elements/PageHeader'

const CourseHistoryWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader backToURL='/profile' isCenter title={intl.formatMessage({id: 'MENU.COURSE_HISTORY'})}></PageHeader>
      </div>
      <div className='px-6 py-4 p-lg-0'>
        <CourseHistory />
      </div>
    </>
  )
}

export default CourseHistoryWrapper

import {FC, useEffect, useState} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'

import {Tab} from '@headlessui/react'
import {Skeleton} from '@mui/material'
import CalendarView from '../../components/lessons/CalendarView'
import ListView from '../../components/lessons/ListView'
import {Lesson} from '../../models/lessons/lesson'
import {LessonRejectionReason} from '../../models/lessons/lesson-rejection-reason'
import {TakenHours} from '../../models/student/taken-hours'
import {checkDisAbleAction} from '../../services/job.service'
import {
  getStudentLessons,
  getTakenHours,
  checkHasPrevLessons,
  getRejectionReasons,
} from '../../services/lesson.service'
import {useCourse} from '../../providers/CourseProvider'
import {ReferFriend} from '../../components/shared/modals/ReferFriend'
import toast from 'react-hot-toast'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const LessonsWrapper: FC = () => {
  const {Course} = useCourse()
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [rejectionReasons, setRejectionReasons] = useState<Array<LessonRejectionReason>>([])
  const [lessons, setLessons] = useState<Array<Lesson>>([])
  const [allLessons, setAllLessons] = useState<Array<Lesson>>([])
  // const [isUpcomingOnly, setIsUpcomingOnly] = useState<number>(1)
  const [dealId, setDealId] = useState<string>(Course.deal_id)
  const [hasPrevLessons, setHasPrevLessons] = useState<boolean>(false)
  const [progress, setProgress] = useState<TakenHours | undefined>(new TakenHours())

  // const [displayedEvents, setDisplayedEvents] = useState<Array<Lesson>>([])
  const [disableAction, setDisableAction] = useState<boolean>(false)
  const [events, setEvents] = useState<Array<any>>([])
  const [showReferFriendModal, setShowReferFriendModal] = useState(false)

  useEffect(() => {
    setDealId(Course.deal_id)
  }, [Course.deal_id])

  useEffect(() => {
    if (dealId) {
      ;(async () => {
        try {
          setLoading(true)
          await getLessonsAndUpcoming()
          await getHasPrevLessons()
          await takenHours()
          const disableResponse = await checkDisAbleAction(dealId).catch((error) => {
            if (!error?.errorMessage) toast.error('Failed to check student ability to take action')
          })
          setDisableAction(disableResponse.results)
        } catch {
          // TODO: Show alert or toaster
          setLoading(false)
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [dealId])

  const getLessonsAndUpcoming = async () => {
    const allLessonsResponse = await getStudentLessons({is_upcomming_only: 0}, dealId).catch(
      (error) => {
        if (!error?.errorMessage) toast.error('Failed to get student lessons')
      }
    )
    setAllLessons(allLessonsResponse?.results ?? [])
    const calenderLessonsResponse = await getStudentLessons({is_upcomming_only: 0,is_calender: 1}, dealId).catch(
      (error) => {
        if (!error?.errorMessage) toast.error('Failed to get student lessons')
      }
    )
    mapCalendarLessons(calenderLessonsResponse?.results ??[])
    const upcomingLessonsResponse = await getStudentLessons({is_upcomming_only: 1}, dealId).catch(
      (error) => {
        if (!error?.errorMessage) toast.error('Failed to get student upcoming lessons')
      }
    )
    setLessons(upcomingLessonsResponse?.results ?? [])
  }

  const handleShowReferFriend = (val: boolean) => {
    if (!progress?.hasAcceptedLessons) {
      setShowReferFriendModal(val)
    }
  }

  const takenHours = async () => {
    const response = await getTakenHours({job_ref: dealId}).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get student taken hours')
    })
    setProgress(response?.results)
  }

  const getHasPrevLessons = async () => {
    const response = await checkHasPrevLessons(dealId).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to check previous lessons')
    })
    setHasPrevLessons(response?.results === true || response?.results === 1)
  }

  // useEffect(() => {
  //   setDisplayedEvents(
  //     lessons.filter(
  //       (event) =>
  //         event.lesson_date?.toString().slice(0, 10) == new Date()?.toISOString().slice(0, 10)
  //     )
  //   )
  // }, [lessons])

  const mapCalendarLessons = (calendarLessons: Array<Lesson>) => {
    let events: Array<any> = []
    calendarLessons.forEach((lesson) => {
      if (lesson.lesson_date_str !== undefined) {
        var date = new Date(lesson.lesson_date_str + ' UTC').toISOString()
        events.push({
          title: lesson.adi_name ?? '-',
          date: date.slice(0, date.length - 2),
          id: lesson.id,
          student_name: lesson.student_name,
          job_ref: lesson.job_ref,
          lesson_date_str: lesson.lesson_date_str,
          duration: lesson.duration,
          adi_name: lesson.adi_name ?? '-',
          confirmed_at: lesson.confirmed_at,
          rejected_at: lesson.rejected_at,
          status: lesson?.status,
          status_id: lesson.status_id,
        })
      }
    })
    setEvents(events)
  }

  useEffect(() => {
    ;(async () => {
      const response = await getRejectionReasons().catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get rejection reasons')
      })
      setRejectionReasons(response?.results ?? [])
    })()
  }, [])

  const updateData = async () => {
    setLoading(true)
    setEvents([])
    getLessonsAndUpcoming()

    const response = await getStudentLessons({is_upcomming_only: false}, dealId).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get student lessons')
    })
    setLessons(response?.results ?? [])
  }

  const tabs = [
    {
      key: 'calendar',
      title: 'Calendar view',
      component: <CalendarView events={events} loading={loading} updateData={updateData} />,
    },
    {
      key: 'list',
      title: 'List view',
      component: (
        <ListView
          hasPrevLessons={hasPrevLessons}
          loading={loading}
          progress={progress}
          upcomingLessons={lessons}
          allLessons={allLessons}
          dealId={dealId}
          getLessons={getLessonsAndUpcoming}
          rejectionReasons={rejectionReasons}
          showReferFriend={handleShowReferFriend}
          disableAction={disableAction}
        />
      ),
    },
  ]

  return (
    <>
      <ReferFriend
        imageURL='first-lesson.jpg'
        onHide={() => {
          setShowReferFriendModal(false)
        }}
        show={showReferFriendModal}
      />
      <div className='bg-white pt-6'>
        <PageHeader title='Lessons' backToURL='/' isCenter />
      </div>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(index) => {
          setSelectedIndex(index)
        }}
      >
        <Tab.List className='flex space-x-1 bg-white'>
          {tabs.map((tab) => (
            <Tab
              key={tab.key}
              className={({selected}) =>
                classNames(
                  'w-full py-2.5 text-base font-medium leading-5',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-none',
                  selected ? ' border-b-4 border-darkBlue' : ' text-[#A8A8A8] hover:brightness-75'
                )
              }
            >
              {loading ? (
                <div className='flex justify-center'>
                  <Skeleton width={80} />
                </div>
              ) : (
                <>{tab.title}</>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className=''>
          {tabs.map((tab) => (
            <Tab.Panel
              key={tab.key}
              className={classNames(
                'grid grid-cols-1 gap-0  overflow-y-hidden ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none'
              )}
            >
              {tab.component}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export {LessonsWrapper}

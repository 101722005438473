/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import * as auth from '../../../../setup/redux/AuthRedux'
import {impersonateUser} from '../../services/auth.service'
import Button from '../../components/shared/elements/Button'
import Input from '../../components/shared/forms/Input'
import Alert from '../../components/shared/overlays/Alert'
import toast from 'react-hot-toast'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function ImpersonatedLogin() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const response = await impersonateUser(values.email, values.password).catch((err) => {
        if (!err?.errorMessage) toast.error('Something went wrong')
      })
      if (response) {
        setSubmitting(true)
        dispatch(auth.actions.setIsLogin(true))
        dispatch(auth.actions.login())
      }
      setSubmitting(false)
      setLoading(false)
    },
  })
  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-6'>
          <h1 className='text-darkBlue mb-3'>Log in to continue</h1>
        </div>
        {/* begin::Heading */}

        <Input
          {...formik.getFieldProps('email')}
          label='Email address'
          placeholder='you@example.com'
          type='email'
          name='email'
          required={true}
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMsg={formik.errors.email}
          disabled={formik.isSubmitting}
        />

        <Input
          type='text'
          {...formik.getFieldProps('password')}
          label='Password'
          placeholder='********'
          error={formik.touched.password && formik.errors.password ? true : false}
          errorMsg={formik.errors.password}
          required={true}
          name='password'
          disabled={formik.isSubmitting}
          id='password'
          eyeIcon={true}
        />

        {/* begin::Action */}
        <Button
          type='submit'
          id='kt_sign_in_submit'
          className='mt-2'
          disabled={formik.isSubmitting}
          fullWidth={true}
          size='large'
        >
          {!formik.isSubmitting && <span className='indicator-label'>Continue</span>}
          {formik.isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
        {/* end::Action */}
      </form>
    </>
  )
}

import modifiedFetch from '../../../setup/fetch/ModifiedFetch'

const OFFERS_URL = process.env.REACT_APP_OFFERS_BASE_URL
const OFFERS_API_KEY = process.env.REACT_APP_API_KEY
export async function getOffersForPostcode(postcode: any): Promise<any> {
  return await modifiedFetch({
    url: `${OFFERS_URL}?postcode=${postcode}&is_private=1`,
    method: 'GET',
    xApiKey: OFFERS_API_KEY,
  })
}

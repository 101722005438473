import {FC, useState} from 'react'
import {Section} from '../../../../models/assessment/section'
import DisclosureItem from '../../../shared/elements/DisclosureItem'

type Props = {
  sections: Array<Section>
  loading?: boolean
}

const ProgressBreakdown: FC<Props> = ({sections, loading}) => {
  const [sectionOneToggler, switchSectionOneToggler] = useState(true)

  return (
    <>
      {loading ? (
        <>
          {[...Array(6)].map((_, index) => (
            <div key={index} className='mb-4'>
              <DisclosureItem disclosureCard disclosureCardSize='md' loading={loading} />
            </div>
          ))}
        </>
      ) : (
        <>
          {sections?.map((section) => {
            return (
              <div key={section.id} className='mb-4'>
                <DisclosureItem
                  disclosureCard
                  disclosureCardSize='md'
                  title={section?.section_name}
                  count={section?.score}
                  progress={true}
                >
                  <div className='pb-2'>
                    {section.section_items.map((item, index) => (
                      <div key={index} className='border-b-2 border-gray-200 last:border-b-0 mx-4'>
                        <div className='py-4'>
                          <div className='flex justify-between text-darkBlue mb-2'>
                            <p className='text-base'>{item.item_name}</p>
                            <p className='text-base font-bold'>{item.rate}</p>
                          </div>
                          <div aria-hidden='true'>
                            <div className='overflow-hidden rounded-full bg-gray-200'>
                              <div
                                className='h-3 rounded-full bg-green'
                                style={{width: (item.rate * 100) / 5 + '%'}}
                              />
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    ))}
                  </div>
                  
                </DisclosureItem>
              </div>
            )
          })}
        </>
      )}

      {sections.map((section) => {
        return (
          <div className='col-12 px-0 mb-3' key={section.id}>
            {/* <Accordion>
              <div className='accordion-item card'>
                <div className='accordion-header w-100 bg-white rounded' >
                  <div className='d-flex justify-content-between align-items-center py-5 px-3 w-100'>
                    <div className='d-flex align-items-center'>
                      <div className='job-card__hours'>
                        <div className='job-card__hours-circle w-40px h-40px'>
                          <span className='job-card__hours-num font-size-16'>{section.score}</span>
                        </div>
                      </div>
                      <h3 className='font-size-18 ms-3 d-flex align-baseline mb-0'>
                        {section.section_name}
                      </h3>
                    </div>
                    <Accordion.Toggle eventKey={section.section_name} className='btn btn-link py-1 px-0 w-50px' onClick={() => switchSectionOneToggler(!sectionOneToggler)}>
                      {sectionOneToggler ? <i className='fas fa-angle-down w-auto'></i> : <i className='fas fa-angle-up w-auto'></i>}
                    </Accordion.Toggle>
                  </div>
                </div>
                <Accordion.Collapse className='accordion-collapse' eventKey={section.section_name}>
                  <div className='border-top mb-8' >
                    {
                      section.section_items.map(item => {
                        return <div className='pt-6 px-5' key={item.section_item_id}>
                          <div className='d-flex justify-content-between'>
                            <p className="font-size-16">{item.item_name}</p>
                            <p className="font-size-16">{item.rate}</p>
                          </div>
                          <ProgressBar key={item.item_name} now={item.rate * 100 / 5} />
                        </div>
                      })
                    }
                  </div>
                </Accordion.Collapse>

              </div>
            </Accordion> */}
          </div>
        )
      })}
    </>
  )
}

export {ProgressBreakdown}

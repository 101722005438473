import * as React from 'react'
import {createContext, useContext, useState} from 'react'

export type SharedDataContextType = {
  data: any
  updateSharedData: (key: any, value: any) => void
}

export const defaultData: {selectedDate: Date} = {
  selectedDate: new Date(),
}
interface Props {
  children: React.ReactNode
}

export const SharedDataContext = createContext<SharedDataContextType | null>(null)

export const SharedDataProvider: React.FC<Props> = ({children}) => {
  const [sharedDataState, updateSharedDataState] = useState<any>(defaultData)
  const updateSharedData = (key: any, value: any = undefined) => {
    if (value === undefined) {
      updateSharedDataState((prev: any) => {
        return {
          ...prev,
          ...key,
        }
      })
      return false
    }
    updateSharedDataState((prevState: any) => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }
  return (
    <SharedDataContext.Provider value={{updateSharedData, data: sharedDataState}}>
      {children}
    </SharedDataContext.Provider>
  )
}

export const useSharedData = () => useContext(SharedDataContext) as SharedDataContextType

import {FC} from 'react'
import {Test} from '../../models/tests/test'
import {useIntl} from 'react-intl'
import InfoList, {InfoListData} from '../shared/data/InfoList'
type Props = {
  test: Test | undefined
}

const TestDetails: FC<Props> = ({test}) => {
  const mapStudentTestData = () => {
    const mappedData: InfoListData[] = []
    if (test?.location != null) {
      mappedData.push({
        value: (
          <div className='flex justify-between grow'> 
            <span className='font-normal'>
              {intl.formatMessage({id: 'STUDENT_TEST.TESTCENTREBOOKED'})}:
            </span>
            <span className='font-normal'>{test?.location?.name}</span>
          </div>
        ),
        icon: 'icon-location',
      })
    }
    if (test?.booked_for_human != null && test?.booked_for_human != '') {
      mappedData.push(
        {
          value: (
            <div className='flex justify-between grow'>
              <span className='font-normal'>
                {intl.formatMessage({id: 'STUDENT_TEST.TESTDATE'})}:
              </span>
              <span className='font-normal'>{test?.booked_for_human.split(' ').shift()}</span>
            </div>
          ),
          icon: 'icon-calendar',
        },
        {
          value: (
            <div className='flex justify-between grow'>
              <span className='font-normal'>
                {intl.formatMessage({id: 'STUDENT_TEST.TEST_TIME'})}:
              </span>
              <span className='font-normal'>{test?.booked_for_human.split(' ').pop()}</span>
            </div>
          ),
          icon: 'icon-history',
        }
      )
    }
    return mappedData
  }

  const intl = useIntl()
  return (
    <>
      <div className='bg-white border border-gray-600 rounded-[10px] pt-1 px-4 pb-1.5 mb-8'>
        <InfoList data={mapStudentTestData()}></InfoList>
      </div>
    </>
  )
}

export default TestDetails

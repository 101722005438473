export const formatPriceIntoTwoDigits = (number: any , allowGrouping = true) => {

    if(Number.isInteger(+number ?? 0)){
        return number ?? 0
    }
    return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping : allowGrouping
    }).format(number ?? 0);
}

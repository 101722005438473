import {FC, useEffect, useState} from 'react'
import Modal from '../../shared/overlays/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Test} from '../../../models/tests/test'
import {RejectionReason} from '../../../models/tests/rejection-reason'
import {getTestUnsuitableDateReasons, rejectTest} from '../../../services/test.service'
import {UnsuitableDateReason} from '../../../models/tests/unsuitable-date-reason'
import TextArea from '../../shared/forms/TextArea'
import {TestTypeEnum} from '../../../models/enums/test-type'
import Alert from '../../shared/overlays/Alert'
import TestDetails from '../TestDetails'
import CheckboxInputGroup from '../../shared/forms/CheckboxInputGroup'
import RadioInputGroup from '../../shared/forms/RadioInputGroup'
import {toAbsoluteUrl} from '../../../helpers'
import {toast} from 'react-hot-toast'
type Props = {
  test: Test | undefined
  rejectionReasons: RejectionReason[]
  show: any
  onHide: any
  onStatusSubmit: any
}

const RejectTest: FC<Props> = ({test, rejectionReasons, show, onHide, onStatusSubmit}) => {
  const intl = useIntl()
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [unsuitableDateReasons, setUnsuitableDateReasons] = useState<UnsuitableDateReason[]>()
  const validationSchema: any = Yup.object().shape({
    rejection_reasons: Yup.array().min(1, 'Rejection reason is required'),
    rejection_reasons_details: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'STUDENT_TEST.REJECT_COMMENT'})}
      )
    ),
    unsuitable_date_reason: Yup.string()
      .nullable()
      .when('rejection_reasons', {
        is: (rejection_reasons: RejectionReason[]) => {
          return rejection_reasons && rejection_reasons.some((x: any) => x == 2 || x == 5)
        },
        then: (schema) =>
          schema.required(
            intl.formatMessage(
              {id: 'VALIDATION.REQUIRED'},
              {field: intl.formatMessage({id: 'STUDENT_TEST.UNSUITABLE_DATE_LABEL'})}
            )
          ),
      }),
  })

  const formik = useFormik({
    initialValues: {
      rejection_reasons: [],
      rejection_reasons_details: '',
      unsuitable_date_reason: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      if (!test) return
      setErrorMessage('')
      setShowError(false)
      const res = await rejectTest(test.id, {
        reasons: values.rejection_reasons,
        notes: values.rejection_reasons_details,
        unsuitable_date_reason: values.rejection_reasons.some((x) => x == 2 || x == 5)
          ? values.unsuitable_date_reason
          : null,
      }).catch((error) => {
        setSubmitting(false)
        setShowError(true)
        if (!error?.errorMessage) toast.error('Failed to get reject test')
      })
      if (!res) return
      await onStatusSubmit()
      resetForm()
      setSubmitting(false)
      onHide()
    },
  })

  const hide = () => {
    setShowError(false)
    formik.resetForm()
    formik.setTouched({...formik.touched}, false)
    onHide()
  }

  const mapReasonOptions = (reasons: any) => {
    const options: any[] = []
    reasons.forEach((reason: any) => {
      let existingOption = options.find((option) => option.label === reason.name)
      if (existingOption) return
      if (reason.id === 1 || reason.id === 4) {
        options.push({
          value: reason.id,
          label: reason.name,
          img: toAbsoluteUrl('/assets/media/3d/location-map-pin.svg'),
        })
      } else if (reason.id === 2 || reason.id === 5) {
        options.push({
          value: reason.id,
          label: reason.name,
          img: toAbsoluteUrl('/assets/media/3d/calendar.svg'),
        })
      } else {
        options.push({
          value: reason.id,
          label: reason.name,
          img: toAbsoluteUrl('/assets/media/svg/rejection-reason/time.svg'),
        })
      }
    })
    return options
  }

  useEffect(() => {
    ;(async () => {
      const res = await getTestUnsuitableDateReasons().catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get test unsuitable date reasons')
      })
      if (!res) return
      setUnsuitableDateReasons(res)
    })()
  }, [])

  return (
    <>
      <Modal
        open={show}
        onClose={hide}
        onSubmit={formik.handleSubmit}
        title='Reject a test'
        submitText={intl.formatMessage({id: 'ACTIONS.REJECT'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        disabled={formik.isSubmitting}
        setMinHeight={false}
      >
        <h4 className='text-base font-bold mb-3'>
          {intl.formatMessage(
            {id: 'STUDENT_TEST.DOYOUWANTTO'},
            {
              option: intl.formatMessage({id: 'STUDENT_TEST.REJECT'}),
            }
          )}
        </h4>
        <div className='flex flex-col justify-between mt-0'>
          {showError && errorMessage && (
            <div>
              <Alert description={errorMessage} colour='red'></Alert>
            </div>
          )}
          <div className='mt-0'>
            <TestDetails test={test} />
          </div>

          <div className='mb-8'>
            <div className='flex flex-wrap justify-between'>
              <label className='text-base font-bold mb-3' htmlFor='reasons'>
                {intl.formatMessage({id: 'STUDENT_TEST.REASONOFREJECTION'})}
              </label>
            </div>
            <div className=''>
              <CheckboxInputGroup
                label=''
                name='rejection-reasons'
                options={mapReasonOptions(rejectionReasons)}
                onChange={(reason) => {
                  formik.setFieldValue('rejection_reasons', reason)
                }}
                values={formik.values.rejection_reasons}
                disabled={formik.isSubmitting}
                error={formik.touched.rejection_reasons && formik.errors.rejection_reasons != null}
                errorMsg={
                  Array.isArray(formik.errors.rejection_reasons)
                    ? formik.errors.rejection_reasons.join(', ')
                    : formik.errors.rejection_reasons ?? ''
                }
              />
            </div>
          </div>
          {formik.values.rejection_reasons?.filter((r) => r == 4).length > 0 && (
            <div className='-mt-5 pt-1 mb-8'>
              <Alert
                title={intl.formatMessage({id: 'STUDENT_TEST.NOT_WHAT_EXPECTED'})}
                description={intl.formatMessage({id: 'STUDENT_TEST.EXPECTED_TEXT'})}
                colour='pink'
              ></Alert>
            </div>
          )}
          {formik.values.rejection_reasons.some((x) => x == 2 || x == 5) &&
            unsuitableDateReasons != null && (
              <div className='mb-8'>
                <RadioInputGroup
                  name='unsuitable_date_reason'
                  label={intl.formatMessage({id: 'STUDENT_TEST.UNSUITABLE_DATE_QUESTION'})}
                  options={unsuitableDateReasons.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  onChange={(reason: any) => {
                    formik.setFieldValue('unsuitable_date_reason', reason)
                  }}
                  value={formik.values.unsuitable_date_reason}
                  disabled={formik.isSubmitting}
                  error={
                    formik.errors.unsuitable_date_reason != null &&
                    formik.touched.unsuitable_date_reason
                  }
                  errorMsg={
                    formik.values.unsuitable_date_reason === 'Date too late'
                      ? test?.type === TestTypeEnum.Theory
                        ? intl.formatMessage({id: 'STUDENT_TEST.TT_UNSUITABLE_DATE_MSG'})
                        : intl.formatMessage({id: 'STUDENT_TEST.PT_UNSUITABLE_DATE_MSG'})
                      : formik.errors.unsuitable_date_reason ?? ''
                  }
                />
              </div>
            )}
          {/* end::Form group */}

          <div className='mb-5'>
            <TextArea
              label={intl.formatMessage({id: 'STUDENT_TEST.FURTHER_DETAILS'})}
              name='details'
              maxLength={1000}
              onChange={(e) => formik.setFieldValue('rejection_reasons_details', e.target.value)}
              disabled={formik.isSubmitting}
              error={
                formik.errors.rejection_reasons_details != null &&
                formik.touched.rejection_reasons_details
              }
              errorMsg={formik.errors.rejection_reasons_details}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RejectTest

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {LessonFeedback} from '../../../models/feedback/lesson-feedback'
import {LessonFeedbackQuestions} from '../../../models/feedback/lesson-feedback-questions'
import {getLessonFeedbackQuestions, addLessonFeedback} from '../../../services/feedback.service'
import Modal from '../../shared/overlays/Modal'
import Alert from '../../shared/overlays/Alert'
import HorizontalCheckboxGroup from '../../shared/forms/HorizontalCheckboxGroup'
import RadioInputGroup from '../../shared/forms/RadioInputGroup'
import {Skeleton} from '@mui/material'
import moment from 'moment'
import InfoList from '../../shared/data/InfoList'
import toast from 'react-hot-toast'

interface IProps {
  dealId: any
  adi_id: any
  lesson_data: any
  job_id: any
  student_contact_id: any
  adi_contact_id: any
  show: any
  onHide: any
  refreshDashboard: () => void
  adiName: string
}

const LessonFeedbackModal: FC<IProps> = (props) => {
  const intl = useIntl()
  const [feedback, setFeedBack] = useState<LessonFeedback | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [lessonTime, setLessonTime] = useState<string>('')
  const [lessonDateFormatted, setLessonDateFormatted] = useState<string>('')

  useEffect(() => {
    const fetchData = async () => {
      if (props.show === true) {
        setLoading(true)
        try {
          const response = await getLessonFeedbackQuestions()
          setFeedBack(response.results)
          updateFormValues(response.results)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      }
    }
    fetchData()
  }, [props.show])

  useEffect(() => {
    if (props?.lesson_data?.lesson_date) {
      const lessonDate = moment(props?.lesson_data?.lesson_date)
      setLessonDateFormatted(lessonDate.format('MM/DD/YYYY'))
      const duration = props?.lesson_data.duration
      const start = lessonDate.format('HH:mm')
      const end = lessonDate.add(duration, 'hours').format('HH:mm')
      setLessonTime(`${start} - ${end}`)
    }
  }, [props?.lesson_data])

  const updateFormValues = (feedback: LessonFeedback) => {
    let items: Array<LessonFeedbackQuestions> = []
    feedback.lessons_feedback_items.forEach((item) => {
      items.push({
        lesson_feedback_question_id: item.lesson_feedback_question_id,
        question_name: item.question_name,
        rate: item.rate,
      })
    })
    formik.setFieldValue('lessons_feedback_items', items)
    formik.setFieldValue('adi_contact_id', props.adi_contact_id)
    formik.setFieldValue('adi_id', props.adi_id)
    formik.setFieldValue('lesson_id', props.lesson_data?.id)
    formik.setFieldValue('job_id', props.job_id)
    formik.setFieldValue('student_contact_id', props.student_contact_id)
    formik.setFieldValue('zendesk_deal_id', props.dealId)
  }

  const validationSchema = Yup.object().shape({
    adi_contact_id: Yup.string().required(),
    adi_id: Yup.string().required(),
    job_id: Yup.string().required(),
    lesson_id: Yup.string().required(),
    student_contact_id: Yup.string().required(),
    zendesk_deal_id: Yup.string().required(),
    driver_commitment: Yup.boolean().required(
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'QUESTIONNAIRE.MEDICALCONDITIONS.MEDICAL_CONDITION'})}
      )
    ),
    lessons_feedback_items: Yup.array().of(
      Yup.object().shape({
        lesson_feedback_question_id: Yup.number().required(),
        rate: Yup.number()
          .required(
            intl.formatMessage(
              {id: 'VALIDATION.REQUIRED'},
              {
                field: intl.formatMessage({
                  id: 'QUESTIONNAIRE.MEDICALCONDITIONS.MEDICAL_CONDITION',
                }),
              }
            )
          )
          .min(1, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 1}))
          .max(5, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 5})),
      })
    ),
  })

  const formik = useFormik({
    validationSchema,
    initialValues: {
      adi_contact_id: props.adi_contact_id,
      adi_id: props.adi_id,
      lesson_id: props.lesson_data?.id,
      job_id: props.job_id,
      student_contact_id: props.student_contact_id,
      zendesk_deal_id: props.dealId,
      driver_commitment: true,
      lessons_feedback_items: feedback?.lessons_feedback_items ?? [],
    },
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      const response = await addLessonFeedback(values).catch((error) => {
        setSubmitting(false)
        if (!error?.errorMessage) {
          toast.error(intl.formatMessage({id: 'GENERAL.ERROR'}))
        }
      })
      if (!response) {
        return
      }
      resetForm()
      setIsSubmitted(true)
      props.refreshDashboard()
      props.onHide()
    },
  })

  const rates = Array.from([1, 2, 3, 4, 5]).map((rate) => {
    return {
      value: rate,
      label: rate + '',
    }
  })

  const handleClose = () => {
    props.onHide()
  }

  const rateChanged = (checked: boolean, rate: number, key: number) => {
    if (formik.isSubmitting) {
      return
    }
    const updatedFeedbackItems = formik.values.lessons_feedback_items
    if (updatedFeedbackItems) {
      if (checked) {
        updatedFeedbackItems[key].rate = rate
      } else {
        updatedFeedbackItems[key].rate = 0
      }
    }
    formik.setFieldValue('lessons_feedback_items', updatedFeedbackItems)
  }

  return (
    <>
      <Modal
        open={props.show}
        title={`${intl.formatMessage({
          id: 'LESSON_FEEDBACK.RATE',
        })}`}
        onClose={handleClose}
        onSubmit={formik.handleSubmit}
        submitText={intl.formatMessage({id: 'ACTIONS.SUBMIT'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        className='w-500px'
        disabled={formik.isSubmitting}
      >
        {!isSubmitted && (
          <>
            <form
              onSubmit={formik.handleSubmit}
              className='d-flex flex-column justify-content-between h-100'
            >
              <div className='flex-grow pt-4 pb-8'>
                {loading ? (
                  <div className='flex justify-center'>
                    <Skeleton width={80} />
                  </div>
                ) : (
                  <>
                    <InfoList
                      data={[
                        {
                          icon: 'icon-instructor',
                          value: (
                            <div className='flex justify-between grow'>
                              <span className='font-normal'>Instructor:</span>
                              <span className='font-normal'>{props?.adiName ?? '-'}</span>
                            </div>
                          ),
                        },
                        {
                          icon: 'icon-calendar',
                          value: (
                            <div className='flex justify-between grow'>
                              <span className='font-normal'>Date:</span>
                              <span className='font-normal'>{lessonDateFormatted}</span>
                            </div>
                          ),
                        },
                        {
                          icon: 'icon-history',
                          value: (
                            <div className='flex justify-between grow'>
                              <span className='font-normal'>Time:</span>
                              <span className='font-normal'>{lessonTime}</span>
                            </div>
                          ),
                        },
                      ]}
                    />
                    <Alert colour='green' icon='pmf-icon-info'>
                      <span className='text-sm'>
                        Select from <b>1-5</b> where <b>1</b> is Strongly Disagree and <b>5</b> is
                        Strongly Agree
                      </span>
                    </Alert>

                    {formik.values.lessons_feedback_items?.map(
                      (item: LessonFeedbackQuestions, index: number) => {
                        return (
                          <div
                            className='mb-5 last:mb-0 px-3 last:pb-4'
                            key={item.lesson_feedback_question_id + '-' + index}
                          >
                            <p className='text-base font-semibold mb-2'>{item.question_name}</p>

                            <div className='w-full'>
                              <HorizontalCheckboxGroup
                                values={[item.rate]}
                                onChange={(e: any, value: number) => {
                                  rateChanged(e.target.checked, value, index)
                                }}
                                name={item.lesson_feedback_question_id + ''}
                                options={rates}
                                disabled={formik.isSubmitting}
                              />
                            </div>
                          </div>
                        )
                      }
                    )}

                    <div className='mt-4 border-top pt-4'>
                      <label className='text-base font-bold text-darkBlue mb-0' htmlFor='reasons'>
                        {intl.formatMessage({id: 'LESSON_FEEDBACK.DRIVER_COMMITMENT'})}
                      </label>
                      <RadioInputGroup
                        fullWidth
                        options={[
                          {value: true, label: 'Yes'},
                          {value: false, label: 'No'},
                        ]?.map((option: any) => {
                          return {
                            value: option.value,
                            label: (
                              <>
                                <div className='flex items-center'>
                                  <span className='documentt_type_name ml-3'>{option.label}</span>
                                </div>
                              </>
                            ),
                          }
                        })}
                        disabled={formik.isSubmitting}
                        label={''}
                        name={'driver_commitment'}
                        onChange={(value: any) => {
                          formik.setFieldValue('driver_commitment', value)
                        }}
                        value={formik.values.driver_commitment}
                      ></RadioInputGroup>
                    </div>
                  </>
                )}
              </div>
            </form>
          </>
        )}
      </Modal>
    </>
  )
}

export {LessonFeedbackModal}

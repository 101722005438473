import React from "react";
import { toAbsoluteUrl } from "../../../helpers";
import Button from "../elements/Button";
import Skeleton from "@mui/material/Skeleton";

export interface StackedListProps extends React.HTMLAttributes<HTMLDivElement> {
  data: {
    imgURL?: string;
    title?: string;
    description?: string;
    status?: string;
    statusColour?: "darkGreen" | "yellow" | "darkRed";
    value?: string;
    valueDesc?: string;
    onCardClicked?: any;
    showButton?: any;
    buttonDisabled?: boolean;
    onButtonClicked?: any;
    buttonText?: string;
    pmfIcon?: boolean;
    loading?: boolean | undefined;
    colour?:"pink" | "darkBlue" | "outline" | "gray" | "link" | "dangerLink" | "lightGray" | "transparent" | "darkGray" | "outlineWhite",
    hasError?:any ,
    error?:any
  }[];
}

export default function StackedList({
  data
}: StackedListProps) {
  return (
    <>
      <ul className="grid grid-cols-1 gap-y-2">
        {data.map((item, i) => (
          <>
          <li key={i} className={`flex justify-between items-center bg-gray-100 px-3 rounded ${item.hasError ? 'border border-red-400' : ''} `}  onClick={item?.onCardClicked}>
            <div className="flex justify-between items-center gap-x-6 py-3">
              <div className="flex min-w-0 gap-x-4">
                {item.loading ?
                  <div className="flex item-center">
                    <Skeleton width={50} height={50} className="me-2" variant="circular" />
                  </div> :

                  item.imgURL && <img
                    className="h-8 w-8 flex-none"
                    src={toAbsoluteUrl(item.imgURL)}
                    alt=""
                  />

                }

                <div className="min-w-0 flex-auto">
                  <div className="min-w-0 flex-1 flex items-center">
                    <p className="text-base font-bold leading-6">
                      {item.loading ? <>
                        <Skeleton width={160} />
                      </> :
                        <>   {item.title} </>
                      }
                    </p>
                    {item.pmfIcon && <img src={toAbsoluteUrl('/assets/media/logos/pmf.svg')} className='h-4 ms-2' alt="pmf-icon" />}
                  </div>

                  <p className="text-xs leading-5">
                    {item.loading ? <>
                      <Skeleton width={150} />
                    </> :
                      <time dateTime={item.description}> {item.description} </time>
                    }
                  </p>

                  <div className="flex items-center gap-x-1.5">
                    <div className="flex-none rounded-full">
                      {item.loading ? <>
                        <Skeleton width={100} />
                      </> :
                        item.statusColour && <div
                          className={`
                        ${{
                              darkGreen: "bg-green",
                              yellow: "bg-yellow",
                              darkRed: "bg-danger",
                            }[item.statusColour]
                            } 
                        h-1.5 w-1.5 rounded-full`}
                        />}
                    </div>
                    <p className="text-xs leading-5">{item.status}</p>
                  </div>

                </div>
              </div>
              <div className="shrink-0 flex flex-col items-end">
                <p className="text-base leading-6">
                  {item.loading ? <>
                    <Skeleton width={50} />
                  </> :
                    item.value
                  }
                </p>
                {item.loading ? <>
                  <Skeleton width={50} />
                </> :
                  item.valueDesc && (
                    <p className="text-sm leading-5 text-[#828282]">
                      {item.valueDesc}
                    </p>
                  )
                }
              </div>
            </div>
            {item.showButton === true ?
              <div className="">
                {
                item.loading? <Skeleton width={100} height={40} />:
                <Button
                  disabled={item?.buttonDisabled}
                  onClick={item?.onButtonClicked}
                  colour={item.colour}
                  fitWidth
                >
                  {item?.buttonText}
                </Button>
                }
              </div>
            : <></>}
            

          </li>
          {item.hasError && item.error && (
              <div>
                <p className='text-sm text-red-600' id='error'>
                  { item.error}
                </p>
              </div>
            )}
            
          </>
        ))}
      </ul>
    </>
  );
}

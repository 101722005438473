import { ChangeEvent } from "react";
import Skeleton from '@mui/material/Skeleton';

interface IProps {
    name: string
    label?:  React.ReactNode;
    options?: Array<{ value: any, label: any }>
    onChange: (array: Array<any> | ChangeEvent<HTMLInputElement>) => void;
    values?: Array<any>
    required: boolean
    disabled?: boolean
    error?: boolean;
    errorMsg?: string;
    isSingleCheckbox?: boolean;
    checked?: number | boolean
    classNameOpt?: string
    loading?:boolean
}
export default function CheckboxInput({
    name,
    label,
    values = [],
    options = [],
    onChange,
    required = false,
    disabled = false,
    error = false,
    errorMsg = "This field is required",
    isSingleCheckbox,
    checked,
    classNameOpt,
    loading=false,
}: IProps) {
    const toggleSelected = (e: any, data: any) => {
        if (e.target.checked) {
            values = [...values, data];
        } else {
            values = values.filter((elem: any) => elem != data);
        }
        onChange(values); // This fires the new selected array back to the parent
    };
    const checkbox = (option: { value: any, label: any }, index: number) => {
        return (
            <div key={index} className='flex items-center mb-1'>
                {loading ? <>
                    <Skeleton width={20} height={20} className="me-2" />
                    <Skeleton width={60} className="me-2" />
                </>
                    : <>
                        <label htmlFor={name + index} className='flex items-start text-base font-medium leading-6 text-darkBlue'>
                        <input
                        className='checked:accent-[#163A5E] me-1 w-4 h-4 min-w-[16px] mt-1'
                        type='checkbox'
                        name={name + index}
                        id={name + index}
                        checked={isSingleCheckbox ? !!checked : values?.indexOf(option.value) !== -1}
                        onChange={(e) => isSingleCheckbox ? onChange(e) : toggleSelected(e, option.value)}
                        data-parsley-multiple={name}
                        disabled={disabled}
                        required={required}
                        />
                            {option.label}
                        </label></>
                }

            </div>
        )
    }
    return (
        <>
            <div className='flex flex-col mt-1'>
                {!isSingleCheckbox && <label htmlFor="has_brand_advertisement" className='block text-base font-bold leading-6 text-darkBlue mb-3'>
                    {
                        loading ? <Skeleton width={60} className="me-2" /> : <>
                            {label}
                            {required && <span className='text-sm text-red-500'>*</span>}
                        </>
                    }
                   
                </label>}
                <div className={classNameOpt}>
                    {options?.map((option, index) => {
                        return <div key={index}>
                            {checkbox(option, index)}
                        </div>
                    })}
                </div>
                {isSingleCheckbox &&
                    <>
                        {checkbox({ label: label, value: null }, 0)}
                    </>
                }
                {error && (
                    <p className="text-sm text-red-600" id="error">
                        {errorMsg}
                    </p>
                )}
            </div>
        </>
    )
}
import {FC, useState} from 'react'
import Modal from '../../shared/overlays/Modal'
import {Lesson} from '../../../models/lessons/lesson'
import {LessonStatusEnum} from '../../../models/enums/lesson-status-enum'
import {cancelLesson} from '../../../services/lesson.service'
import Alert from '../../shared/overlays/Alert'

type IProps = {
  open: boolean
  onClose: any
  lesson: Lesson
}
const CancelLessonModal: FC<IProps> = ({onClose, open, lesson}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const cancelStudentLesson = async () => {
    setIsSubmitting(true)
    const response = await cancelLesson(
      {status_id: lesson?.cancellation_type},
      lesson?.id ?? ''
    ).catch(() => {})
    setIsSubmitting(false)
    if (!response) {
      return
    }
    onClose({reloadLessons: true})
  }

  const cancellationDescriptionMsg = () => {
    return lesson?.cancellation_type === LessonStatusEnum.CancelledNotChargeable
      ? `You're about to cancel your driving lesson on ${lesson?.lesson_date_str}. You've given us enough notice, so you'll keep the hours for future lessons.`
      : `You're about to cancel the lesson on ${lesson?.lesson_date_str}. The hours for this lesson will be removed from your course, as your instructor requires ${lesson?.adi_cancellation_policy}  hours' notice for cancelling or rescheduling lessons.`
  }

  return (
    <Modal
      open={open}
      title='Cancel Lesson'
      onClose={onClose}
      onSubmit={cancelStudentLesson}
      submitText='Yes'
      closeText='No'
      className='w-500px'
      description={cancellationDescriptionMsg()}
      disabled={isSubmitting}
    >
      <div className='max-w-[450px] text-sm px-8 mb-4 mt-4 font-medium'>
        Are you sure you want to cancel the lesson?
      </div>
    </Modal>
  )
}

export default CancelLessonModal

import {FC} from 'react'
import {CheckCircleIcon} from '@heroicons/react/24/outline'
import CircularProgress from '@mui/joy/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import Badge from '../shared/elements/Badge'
import {convertToTimeFormat} from '../../helpers/DateHelper'

interface ILessonsProgressProps {
  remainingHours: any
  scheduled: any
  courseHours: any
  progress: any
  loading: any
}
export const LessonsProgress: FC<ILessonsProgressProps> = ({
  remainingHours,
  scheduled,
  courseHours,
  progress,
  loading,
}) => {
  return (
    <>
      <div className='bg-gradient-to-br bg-gradient-to-30 from-green via-darkBlue to-green flex items-center justify-between rounded-[20px] text-white p-5'>
        <div>
          <h2 className='text-base font-bold'>
            {loading ? (
              <>
                {' '}
                <Skeleton width={150} className='me-2' />
              </>
            ) : (
              'Remaining hours:'
            )}
          </h2>
          <div className='flex items-center my-2'>
            {loading ? (
              <div className='flex item-center'>
                <Skeleton width={20} className='me-2' />

                <Skeleton width={150} className='me-2' />
              </div>
            ) : (
              <>
                <Badge
                  roundedPill={false}
                  title={
                    +remainingHours === 0 ? '0 Hour' : convertToTimeFormat(remainingHours * 60)
                  }
                  colour='white'
                ></Badge>{' '}
                <span className='text-xs ms-1'>left to go </span>
              </>
            )}
          </div>
          <p className='flex items-center'>
            {loading ? (
              <span className='flex item-center'>
                <Skeleton width={20} className='me-2' />

                <Skeleton width={150} className='me-2' />
              </span>
            ) : (
              <>
                <CheckCircleIcon className='w-4 h-4' />
                <span className='text-xs ms-1'>
                  {convertToTimeFormat(scheduled * 60) === ''
                    ? '0'
                    : convertToTimeFormat(scheduled * 60)}{' '}
                  out of {convertToTimeFormat(courseHours * 60)} booked
                </span>
              </>
            )}
          </p>
        </div>

        <>
          {loading ? (
            <Skeleton width={100} height={100} className='me-2' variant='circular' />
          ) : (
            <CircularProgress
              determinate
              sx={{
                '--CircularProgress-size': '90px',
                color: '#fff',
                '--CircularProgress-linecap': 'square',
                '--CircularProgress-progressColor': '#32C3D7',
                '--CircularProgress-trackColor': '#DADADA',
              }}
              value={Math.round(progress)}
              thickness={10}
            >
              <span className=' font-bold text-2xl'>{Math.round(progress)}%</span>
            </CircularProgress>
          )}
        </>
      </div>
    </>
  )
}

import toast from 'react-hot-toast'
import {BaseResponse} from '../models/base-response'
import {Auth} from 'aws-amplify'
const DOCUMENTS_URL = process.env.REACT_APP_DOCUMENTS_BASE_URL
export const UPLOAD_DOCUMENT_URL = `${DOCUMENTS_URL}/student-upload`

export async function uploadDocument(document: any): Promise<BaseResponse<string>> {
  var formData = new FormData()
  formData.append('file', document)
  const authResponse = await Auth.currentSession()
  var token = authResponse.getIdToken().getJwtToken()
  const headers: any = {
    Authorization: `Bearer ${token}`,
  }
  const impersonatorToken = localStorage.getItem(`impersonator-token`)
  if (impersonatorToken) {
    headers['Impersonator-Token'] = impersonatorToken
  }
  const response = await fetch(UPLOAD_DOCUMENT_URL, {
    method: 'POST',
    headers: headers,
    body: formData,
  })
  if (response.ok) {
    return response.json()
  } else {
    let jsonError = await response.json()
    if (jsonError?.errors?.length) {
      toast.error(jsonError?.errors[0].errorMessage)
    } else {
      toast.error('Sorry,an error occured while uploading file')
    }
    throw jsonError?.errors?.length ? jsonError?.errors[0] : jsonError
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */

import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {ForgotPassword} from './ForgotPassword'
import {ImpersonateTransit} from './ImpersonateTransit'
import {ImpersonatedLogin} from './ImpersonatedLogin'
import {Login} from './Login'
import {Registration} from './Registration'
import {ResetPassword} from './ResetPassword'
import {toAbsoluteUrl} from '../../helpers'

export function AuthPage() {
  let location = useLocation()
  let returnUrl = location.search
  if (location.search.includes('_gl=')) {
    location.search = ''
  }
  if (!location.search.includes('returnUrl')) {
    returnUrl = 'returnUrl=' + location.pathname + location.search
  }
  return (
    <>
      <div className='flex h-screen justify-center items-center'>
        <div className='flex flex-col content-center py-10 px-6 lg:px-4 lg:py-20 lg:w-[640px]'>
          <div className='flex content-between w-100 mb-4'>
            <div className='grid grid-cols-2 gap-8 items-center'>
              <img
                alt='ADI Network Logo'
                src={toAbsoluteUrl('/assets/media/logos/PMF-logo-green.png')}
                className='w-full'
              />
              <img
                src={toAbsoluteUrl(
                  `/assets/media/misc/intelligent-instructor-awards-2023-logo-bronze.png`
                )}
                alt='Intelligent Instructor Awards 2023 Bronze - Professional Support Provider of the Year'
                className='w-full'
              />
            </div>
          </div>

          <div className='lg:w-[500px] bg-white rounded sm:shadow p-10 lg:py-10 lg:px-15'>
            {/* begin::Wrapper */}
            <Routes>
              <Route path='*' element={<Navigate to={'/auth/login?' + returnUrl} replace />} />
              <Route path='auth'>
                <Route path='login' element={<Login />} />
                <Route path='admin-login' element={<ImpersonatedLogin />} />
                <Route path='registration/:token' element={<Registration />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route path='reset-password' element={<ResetPassword />} />
                <Route path='transition' element={<ImpersonateTransit />} />
              </Route>
            </Routes>
            {/* end::Wrapper */}
          </div>
        </div>
      </div>
    </>
  )
}

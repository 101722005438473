import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import * as auth from '../../../../setup/redux/AuthRedux'
import {checkEmailExists, login} from '../../services/auth.service'
import Input from '../../components/shared/forms/Input'
import Button from '../../components/shared/elements/Button'
import Alert from '../../components/shared/overlays/Alert'
import LinkItem from '../../components/shared/elements/LinkItem'
import toast from 'react-hot-toast'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [showError, setShowError] = useState(false)
  const email = 'support@passmefast.com'
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      setShowError(false)
      const response = await checkEmailExists(values.email).catch((err: any) => {
        setLoading(false)
        setSubmitting(false)
        toast.error(err?.message ?? 'Email is not exist')
      })
      if (response?.results === true) {
        const loginResponse = await login(values.email, values.password).catch((err) => {
          setLoading(false)
          setSubmitting(false)
          toast.error(err?.message ?? 'Something went wrong while login')
        })
        setLoading(false)
        if (loginResponse) {
          dispatch(auth.actions.setIsLogin(true))
          dispatch(auth.actions.login())
        }
      } else {
        setShowError(true)
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {showError && (
          <Alert colour='pink'>
            <span className='text-sm'>
              Sorry, your email wasn’t recognised. The Student Portal is only available for current
              PassMeFast customers. If you’re a current customer and booked before 1st July 2022 —
              please email your enquiry to &nbsp;
              <div className='p-0 m-0 font-bold'>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            </span>
          </Alert>
        )}

        <Input
          {...formik.getFieldProps('email')}
          label='Email address'
          placeholder='you@example.com'
          type='email'
          name='email'
          required={true}
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMsg={formik.errors.email}
          disabled={formik.isSubmitting}
        />

        <Input
          {...formik.getFieldProps('password')}
          type='text'
          label='Password'
          placeholder='********'
          error={formik.touched.password && formik.errors.password ? true : false}
          errorMsg={formik.errors.password}
          required={true}
          name='password'
          disabled={formik.isSubmitting}
          id='password'
          eyeIcon={true}
        />

        <LinkItem url='/auth/forgot-password'>Forgot Password?</LinkItem>

        {/* end:: Password form group */}

        {/* begin::Action */}
        <Button
          type='submit'
          id='kt_sign_in_submit'
          className='mt-2'
          disabled={formik.isSubmitting}
          fullWidth={true}
          size='large'
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </form>
    </>
  )
}

import {useDispatch} from 'react-redux'
import * as auth from '../../../../setup/redux/AuthRedux'
import {CockpitImpersonate} from '../../services/auth.service'
import Alert from '../../components/shared/overlays/Alert'
import {useEffect, useState} from 'react'

export const IMPERSONATOR_TOKEN = `impersonator-token`

export function ImpersonateTransit() {
  const [errorMessage, setErrorMessage] = useState<string>()
  const dispatch = useDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const impersonatorToken = urlSearchParams.get('impersonatorToken')
  const userToken = urlSearchParams.get('token')
  const setToken = async () => {
    if (impersonatorToken) {
      localStorage.setItem(IMPERSONATOR_TOKEN, impersonatorToken)
      sessionStorage.setItem("isSessionOpened", "true")
      const response = await CockpitImpersonate(userToken).catch((err) => {
         if (!err?.errorMessage) setErrorMessage('Something went wrong')
      })
      if (response) {
        dispatch(auth.actions.setIsLogin(true))
        dispatch(auth.actions.login())
      }
    }
  }
  useEffect(() => {
    setToken()
  })

  return (
    <>
      {errorMessage && <Alert colour='red' description={errorMessage}></Alert>}
      <div className='text-center mb-6'>
        <h1 className='text-darkBlue mb-3'>Redirecting to student's account</h1>
      </div>
    </>
  )
}

export class StudentAddress {
    constructor(address_type_id: number) {
        this.address_type_id = address_type_id;
    }
    id: string | null = null;
    street_address: string = "";
    postal_code: string = "";
    city_name: string = "";
    city_id: string | null = null;
    lat?: number;
    long?: number;
    address_type_id: number | null = null;
    is_copied_from_licence: boolean = false;
    is_pending_payment?: boolean = false;
}

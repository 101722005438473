import {FC} from 'react'
import {useIntl} from 'react-intl'
import Alert from '../overlays/Alert'

const PendingAdiTestAlert: FC = () => {
  const intl = useIntl()

  return (
    <Alert description={intl.formatMessage({id: 'STUDENT_TEST.PENDING_ADI'})} colour="red"/>
  )
}

export {PendingAdiTestAlert}

import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import {DayModel} from '../models/student/day-model'

const API_URL = process.env.REACT_APP_BASE_URL
const Days_URL = 'days'

export async function getDays(): Promise<BaseResponse<Array<DayModel>>> {
  return await modifiedFetch({
    url: `${API_URL}/${Days_URL}`,
    method: 'GET',
  })
}

import {FC} from 'react'
import ReactPlayer from 'react-player'
import Modal from '../../shared/overlays/Modal'

type Props = {
  vimeoId: string
  title: string
  show: boolean
  onHide: any
}

const VideoModal: FC<Props> = ({vimeoId, title, show, onHide}) => {
  return (
    <>
      <Modal open={show} title={title} onClose={onHide} hideSubmit>
        <ReactPlayer
          playing
          url={`https://player.vimeo.com/video/${vimeoId}?h=97beebe64c&amp;badge=0&amp;player_id=0&amp;app_id=58479`}
          width='100%'
          controls={true}
          allow='fullscreen; picture-in-picture'
          key='vimeo'
          config={{
            vimeo: {
              title: title,
            },
          }}
        />
      </Modal>
    </>
  )
}

export {VideoModal}

import {signOut} from '../../app/content/services/auth.service'
import * as auth from '../redux/AuthRedux'
import {setHeader} from './TokenSetup'
import toast from 'react-hot-toast'

export default async function modifiedFetch({
  url,
  method,
  xApiKey,
  data,
}: {
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  xApiKey?: string
  data?: any
}) {
  const headers = await setHeader(xApiKey)
  const response = await fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify(data),
  })
  if (response.status === 204) {
    return response
  } else if (response.ok) {
    return response.json()
  } else {
    let jsonError = await response.json()
    if (response.status === 401) {
      window.location.href = '/'
      auth.actions.logout()
      signOut()
    }
    if (jsonError?.errors?.length) {
      toast.error(jsonError?.errors[0].errorMessage)
    }
    throw jsonError?.errors?.length ? jsonError?.errors[0] : jsonError
  }
}

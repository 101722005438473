import {useState} from 'react'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
import * as auth from '../../../../setup/redux/AuthRedux'
import {useIntl} from 'react-intl'
import {
  checkForgetPasswordEmailExists,
  requestPassword,
  resendValidationToken,
} from '../../services/auth.service'
import Input from '../../components/shared/forms/Input'
import Button from '../../components/shared/elements/Button'
import Alert from '../../components/shared/overlays/Alert'
import LinkItem from '../../components/shared/elements/LinkItem'
import toast from 'react-hot-toast'

export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = {
    email: '',
  }

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage(
          {id: 'VALIDATION.INVALID'},
          {field: intl.formatMessage({id: 'FORGOT_PASSWORD.EMAIL'})}
        )
      )
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'FORGOT_PASSWORD.EMAIL'})}
        )
      ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      setHasErrors(undefined)
      const response = await checkForgetPasswordEmailExists(values.email).catch((err) => {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        toast.error(err?.message ?? 'Email is not exist')
      })
      if (
        response.results.cognitoRegisteredStudent === true &&
        response.results.emailExists === true
      ) {
        const requestPasswordResponse = await requestPassword(values.email).catch((err) => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          if (err?.message) toast.error(err.message)
        })
        if (requestPasswordResponse) {
          setHasErrors(false)
          setLoading(false)
          setSubmitting(false)
          dispatch(auth.actions.forgotPassword(values.email))
          navigate('/auth/reset-password')
        }
      } else if (
        response.results.cognitoRegisteredStudent === false &&
        response.results.emailExists === true
      ) {
        await resendValidationToken(response.results.jwtToken)
        setLoading(false)
        setSubmitting(false)
        setHasErrors(true)
        setStatus(
          'If there is an account linked to this address then we have just sent you an email'
        )
      } else {
        setLoading(false)
        setSubmitting(false)
        setHasErrors(true)
        setStatus(
          'If there is an account linked to this address then we have just sent you an email'
        )
      }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-darkBlue mb-3'>
            {' '}
            {intl.formatMessage({id: 'FORGOT_PASSWORD.TITLE'})}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'FORGOT_PASSWORD.ENTER_EMAIL'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <Alert description={intl.formatMessage({id: 'FORGOT_PASSWORD.ERRORS'})} colour='red' />
        )}

        {hasErrors === false && (
          <Alert
            description={intl.formatMessage({id: 'FORGOT_PASSWORD.CHECK_MAIL'})}
            colour='pink'
          />
        )}
        {/* end::Title */}

        <Input
          {...formik.getFieldProps('email')}
          label='Email address'
          placeholder='you@example.com'
          type='email'
          name='email'
          required={true}
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMsg={formik.errors.email}
          disabled={formik.isSubmitting}
        ></Input>

        <Button disabled={formik.isSubmitting} type='submit' fullWidth={true} size='large'>
          {!loading && (
            <span className='indicator-label'> {intl.formatMessage({id: 'ACTIONS.SUBMIT'})}</span>
          )}
          {loading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'ACTIONS.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>

        <LinkItem url='/auth/login'>
          <Button
            colour='link'
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            disabled={formik.isSubmitting}
            fullWidth={true}
          >
            {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
          </Button>
        </LinkItem>
      </form>
    </>
  )
}

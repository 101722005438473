import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {TestStatusTypeEnum} from '../../../../models/enums/test-status-type'
import {Test} from '../../../../models/tests/test'
import {TestTypeEnum} from '../../../../models/enums/test-type'
import Button from '../../../shared/elements/Button'
import AddTest from '../../../tests/_modals/AddTest'

type Props = {
  hasTheoryTest: boolean
  test: Test | undefined
  getStudentBookingList: any
  theoryTestCenters: any
  disableAction?: any
  dealId: string
  canAddPassMeTheory: any
  stepHasContent: boolean
  preferredTestCenters: Array<any>
}

const ArrangeTheoryTest: FC<Props> = ({
  preferredTestCenters,
  hasTheoryTest,
  test,
  getStudentBookingList,
  theoryTestCenters,
  disableAction,
  dealId,
  canAddPassMeTheory,
  stepHasContent,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [showTestModal, setShowTestModal] = useState(false)
  const [testCenters, setTestCenters] = useState<Array<string>>([])
  useEffect(() => {
    if (preferredTestCenters) {
      const testCenterNames = preferredTestCenters
        .filter((item) => item.deleted_at === null)
        .map((item) => item.test_centre.test_center_name)
      setTestCenters(testCenterNames)
    }
  }, [preferredTestCenters])
  return (
    <>
      <AddTest
        onTestSubmit={getStudentBookingList}
        practicalTestCenters={[]}
        theoryTestCenters={theoryTestCenters}
        initialTestType={TestTypeEnum.Theory}
        show={showTestModal}
        booking_id={+dealId}
        onHide={() => {
          setShowTestModal(false)
        }}
        saveTest={true}
        hidePassed={false}
        title={intl.formatMessage(
          {id: 'STUDENT_TEST.TEST_DETAILS_HAVE_BEEN_BOOKED'},
          {type: 'theory'}
        )}
      />
      {!hasTheoryTest &&
        (test === undefined ||
          test?.status === TestStatusTypeEnum.Rejected ||
          test?.status === TestStatusTypeEnum.PendingReschedule ||
          test?.status === TestStatusTypeEnum.NotApplicable ||
          test?.status === TestStatusTypeEnum.Rebooked ||
          test?.status === TestStatusTypeEnum.Cancelled) && (
          <>
            <h3 className='text-base font-semibold mb-0 mt-2'>
              {intl.formatMessage({id: 'STUDENT_TEST.BOOKTESTYOURSELF'}, {type: 'theory'})}
            </h3>
            <div className='flex mt-3'>
              <Button
                colour='pink'
                disabled={disableAction}
                type='button'
                onClick={() => {
                  setShowTestModal(true)
                }}
              >
                {intl.formatMessage({id: 'STUDENT_TEST.ADDYOURTEST'})}
              </Button>
            </div>
          </>
        )}

      {/* START:: Scenario 1 */}
      {((hasTheoryTest &&
        (test === undefined ||
          test?.status === TestStatusTypeEnum.Rejected ||
          test?.status === TestStatusTypeEnum.PendingReschedule ||
          test?.status === TestStatusTypeEnum.NotApplicable ||
          test?.status === TestStatusTypeEnum.Rebooked ||
          test?.status === TestStatusTypeEnum.Cancelled)) ||
        (test?.status === TestStatusTypeEnum.Failed && !test?.abilities.can_rebook)) && (
        <>
          <div className='py-[1px]text-base text-darkBlue'>
            <p>{intl.formatMessage({id: 'STUDENT_TEST.FAST_TRACKED_NOTE'})}</p>
            <ul>
              {testCenters.map((testCenter, i) => {
                return (
                  <b key={i}>
                    <li className='mb-1' key={i}>
                      {testCenter}
                    </li>
                  </b>
                )
              })}
            </ul>
            <p>{intl.formatMessage({id: 'STUDENT_TEST.TEST_CENTER_MESSAGE_3'})}</p>
          </div>
        </>
      )}
      {/* END:: Scenario 1 */}

      {stepHasContent && canAddPassMeTheory && (
        <div className='flex mt-3'>
          <Button
            colour='outline'
            type='button'
            onClick={() => {
              navigate('/addons')
            }}
          >
            {intl.formatMessage({id: 'STUDENT_TEST.NEEDSUPPORT'})}
          </Button>
        </div>
      )}
    </>
  )
}
export {ArrangeTheoryTest}

import {FC} from 'react'
import {useIntl} from 'react-intl'
import {CircularProgressbar} from 'react-circular-progressbar'
import CircularProgress from '@mui/joy/CircularProgress'
import 'react-circular-progressbar/dist/styles.css'
import {Skeleton} from '@mui/material'
import {CalendarIcon} from '@heroicons/react/24/outline'

type Props = {
  percentage: number
  date: any
  loading?: boolean
}

const OverallProgress: FC<Props> = ({percentage, date, loading}) => {
  const intl = useIntl()

  return (
    <>
      <div className='flex items-center justify-between bg-pmf-image bg-cover bg-center rounded-[20px] text-white p-5'>
        <div>
          <h2 className='text-base font-bold'>
            {loading ? (
              <>
                <Skeleton width={150} />
              </>
            ) : (
              <>{intl.formatMessage({id: 'LESSONS.MY_PROGRESS.OVERALL'})}</>
            )}
          </h2>
          <p className='flex items-center my-2'>
            {loading ? (
              <>
                <Skeleton width={170} />
              </>
            ) : (
              <>
                <CalendarIcon className='w-4 h-4' />
                <span className='text-xs ms-1'> Created on {date}</span>
              </>
            )}
          </p>

          <div className='flex items-center text-sm my-2'>
            <p>
              {loading ? (
                <>
                  <Skeleton width={150} />
                </>
              ) : (
                <>{intl.formatMessage({id: 'LESSONS.MY_PROGRESS.OVERALL_DESC'})}</>
              )}
            </p>
          </div>
        </div>

        <>
          {loading ? (
            <Skeleton width={100} height={100} className='me-2' variant='circular' />
          ) : (
            <CircularProgress
              determinate
              sx={{
                '--CircularProgress-size': '90px',
                color: '#fff',
                '--CircularProgress-linecap': 'square',
                '--CircularProgress-progressColor': '#32C3D7',
                '--CircularProgress-trackColor': '#DADADA',
              }}
              value={percentage == undefined ? 0 : percentage}
              thickness={10}
            >
              <span className=' font-bold text-2xl'>
                {`${percentage == undefined ? 0 : percentage}%`}
              </span>
            </CircularProgress>
          )}
        </>
      </div>
    </>
  )
}

export {OverallProgress}

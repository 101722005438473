import React from "react";
import MenuLinks from "./MenuLinks";
import { toAbsoluteUrl } from "../helpers";
// import { toAbsoluteUrl } from "../helpers";

const Sidebar: React.FC = () => {
  return (
    <>
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white border-r-4 border-gray-500 px-6 pb-4">
            <div className="flex h-24 shrink-0 items-center">
            <img alt='ADI Network Logo' 
              src={toAbsoluteUrl('/assets/media/logos/logo-1.svg')} 
              className='h-9 w-auto mt-7 mb-4' />
             
            </div>
            <nav className="flex flex-1 flex-col">
              <MenuLinks />
            </nav>
          </div>
    </>

  );
};

export { Sidebar };

import {FC} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import {Communication} from '../../components/account-settings/communication/communication'

const CommunicationWrapper: FC = () => {
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='Communication' backToURL='/profile' isCenter></PageHeader>
      </div>

      <div className='px-5 py-6 p-lg-0'>
        <Communication></Communication>
      </div>
    </>
  )
}

export default CommunicationWrapper

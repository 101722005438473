import * as React from 'react'
import {createContext, useContext, useState} from 'react'
import {useDispatch} from 'react-redux'
import {AddressType} from '../models/enums/address-type-enum'
import {StudentAddress} from '../models/student/student-address'
import {StudentBooking} from '../models/student/student-booking'
import {getOrder} from '../services/booking.service'
import {getStudentBooking, getStudentBookings, getStudentAddress} from '../services/student.service'
import * as auth from '../../../setup/redux/AuthRedux'
import toast from 'react-hot-toast'

type IProps = {
  children: React.ReactNode
}

export type CourseContextType = {
  Course: any
  getCourses: () => void
  getCurrentCourse: () => void
  getAddress: () => void
  updateCourse: (key: any, value: any) => void
}

export const defaultCourse: {
  deal_id: string
  courses: StudentBooking[]
  currentCourse: StudentBooking
  pickupAddress: StudentAddress | undefined
  billingAddress: StudentAddress | undefined
  order: any
  last_active_deal: any
} = {
  deal_id: '',
  courses: [],
  currentCourse: {
    deal_id: '',
    has_theory_test: false,
    has_practical_test: false,
    cancelled_at: undefined,
    inActiveStage: false,
  },
  pickupAddress: undefined,
  billingAddress: undefined,
  order: undefined,
  last_active_deal: undefined,
}

export const CourseContext = createContext<CourseContextType | null>(null)

export const CourseProvider: React.FC<IProps> = ({children}) => {
  const [courseState, updateCourseState] = useState<any>(defaultCourse)
  const dispatch = useDispatch()

  React.useEffect(() => {
    getCurrentCourse()
    getCourses()
    getAddress()
  }, [])

  const getCurrentCourse = async () => {
    const response = await getStudentBooking().catch((error) => {
      if (!error?.errorMessage) {
        toast.error('Failed to get student booking')
      }
    })
    const booking = response?.results
    if (booking) {
      updateCourse('currentCourse', booking)
      if (courseState.deal_id != booking?.deal_id) {
        updateCourse('deal_id', booking?.deal_id)
      }
      if (booking?.steps_completed_at) {
        dispatch(auth.actions.setIsStepsCompleted(true))
      } else {
        dispatch(auth.actions.setIsStepsCompleted(false))
      }
      const res = await getOrder(booking.deal_id).catch((error) => {
        if (!error?.errorMessage) {
          toast.error('Failed to get order')
        }
      })
      if (res) updateCourse('order', res)
    }
  }

  const getCourses = async () => {
    const response = await getStudentBookings().catch((error) => {
      if (!error?.errorMessage) {
        toast.error('Failed to get student booking')
      }
    })
    if (response?.results) {
      updateCourse('courses', response.results)
      var list = response.results
        .sort(
          (a, b) =>
            new Date(a.created_at_without_formate ?? '').getTime() -
            new Date(b.created_at_without_formate ?? '').getTime()
        )
        .reverse()
      if (list.length > 0) updateCourse('last_active_deal', list[0].deal_id)
    }
  }

  const getAddress = async () => {
    const response = await getStudentAddress().catch((error) => {
      if (!error?.errorMessage) {
        toast.error('Failed to get student address')
      }
    })
    if (response?.results) {
      if (response.results && response.results.length > 0) {
        const pickupAddress = response.results.filter(
          (a) => a.address_type_id == AddressType.Pickup
        )
        if (pickupAddress && pickupAddress.length > 0)
          updateCourse('pickupAddress', pickupAddress[0])
        const billingAddress = response.results.filter(
          (a) => a.address_type_id == AddressType.Billing
        )
        if (billingAddress && billingAddress.length > 0)
          updateCourse('billingAddress', billingAddress[0])
      }
    }
  }

  const updateCourse = (key: any, value: any = undefined) => {
    if (value == undefined) {
      updateCourseState((prev: any) => {
        return {
          ...prev,
          ...key,
        }
      })
      return false
    }
    updateCourseState((prevState: any) => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  return (
    <CourseContext.Provider
      value={{getCourses, getCurrentCourse, Course: courseState, updateCourse, getAddress}}
    >
      {children}
    </CourseContext.Provider>
  )
}

export const useCourse = () => useContext(CourseContext) as CourseContextType

import {FC, useState} from 'react'
import Modal from '../../shared/overlays/Modal'
import {Lesson} from '../../../models/lessons/lesson'
import {LessonStatusEnum} from '../../../models/enums/lesson-status-enum'
import {UpdateLessonStatusRequest} from '../../../models/student/update-lesson-status-request'
import {updateLessonStatus} from '../../../services/lesson.service'
import Alert from '../../shared/overlays/Alert'
import toast from 'react-hot-toast'

type IProps = {
  open: boolean
  onClose: any
  lesson: Lesson
}
const DisputeLessonModal: FC<IProps> = ({onClose, open, lesson}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const updateLessonStatusFunction = async (statusId: any) => {
    let data: UpdateLessonStatusRequest = {
      deal_id: lesson?.job_ref,
      id: lesson?.id ?? '',
      status_id: statusId,
    }
    setIsSubmitting(true)
    const response = await updateLessonStatus(data).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to update student lessons')
    })
    setIsSubmitting(false)
    if (!response) {
      return
    }
    onClose({reloadLessons: true})
  }

  return (
    <Modal
      open={open}
      title='Dispute Lesson'
      onClose={onClose}
      onSubmit={() => {
        updateLessonStatusFunction(LessonStatusEnum.Disputed)
      }}
      submitText='Confirm'
      closeText='Cancel'
      className='w-500px'
      disabled={isSubmitting}
      description="Please confirm this lesson didn't go ahead"
    ></Modal>
  )
}

export default DisputeLessonModal

import {Disclosure} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/24/outline'
import React, {useEffect, useState} from 'react'
import Badge from './Badge'
import Skeleton from '@mui/material/Skeleton'
import LinkItem from './LinkItem'
import {scrollToTagBySelector} from '../../../helpers/ScrollHelper'

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  fullWidth?: boolean
  count?: number
  id?: string
  title?: string
  subTitle?: string
  disclosureIcon?: string
  disclosureImg?: string
  children?: React.ReactNode
  disclosureCard?: boolean
  disclosureCardSize?: 'xs' | 'sm' | 'md' | 'lg'
  background?: string
  loading?: boolean
  disabled?: boolean
  progress?: boolean
  expanded?: boolean
  linkName?: string
  linkURL?: string
  hidden?: boolean
  storageKey?: string
  border?: string
  description?: string
  imageBadge?: any
  imageBadgeColour?:
  | 'blue'
  | 'green'
  | 'darkGreen'
  | 'yellow'
  | 'red'
  | 'outlineRed'
  | 'gray'
  | 'darkRed'
  | 'white'
  | 'darkBlue'
}

export default function DisclosureItem({
  id,
  title,
  subTitle,
  disclosureIcon,
  disclosureImg,
  fullWidth,
  count = 0,
  children,
  disclosureCard = false,
  disclosureCardSize = 'md',
  background = 'bg-white',
  loading,
  disabled = false,
  progress,
  expanded = false,
  linkName,
  linkURL,
  hidden,
  storageKey,
  border,
  description,
  imageBadge,
  imageBadgeColour,
}: BadgeProps) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) scrollToTagBySelector('#' + id)
  }, [isOpen])

  useEffect(() => {
    if (expanded) setIsOpen(expanded)
  }, [expanded])

  return (
    <div key={expanded === true ? 1 : 0}>
      <Disclosure
        as='div'
        hidden={hidden}
        defaultOpen={expanded}
        className={`w-full
        ${disclosureCard ? `${background} rounded-[10px]` : ''}
          ${border ? border : ''} `}
      >
        {({open}) => (
          <>
            <Disclosure.Button
              disabled={(count === 0 && linkURL != null) || disabled}
              onClick={() => setIsOpen(!isOpen)}
              className={` min-h-[40px] 
              ${
                disclosureCard &&
                {
                  xs: 'py-1.5',
                  sm: 'p-3',
                  md: 'py-3 px-4',
                  lg: 'p-4',
                }[disclosureCardSize]
              }
              flex w-full justify-between items-center text-darkBlue font-bold`}
            >
              {disclosureCard ? (
                <>
                  <div className='flex items-center justify-center min-w-0 gap-x-4 py-2'>
                    {disclosureIcon && (
                      <div className='shrink-0'>
                        {loading ? (
                          <Skeleton width={30} height={50} />
                        ) : (
                          <i className={`text-3xl ${disclosureIcon}`}></i>
                        )}
                      </div>
                    )}
                    {disclosureImg && (
                      <div className='shrink-0 w-24 flex flex-col items-center'>
                        {loading ? (
                          <Skeleton width={30} height={50} />
                        ) : (
                          <>
                            <img className='' src={disclosureImg} />
                            {imageBadge ? (
                              <div className='mt-2 w-full'>
                                <Badge colour={imageBadgeColour} title={imageBadge} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <div className='min-w-0 flex-auto flex flex-col justify-start items-start'>
                      <div className='flex items-center'>
                        <h3
                          className={`
                        ${
                          {
                            xs: 'text-small',
                            sm: 'text-base',
                            md: 'text-lg',
                            lg: 'text-xl mb-1',
                          }[disclosureCardSize]
                        }
                        font-normalf text-left me-2`}
                        >
                          {' '}
                          {loading ? (
                            <>
                              <Skeleton width={150} className='me-2' />
                            </>
                          ) : (
                            <>
                              {title}
                              <div className='text-sm font-normal'>
                                {' '}
                                {loading ? (
                                  <>
                                    <Skeleton width='100%' height={20} />
                                  </>
                                ) : (
                                  description
                                )}
                              </div>
                            </>
                          )}{' '}
                        </h3>
                        {loading ? (
                          <>
                            <Skeleton variant='circular' width={20} height={20} />
                          </>
                        ) : (
                          <>
                            {(progress || count > 0) && (
                              <Badge  notificationBadge={true} title={count ? count : 0} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='ps-3 flex-shrink'>
                    <ChevronDownIcon
                      className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-darkBlue`}
                    />
                  </div>
                  
                </>
              ) : (
                <>
                  <h3 className='flex items-center'>
                    <span
                      className={`${
                        disclosureCard
                          ? 'text-base font-normal font-normalf'
                          : 'text-base leading-none font-normalf'
                      }`}
                    >
                      {loading ? (
                        <>
                          <Skeleton width={150} className='me-2' />
                        </>
                      ) : (
                        <>
                          {linkURL != null && count == 0 ? (
                            <LinkItem
                              url={
                                storageKey
                                  ? sessionStorage.getItem(storageKey) != null
                                    ? linkURL + sessionStorage.getItem(storageKey)
                                    : linkURL
                                  : linkURL
                              }
                            >
                              <span className='text-2xl leading-none font-normalf'>{title}</span>
                            </LinkItem>
                          ) : (
                            <> {title} </>
                          )}
                        </>
                      )}
                    </span>
                    {(loading && count > 0) ? (
                      <>
                        <Skeleton variant='circular' width={20} height={20} />
                      </>
                    ) : (
                      <>{count > 0 && <Badge notificationBadge={true} title={count} />}</>
                    )}
                  </h3>

                  {loading ? <>
                    <Skeleton variant="circular" width={20} height={20} />
                    </> :
                    <>
                    {((count > 0) || !disabled) && (
                      <ChevronDownIcon
                        className={`${open ? 'rotate-180 transform' : ''} h-4 w-4 text-darkBlue`}
                      />
                    )}
                  </>}

                  
             
                </>
              )}
            </Disclosure.Button>
            {isOpen && (
              <Disclosure.Panel id={id} className={`${disclosureCard ? '' : 'pt-4'} text-sm`}>
                {children}
              </Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    </div>
  )
}

import moment from 'moment'
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {EnvelopeIcon, PhoneIcon} from '@heroicons/react/24/outline'
import {Job} from '../../../../models/payments/job'
import Button from '../../../shared/elements/Button'
import Alert from '../../../shared/overlays/Alert'
import {FeedbackModal} from '../../../shared/modals/FeedbackModal'

type Props = {
  adiData: any
  paymentInfo: Job | null
  hasLessons: boolean
  canProvideFeedback: boolean
  zendeskContactId: any
  dealId: any
  checkCanAddFeedback: any
}
const Instructor: FC<Props> = ({
  adiData,
  paymentInfo,
  hasLessons,
  canProvideFeedback,
  zendeskContactId,
  dealId,
  checkCanAddFeedback,
}) => {
  const intl = useIntl()
  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false)
  const hideFeedbackDialog = () => {
    checkCanAddFeedback(adiData?.id, dealId)
    setShowFeedbackDialog(false)
  }
  return (
    <>
      <FeedbackModal
        adi_contact_id={adiData?.zendesk_contact_id}
        adi_id={adiData?.id}
        dealId={dealId}
        job_id={paymentInfo?.id}
        show={showFeedbackDialog}
        student_contact_id={zendeskContactId}
        onHide={() => {
          hideFeedbackDialog()
        }}
      />

      {!!paymentInfo?.paid_in_full === true ? (
        <>
          {/*START:: Scenario 4: ADI has been assigned, student has paid in full */}
          <div className='flex '>
            <div className=' w-14 h-14 bg-gray-600 rounded-full flex items-center justify-center text-white'>
              <i className='icon-instructor text-3xl'></i>
            </div>
            <div className='ps-3 flex-grow'>
              <h3 className='font-bold text-base mb-2'>
                {adiData?.name} {adiData?.sur_name}
              </h3>
              <ul className='list-none'>
                <li className='mb-2 last:mb-0 w-full flex items-center'>
                  {/* envelope */}
                  <PhoneIcon className='w-5 h-5 me-2' />
                  <a href={`tel:${adiData?.phone}`} className='text-g-850 font-size-14 fw-500'>
                    0{adiData?.phone}
                  </a>
                </li>
                <li className='mb-1 last:mb-0 w-full flex items-center'>
                  <EnvelopeIcon className='w-5 h-5 me-2' />
                  <a
                    href={`mailto:${adiData?.email}`}
                    className='text-g-850 d-inline-block font-size-14 fw-500 max-w-280px w-md-120px w-unset-mobile word-br eak-mobile text-truncate'
                    title={adiData?.email}
                  >
                    {adiData?.email}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {hasLessons ? (
            <></>
          ) : (
            <div className='flex pt-6 pb-1 -mb-4'>
              <Alert
                description={intl.formatMessage({id: 'DASHBOARD.INSTRUCTOR.CONTACT_YOUR_ADI'})}
                colour='green'
              />
            </div>
          )}

          {canProvideFeedback && (
            <div className='flex mt-7'>
              <Button colour='pink' onClick={() => setShowFeedbackDialog(true)}>
                <span className='fs-4'>{intl.formatMessage({id: 'ACTIONS.PROVIDE_FEEDBACK'})}</span>
              </Button>
            </div>
          )}

          {/*END:: Scenario 4: ADI has been assigned, student has paid in full */}
        </>
      ) : (
        <>
          {/*Case1 : Scenario 3: ADI has been assigned, student hasn’t paid in full */}
          <Alert
            colour='pink'
            customDesc={
              moment(adiData?.allocation_date)
                .add(7, 'days')
                .isAfter(moment.now()) ? (
                <span>
                  {intl.formatMessage(
                    {id: 'DASHBOARD.INSTRUCTOR.UNPAID_NOTE'},
                    {
                      date: moment(adiData?.allocation_date)
                        .add(7, 'days')
                        .format('DD/MM'),
                    }
                  )}
                </span>
              ) : (
                <span>
                  {intl.formatMessage({id: 'DASHBOARD.INSTRUCTOR.UNPAID_URGENTLY_NOTE'})}
                  <b> {intl.formatMessage({id: 'DASHBOARD.PAYMENT.URGENTLY'})} </b>
                </span>
              )
            }
          />
          {/*end Case1 */}
        </>
      )}
    </>
  )
}

export {Instructor}

import {useFormik} from 'formik'
import {FC, useState} from 'react'
import Modal from '../../shared/overlays/Modal'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import * as Yup from 'yup'
import {TestStatusTypeEnum} from '../../../models/enums/test-status-type'
import {TestTypeEnum} from '../../../models/enums/test-type'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {failTest, passTest} from '../../../services/test.service'
import {Test} from '../../../models/tests/test'
import {PassprotectWithRedemptionRemaining} from '../../../models/student/pass-protect-with-redemption-remaining'
import RadioInputGroup from '../../shared/forms/RadioInputGroup'
import {rebookCourse} from '../../../services/rebook.service'
import UsePassProtectModal from '../../add-ons/_modals/UsePassProtectModal'
import TestDetails from '../TestDetails'
import {ReferFriend} from '../../shared/modals/ReferFriend'
import {BookNewCourseModal} from '../../rebook-new-course/BookNewCourseModal'
import toast from 'react-hot-toast'
import Alert from '../../shared/overlays/Alert'
type Props = {
  show: any
  onHide: any
  test: Test | undefined
  setShowTestModal: any
  onStatusSubmit: any
  passProtectOption: PassprotectWithRedemptionRemaining | undefined
}

const AddTestResult: FC<Props> = ({
  show,
  onHide,
  test,
  onStatusSubmit,
  setShowTestModal,
  passProtectOption,
}) => {
  const intl = useIntl()
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true
  const [showUsePassProtectModal, setShowUsePassProtectModal] = useState(false)
  const [showReferFriendModal, setShowReferFriendModal] = useState(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const validationSchema = Yup.object().shape({
    status: Yup.string().required('Result is required'),
  })
  const [showBookNewCourseModal, setShowBookNewCourseModal] = useState(false)

  const formik = useFormik({
    initialValues: {
      status:
        test?.status === TestStatusTypeEnum.Passed || test?.status === TestStatusTypeEnum.Failed
          ? test?.status
          : '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      if (test) {
        // pass test
        if (values.status === TestStatusTypeEnum.Passed) {
          const res = await passTest(test.id).catch((error) => {
            if (!error?.errorMessage){
              setShowError(true)
              setErrorMessage(error.message)
              toast.error('Failed to get pass test')
            } 
            setSubmitting(false)
          })
          if (!res) return
          await onStatusSubmit()
          resetForm()
          setSubmitting(false)
          onHide()
          setShowReferFriendModal(true)
        } else {
          // fail test
          const res = await failTest(test.id).catch((error) => {
            if (!error?.errorMessage){
              setShowError(true)
              setErrorMessage(error.message)
              toast.error('Failed to get fail test')
            } 
            setSubmitting(false)
          })
          if (!res) return
          if (test.type === TestTypeEnum.Practical) {
            if (
              passProtectOption?.has_pass_protect &&
              passProtectOption?.number_of_pass_protect_redemption_remaining > 0
            ) {
              setShowUsePassProtectModal(true)
            } else {
              setShowBookNewCourseModal(true)
            }
            resetForm()
            setSubmitting(false)
            onHide()
          } else {
            await onStatusSubmit()
            resetForm()
            setSubmitting(false)
            onHide()
          }
        }
      }
    },
  })

  const hide = () => {
    formik.resetForm()
    formik.setTouched({...formik.touched}, false)
    onHide()
  }
  return (
    <>
      <Modal
        open={show}
        onClose={hide}
        onSubmit={formik.handleSubmit}
        title={intl.formatMessage({id: 'STUDENT_TEST.ADDTESTRESULT'})}
        submitText={intl.formatMessage({id: 'ACTIONS.CONFIRM'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        disabled={formik.isSubmitting}
      >
        {showError && <Alert description={errorMessage} colour='red'></Alert>}
        <form onSubmit={formik.handleSubmit} className='flex flex-col justify-between h-full'>
          <div className='pt-2 pb-8'>
            <div className='flex flex-col mt-0'>
              <h3 className='mb-4 me-3 brand text-base'>
                {intl.formatMessage({id: 'STUDENT_TEST.ENTERYOURTESTRESULTFOR'})}{' '}
                <span className='font-size-18 text-lowercase'>{test?.type}</span>
              </h3>
              <TestDetails test={test} />
              <div className='row mt-4 justify-start'>
                <RadioInputGroup
                  label=''
                  name='test-result'
                  options={[
                    {
                      value: TestStatusTypeEnum.Passed,
                      label: TestStatusTypeEnum.Passed,
                      img: toAbsoluteUrl('/assets/media/svg/pass-icon.svg'),
                    },
                    {
                      value: TestStatusTypeEnum.Failed,
                      label: TestStatusTypeEnum.Failed,
                      img: toAbsoluteUrl('/assets/media/svg/fail-icon.svg'),
                    },
                  ]}
                  onChange={(value) => {
                    formik.setFieldValue('status', value)
                  }}
                  value={formik.values.status}
                  disabled={formik.isSubmitting}
                  error={formik.touched.status && formik.errors.status != null}
                  errorMsg={formik.errors.status}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <BookNewCourseModal
        handleClose={() => {
          setShowBookNewCourseModal(false)
          onStatusSubmit()
        }}
        show={showBookNewCourseModal}
        handleSubmit={() => {
          rebookCourse(test?.deal_id, isMobile)
          onStatusSubmit()
        }}
      />

      <ReferFriend
        imageURL={test?.type === TestTypeEnum.Practical ? 'pt-pass.jpg' : 'tt-pass.jpg'}
        onHide={() => {
          setShowReferFriendModal(false)
        }}
        show={showReferFriendModal}
      />

      <UsePassProtectModal
        handleClose={() => {
          setShowBookNewCourseModal(false)
          setShowUsePassProtectModal(false)
          onStatusSubmit()
        }}
        show={showUsePassProtectModal}
        handleSubmit={() => {
          onStatusSubmit()
        }}
        passProtectOption={passProtectOption}
      />
    </>
  )
}

export default AddTestResult

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {Skeleton} from '@mui/material'
import {toAbsoluteUrl} from '../../../helpers'
import {CancellationTypeEnum} from '../../../models/enums/cancellation-type'
import {ZendeskStages} from '../../../models/enums/zendesk-stages-enum'
import {useCourse} from '../../../providers/CourseProvider'
import {refundEligibility} from '../../../services/booking.service'
import {getJobstage, getAllCancellationReason} from '../../../services/job.service'
import Alert from '../../shared/overlays/Alert'
import toast from 'react-hot-toast'

const CancelCourse: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const [refundDetails, setRefundDetails] = useState<any>()
  const {Course, getCurrentCourse} = useCourse()
  const [showCancellationSurveyModal, setShowCancellationSurveyModal] = useState(false)
  const [cancellationReason, setCancellationReason] = useState<Array<any>>()
  const [dealExpired, setDealExpired] = useState<boolean>(false)
  const [cancelledDeal, setCancelledDeal] = useState<boolean>(false)

  useEffect(() => {
    getCancellationReasonList()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (Course.deal_id) {
        setLoading(true)
        const res = await refundEligibility(Course.deal_id).catch((error) => {
          if (!error?.errorMessage) {
            toast.error('Failed to get refund eligibility')
          }
        })
        setLoading(false)
        setRefundDetails(res)
        const result = await getJobstage(Course.deal_id).catch((error) => {
          if (!error?.errorMessage) {
            toast.error('Failed to get job stage')
          }
        })
        setCancelledDeal(result?.results.stage_id == ZendeskStages.Cancelled)
        setDealExpired(result?.results.stage_id == ZendeskStages.Expired)
      }
    }
    fetchData()
  }, [Course.deal_id])

  async function getCancellationReasonList() {
    const res = await getAllCancellationReason().catch((error) => {
      if (!error?.errorMessage) {
        toast.error('Failed to get cancellation reason')
      }
    })
    setCancellationReason(
      res?.results.map((item) => ({
        label: item.reason,
        value: item.id,
        zendesk_value: item.zendesk_value,
      }))
    )
  }

  const renderMessages = (type: string) => {
    switch (type) {
      case 'partial':
        return (
          <Alert
            colour='green'
            description={intl.formatMessage({id: 'JOB_CANCELLATION.PARTIAL_REFUND'})}
          ></Alert>
        )
      case 'full':
        return (
          <Alert
            colour='green'
            description={intl.formatMessage({id: 'JOB_CANCELLATION.FULL_REFUND'})}
          ></Alert>
        )
      default:
        return (
          <Alert
            colour='orange'
            description={intl.formatMessage({id: 'JOB_CANCELLATION.NO_REFUND'})}
          ></Alert>
        )
    }
  }

  return (
    <>
      {/* <CancellationSurvey
        show={showCancellationSurveyModal}
        onHide={(res?: boolean) => {
          setShowCancellationSurveyModal(false)
        }}
        cancellation_reasons={cancellationReason}
        refundDetails={refundDetails}
        studentBooking={Course.currentCourse}
        cancelJobSubmit={getCurrentCourse}
      /> */}

      {Course.currentCourse?.cancelled_at || cancelledDeal ? (
        <div className='flex flex-col items-center bg-white pb-2 mt-0 pt-10 pb-12'>
          <div>
            <img loading="lazy"
              src={toAbsoluteUrl('/assets/media/svg/course-canceled.svg')}
              className='mb-3 w-90px'
              alt='cancelled deal'
            ></img>
          </div>
          <p className='font-size-20 fw-700 w-50 text-center'>
            {intl.formatMessage({id: 'DASHBOARD.CANCELLED_DEAL'})}
          </p>
        </div>
      ) : null}

      {dealExpired ? (
        <>
          <div className='flex flex-col items-center justify-center empty-placeholder rounded-2 text-center'>
            <img loading="lazy"
              src={toAbsoluteUrl('/assets/media/svg/expired.svg')}
              className='mb-3 w-90px'
              alt='no provisional licence'
            />
            <h3 className='font-size-16 fw-600 text-center mb-0'>
              {intl.formatMessage({id: 'DASHBOARD.DEAL_EXPIRED'})}
            </h3>
          </div>
        </>
      ) : null}

      {(!Course.currentCourse?.cancelled_at &&
        !dealExpired &&
        !cancelledDeal &&
        refundDetails?.refund_type) ||
      loading ? (
        <>
          <div className='bg-white'>
            <div className='p-8 bg-white'>
              <div className='mb-5 mb-xxl-8'>
                <div className='pt-0 px-0 pb-6'>
                  {loading ? <Skeleton width='100%' /> : renderMessages(refundDetails?.refund_type)}
                  <div className='border-t mt-6 pt-2'>
                    <h3 className='mb-2 mt-4 font-semibold'>
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        intl.formatMessage({id: 'JOB_CANCELLATION.REFUND_SUMMARY'})
                      )}
                    </h3>
                    <div className='grid grid-cols-1 md:grid-cols-8'>
                      <table className='table-fixed border-b text-darkBlue md:col-span-4'>
                        <tbody>
                          <tr className='border-b'>
                            <td className='text-left py-3.5 px-5'>
                              {loading ? (
                                <Skeleton width={300} />
                              ) : (
                                intl.formatMessage({id: 'JOB_CANCELLATION.APTD'})
                              )}
                            </td>
                            <td className='text-right py-3.5 px-5'>
                              {loading ? (
                                <Skeleton width={300} />
                              ) : (
                                <>£{refundDetails?.amount_paid}</>
                              )}
                            </td>
                          </tr>
                          {loading ? (
                            <tr className='border-b'>
                              <td className='text-left py-3.5 px-5'>
                                <Skeleton width={200} />
                              </td>
                              <td className='text-right py-3.5 px-5'>
                                <Skeleton width={50} />
                              </td>
                            </tr>
                          ) : (
                            Object.keys(refundDetails.deductables).length > 0 &&
                            refundDetails.refund_type != CancellationTypeEnum.NoRefund &&
                            refundDetails.refund_type != CancellationTypeEnum.NegativeRefund && (
                              <>
                                <tr className='font-bold border-b'>
                                  <td className='text-left py-3.5 px-5'>
                                    {intl.formatMessage({
                                      id: 'JOB_CANCELLATION.DEDUCTED_AMOUNTS',
                                    })}
                                  </td>
                                  <td className='text-right py-3.5 px-5'></td>
                                </tr>
                                {refundDetails.deductables.map((deductable: any, i: number) => (
                                  <tr key={i} className='border-b'>
                                    <td className='text-left py-3.5 px-5'>{deductable.name}</td>
                                    <td className='text-right py-3.5 px-5'>£{deductable.amount}</td>
                                  </tr>
                                ))}
                              </>
                            )
                          )}

                          {loading ? (
                            <tr className='border-b'>
                              <td className='text-left py-3.5 px-5'>
                                <Skeleton width={200} />
                              </td>
                              <td className='text-right py-3.5 px-5'>
                                <Skeleton width={50} />
                              </td>
                            </tr>
                          ) : (
                            (refundDetails?.refund_type == CancellationTypeEnum.NoRefund ||
                              refundDetails?.refund_type ==
                                CancellationTypeEnum.NegativeRefund) && (
                              <>
                                <tr className='border-b'>
                                  <td className='text-left py-3.5 px-5'>
                                    {intl.formatMessage({
                                      id: 'JOB_CANCELLATION.DEDUCTED_AMOUNTS',
                                    })}
                                  </td>
                                  <td className='text-right py-3.5 px-5'>
                                    £{refundDetails?.amount_paid}
                                  </td>
                                </tr>
                              </>
                            )
                          )}
                          {loading ? (
                            <>
                              <td className='text-left py-3.5 px-5'>
                                <Skeleton width={200} />
                              </td>
                              <td className='text-right py-3.5 px-5'>
                                <Skeleton width={50} />
                              </td>
                            </>
                          ) : (
                            <tr className='font-bold bg-gray-100'>
                              <td className='text-left py-3.5 px-5'>
                                {intl.formatMessage({id: 'JOB_CANCELLATION.TOTAL_REFUND'})}
                              </td>
                              <td
                                className={clsx(
                                  'text-right py-3.5 px-5',
                                  refundDetails?.refund_type == CancellationTypeEnum.NoRefund ||
                                    refundDetails?.refund_type ==
                                      CancellationTypeEnum.NegativeRefund
                                    ? 'text-danger'
                                    : ''
                                )}
                              >
                                £
                                {refundDetails?.refund_type == CancellationTypeEnum.NoRefund ||
                                refundDetails?.refund_type == CancellationTypeEnum.NegativeRefund
                                  ? 0
                                  : refundDetails?.refund_amount}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div>
                    <ul className='m-0 p-0 mt-3'>
                      <li className='my-2'>
                        {loading ? (
                          <Skeleton width='100%' />
                        ) : (
                          intl.formatMessage({id: 'JOB_CANCELLATION.NO_REFUND_CONTACT_NOTE'})
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* {loading ? (
                  <Skeleton width='100%' height={40} />
                ) : (
                  <button
                    type='button'
                    className='btn btn-danger btn-md rouded'
                    onClick={() => {
                      setShowCancellationSurveyModal(true)
                    }}
                  >
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'JOB_CANCELLATION.CANCEL_BOOKING'})}
                    </span>
                  </button>
                )} */}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export {CancelCourse}

import {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {FormikProps, useFormik} from 'formik'
import * as Yup from 'yup'
import * as auth from '../../../../setup/redux/AuthRedux'
import {useNavigate, useParams} from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../helpers'
import {scrollToFirstError} from '../../helpers/ScrollHelper'
import {
  validateToken,
  verifyRecaptcha,
  register,
  login,
  resendValidationToken,
} from '../../services/auth.service'
import {syncJobs} from '../../services/student.service'
import Input from '../../components/shared/forms/Input'
import Alert from '../../components/shared/overlays/Alert'
import CheckboxInput from './../../components/shared/forms/CheckboxInput'
import Button from '../../components/shared/elements/Button'
import {Skeleton} from '@mui/material'
import LinkItem from '../../components/shared/elements/LinkItem'
import toast from 'react-hot-toast'
interface User {
  first_name: string
  surname: string
  email: string
  password: string
  changePassword: string
  acceptTerms: boolean
}

export function Registration() {
  const intl = useIntl()

  const registrationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 2}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'REGISTRATION.FIRSTNAME'})}
        )
      ),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'REGISTRATION.EMAIL'})}
        )
      ),
    surname: Yup.string()
      .min(2, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 2}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'REGISTRATION.SURNAME'})}
        )
      ),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 8}))
      .matches(new RegExp('.*\\d.*'), intl.formatMessage({id: 'VALIDATION.NUMBER_REQUIRED'}))
      .matches(new RegExp('.*[a-z].*'), intl.formatMessage({id: 'VALIDATION.LOWERCASE_REQUIRED'}))
      .matches(new RegExp('.*[A-Z].*'), intl.formatMessage({id: 'VALIDATION.UPPERCASE_REQUIRED'}))
      .matches(new RegExp('.*\\W.*'), intl.formatMessage({id: 'VALIDATION.SYMBOL_REQUIRED'}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'REGISTRATION.PASSWORD'})}
        )
      ),
    changePassword: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'REGISTRATION.CONFIRM_PASSWORD'})}
        )
      )
      .when('password', ([password], schema) => {
        return password && password.length > 0
          ? schema.oneOf(
              [Yup.ref('password')],
              intl.formatMessage({id: 'VALIDATION.PASSWORD_CONFIRM_MATCH'})
            )
          : schema
      }),
    acceptTerms: Yup.bool().required(intl.formatMessage({id: 'VALIDATION.TERMS_AND_CONDITION'})),
  })

  // Initialize a boolean state
  const [isExpiredToken, setIsExpiredToken] = useState(false)
  const recaptchaKey: string = process.env.REACT_APP_RECAPTCHA_KEY ?? ''
  const recaptchaRef = useRef<any>(null)
  const [recaptchaChecked, setRecaptchaChecked] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isSubmit, setSubmit] = useState(false)
  const [isInvalid, setIsInvalidToken] = useState(false)
  const [isFailedToValidate, setIsFailedToValidate] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {token} = useParams<{token: string}>()
  let [user, setUser] = useState<User>({
    first_name: '',
    surname: '',
    email: '',
    password: '',
    changePassword: '',
    acceptTerms: false,
  })
  let [userData, setUserData] = useState({
    zendesk_contact_id: null,
    first_name: '',
    surname: '',
    email: '',
  })
  useEffect(() => {
    setIsInvalidToken(false)
    setIsFailedToValidate(false)
    const fetch = async () => {
      const response = await validateToken(token).catch((err) => {
        toast.error(err?.errorMessage ?? 'Something went wrong')
        if (err?.results?.is_Expired) {
          setIsExpiredToken(true)
        } else {
          setIsFailedToValidate(true)
        }
        setLoading(false)
      })

      if (response?.results?.alreadyRegisterd) {
        navigate('/questionnaire')
      } else if (!response?.results?.isValid) {
        setIsInvalidToken(true)
      } else {
        const userData = response.results.user
        userData.old_id = userData?.id
        setUserData(userData)
        const value = {
          ...user,
          first_name: userData.first_name,
          surname: userData.surname,
          email: userData.email ?? '',
        }
        setUser(value)
      }
      setLoading(false)
    }
    fetch()
    return () => {
      setLoading(false)
    }
  }, [])
  const resendEmail = async () => {
    setSubmit(true)
    setIsEmailSent(false)
    const response = await resendValidationToken(token).catch(() => {
      setSubmit(false)
    })

    if (response) {
      setSubmit(false)
      setIsEmailSent(true)
    }
  }
  const recaptchaCallback = useCallback(() => setRecaptchaChecked(true), [recaptchaRef])

  const formik: FormikProps<User> = useFormik<User>({
    initialValues: user,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      userData.first_name = values.first_name
      userData.surname = values.surname
      setSubmit(true)
      setTimeout(async () => {
        const captchaToken = recaptchaRef.current.getValue()
        await verifyRecaptcha(captchaToken).catch((err) => {
          recaptchaRef?.current?.reset()
          setSubmit(false)
          setRecaptchaChecked(false)
          setSubmitting(false)
          toast.error(err?.message ?? 'failed to verify recaptcha ')
          return
        })
        await register(values.email, values.password, userData, token).catch((err) => {
          return
        })
        await login(values.email, values.password).catch((err) => {
          if (!err?.errorMessage) toast.error('Something went wrong.')
          return
        })
        recaptchaRef?.current?.reset()
        setSubmit(false)
        setRecaptchaChecked(false)
        dispatch(auth.actions.login())
        await syncJobs().catch((error) => {
          setSubmitting(false)
          if (!error?.errorMessage) {
            toast.error('Failed to sync job')
          }
        })
      }, 1000)
    },
  })

  useEffect(() => {
    scrollToFirstError(formik)
  }, [formik.errors, formik.isSubmitting, formik.isValidating])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {isFailedToValidate ? (
          <div className='flex flex-col items-center bg-white pb-2 mt-0'>
            {/* Start:: Empty test placeholder */}
            <div>
              <img
                src={toAbsoluteUrl('/assets/media/svg/dizzy-robot.svg')}
                className='mb-3'
                alt=''
              ></img>
            </div>

            <p className='text-lg text-center font-bold mt-6 mx-15'>
              {intl.formatMessage({id: 'REGISTRATION.FAILED_TO_VALIDATE'})}
              <a
                className='brand p-0 text-xl font-bold'
                onClick={() => {
                  window.location.reload()
                }}
              >
                {intl.formatMessage({id: 'REGISTRATION.REFRESH'})}
              </a>
              {intl.formatMessage({id: 'REGISTRATION.THE_PAGE'})}
            </p>
            {/* End:: Empty test placeholder */}
          </div>
        ) : isExpiredToken ? (
          <>
            <div className='flex flex-col justify-items-center'>
              {isEmailSent && (
                <Alert
                  description={intl.formatMessage({id: 'REGISTRATION.EMAIL_SENT'})}
                  colour='green'
                ></Alert>
              )}
              <img
                src={toAbsoluteUrl('/assets/media/svg/rejection-reason/time.svg')}
                className='w-100px mx-auto mb-2'
              />
              <p className='text-center mb-10 text-base font-bold'>
                {intl.formatMessage({id: 'REGISTRATION.TOKEN_EXPIRED'})}
              </p>
              <Button
                type='button'
                id='kt_login_signup_form_cancel_button'
                disabled={isSubmit}
                onClick={resendEmail}
              >
                {intl.formatMessage({id: 'ACTIONS.RESEND_EMAIL'})}
              </Button>
            </div>
          </>
        ) : isInvalid ? (
          <div className='flex flex-col items-center bg-white pb-2 mt-0'>
            <div>
              <img
                src={toAbsoluteUrl('/assets/media/svg/dizzy-robot.svg')}
                className='mb-3'
                alt=''
              ></img>
            </div>
            <p className='text-center mb-10 text-lg font-bold'>
              {intl.formatMessage({id: 'REGISTRATION.TOKEN_INVALID'})}
            </p>
          </div>
        ) : (
          <>
            {/* begin::Heading */}
            <div className='mb-5 text-center'>
              {/* begin::Title */}
              <h1 className='text-darkBlue mb-3'>
                {intl.formatMessage({id: 'REGISTRATION.CREATE_ACCOUNT'})}
              </h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='flex text-lg items-center justify-center'>
                <span className='text-gray-400 font-medium'>
                  {intl.formatMessage({id: 'REGISTRATION.HAVE_ACCOUNT'})}
                </span>

                <div className='font-semibold ml-1'>
                  <LinkItem url='/auth/login'>
                    {intl.formatMessage({id: 'REGISTRATION.LOGIN'})}
                  </LinkItem>
                </div>
              </div>
              {/* end::Link */}
            </div>
            {/* end::Heading */}

            {/* begin::Form group Firstname */}
            <div className='row fv-row mb-2'>
              {loading ? (
                <Skeleton width={150} />
              ) : (
                <Input
                  id='first_name'
                  label={intl.formatMessage({id: 'REGISTRATION.FIRSTNAME'})}
                  placeholder={intl.formatMessage({id: 'REGISTRATION.FIRSTNAME'})}
                  type='text'
                  {...formik.getFieldProps('first_name')}
                  error={formik.touched.first_name && formik.errors.first_name ? true : false}
                  errorMsg={formik.errors.first_name}
                  required={true}
                />
              )}

              {loading ? (
                <Skeleton width={150} />
              ) : (
                <Input
                  id='surname'
                  label={intl.formatMessage({id: 'REGISTRATION.SURNAME'})}
                  placeholder={intl.formatMessage({id: 'REGISTRATION.SURNAME'})}
                  type='text'
                  {...formik.getFieldProps('surname')}
                  error={formik.touched.surname && formik.errors.surname ? true : false}
                  errorMsg={formik.errors.surname}
                  required={true}
                />
              )}
            </div>
            {/* end::Form group */}

            {loading ? (
              <Skeleton width={150} />
            ) : (
              <Input
                id='email'
                label={intl.formatMessage({id: 'REGISTRATION.EMAIL'})}
                placeholder={intl.formatMessage({id: 'REGISTRATION.EMAIL'})}
                type='email'
                {...formik.getFieldProps('email')}
                disabled
                error={formik.touched.email && formik.errors.email ? true : false}
                errorMsg={formik.errors.email}
                required={true}
              />
            )}

            <div className='mb-1'>
              <div className='position-relative mb-3'>
                {loading ? (
                  <Skeleton width={150} />
                ) : (
                  <>
                    <Input
                      id='password'
                      type='text'
                      label={intl.formatMessage({id: 'REGISTRATION.PASSWORD'})}
                      placeholder={intl.formatMessage({id: 'REGISTRATION.PASSWORD'})}
                      {...formik.getFieldProps('password')}
                      error={formik.touched.password && formik.errors.password ? true : false}
                      errorMsg={formik.errors.password}
                      required={true}
                      eyeIcon={true}
                    />
                  </>
                )}
              </div>
            </div>

            <div className='input-group mb-0'>
              {loading ? (
                <Skeleton width={150} />
              ) : (
                <>
                  <Input
                    id='confirm_password'
                    type='text'
                    label={intl.formatMessage({id: 'REGISTRATION.CONFIRM_PASSWORD'})}
                    placeholder={intl.formatMessage({id: 'REGISTRATION.CONFIRM_PASSWORD'})}
                    {...formik.getFieldProps('changePassword')}
                    error={
                      formik.touched.changePassword && formik.errors.changePassword ? true : false
                    }
                    errorMsg={formik.errors.changePassword}
                    required={true}
                    eyeIcon={true}
                  />
                </>
              )}
            </div>

            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-5'>
              {loading ? (
                <Skeleton width={150} />
              ) : (
                <ReCAPTCHA sitekey={recaptchaKey} ref={recaptchaRef} onChange={recaptchaCallback} />
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='form-check form-check-custom form-check-solid'>
                {loading ? (
                  <Skeleton width={150} />
                ) : (
                  <CheckboxInput
                    label={
                      <>
                        {intl.formatMessage({id: 'REGISTRATION.AGREE'})}
                        <a
                          className='ms-1 link-primary'
                          href={process.env.REACT_APP_TEARMS_CONDITIONS_URL}
                          title={intl.formatMessage({id: 'REGISTRATION.TERMS_AND_CONDITIONS'})}
                          rel='noreferrer'
                          target='_blank'
                          onClick={(e) => e.stopPropagation()}
                        >
                          {intl.formatMessage({id: 'REGISTRATION.TERMS_AND_CONDITIONS'})}
                        </a>
                      </>
                    }
                    {...formik.getFieldProps('acceptTerms')}
                    error={formik.touched.acceptTerms && formik.errors.acceptTerms ? true : false}
                    errorMsg={formik.errors.acceptTerms}
                    required={true}
                    isSingleCheckbox={true}
                    onChange={() => formik.setFieldValue('acceptTerms', !formik.values.acceptTerms)}
                    checked={formik.values.acceptTerms}
                  ></CheckboxInput>
                )}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='flex flex-col justify-items-center lg:pb-0'>
              <Button
                type='submit'
                disabled={formik.isSubmitting || !formik.values.acceptTerms || !recaptchaChecked}
                size='large'
              >
                {loading ? (
                  <>
                    <Skeleton width={20} height={20} />{' '}
                  </>
                ) : (
                  <>
                    {!isSubmit && (
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'ACTIONS.SUBMIT'})}
                      </span>
                    )}
                    {isSubmit && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </>
                )}
              </Button>
              <LinkItem url='/auth/login'>
                <Button type='button' colour='transparent' size='large'>
                  {loading ? (
                    <>
                      <Skeleton width={20} height={20} />{' '}
                    </>
                  ) : (
                    intl.formatMessage({id: 'ACTIONS.CANCEL'})
                  )}
                </Button>
              </LinkItem>
            </div>
            {/* end::Form group */}
          </>
        )}
      </form>
    </>
  )
}

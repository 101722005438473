import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import Skeleton from '@mui/material/Skeleton'
import {licenceNumberExpression} from '../../../models/enums/patterns-enum'
import {IQuationnaireTabProps} from '../../../models/quationnaire-tab-props'
import {LicenseDataModel} from '../../../models/student/License-data-model'
import {toAbsoluteUrl} from '../../../helpers'
import {getUpcomingYearMonths, getSelectedUpcomingYearMonths} from '../../../helpers/DateHelper'
import {scrollToTop} from '../../../helpers/ScrollHelper'
import {AddressType} from '../../../models/enums/address-type-enum'
import {LicenseDocumentData} from '../../../models/student/licence-document-data'
import {StudentAddress} from '../../../models/student/student-address'
import {UpdateLicenseDataModel} from '../../../models/student/update-license-data-model'
import {UpdateLicenseDocumentModel} from '../../../models/student/update-license-document-model'
import {
  getLicenceByStudentIDFromDcument,
  addUpdateLicence,
  deleteLicenceByStudentIDFromDcument,
} from '../../../services/document.service'
import {getCities} from '../../../services/lookup.service'
import {
  getCurrentStudentLicenceDetails,
  addLicenceAddress,
  updateLicenceData,
} from '../../../services/student.service'
import {uploadDocument} from '../../../services/upload.service'
import Button from '../../shared/elements/Button'
import Input from '../../shared/forms/Input'
import Toggler from '../../shared/forms/Toggler'
import Dropdown from '../../shared/forms/Dropdown'
import {AddAddress} from '../_modals/AddAddress'
import Alert from '../../shared/overlays/Alert'
import toast from 'react-hot-toast'

const licenceRegx = new RegExp(licenceNumberExpression + '$')

const LicenseDetails: FC<IQuationnaireTabProps> = (props) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [show, setShow] = useState(false)
  const [licenceData, setLicenceData] = useState<LicenseDataModel>(new LicenseDataModel())
  const [licenceDocumentData, setlicenceDocumentData] = useState<LicenseDocumentData>(
    new LicenseDocumentData()
  )
  const months = getUpcomingYearMonths()
  const validationSchema = Yup.object().shape({
    have_licence: Yup.boolean()
      .required(
        intl.formatMessage(
          {
            id: 'VALIDATION.REQUIRED',
          },
          {
            field: intl.formatMessage({
              id: 'QUESTIONNAIRE.LICENCEDETAILS.HAVE_LICENCE',
            }),
          }
        )
      )
      .nullable(),
    expected_month: Yup.string().when('have_licence', {
      is: false,
      then: (schema) =>
        schema.required(
          intl.formatMessage(
            {
              id: 'VALIDATION.REQUIRED',
            },
            {
              field: intl.formatMessage({
                id: 'QUESTIONNAIRE.LICENCEDETAILS.EXPECTED_DATE',
              }),
            }
          )
        ),
    }),
    licence_number: Yup.string()
      .when('have_licence', {
        is: true,
        then: (schema) =>
          schema
            .required(
              intl.formatMessage(
                {id: 'VALIDATION.REQUIRED'},
                {
                  field: intl.formatMessage({
                    id: 'QUESTIONNAIRE.LICENCEDETAILS.LICENCENUMBERSMALLLABEL',
                  }),
                }
              )
            )
            .min(8, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 8}))
            .max(16, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 16}))
            .matches(
              licenceRegx,
              intl.formatMessage(
                {id: 'VALIDATION.INVALID'},
                {field: intl.formatMessage({id: 'QUESTIONNAIRE.LICENCEDETAILS.LICENCENUMBERLABEL'})}
              )
            )
            .nullable(),
      })
      .nullable(),
    document_url: Yup.string(),
    document_title: Yup.string().nullable(),
  })
  const [loadingFile, setLoadingFile] = useState<boolean>(false)

  const getAddress = (addressType: AddressType): StudentAddress => {
    const address = {
      address_type_id: addressType,
      city_id: '',
      city_name: '',
      id: '',
      postal_code: '',
      street_address: '',
      is_copied_from_licence: false,
    }
    return address
  }

  const addressHasData = (address: StudentAddress) => {
    if (address.street_address && address.postal_code && address.city_name) return true
    return false
  }

  const handleClose = (address: StudentAddress) => {
    setShow(false)
    if (address === null || addressHasData(address) === false) {
      setToggle(false)
    } else {
      setLicenceAddress(address)
    }
  }
  const [licenceAddress, setLicenceAddress] = useState<StudentAddress>(
    getAddress(AddressType.Licence)
  )
  const [cities, setCities] = useState<Array<any>>([])
  const [addressType, setAddressType] = useState({})

  useEffect(() => {
    setLicenceData({
      ...licenceData,
      have_licence: !!Number(props.have_licence),
    })
  }, [props.have_licence])

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      let currentStudentLicenceDetailsRes = await getCurrentStudentLicenceDetails().catch(
        (error) => {
          if (!error?.errorMessage) toast.error('Failed to get student licence details')
          return
        }
      )
      if (currentStudentLicenceDetailsRes?.results != null) {
        const dateSelected = getSelectedUpcomingYearMonths(
          currentStudentLicenceDetailsRes.results.have_licence_at
            ? new Date(currentStudentLicenceDetailsRes.results.have_licence_at)
            : null,
          months
        )
        setLicenceData({
          ...licenceData,
          licence_number: currentStudentLicenceDetailsRes.results.licence_number ?? '',
          expected_month: dateSelected ? dateSelected.label : '',
          have_licence: !!Number(currentStudentLicenceDetailsRes.results.have_licence),
          have_licence_at: currentStudentLicenceDetailsRes.results.have_licence_at ?? '',
          expected_date: dateSelected ? dateSelected.value : '',
        })
      }
      let citiesRes = await getCities().catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get cities')
      })
      setLoading(false)
      if (citiesRes?.results != null) {
        const cities = citiesRes.results.data.data
        const citiesBinded = cities.map((city: any) => ({
          label: city.city_name,
          value: city.id,
        }))
        setCities(citiesBinded)
        setLoading(citiesRes.results === null)
      }
      let licenceByStudentIDFromDcumenRes = await getLicenceByStudentIDFromDcument().catch(
        (error) => {
          if (!error?.errorMessage) toast.error('Failed to get student licence ')
        }
      )
      if (licenceByStudentIDFromDcumenRes?.results != null) {
        setlicenceDocumentData({
          licence_number: licenceByStudentIDFromDcumenRes.results.licence_number,
          document_title: licenceByStudentIDFromDcumenRes.results.document_title,
          document_url: licenceByStudentIDFromDcumenRes.results.document_url,
          document_id: licenceByStudentIDFromDcumenRes.results.document_id,
        })
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  const formik = useFormik({
    initialValues: {
      licence_number: licenceData.licence_number,
      have_licence: licenceData.have_licence,
      expected_month: licenceData.expected_month,
      expected_date: licenceData.expected_date,
      document_url: licenceDocumentData.document_url ?? '',
      document_title: licenceDocumentData.document_title,
      document_id: licenceDocumentData.document_title,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if (formik.isValid) {
        if (
          values.have_licence === true &&
          (licenceDocumentData.document_id || licenceDocumentData.document_title)
        ) {
          setLicenceData({...licenceData, expected_month: '', expected_date: ''})
          let documentModel = new UpdateLicenseDocumentModel()
          documentModel.licence_number = values.licence_number
          documentModel.document_title = licenceDocumentData.document_id
            ? null
            : values.document_title
          documentModel.document_url = licenceDocumentData.document_id ? null : values.document_url
          let updateLicenceRes = await addUpdateLicence(documentModel).catch((error) => {
            setSubmitting(false)
            scrollToTop()
            if (error && error.errors && error.errors.length > 0) {
              setSubmitting(false)
              toast.error(error.errors[0].errorMessage)
            } else {
              setSubmitting(false)
              toast.error(intl.formatMessage({id: 'GENERAL.ERROR'}))
            }
          })
          if (updateLicenceRes) {
            let updateModel = new UpdateLicenseDataModel()
            updateModel.licence_number = values.licence_number
            updateModel.have_licence = values.have_licence
            await updateStudentLicenceFunction(updateModel, setSubmitting)
            if (addressHasData(licenceAddress)) {
              await addLicenceAddress(licenceAddress).catch((error) => {
                if (!error?.errorMessage) toast.error('Failed to add licence address')
              })
            }
          }
        } else {
          setlicenceDocumentData(new LicenseDocumentData())
          setLicenceData({...licenceData, licence_number: ''})
          let updateModel = new UpdateLicenseDataModel()
          updateModel.licence_number = values.licence_number
          updateModel.have_licence_at = formatSelectedDate()
          updateModel.have_licence = values.have_licence
          let deleteLicenceByStudentIDFromDcumentRes =
            await deleteLicenceByStudentIDFromDcument().catch((error) => {
              setSubmitting(false)
              scrollToTop()
              if (!error?.errorMessage) {
                toast.error('Failed to get saved steps')
              }
            })
          if (deleteLicenceByStudentIDFromDcumentRes) {
            await updateStudentLicenceFunction(updateModel, setSubmitting)
            if (addressHasData(licenceAddress) && values.have_licence === true) {
              await addLicenceAddress(licenceAddress).catch((error) => {
                if (!error?.errorMessage) toast.error('Failed to add licence address')
              })
            }
          }
        }
      } else {
        setSubmitting(false)
      }
    },
  })

  const formatSelectedDate = () => {
    let formatedDate = null
    if (formik.values.expected_date) {
      const date: {month: number; year: string} = JSON.parse(formik.values.expected_date ?? '')
      formatedDate = date.year + '-' + (date.month + 1) + '-' + '1'
    }
    return formatedDate
  }
  const updateStudentLicenceFunction = async (
    updateModel: UpdateLicenseDataModel,
    setSubmitting: any
  ) => {
    setSubmitting(true)
    let updateLicenceDataRes = await updateLicenceData(updateModel).catch((error: any) => {
      setSubmitting(false)
      scrollToTop()
      if (!error?.errorMessage) toast.error('Failed to update licence data')
    })
    if (updateLicenceDataRes) {
      props.goToNextTab()
      setSubmitting(false)
      scrollToTop()
    }
  }

  const setMonthFieldTouched = () => {
    formik.setFieldTouched('expected_month', true)
  }
  const uploadLicenseDocument = async (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setLoadingFile(true)
      const file = event.target.files[0]
      let res = formik.getFieldProps('licence_number')
      let uploadDocumentRes = await uploadDocument(file).catch((res) => {
        setLoadingFile(false)
        formik.setSubmitting(false)
        scrollToTop()
      })
      if (uploadDocumentRes) {
        setlicenceDocumentData({
          ...licenceDocumentData,
          document_title: file.name,
          licence_number: res.value,
        })
        setLicenceData({...licenceData, licence_number: res.value})
        formik.setFieldValue('document_url', uploadDocumentRes.results)
        formik.setFieldValue('document_title', file.name)
        formik.setFieldValue('have_licence', true)
        licenceDocumentData.document_id = null
        setlicenceDocumentData({
          ...licenceDocumentData,
          document_title: file.name,
          document_id: null,
        })
        setLicenceData({...licenceData, licence_number: res.value})
        setLoadingFile(false)
      }
    }
  }
  const toInputUppercase = (e: any) => {
    e.target.value = ('' + e.target.value).toUpperCase()
  }
  const toggleChanged = () => {
    if (toggle) {
      formik.setFieldValue('have_licence', false)
      setToggle(false)
    } else {
      setAddressType('Licence')
      formik.setFieldValue('have_licence', true)
      setToggle(true)
      setShow(true)
    }
  }

  return (
    <>
      <form  >
        {/* {props.parentWrapper === 'questionnaire' ? <AlertCompleteProfile /> : <></>} */}
        <div
          className={
            `w-full m-auto ` + (props.parentWrapper === 'questionnaire' ? 'lg:w-3/4' : 'lg:w-full')
          }
        >
          {props.parentWrapper === 'questionnaire' ? (
            <Alert
              description='We can’t start arranging your course until you’ve completed all steps of this form. Please submit your details ASAP.'
              colour='green'
            ></Alert>
          ) : (
            <></>
          )}
          <div className='bg-white rounded-lg px-4 py-5'>
            <div className={props.parentWrapper === 'questionnaire' ? '' : 'hidden'}>
              <h1 className='text-darkBlue font-bold text-2xl my-3'>
                {' '}
                {loading ? <Skeleton width={150} /> : <>Licence Details</>}
              </h1>
            </div>
            {formik.status && <Alert colour='red' description={formik.status}></Alert>}

            {/* end::First Name form group */}

            {/* start::Not has license form group */}
            {licenceData.have_licence === false && !loading && (
              <div>
                {loading ? (
                  <Skeleton width='100%' height={30} />
                ) : (
                  <div className=''>
                    <Dropdown
                      className=''
                      {...formik.getFieldProps('month')}
                      name='expected_month'
                      id='expected_month'
                      value={months.find((m) => {
                        if (formik.values.expected_month == m.label) {
                          return m
                        }
                      })}
                      onChange={(e) => {
                        formik.setFieldValue('expected_month', e?.label)
                        formik.setFieldValue('expected_date', e?.value)
                        setLicenceData({
                          ...licenceData,
                          expected_month: e?.label ?? '',
                          expected_date: e?.value ?? '',
                        })
                      }}
                      placeholder={intl.formatMessage({
                        id: 'QUESTIONNAIRE.STUDENTDETAILS.MONTH',
                      })}
                      isSearchable={true}
                      options={months}
                      disabled={formik.isSubmitting}
                      onBlur={setMonthFieldTouched}
                      label={intl.formatMessage({id: 'QUESTIONNAIRE.LICENCEDETAILS.EXPECTED_DATE'})}
                      error={
                        formik.touched.expected_month && formik.errors.expected_month ? true : false
                      }
                      errorMsg={formik.errors.expected_month}
                    />
                  </div>
                )}
              </div>
            )}
            {props.parentWrapper !== 'questionnaire' &&
              licenceData.have_licence === false &&
              !loading && (
                <div className='form-group  w-full mt-2 py-4'>
                  <label className='font-semibold' htmlFor='switchLicense'>
                    {loading ? <Skeleton width={150} /> : <></>}
                  </label>
                  {loading ? (
                    <Skeleton width={150} />
                  ) : (
                    <>
                      <Toggler
                        checked={toggle}
                        onChange={toggleChanged}
                        label={intl.formatMessage({
                          id: 'QUESTIONNAIRE.LICENCEDETAILS.TOGGLE_LABEL',
                        })}
                      />
                    </>
                  )}
                </div>
              )}
            {/* end::Not has license form group */}

            {/* start::Has license form group */}
            {(toggle === true || licenceData.have_licence === true || loading) && (
              <>
                <div
                  className={
                    formik.touched.licence_number && formik.errors.licence_number
                      ? 'form-group is-invalid mb-10'
                      : 'form-group  mb-10'
                  }
                >
                  <div className='clearfix'>
                    {loading ? (
                      <Skeleton width='100%' height={30} />
                    ) : (
                      <Input
                        className='outline outline-1 outline-gray-600'
                        {...formik.getFieldProps('licence_number')}
                        type='text'
                        name='licence_number'
                        disabled={formik.isSubmitting}
                        id='licence_number'
                        maxLength={16}
                        error={
                          formik.touched.licence_number && formik.errors.licence_number
                            ? true
                            : false
                        }
                        errorMsg={formik.errors.licence_number}
                        label={intl.formatMessage({
                          id: 'QUESTIONNAIRE.LICENCEDETAILS.LICENCENUMBER',
                        })}
                        required={true}
                        hasNote={true}
                        note={intl.formatMessage({id: 'QUESTIONNAIRE.LICENCEDETAILS.LICENCENOTE'})}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={
                    formik.touched.document_url && formik.errors.document_url
                      ? 'form-group  mt-10 mb-3 is-invalid'
                      : 'form-group  mt-10 mb-3 '
                  }
                >
                  {loading ? (
                    <Skeleton width='70%' height={300} />
                  ) : (
                    <img
                      loading='lazy'
                      src={toAbsoluteUrl('/assets/media/misc/drivinglicencesample.jpg')}
                      alt='driving licence sample'
                      className='w-3/5 md:w-96'
                    />
                  )}
                  <div
                    className={
                      formik.touched.document_url && formik.errors.document_url
                        ? 'form-group  mt-10 mb-3 is-invalid'
                        : 'form-group  mt-10 mb-3 '
                    }
                  >
                    <div className='clearfix'>
                      {loading ? (
                        <Skeleton width='100%' height={30} />
                      ) : (
                        <div className='custom-file-input  p-0 mt-3'>
                          {loading ? (
                            <Skeleton width='100%' height={30} />
                          ) : (
                            <div>
                              <label
                                htmlFor='AttachmentUrl'
                                className='bg-white px-1 text-base font-medium text-darkBlue'
                              >
                                {intl.formatMessage({
                                  id: 'QUESTIONNAIRE.LICENCEDETAILS.UPLOADPROVISIONALLICENCE',
                                })}
                                <span className='text-sm text-red-500'>*</span>
                              </label>

                              <input
                                type='file'
                                name='AttachmentUrl'
                                id='AttachmentUrl'
                                hidden
                                disabled={formik.isSubmitting}
                                accept='.jpeg, .jpg, .png, .pdf'
                                onChange={(e) => {
                                  uploadLicenseDocument(e)
                                }}
                              />
                              <div
                                className={`grid grid-cols-1 md:grid-cols-2 gap-4 px-2 py-2 mt-2`}
                              >
                                <label htmlFor='AttachmentUrl'>
                                  <div
                                    className={` grid grid-cols-2 md:grid-cols-4 gap-4 text-darkBlue border placeholder:text-gray-400 focus:border focus:border-inset focus:border-indigo-600 text-sm ${
                                      formik.touched.document_url && formik.errors.document_url
                                        ? 'border-red-500'
                                        : 'border-gray-300'
                                    }`}
                                  >
                                    <div className='col-span-1 md:col-span-3'>
                                      {licenceDocumentData.document_title ? (
                                        <label
                                          className='line-clamp-1 mx-2 my-2 text-base '
                                          htmlFor='AttachmentUrl'
                                        >
                                          {licenceDocumentData.document_title}
                                        </label>
                                      ) : (
                                        <span className='mx-2 my-2 text-base'>
                                          {intl.formatMessage({
                                            id: 'QUESTIONNAIRE.LICENCEDETAILS.NO_FILE_CHOSEN',
                                          })}
                                        </span>
                                      )}
                                    </div>

                                    <div className='flex justify-center bg-gray-300 px-2 py-2'>
                                      <label htmlFor='AttachmentUrl'>Choose file</label>
                                    </div>
                                  </div>
                                </label>
                              </div>

                              {formik.touched.document_url && formik.errors.document_url && (
                                <div>
                                  <p className='text-sm text-red-600' id='error'>
                                    {formik.errors.document_url}
                                  </p>
                                </div>
                              )}
                              <div className='mt-2 text-sm'>
                                {intl.formatMessage({
                                  id: 'QUESTIONNAIRE.LICENCEDETAILS.UPLOADNOTE',
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {loading ? (
                        <Skeleton width='100%' />
                      ) : (
                        loadingFile && (
                          <p>
                            {intl.formatMessage({id: 'QUESTIONNAIRE.LICENCEDETAILS.UPLOADING'})}
                          </p>
                        )
                      )}
                    </div>
                    <div className='fv-plugins-message-container'>
                      {formik.touched.document_url && formik.errors.document_url && (
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.document_url}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* end::First Name form group */}

            {/* end::Has license form group */}
          </div>
          <div className='py-5'>
            {loading ? (
              <div className='flex items-center justify-between gap-2'>
                <Skeleton className='w-1/2 !rounded-full' height={50} variant='rounded'></Skeleton>
                <Skeleton className='w-1/2 !rounded-full' height={50} variant='rounded'></Skeleton>
              </div>
            ) : (
              <div className='flex items-center justify-between gap-2'>
                <Button
                  type='button'
                  disabled={formik.isSubmitting}
                  className={`w-1/2 px-3 ${
                    props.parentWrapper !== 'questionnaire' ? 'order-2' : ''
                  }`}
                  onClick={() => props.goToPrevTab()}
                  size='large'
                  halfWidth={true}
                  colour='outline'
                >
                  {props.parentWrapper === 'questionnaire'
                    ? intl.formatMessage({id: 'ACTIONS.BACK'})
                    : intl.formatMessage({id: 'ACTIONS.DISCARD'})}
                </Button>
                <Button
                  type='submit'
                  disabled={formik.isSubmitting || loadingFile}
                  name='next'
                  className={`w-1/2 px-3 ${
                    props.parentWrapper !== 'questionnaire' ? 'order-1' : ''
                  }`}
                  size='large'
                  halfWidth={true}
                  colour='gradient'
                  onClick={formik.handleSubmit}
                >
                  {props.parentWrapper === 'questionnaire' ? 'Save & Continue' : 'Save'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>
      <AddAddress
        addressType={addressType}
        show={show}
        onClose={(address: StudentAddress) => {
          handleClose(address)
        }}
        address={licenceAddress}
        citiesBinded={cities}
        licence_address={licenceAddress}
        billing_address={getAddress(AddressType.Billing)}
        pickup_address={getAddress(AddressType.Pickup)}
        licenceAddressDisabled={false}
      />
    </>
  )
}

export {LicenseDetails}

import React from 'react'

export interface TextAreaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
  label?: string
  id?: string
  name?: string
  className?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  error?: boolean
  errorMsg?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  inputGroup?: boolean
  inputGroupValue?: string
  rows?: number
  maxLength?: any
}

export default function TextArea({
  label,
  className = '',
  name,
  id = '',
  value,
  onChange,
  required = false,
  disabled = false,
  inputGroup = false,
  inputGroupValue = '',
  error = false,
  errorMsg = 'This field is required',
  placeholder,
  maxLength,
  rows,
  ...props
}: TextAreaProps) {
  return (
    <>
      <div className='flex flex-col items-start'>
        <div className='relative w-full'>
          <label
            htmlFor={name}
            className='inline-block text-base font-bold text-darkBlue'
          >
            {label}
            {required && <span className='text-sm text-red-500'>*</span>}
          </label>
          <div className='relative '>
            {inputGroup && (
              <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                <span className='text-gray-500 sm:text-sm'>{inputGroupValue}</span>
              </div>
            )}
            <textarea
              {...props}
              name={name}
              id={id}
              value={value}
              className={`${error ? 'ring-red-500' : 'ring-gray-300'}
                            ${inputGroup ? 'pl-7 pr-20' : 'px-2'} 
                            bg-gray-200
                            block w-full rounded-[10px] mt-1 border border-gray-600 py-2.5  rin g-1 rin g-inset  placeholder:text-gray-400 outline-none focus:ring-1 focus:ring-inset focus:ring-indigo-600 text-sm leading-6 focus:border focus:border-inset focus:border-indigo-600 ${className}`}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              required={required}
              maxLength={maxLength}
              rows={rows}
            />
          </div>

          {error && (
            <p className='text-sm text-red-600' id='error'>
              {errorMsg}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

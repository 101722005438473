import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {updateUserEmail} from '../../../services/auth.service'
import {useIntl} from 'react-intl'
import {
  deleteMyAccount,
  getCurrentStudentEmail,
  updateCurrentStudentEmail,
} from '../../../services/student.service'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../../setup'
import {User} from '../../../models/auth/User'
import * as auth from '../../../../../setup/redux/AuthRedux'
import {Auth} from 'aws-amplify'
import Alert from '../../shared/overlays/Alert'
import Input from '../../shared/forms/Input'
import Button from '../../shared/elements/Button'
import {Skeleton} from '@mui/material'
import {DeleteStudentAccountModal} from '../_modals/DeleteStudentAccountModal'
import {useNavigate} from 'react-router-dom'
import toast from 'react-hot-toast'

const ChangeEmail: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true
  const personalData = useSelector<RootState>(({auth}) => auth.personalData, shallowEqual) as User
  const dispatch = useDispatch()
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage(
          {id: 'VALIDATION.INVALID'},
          {field: intl.formatMessage({id: 'FORGOT_PASSWORD.EMAIL'})}
        )
      )
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'FORGOT_PASSWORD.EMAIL'})}
        )
      ),
  })
  const [email, setEmail] = useState<string>('')
  const [updated, setUpdated] = useState<boolean>(false)
  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const res = await getCurrentStudentEmail().catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get student email')
        setLoading(false)
      })
      if (res) {
        setEmail(res.results.email)
        setLoading(false)
        formik.setFieldValue('email', res.results.email)
      }
    }
    fetchData()
  }, [])

  const formik = useFormik({
    initialValues: {email: email},
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setUpdated(false)
      const res = await updateUserEmail(values.email).catch((err) => {
        setSubmitting(false)
        if (!err?.errorMessage) {
          toast.error('Unable to update user email')
        }
      })
      if (res) {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          const result = await updateCurrentStudentEmail(values.email, user.username).catch(
            (error) => {
              setSubmitting(false)
              if (!error?.errorMessage) {
                toast.error('Sorry, an error has occurred while updating the student email')
              }
            }
          )
          if (result) {
            setSubmitting(false)
            setUpdated(true)
            toast.success('Email updated successfully')
          }
        }
      }
    },
  })

  const sendDeleteRequest = async () => {
    setErrorMessage('')
    setSubmitting(true)
    const result = await deleteMyAccount().catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to delete account')
      setSubmitting(false)
    })
    if (result) {
      personalData.request_delete_account_at = Date.now().toString()
      dispatch(auth.actions.setPersonalData(personalData))
      setSubmitting(false)
    }
  }

  return (
    <>
      <form noValidate  onSubmit={formik.handleSubmit}>
        <div className='bg-white rounded-lg px-4 py-5'>
          {errorMessage != '' && <Alert colour='red' description={errorMessage} />}
          {/* begin::Title */}
          <div className=''>
            {!updated && formik.status ? <Alert colour='red' description={formik.status} /> : <></>}
            {updated ? <Alert colour='green' description={formik.status} /> : <></>}
            {/* end::Title */}

            {/* begin::Form group */}
            <div>
              {loading ? (
                <Skeleton width='100%' height={40} />
              ) : (
                <Input
                  label={intl.formatMessage({id: 'ACCOUNT_INFO.EMAIL'})}
                  type='email'
                  error={formik.touched.email && formik.errors.email ? true : false}
                  errorMsg={formik.errors.email}
                  {...formik.getFieldProps('email')}
                  readonly={formik.isSubmitting}
                />
              )}
            </div>
            {/* end::Form group */}
          </div>

          {isMobile && (
            <>
              <div className='bg-white flex flex-col shadow-none  py-5 my-5 mx-4 px-4 rounded w-100'>
                <div className='flex flex-col pb-7 w-full'>
                  {loading ? (
                    <Skeleton width='60%' />
                  ) : (
                    <h4 className='text-lg'>
                      {intl.formatMessage({id: 'ACCOUNT_INFO.DELETE_MY_ACCOUNT'})}
                    </h4>
                  )}
                  {loading ? (
                    <Skeleton width='100%' />
                  ) : (
                    <p className='text-base text-gray-650'>
                      {intl.formatMessage({id: 'ACCOUNT_INFO.DELETE_ACCOUNT_WARNINING'})}
                    </p>
                  )}
                </div>
                {personalData.request_delete_account_at != null && (
                  <Alert
                    colour='green'
                    description={intl.formatMessage({id: 'ACCOUNT_INFO.DELETE_ACCOUNT_SUBMITTED'})}
                  />
                )}
                {loading ? (
                  <Skeleton className=' !rounded-full' height={35} variant='rounded' />
                ) : (
                  <Button
                    type='button'
                    id='delete_account'
                    onClick={() => setShowDeleteAccountModal(true)}
                    disabled={personalData.request_delete_account_at != null}
                    size='large'
                    colour='pink'
                  >
                    {intl.formatMessage({id: 'ACTIONS.DELETE_ACCOUNT'})}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
        {/*START:: Form Actions */}
        <div className='py-5'>
          <>
            {loading ? (
              <div className='flex items-center justify-between gap-2'>
                <Skeleton className='w-1/2 !rounded-full' height={35} variant='rounded'></Skeleton>

                <Skeleton className='w-1/2 !rounded-full' height={35} variant='rounded'></Skeleton>
              </div>
            ) : (
              <div className='flex items-center justify-between gap-2'>
                <Button
                  disabled={formik.isSubmitting || !formik.isValid}
                  colour='gradient'
                  size='large'
                  halfWidth={true}
                  type='submit'
                >
                  {!loading && intl.formatMessage({id: 'ACTIONS.SUBMIT'})}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
                <Button
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    navigate('/')
                  }}
                  colour='outline'
                  size='large'
                  halfWidth={true}
                >
                  {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
                </Button>
              </div>
            )}
          </>
        </div>
        {/*END:: Form Actions */}
      </form>

      <DeleteStudentAccountModal
        show={showDeleteAccountModal}
        submitForDelete={() => {
          sendDeleteRequest()
          setShowDeleteAccountModal(false)
        }}
        onHide={() => setShowDeleteAccountModal(false)}
      ></DeleteStudentAccountModal>
    </>
  )
}
export {ChangeEmail}

export enum ZendeskStages {
  OnHold = 13301233,
  Expired = 13301232,
  Cancelled = 13301231,
  FIInProgress = 13301164,
  GRP = 13301198,
  Completed = 13301230,
  Lost = 12559593,
  LostPipeline = 4761783,
}

import {FC, useEffect, useState} from 'react'
import Toggler from '../../shared/forms/Toggler'
import {
  getNotificationSettings,
  updateNotificationSettings,
} from '../../../services/notification.service'
import {NotificationModel} from '../../../models/notification-model'
import toast from 'react-hot-toast'

const AppNotification: FC = () => {
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [notificationValues, setNotificationValues] = useState<Array<NotificationModel>>([])

  useEffect(() => {
    getNotificationValue()
  }, [])

  const getNotificationValue = async () => {
    setLoading(true)

    const notificationSettings = await getNotificationSettings().catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get notification settings')
    })
    setLoading(false)
    if (notificationSettings?.results) setNotificationValues(notificationSettings.results)

    setLoading(false)
  }

  const updateNotificationValue = async (id: string, value: boolean) => {
    setSubmitting(true)
    await updateNotificationSettings(id, {
      is_enabled: value,
    }).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to update notification settings')
    })
    setSubmitting(false)
  }

  return (
    <>
      <div className='bg-white rounded-lg px-4 py-5 mb-6'>
        {loading ? (
          <>
            <div className='flex items-center justify-between mb-5 rounded border px-6 pt-4 pb-3'>
              <Toggler size='full' loading={loading} checked={false} onChange={() => {}} />
            </div>
            <div className='flex items-center justify-between mb-5 rounded border px-6 pt-4 pb-3'>
              <Toggler size='full' loading={loading} checked={false} onChange={() => {}} />
            </div>
            <div className='flex items-center justify-between mb-5 rounded border px-6 pt-4 pb-3'>
              <Toggler size='full' loading={loading} checked={false} onChange={() => {}} />
            </div>
            <div className='flex items-center justify-between mb-5 rounded border px-6 pt-4 pb-3'>
              <Toggler size='full' loading={loading} checked={false} onChange={() => {}} />
            </div>
            <div className='flex items-center justify-between mb-5 rounded border px-6 pt-4 pb-3'>
              <Toggler size='full' loading={loading} checked={false} onChange={() => {}} />
            </div>
          </>
        ) : (
          <>
            {notificationValues?.map((notificationValue, index: number) => (
              <div className='flex items-center justify-between mb-5 rounded border px-6 pt-4 pb-3'>
                <Toggler
                  size='full'
                  loading={loading}
                  label={notificationValue.action_name_human}
                  checked={!!notificationValue.is_enabled}
                  onChange={() => {
                    notificationValue.is_enabled = !notificationValue.is_enabled
                    updateNotificationValue(
                      notificationValue.user_notification_action_settings_id,
                      notificationValue.is_enabled
                    )
                  }}
                  disabled={submitting}
                />
                <span
                  className={submitting ? 'slider round' : 'slider round cursor-pointer'}
                ></span>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  )
}

export {AppNotification}

import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import * as auth from '../../../../setup/redux/AuthRedux'
import {mobileLogin} from '../../../../setup/amplify/SetupMoblieAmplify'

export function MobileLogin() {
  const dispatch = useDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search)
  let sessionFromURL = Object.fromEntries(urlSearchParams.entries())

  useEffect(() => {
    localStorage.clear()
    dispatch(auth.actions.setIsMobile(true))
    dispatch(auth.actions.setIsLogin(true))
    dispatch(auth.actions.login())
    mobileLogin(
      sessionFromURL.idToken,
      sessionFromURL.refreshToken,
      sessionFromURL.username,
      sessionFromURL.accessToken
    )
  }, [])
  return <></>
}

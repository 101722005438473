import {FC} from 'react'
import {useIntl} from 'react-intl'
import PageHeader from '../../components/shared/elements/PageHeader'
import {PaymentHistory} from '../../components/payment-history/PaymentsHistory'

const PaymentHistoryWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader
          title={intl.formatMessage({id: 'MENU.PAYMENT_HISTORY'})}
          backToURL='/profile'
          isCenter
        />
      </div>
      <div className='px-6 py-4'>
        <PaymentHistory />
      </div>
    </>
  )
}

export {PaymentHistoryWrapper}

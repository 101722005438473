import {FC} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import {AppNotification} from '../../components/account-settings/app-notification/AppNotification'

const NotificationWrapper: FC = () => {
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='Notification' backToURL='/profile' isCenter />
      </div>
      <div className='px-5 py-6 p-lg-0'>
        <AppNotification />
      </div>
    </>
  )
}

export {NotificationWrapper}

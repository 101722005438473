import {FC} from 'react'
import {ChangeEmail} from '../../components/account-settings/change-email/ChangeEmail'
import PageHeader from '../../components/shared/elements/PageHeader'

const ChangeEmailWrapper: FC = () => {
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='Change Your Email' backToURL='/profile' isCenter></PageHeader>
      </div>

      <div className='px-5 py-6 p-lg-0'>
        <ChangeEmail></ChangeEmail>
      </div>
    </>
  )
}

export default ChangeEmailWrapper

export enum JourneyStepsEnum {
  ArrangingTheory = 1,
  TheoryTest = 2,
  ArrangingPractical = 3,
  AllocatingInstructor = 4,
  Balance = 5,
  Instructor = 6,
  Lessons = 7,
  PracticalTest = 8,
}

import React, {useEffect, useState} from 'react'
import Button from '../shared/elements/Button'
import {getQueryRelatedOptions} from '../../services/student.service'
import {GetInTouch} from './GetInTouch'
import toast from 'react-hot-toast'

interface IProps {
  isNotAllowed: boolean
}

const ContactInfo: React.FC<IProps> = (props) => {
  const title = props.isNotAllowed
    ? 'You are not allowed to access the portal,'
    : 'Got a question? Check our HelpCentre.'
  const [showGetInTouchModal, setShowGetInTouchModal] = useState<boolean>(false)
  const onClose = () => {
    setShowGetInTouchModal(false)
  }
  const [queryRelatedOptions, setQueryRelatedOptions] = useState<Array<any>>([])

  useEffect(() => {
    async function fetchQueryRelatedOptions() {
      let response = await getQueryRelatedOptions().catch((error) => {
        if (!error?.errorMessage) toast.error('Something went wrong ,please try again later')
      })
      if (!response) {
        return
      }
      let queryRelatedOptionsList = response.results.map((q: any) => ({label: q.name, value: q.id}))
      setQueryRelatedOptions(queryRelatedOptionsList)
    }
    fetchQueryRelatedOptions()
  }, [])
  return (
    <>
    <div className='help-content bg-white rounded-lg px-4 py-5 mb-6 w-full'>
      <p className='text-base	font-bold py-3'>{title}</p>
      <Button
        type='button'
        
        name='next'
        className='link--basic btn btn-secondary'
        size='large'
        fullWidth
        colour='darkBlue'
        onClick={() => {
          window.open('https://passmefast.zendesk.com/hc/en-gb', '_blank', 'noopener,noreferrer')
        }}
      >
        PassMeFast FAQ
      </Button>
      <p className='text-base	font-bold py-3'>Can't find the answer you're looking for?</p>
      <Button
        type='button'
        
        name='next'
        className='link--basic btn btn-secondary'
        size='large'
        fullWidth
        colour='darkBlue'
        onClick={() => {
          setShowGetInTouchModal(true)
        }}
      >
        Get in touch
      </Button>
      
    </div>
    <GetInTouch
    onClose={onClose}
    show={showGetInTouchModal}
    queryRelatedOptions={queryRelatedOptions}
  />
  </>
  )
}

export default ContactInfo

import {FC} from 'react'
import Modal from '../../shared/overlays/Modal'
import Alert from '../../shared/overlays/Alert'

export interface IAvailabilityWarningInterface {
  hasAssignedADI: boolean
  show: boolean
  onclose: () => void
  onSubmit: () => void
}

const AvailabilityWarningModal: FC<IAvailabilityWarningInterface> = ({
  hasAssignedADI,
  show,
  onclose,
  onSubmit,
}) => {
  return (
    <Modal
      open={show}
      title='Availability Restricted'
      closeText='Go Back'
      submitText='Confirm'
      onClose={onclose}
      onSubmit={onSubmit}
    >
      <div className='px-2 mx-1'>
        <Alert
        colour='pink'
        description={hasAssignedADI
          ? 'Your instructor has taken on the course based on your existing availability. Please discuss any changes with them to ensure they can still provide lessons.'
          : 'Please bear in mind that restricting your availability will make it more difficult to match you with an instructor and could cause delays'}
          icon='pmf-icon-info'
        />        
      </div>
      
    </Modal>
  )
}

export {AvailabilityWarningModal}

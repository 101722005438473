/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Route, Routes} from 'react-router-dom'
import {AccountSettings} from '../../components/account-settings/AccountSettings'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {User} from '../../models/auth/User'
import {SignOutButton} from '../../components/account-settings/SignOutButton'
import {AboutCourseWrapper} from './AboutCourseWrapper'
import {LicenceDetailsWrapper} from './LicenceDetailsWrapper'
import {NotificationWrapper} from './NotificationWrapper'
import ChangeEmailWrapper from './ChangeEmailWrapper'
import CommunicationWrapper from './CommunicationWrapper'
import MedicalConditionWrapper from './MedicalConditionWrapper'
import AboutStudentWrapper from './AboutStudentWrapper'

const AccountSettingsWrapper: FC = () => {
  const personalData: User = useSelector<RootState>(
    ({auth}) => auth.personalData,
    shallowEqual
  ) as User

  return (
    <>
      {/* begin::Account settings routes */}
      <Routes>
        <Route path='/*'>
          <Route index element={<AccountSettings personalData={personalData} />} />
          <Route path='about-u' element={<AboutStudentWrapper />} />
          <Route path='change-email' element={<ChangeEmailWrapper />} />
          <Route path='licence-details' element={<LicenceDetailsWrapper />} />
          <Route path='availability' element={<AboutCourseWrapper />} />
          <Route path='medical-conditions' element={<MedicalConditionWrapper />} />
          <Route path='communication' element={<CommunicationWrapper />} />
          <Route path='notification' element={<NotificationWrapper />} />
        </Route>
      </Routes>

      {/* end::Account settings routes */}
    </>
  )
}

export {AccountSettingsWrapper}

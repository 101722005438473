import {FC, useState} from 'react'
import Modal from '../../shared/overlays/Modal'
import {useIntl} from 'react-intl'
import {Test} from '../../../models/tests/test'
import {acceptTest} from '../../../services/test.service'
import {useCourse} from '../../../providers/CourseProvider'
import {TestTypeEnum} from '../../../models/enums/test-type'
import TestDetails from '../TestDetails'
import Alert from '../../shared/overlays/Alert'
import {InformationCircleIcon} from '@heroicons/react/24/outline'
import toast from 'react-hot-toast'

type Props = {
  test: Test | undefined
  show: any
  onHide: any
  onStatusSubmit: any
}

const AcceptTest: FC<Props> = ({test, show, onHide, onStatusSubmit}) => {
  const intl = useIntl()
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const {Course} = useCourse()

  const handleSubmit = async () => {
    if (!test) return
    setErrorMessage('')
    setShowError(false)
    setIsSubmitting(true)
    const res = await acceptTest(test.id).catch((error) => {
      setIsSubmitting(false)
      setShowError(true)
      if (!error?.errorMessage) toast.error('Failed to accept test')
    })
    if (!res) return
    await onStatusSubmit()
    setIsSubmitting(false)
    onHide()
  }

  return (
    <>
      <Modal
        open={show}
        onClose={onHide}
        onSubmit={handleSubmit}
        title="Accept a test"
        submitText={intl.formatMessage({id: 'ACTIONS.ACCEPT'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        disabled={isSubmitting}
      >
        <div>
          <h4 className='text-base font-bold mb-3'>{intl.formatMessage(
          {id: 'STUDENT_TEST.DOYOUWANTTO'},
          {
            option: intl.formatMessage({id: 'STUDENT_TEST.ACCEPT'}),
          }
        )}</h4>
          {showError && <Alert description={errorMessage} colour='red'></Alert>}
          <TestDetails test={test} />
          {Course.order?.is_pto &&
          Course.order?.is_rebooking &&
          test?.type === TestTypeEnum.Practical ? (
            <div className='alert alert-warning mb-0 shadow-none mt-3'>
              <InformationCircleIcon className='w-4 h-4' />{' '}
              <span className='font-size-16 fw-600'>
                <span>{intl.formatMessage({id: 'STUDENT_TEST.ACCEPT_PT_MSG'})}</span>
              </span>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  )
}

export default AcceptTest

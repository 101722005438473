import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'

interface IProps {
  label: string
  name: string
  options: Array<{
    value: any
    label: string | React.ReactNode
    img?: string
    nonselectImg?: string | undefined
    description?: string
  }>
  onChange: (value: any) => void
  values?: string[]
  disabled: boolean
  error?: boolean
  fullWidth?: boolean
  errorMsg?: string
  required?: boolean
}

export default function CheckboxInputGroup({
  label,
  name,
  values = [],
  options = [],
  onChange,
  disabled = false,
  error = false,
  fullWidth = false,
  errorMsg = 'This field is required',
  required,
}: IProps) {
  const toggleSelected = (e: any, data: any) => {
    if (e.target.checked) {
      values = [...values, data]
    } else {
      values = values.filter((elem: any) => elem != data)
    }
    onChange(values)
  }

  return (
    <>
      <div className='mb-[2px]'>
        <span className='text-base font-bold text-darkBlue'>
          {label}
          {required && <span className='text-sm text-red-500'>*</span>}
        </span>
        <div
          className={`${options.length > 2 ? 'grid-cols-3' : 'grid-cols-2'} ${
            fullWidth && ' !grid-cols-1'
          } mt-1 grid gap-2`}
        >
          {options.map((option, index) => (
            <div key={index} className='relative'>
              <label
                className={`${values.includes(option.value) ? 'border-darkBlue' : error ? 'border-red-300':'border-gray-600'} ${
                  option.img || option.nonselectImg
                    ? 'sm:p-3.5 px-0 py-3.5 h-full'
                    : 'px-3.5 py-2.5 h-[80px]'
                } 
                ${fullWidth && ' !h-auto'}
               
                relative flex focus:outline-none cursor-pointer items-center rounded-[10px] border-2`}
              >
                <input
                  className='absolute z-[1] opacity-0 h-full w-full cursor-pointer'
                  type='checkbox'
                  name={name + index}
                  id={name + index}
                  checked={values?.indexOf(option.value) !== -1}
                  onChange={(e) => toggleSelected(e, option.value)}
                  data-parsley-multiple={name}
                  disabled={disabled}
                  required={required}
                />
                <span
                  className={` ${
                    option.img || option.nonselectImg ? 'items-center text-center' : 'items-start'
                  } flex flex-1 flex-col justify-center`}
                >
                  {(option.img || option.nonselectImg) && (
                    <img
                      alt={label}
                      src={toAbsoluteUrl(
                        !values.includes(option.value) && option.nonselectImg
                          ? option.nonselectImg
                          : option.img
                            ? option.img
                            : ''
                      )}
                      className='px-0 w-16 h-auto mb-4'
                    />
                  )}

                  <span
                    className={`${
                      !values.includes(option.value) || option.img || option.nonselectImg
                        ? 'text-darkBlue'
                        : 'text-white'
                    } block text-base font-semibold`}
                  >
                    {option.label}
                  </span>
                </span>
                {option.description && (
                  <div
                    className={`${
                      !values.includes(option.value) || option.img || option.nonselectImg
                        ? 'text-darkBlue'
                        : 'text-white'
                    } block text-xs font-medium mx-10`}
                  >
                    {option.description}
                  </div>
                )}
              </label>
            </div>
          ))}
        </div>
      </div>
      {error && (
        <p className='text-sm text-red-600' id='error'>
          {errorMsg}
        </p>
      )}
    </>
  )
}

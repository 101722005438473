import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {StudentPersonalData} from '../../../models/questionnaire/student-personal-data'
import {scrollToFirstError, scrollToTop} from '../../../helpers/ScrollHelper'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {postalCodeExpression, phoneExpression} from '../../../models/enums/patterns-enum'
// TODO: fix typo => Questionnaire
import {IQuationnaireTabProps} from '../../../models/quationnaire-tab-props'
import moment from 'moment'

import {StudentAddress} from '../../../models/questionnaire/student-address'
import {AddressType} from '../../../models/enums/address-type-enum'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {User} from '../../../models/auth/User'
import {RootState} from '../../../../../setup'
import {useCourse} from '../../../providers/CourseProvider'
import {Skeleton} from '@mui/material'
import {purchaseRequest} from '../../../services/addons.service'
import {CourseUpgradeResponse} from '../../../models/order/course-upgrade-response'
import {getAdiBasicData} from '../../../services/adi.service'
import {
  checkCourseUpgrade,
  upgradeStudentCourse,
  rescheduleTestForCourseUpgrade,
} from '../../../services/booking.service'
import {getYears, getMonths, getAllDays} from '../../../services/date.service'
import {getTakenHours} from '../../../services/lesson.service'
import {getCities, getTitles, getGenders} from '../../../services/lookup.service'
import {
  getSavedSteps,
  getCurrentStudent,
  updatePersonalData,
  addOrUpdateAddress,
} from '../../../services/student.service'
import Dropdown from '../../shared/forms/Dropdown'
import Alert from '../../shared/overlays/Alert'
import Input from '../../shared/forms/Input'
import StackedList from '../../shared/data/StackedList'
import Button from '../../shared/elements/Button'
import {AddAddress} from '../_modals/AddAddress'
import CourseUpgradeModal from '../_modals/CourseUpgradeModal'
import {formatDate} from '../../../helpers/DateHelper'
import * as auth from '../../../../../setup/redux/AuthRedux'
import {getPaymentUrl} from '../../../helpers/PaymentHelper'
import PageHeader from '../../shared/elements/PageHeader'
import toast from 'react-hot-toast'
const prepareInitialData = (data: StudentPersonalData = new StudentPersonalData()) => {
  const studentData = {
    id: data.id ?? '',
    zendesk_contact_id: data.zendesk_contact_id ?? '',
    first_name: data.first_name ? data.first_name : '',
    title_id: data.title_id ?? 0,
    gender_id: data.gender_id ?? 0,
    other_title: data.other_title ? data.other_title : '',
    surname: data.surname ? data.surname : '',
    date_of_birth: data.date_of_birth ? data.date_of_birth : '',
    day: data.day ? data.day : '',
    month: data.month ? data.month : '',
    year: data.year ? data.year : '',
    phone: {
      id: data.phone.id,
      phone: data.phone.phone ? data.phone.phone : '',
    },
    addresses: data.addresses ? data.addresses : [],
    billing_address:
      data.addresses?.find((x) => x.address_type_id === AddressType.Billing) ??
      new StudentAddress(AddressType.Billing),
    pickup_address:
      data.addresses?.find((x) => x.address_type_id === AddressType.Pickup) ??
      new StudentAddress(AddressType.Pickup),
    licence_address:
      data.addresses?.find((x) => x.address_type_id === AddressType.Licence) ??
      new StudentAddress(AddressType.Licence),
    have_licence: data.have_licence !== null ? !!Number(data.have_licence) : null,
    pickup_required: data.pickup_required,
  }
  return studentData
}

const getAddress = (addressType: AddressType | number): StudentAddress => {
  const address = {
    address_type_id: addressType,
    city_id: '',
    city_name: '',
    id: '',
    postal_code: '',
    street_address: '',
    is_copied_from_licence: false,
  }
  return address
}

const AboutStudent: FC<IQuationnaireTabProps> = (props) => {
  const personalData: User = useSelector<RootState>(
    ({auth}) => auth.personalData,
    shallowEqual
  ) as User
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [titles, setTitles] = useState<Array<any>>([])
  const [cities, setCities] = useState<Array<any>>([])
  const [genders, setGenders] = useState<Array<any>>([])
  const [selectedGender, setSelectedGender] = useState<any>({id: 0, gender_name: 'Gender'})
  const [selectedTitle, setSelectedTitle] = useState<any>({id: 0, title_name: 'Title'})
  const [titlesBinded, setTitlesBinded] = useState<Array<any>>([])
  const [gendersBinded, setGendersBinded] = useState<Array<any>>([])
  const [studentData, setStudentData] = useState<StudentPersonalData>(prepareInitialData())
  const [licenceAddressDisabled, setLicenceAddressDisabled] = useState(false)
  const years = getYears(100, new Date().getMonth() > 8 ? 16 : 17, false)
  const months = getMonths()
  const days = getAllDays()
  const dispatch = useDispatch()
  const [pickupAddressEnabled, setPickupAddressEnabled] = useState<boolean>(true)
  const course = useCourse()
  // course upgrade
  const [showCourseUpgradeModal, setShowCourseUpgradeModal] = useState<boolean>(false)
  const [courseUpgradeResponse, setCourseUpgradeResponse] = useState<CourseUpgradeResponse>()
  const pattern: RegExp = new RegExp((process.env.REACT_APP_NAME_REGX ?? '') + '$')
  const validPostalCode = new RegExp(postalCodeExpression + '$')
  const validPhone = new RegExp(phoneExpression + '$')
  const [licenceAddress, setLicenceAddress] = useState<StudentAddress>(
    getAddress(AddressType.Licence)
  )
  const [pickupAddress, setPickupAddress] = useState<StudentAddress>(getAddress(AddressType.Pickup))
  const [originalPickupAddress, setOriginalPickupAddress] = useState<StudentAddress>(
    getAddress(AddressType.Pickup)
  )
  const [billingAddress, setBillingAddress] = useState<StudentAddress>(
    getAddress(AddressType.Billing)
  )
  const [selectedAddress, setSelectedAddress] = useState<StudentAddress>(getAddress(0))
  const [show, setShow] = useState(false)
  const [addressType, setAddressType] = useState({})
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) === true

  const updateAboutSchema = Yup.object().shape({
    title_id: Yup.number()
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.TITLE'})}
        )
      )
      .min(
        1,
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.TITLE'})}
        )
      )
      .nullable(),
    other_title: Yup.string().when('title_id', {
      is: (title_id: any) => {
        const condition = title_id === 5
        return condition
      },
      then: (schema) =>
        schema
          .required(
            intl.formatMessage(
              {id: 'VALIDATION.REQUIRED'},
              {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.OTHERTITLE'})}
            )
          )
          .nullable(),
    }),
    gender_id: Yup.number()
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.GENDER'})}
        )
      )
      .min(
        1,
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.GENDER'})}
        )
      )
      .nullable(),
    year: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.YEAR'})}
      )
    ),
    month: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.MONTH'})}
      )
    ),
    day: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.DAY'})}
      )
    ),
    first_name: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.PLURAL_REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.FIRST_AND_MIDDLE_NAMES'})}
        )
      )
      .matches(
        pattern,
        intl.formatMessage(
          {id: 'VALIDATION.INVALID'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.FIRST_AND_MIDDLE_NAMES'})}
        )
      )
      .min(2, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 2}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50})),
    surname: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.SURNAME'})}
        )
      )
      .matches(
        pattern,
        intl.formatMessage(
          {id: 'VALIDATION.INVALID'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.SURNAME'})}
        )
      )
      .min(2, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 2}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50})),
    have_licence: Yup.boolean().nullable(),
    pickup_required: Yup.boolean().nullable(),
    licence_address: Yup.object().when('have_licence', {
      is: (val: boolean) => val === true,
      then: () =>
        Yup.object().shape({
          street_address: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
            .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
            .required(
              intl.formatMessage(
                {id: 'VALIDATION.REQUIRED'},
                {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.STREETADDRESS'})}
              )
            ),
          postal_code: Yup.string()
            .matches(validPostalCode, 'Must be a valid postal code')
            .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
            .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
            .required(
              intl.formatMessage(
                {id: 'VALIDATION.REQUIRED'},
                {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.POSTCODE'})}
              )
            ),
          city_name: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
            .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
            .required(
              intl.formatMessage(
                {id: 'VALIDATION.REQUIRED'},
                {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.CITY'})}
              )
            ),
        }),
    }),
    phone: Yup.object().shape({
      phone: Yup.string()
        .matches(
          validPhone,
          intl.formatMessage(
            {id: 'VALIDATION.INVALID'},
            {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.MOBILENUMBER'})}
          )
        )
        .required(
          intl.formatMessage(
            {id: 'VALIDATION.REQUIRED'},
            {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.MOBILENUMBER'})}
          )
        ),
    }),
    pickup_address: Yup.object().when('pickup_required', {
      is: (val: boolean) => val === true,
      then: () =>
        Yup.object().shape({
          street_address: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
            .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
            .required(
              intl.formatMessage(
                {id: 'VALIDATION.REQUIRED'},
                {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.STREETADDRESS'})}
              )
            ),
          postal_code: Yup.string()
            .matches(validPostalCode, 'Must be a valid postal code')
            .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
            .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
            .required(
              intl.formatMessage(
                {id: 'VALIDATION.REQUIRED'},
                {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.POSTCODE'})}
              )
            ),
          city_name: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
            .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
            .required(
              intl.formatMessage(
                {id: 'VALIDATION.REQUIRED'},
                {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.CITY'})}
              )
            ),
        }),
    }),
    billing_address: Yup.object().shape({
      street_address: Yup.string()
        .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
        .required(
          intl.formatMessage(
            {id: 'VALIDATION.REQUIRED'},
            {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.STREETADDRESS'})}
          )
        ),
      postal_code: Yup.string()
        .matches(validPostalCode, 'Must be a valid postal code')
        .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
        .required(
          intl.formatMessage(
            {id: 'VALIDATION.REQUIRED'},
            {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.POSTCODE'})}
          )
        ),
      city_name: Yup.string()
        .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
        .required(
          intl.formatMessage(
            {id: 'VALIDATION.REQUIRED'},
            {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.CITY'})}
          )
        ),
    }),

    date_of_birth: Yup.object().when(['day', 'month', 'year'], {
      is: () => {
        return formik.values.year && formik.values.month && formik.values.day
      },
      then: () =>
        Yup.string()
          .test(
            'ValidateDate',
            intl.formatMessage(
              {id: 'VALIDATION.INVALID'},
              {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.DATEOFBIRTH'})}
            ),
            () => {
              const date: string = `${formik.values.year}-${
                getMonths().findIndex((a) => a.value === formik.values.month) + 1
              }-${formik.values.day}`
              const isValid = moment(date, 'YYYY-M-D', true).isValid()
              return isValid
            }
          )
          .test(
            'ValidateDate',
            intl.formatMessage({id: 'VALIDATION.DATE_OF_BIRTH_LESS_THAN_17_YEARS'}),
            () => {
              const day: number = Number(formik.values.day)
              const month: number =
                getMonths().findIndex((a) => a.value === formik.values.month) + 1
              const year: number = Number(formik.values.year)
              return isDate17orMoreYearsOld(day, month, year)
            }
          ),
    }),
  })

  const isDate17orMoreYearsOld = (day: number, month: number, year: number) => {
    // allow student whose age 17 || will be 17 in next 3 months
    let min_date = moment().subtract(17, 'years').add(3, 'months')
    let birthDate = moment(new Date(year, month - 1, day))
    return birthDate.isBefore(min_date)
  }

  useEffect(() => {
    if (props.parentWrapper === 'questionnaire') {
      if (props.have_licence === false) {
        setLicenceAddress(getAddress(AddressType.Licence))
        formik.setFieldValue('have_licence', false)
        formik.setFieldValue('licence_address.street_address', '')
        formik.setFieldValue('licence_address.city_name', '')
        formik.setFieldValue('licence_address.postal_code', '')
        setLicenceAddressDisabled(true)
      } else if (props.have_licence === true) {
        formik.setFieldValue('have_licence', true)
        setLicenceAddressDisabled(false)
      }
    }
  }, [props.have_licence])

  useEffect(() => {
    setLoading(true)
    if (!course?.Course?.deal_id) return
    const fetchData = async () => {
      const citiesPromise = await getCities().catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get cities')
      })
      if (citiesPromise) {
        const cities = citiesPromise
        const citiesBinded = cities.data.map((city: any) => ({
          label: city.city_name,
          value: city.id,
        }))
        setCities(citiesBinded)
      }
      const titlesPromise = await getTitles().catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get titles')
      })
      if (titlesPromise) {
        const titles = titlesPromise.results
        setTitles(titles)
      }

      const gendersPromise = await getGenders().catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get genders')
      })
      if (gendersPromise) {
        const genders = gendersPromise.results
        setGenders(genders)
      }
      let arrPromises = []
      if (props.bookingId) {
        const savedStepsPromise = getSavedSteps()
        arrPromises = [citiesPromise, titlesPromise, gendersPromise, savedStepsPromise]
      } else {
        arrPromises = [citiesPromise, titlesPromise, gendersPromise]
      }

      const response = await Promise.all(arrPromises).catch((err) => {})
      if (response) {
        const stepCompleted =
          response.length === 4 ? response[3]?.results.saved_steps.some((x: any) => x === 2) : true
        let currentStudentResponse = await getCurrentStudent().catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get current student')
          return
        })

        let adiBasicDataResponse = await getAdiBasicData(course?.Course?.deal_id)

        let takenHoursResponse = await getTakenHours({job_ref: course?.Course?.deal_id}).catch(
          (error) => {
            if (!error?.errorMessage) toast.error('Failed to get student taken hours')
          }
        )

        if (currentStudentResponse.results != null) {
          if (!stepCompleted && props.parentWrapper === 'questionnaire') {
            currentStudentResponse.results.first_name = ''
            currentStudentResponse.results.surname = ''
          }
          const studentData = prepareInitialData(currentStudentResponse.results)
          setStudentData(studentData)
          props.updateHaveLicense && props.updateHaveLicense(studentData.have_licence ?? false)
          setLicenceAddressDisabled(studentData.have_licence === true ? false : true)
          setAddresses(
            currentStudentResponse.results.addresses,
            adiBasicDataResponse?.results != null,
            +(takenHoursResponse?.results?.taken_hours ?? 0) > 0
          )
        }
        setLoading(response === null)
      }
    }
    fetchData()
  }, [course?.Course?.deal_id])

  useEffect(() => {
    if (titles.length > 0) {
      let titlesList = titles.map((title) => ({label: title.title_name, value: title.id}))
      setTitlesBinded(titlesList)
    }
    if (genders.length > 0) {
      let gendersList = genders.map((gender) => ({label: gender.gender_name, value: gender.id}))
      setGendersBinded(gendersList)
    }
  }, [titles, genders])

  useEffect(() => {
    if (studentData.title_id && titlesBinded.length > 0) {
      setSelectedTitle({
        id: studentData.title_id,
        title_name: titlesBinded.find((t) => t.value === studentData.title_id)?.label,
      })
    }
    if (studentData.gender_id && gendersBinded.length > 0) {
      setSelectedGender({
        id: studentData.gender_id,
        gender_name: gendersBinded.find((g) => g.value == studentData.gender_id)?.label,
      })
    }
  }, [studentData, gendersBinded, titlesBinded])

  const setAddresses = (
    addresses?: Array<StudentAddress>,
    haveAdi?: boolean,
    haveCompletedLessons?: boolean
  ) => {
    if (addresses !== null) {
      let l = addresses?.find((a) => a.address_type_id === AddressType.Licence)
      let p = addresses?.find((a) => a.address_type_id === AddressType.Pickup)
      let b = addresses?.find((a) => a.address_type_id === AddressType.Billing)

      if (l !== undefined) {
        setLicenceAddress({...l, is_copied_from_licence: !!Number(l.is_copied_from_licence)})
      }
      if (p !== undefined) {
        setPickupAddress({...p, is_copied_from_licence: !!Number(p.is_copied_from_licence)})
        setOriginalPickupAddress({...p, is_copied_from_licence: !!Number(p.is_copied_from_licence)})
        setPickupAddressEnabled(
          props.parentWrapper === 'questionnaire' ||
            (props.parentWrapper !== 'questionnaire' && !haveAdi && !haveCompletedLessons)
        )
      } else {
        let address = getAddress(AddressType.Pickup)
        address.postal_code = course.Course.order.postcode
        setPickupAddress({...address})
        setOriginalPickupAddress({...address})
      }
      if (b !== undefined) {
        setBillingAddress({...b, is_copied_from_licence: !!Number(b.is_copied_from_licence)})
      }
    }
  }

  const updateSelectedAddress = (address: StudentAddress) => {
    if (address) {
      if (address.address_type_id === AddressType.Licence) {
        setLicenceAddress(address)
        formik.setFieldValue('licence_address', address)
        formik.setFieldTouched('licence_address', true)
        if (pickupAddress?.is_copied_from_licence) {
          if (pickupAddressEnabled) {
            pickupAddress.city_id = address.city_id
            pickupAddress.city_name = address.city_name
            pickupAddress.lat = address.lat
            pickupAddress.long = address.long
            pickupAddress.postal_code = address.postal_code
            pickupAddress.street_address = address.street_address
          } else {
            pickupAddress.is_copied_from_licence = false
          }
          setPickupAddress(pickupAddress)
          formik.setFieldValue('pickup_address', pickupAddress)
          formik.setFieldTouched('pickup_address', true)
        }
        if (billingAddress?.is_copied_from_licence) {
          billingAddress.city_id = address.city_id
          billingAddress.city_name = address.city_name
          billingAddress.lat = address.lat
          billingAddress.long = address.long
          billingAddress.postal_code = address.postal_code
          billingAddress.street_address = address.street_address
          setBillingAddress(billingAddress)
          formik.setFieldValue('billing_address', billingAddress)
          formik.setFieldTouched('billing_address', true)
        }
      } else if (address.address_type_id === AddressType.Pickup) {
        setPickupAddress(address)
        formik.setFieldValue('pickup_address', address)
        formik.setFieldTouched('pickup_address', true)
      } else if (address.address_type_id === AddressType.Billing) {
        setBillingAddress(address)
        formik.setFieldValue('billing_address', address)
        formik.setFieldTouched('billing_address', true)
      }
    }
  }
  const checkCourseUpgradeWhenPickupAddressChange = async (
    setSubmitting: any,
    selectedAddress: StudentAddress,
    isSubmit?: boolean
  ) => {
    if (selectedAddress.address_type_id === AddressType.Pickup) {
      const res = await checkCourseUpgrade(
        course.Course.order.id,
        selectedAddress.postal_code
      ).catch((error) => {
        setSubmitting(false)
        scrollToTop()
        if (!error?.errorMessage) toast.error('Failed to check course upgrade')
      })
      if (res) {
        setCourseUpgradeResponse(res)
        if (selectedAddress.postal_code === originalPickupAddress.postal_code) {
          if (props.parentWrapper === 'questionnaire') {
            await upgradeCourse()
          }
          setSubmitting(false)
        } else {
          setShowCourseUpgradeModal(true)
        }
        setSubmitting(false)
        return true
      }
    } else {
      await updateStudentPersonalData(studentData, false)
      setSubmitting(false)
      return true
    }
  }

  const addressHasData = (address: StudentAddress) => {
    if (address.street_address && address.postal_code && address.city_name) return true
    return false
  }
  const formik = useFormik({
    initialValues: prepareInitialData(studentData),
    enableReinitialize: true,
    validationSchema: updateAboutSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      studentData.phone.phone = formik.values.phone.phone.replace(/^0+/, '')
      studentData.date_of_birth = formatDate(values.year, values.month, values.day)
      studentData.day = values.day
      studentData.month = values.month
      studentData.year = values.year
      studentData.first_name = formik.values.first_name
      studentData.surname = formik.values.surname
      studentData.other_title = formik.values.other_title
      studentData.title_id = formik.values.title_id
      studentData.gender_id = formik.values.gender_id
      studentData.addresses = []
      if (addressHasData(licenceAddress)) {
        studentData.addresses.push(licenceAddress)
      }
      if (addressHasData(pickupAddress)) {
        if (licenceAddressDisabled) {
          pickupAddress.is_copied_from_licence = false
        }
        studentData.addresses.push(pickupAddress)
      }
      if (addressHasData(billingAddress)) {
        if (licenceAddressDisabled) {
          billingAddress.is_copied_from_licence = false
        }
        studentData.addresses.push(billingAddress)
      }
      setStudentData(studentData)
      // handle pickup address postcode changes
      props.parentWrapper === 'questionnaire' &&
        (await checkCourseUpgradeWhenPickupAddressChange(setSubmitting, pickupAddress, true))
      props.parentWrapper === 'accountsettings' &&
        (await updateStudentPersonalData(studentData, formik.isSubmitting))
    },
  })

  const updateStudentPersonalData = async (
    data: StudentPersonalData,
    continueIfSuccess: boolean
  ) => {
    await updatePersonalData(data).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to update personal data')
      formik.setSubmitting(false)
      scrollToTop()
      return false
    })

    personalData.sur_name = studentData.surname
    personalData.name = studentData.first_name
    dispatch(auth.actions.setPersonalData(personalData))
    course.getAddress()
    formik.setSubmitting(false)
    if (continueIfSuccess) {
      props.goToNextTab()
    } else {
      return true
    }
  }

  const upgradeCourse = async (
    can_be_updated: boolean = false,
    from_upgrade_course_modal: boolean = false
  ) => {
    if (
      courseUpgradeResponse?.has_accepted_practical ||
      courseUpgradeResponse?.has_accepted_theory
    ) {
      rescheduleTest()
      return
    }
    formik.setSubmitting(true)
    // Call to upgrade api and updateStudentPersonalData
    const response = await upgradeStudentCourse(
      course.Course.order.id,
      pickupAddress.postal_code,
      can_be_updated
    )
      .catch((error) => {
        formik.setSubmitting(false)
        scrollToTop()
        if (!error?.errorMessage) toast.error('Failed to update student course')
      })
      .finally(() => {
        setShowCourseUpgradeModal(false)
      })
    if (response && response.updated) {
      course.updateCourse('order', {
        ...course.Course?.order,
        postcode: pickupAddress.postal_code,
      })
      if (from_upgrade_course_modal) {
        await addOrUpdateAddress(studentData.id ?? '', pickupAddress).catch((error) => {
          formik.setSubmitting(false)
          scrollToTop()
          if (!error?.errorMessage) toast.error('Failed to add/update student address')
        })
      } else {
        await updateStudentPersonalData(
          studentData,
          props.parentWrapper === 'accountsettings' ? false : true
        )
      }
      studentData.addresses!.forEach((a) => {
        if (a.address_type_id == AddressType.Pickup) {
          setPickupAddress(a)
          setOriginalPickupAddress(a)
        } else if (a.address_type_id == AddressType.Licence) {
          setLicenceAddress(a)
        } else if (a.address_type_id == AddressType.Billing) {
          setBillingAddress(a)
        }
      })
      setStudentData(studentData)
      setShow(false)
      scrollToTop()
    }
  }

  const rescheduleTest = async () => {
    formik.setSubmitting(true)
    // 1- Create cart with reschedule test product
    let requestBody = {
      product_id: courseUpgradeResponse?.has_accepted_practical
        ? courseUpgradeResponse?.reschedule_pt_product?.id
        : courseUpgradeResponse?.reschedule_tt_product?.id,
      order_id: course.Course.order?.id,
      deal_id: course.Course.deal_id,
      postcode: pickupAddress.postal_code,
      zendesk_contact_id: studentData.zendesk_contact_id,
      source: 'Reschedule Test for course upgrade',
    }
    try {
      let cartResponse = await rescheduleTestForCourseUpgrade(requestBody)
      // 2- update personal data with cart token and add is_pending_payment flag to pickup address
      let updateData = studentData
      let index =
        updateData.addresses?.findIndex((a) => a.address_type_id === AddressType.Pickup) ?? -1
      if (index > -1 && updateData.addresses) {
        updateData.addresses[index].is_pending_payment = true
        updateData.cart_token = cartResponse.token
        let updateSuccess = await updateStudentPersonalData(updateData, false)
        if (!updateSuccess) {
          return
        }
        formik.setSubmitting(true)
        // 3- create payment request and redirect to payment app
        // in payment request we need to have a flag that calls upgrade course endpoint in booking service
        let acceptedTest = courseUpgradeResponse?.has_accepted_practical
          ? courseUpgradeResponse.accepted_pt
          : courseUpgradeResponse?.accepted_tt
        let successUrl =
          process.env.REACT_APP_WEBSITE_BASE_URL +
          'profile/about-u?test_id=' +
          acceptedTest?.id +
          '&is_rebook=0'
        const res = await purchaseRequest(
          cartResponse.total_final,
          course.Course.deal_id,
          cartResponse.token,
          successUrl ?? '',
          'Addons Payment'
        ).catch((error) => {
          formik.setSubmitting(false)
          if (!error?.errorMessage)
            toast.error('Error Occurred while processing your request! try again later.')
          setShowCourseUpgradeModal(false)
        })
      }
    } catch (error: any) {
      if (!error?.errorMessage) toast.error('Failed to get preferred test centers ')
      formik.setSubmitting(false)
    }
  }

  useEffect(() => {
    scrollToFirstError(formik)
  }, [formik, formik.errors, formik.isSubmitting, formik.isValidating])

  useEffect(() => {
    if (studentData.date_of_birth) {
      let date = new Date(studentData.date_of_birth ? studentData.date_of_birth : '')
      setStudentData({
        ...studentData,
        day: date.getDate().toString(),
        month: months[date.getMonth()]?.value,
        year: date.getFullYear().toString(),
      })
    }
  }, [studentData.date_of_birth])

  return (
    <>
      <div className='flex flex-wrap'>
        <div
          className={
            `w-full m-auto ` + (props.parentWrapper === 'questionnaire' ? 'lg:w-3/4' : 'lg:w-full')
          }
        >
          {props.parentWrapper === 'questionnaire' ? (
            <Alert
              description='We can’t start arranging your course until you’ve completed all steps of this form. Please submit your details ASAP.'
              colour='green'
            ></Alert>
          ) : (
            <></>
          )}
          <form>
            <div className='relative flex flex-col min-w-0 rounded break-words border bg-white border-1 border-gray-300 card--form-container px-5 py-6'>
              <div className={props.parentWrapper === 'questionnaire' ? '' : 'hidden'}>
                <h1 className='text-darkBlue font-bold text-2xl my-3'>
                  {loading ? <Skeleton width={150} /> : <>About You</>}{' '}
                </h1>
              </div>
              {/* begin::Title form group */}
              <div>
                <div className='w-full'>
                  <div className='clearfix'>
                    {loading ? (
                      <Skeleton width='100%' height={40} />
                    ) : (
                      <Dropdown
                        label='Title'
                        name='title'
                        id='title'
                        value={{
                          value: formik.values.title_id,
                          label: selectedTitle.title_name,
                        }}
                        onChange={(e: any) => {
                          formik.setFieldValue('title_id', e?.value)
                          setSelectedTitle({id: e?.value, title_name: e?.label})
                        }}
                        placeholder={intl.formatMessage({
                          id: 'QUESTIONNAIRE.STUDENTDETAILS.TITLE',
                        })}
                        isSearchable={true}
                        options={titlesBinded}
                        disabled={formik.isSubmitting}
                        required={true}
                        error={formik.touched.title_id && formik.errors.title_id ? true : false}
                        errorMsg={formik.errors.title_id}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* end::Title form group */}

              {/*begin:: Other title form group */}
              {selectedTitle?.id === 5 && (
                <div className='w-full'>
                  <div className='clearfix'>
                    {loading ? (
                      <Skeleton width='100%' height={40} />
                    ) : (
                      <Input
                        label={intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.OTHERTITLE'})}
                        {...formik.getFieldProps('other_title')}
                        type='text'
                        name='other_title'
                        id='other_title'
                        required={true}
                        readonly={formik.isSubmitting}
                        error={
                          formik.touched.other_title && formik.errors.other_title ? true : false
                        }
                        errorMsg={formik.errors.other_title}
                      />
                    )}
                  </div>
                </div>
              )}
              {/*end:: Other title form group */}

              {/* start::surname form group */}

              <div className='w-full'>
                {loading ? (
                  <Skeleton width='100%' height={40} />
                ) : (
                  <Input
                    label={intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.SURNAME'})}
                    {...formik.getFieldProps('surname')}
                    type='text'
                    name='surname'
                    id='surname'
                    required={true}
                    readonly={formik.isSubmitting}
                    error={formik.touched.surname && formik.errors.surname ? true : false}
                    errorMsg={formik.errors.surname}
                    hasNote={!licenceAddressDisabled}
                    note={intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.SURNAME_NOTE'})}
                  />
                )}
              </div>
              {/* end::surname form group */}

              {/* start::First and middle Names form group */}
              <div className='w-full'>
                {loading ? (
                  <Skeleton width='100%' height={40} />
                ) : (
                  <Input
                    label={intl.formatMessage({
                      id: 'QUESTIONNAIRE.STUDENTDETAILS.FIRST_AND_MIDDLE_NAMES',
                    })}
                    {...formik.getFieldProps('first_name')}
                    type='text'
                    name='first_name'
                    id='first_name'
                    readonly={formik.isSubmitting}
                    error={formik.touched.first_name && formik.errors.first_name ? true : false}
                    errorMsg={formik.errors.first_name}
                    hasNote={!licenceAddressDisabled}
                    note='Please enter exactly as shown on line 2 of your licence'
                    required={true}
                  />
                )}
              </div>
              {/* end::First and middle Names form group */}

              {/* begin::Gender form group */}
              <div className='w-full'>
                {loading ? (
                  <Skeleton width='100%' height={40} />
                ) : (
                  <Dropdown
                    label={intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.GENDER'})}
                    name='gender_name'
                    id='gender'
                    value={{
                      value: formik.values.gender_id,
                      label: selectedGender.gender_name,
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue('gender_id', e?.value)
                      setSelectedGender({id: e?.value, gender_name: e?.label})
                    }}
                    placeholder={intl.formatMessage({
                      id: 'QUESTIONNAIRE.STUDENTDETAILS.GENDER',
                    })}
                    isSearchable={true}
                    options={gendersBinded}
                    disabled={formik.isSubmitting}
                    error={formik.touched.gender_id && formik.errors.gender_id ? true : false}
                    errorMsg={formik.errors.gender_id}
                    required={true}
                  />
                )}
              </div>
              {/* end::Gender form group */}

              {/* start::date of birth form group */}
              <div className='mb-1'>
                <div className='flex flex-wrap items-end justify-between'>
                  <h4 className='text-base font-bold '>
                    {loading ? (
                      <Skeleton width={50} className='me-2' />
                    ) : (
                      <>
                        Date of Birth<span className='text-red-500'>*</span>
                      </>
                    )}
                  </h4>
                </div>

                <div className='w-full'>
                  <div className='flex flex flex-wrap  input-icons'>
                    <div className='flex flex-col w-1/3'>
                      {loading ? (
                        <Skeleton width='100%' height={40} />
                      ) : (
                        <Dropdown
                          className='stu-select'
                          label='Day'
                          name='day'
                          id='day'
                          value={{
                            value: formik.values.day,
                            label: formik.values.day === '' ? 'Day' : formik.values.day,
                          }}
                          onChange={(e: any) => {
                            formik.setFieldValue('day', e?.value)
                          }}
                          placeholder={intl.formatMessage({
                            id: 'QUESTIONNAIRE.STUDENTDETAILS.DAY',
                          })}
                          isSearchable={true}
                          options={days}
                          disabled={formik.isSubmitting}
                        />
                      )}
                      <div className='fv-plugins-message-container'>
                        {formik.touched.day && formik.errors.day && (
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.day}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col w-1/3 px-1'>
                      {loading ? (
                        <Skeleton width='100%' height={40} />
                      ) : (
                        <Dropdown
                          className='stu-select'
                          label='Month'
                          name='month'
                          id='month'
                          value={{
                            value: formik.values.month,
                            label: formik.values.month === '' ? 'Month' : formik.values.month,
                          }}
                          onChange={(e: any) => {
                            formik.setFieldValue('month', e?.value)
                          }}
                          placeholder={intl.formatMessage({
                            id: 'QUESTIONNAIRE.STUDENTDETAILS.MONTH',
                          })}
                          isSearchable={true}
                          options={months}
                          disabled={formik.isSubmitting}
                        />
                      )}
                      <div className='fv-plugins-message-container'>
                        {formik.touched.month && formik.errors.month && (
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.month}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col w-1/3'>
                      {loading ? (
                        <Skeleton width='100%' height={40} />
                      ) : (
                        <Dropdown
                          className='stu-select'
                          label='Year'
                          name='year'
                          id='year'
                          value={{
                            value: formik.values.year,
                            label: formik.values.year === '' ? 'Year' : formik.values.year,
                          }}
                          onChange={(e: any) => {
                            formik.setFieldValue('year', e?.value)
                          }}
                          placeholder={intl.formatMessage({
                            id: 'QUESTIONNAIRE.STUDENTDETAILS.YEAR',
                          })}
                          isSearchable={true}
                          options={years}
                          disabled={formik.isSubmitting}
                        />
                      )}
                      <div className='fv-plugins-message-container'>
                        {formik.touched.year && formik.errors.year && (
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.year}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='fv-plugins-message-container'>
                      {formik.errors.date_of_birth && (
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.date_of_birth}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!licenceAddressDisabled && (
                  <>
                    <span className='text-sm -my-5'>
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.DATEOFBIRTH_NOTE'})
                      )}
                    </span>
                  </>
                )}
              </div>
              {/* end::date of birth form group */}

              {/* start::Your addresses form group */}
              <div
                className={
                  (formik.touched.licence_address && formik.errors.licence_address) ||
                  (formik.touched.pickup_address && formik.errors.pickup_address) ||
                  (formik.touched.billing_address && formik.errors.billing_address)
                    ? 'form-group row is-invalid'
                    : 'form-group row'
                }
              >
                <div className='d-flex flex-wrap justify-content-between'>
                  <h4 className='text-base font-bold '>
                    {loading ? (
                      <Skeleton width={150} />
                    ) : (
                      <>
                        {intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.ADDRESSES'})}{' '}
                        <span className='text-red-500'>*</span>
                      </>
                    )}
                  </h4>
                </div>
                <StackedList
                  data={[
                    {
                      buttonDisabled: licenceAddressDisabled,
                      loading: loading,
                      onCardClicked: () => {
                        if (!licenceAddressDisabled) {
                          setShow(true)
                          setAddressType('Licence')
                          setSelectedAddress(licenceAddress)
                        }
                      },
                      imgURL: '/assets/media/svg/addresses/Licence.svg',
                      description:
                        addressHasData(licenceAddress) && licenceAddressDisabled === false
                          ? `${licenceAddress.street_address} -${licenceAddress.city_name} -
                      ${licenceAddress.postal_code}`
                          : '',
                      title: 'Licence address',
                      showButton: true,
                      buttonText:
                        addressHasData(licenceAddress) && licenceAddressDisabled === false
                          ? 'Added'
                          : 'Add',
                      colour:
                        addressHasData(licenceAddress) && licenceAddressDisabled === false
                          ? 'darkBlue'
                          : 'outline',
                      onButtonClicked: () => {
                        if (!licenceAddressDisabled) {
                          setShow(true)
                          setAddressType('Licence')
                          setSelectedAddress(licenceAddress)
                        }
                      },
                      hasError: formik.touched.licence_address && formik.errors.licence_address,
                      error: 'Licence address is required',
                    },
                    {
                      buttonDisabled: !pickupAddressEnabled,
                      loading: loading,
                      onCardClicked: () => {
                        if (pickupAddressEnabled) {
                          setShow(true)
                          setAddressType('Pickup')
                          setSelectedAddress(pickupAddress)
                        }
                      },
                      imgURL: '/assets/media/svg/addresses/Pickup.svg',
                      description: addressHasData(pickupAddress)
                        ? `${pickupAddress.street_address} -${pickupAddress.city_name} -
                      ${pickupAddress.postal_code}`
                        : '',
                      title: 'Pickup address',
                      showButton: true,
                      colour: addressHasData(pickupAddress) ? 'darkBlue' : 'outline',
                      buttonText: addressHasData(pickupAddress) ? 'Added' : 'Add',
                      onButtonClicked: () => {
                        if (pickupAddressEnabled) {
                          setShow(true)
                          setAddressType('Pickup')
                          setSelectedAddress(pickupAddress)
                        }
                      },
                      hasError: formik.touched.pickup_address && formik.errors.pickup_address,
                      error: 'Pick up address is required',
                    },
                    {
                      buttonDisabled: false,
                      loading: loading,
                      onCardClicked: () => {
                        setShow(true)
                        setAddressType('Billing')
                        setSelectedAddress(billingAddress)
                      },
                      imgURL: '/assets/media/svg/addresses/Billing.svg',
                      description: addressHasData(billingAddress)
                        ? `${billingAddress.street_address} -${billingAddress.city_name} -
                      ${billingAddress.postal_code}`
                        : '',
                      title: 'Billing address',
                      showButton: true,
                      colour: addressHasData(billingAddress) ? 'darkBlue' : 'outline',
                      buttonText: addressHasData(billingAddress) ? 'Added' : 'Add',
                      onButtonClicked: () => {
                        setShow(true)
                        setAddressType('Billing')
                        setSelectedAddress(billingAddress)
                      },
                      hasError: formik.touched.billing_address && formik.errors.billing_address,
                      error: 'Billing address is required',
                    },
                  ]}
                />
              </div>
              {/* end::Your addresses form group */}

              {/* start::Phone form group */}
              <div
                className={
                  formik.touched.phone?.phone && formik.errors.phone?.phone
                    ? 'form-group row is-invalid'
                    : 'form-group row'
                }
              >
                <div className='w-full'>
                  {loading ? (
                    <Skeleton width='100%' className='w-full' height={40} />
                  ) : (
                    <div className='clearfix input-group mb-3'>
                      <Input
                        label={intl.formatMessage({
                          id: 'QUESTIONNAIRE.STUDENTDETAILS.MOBILENUMBER',
                        })}
                        {...formik.getFieldProps('phone.phone')}
                        type='text'
                        name='phone.phone'
                        readonly={formik.isSubmitting}
                        className='form-control col-md-12'
                        id='phone.phone'
                        required={true}
                        inputGroup={true}
                        inputGroupValue='+44'
                        error={
                          formik.touched.phone?.phone && formik.errors.phone?.phone ? true : false
                        }
                        errorMsg={formik.errors.phone?.phone}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* end:: Phone form group */}
            </div>

            <div className='py-5'>
              {loading ? (
                <div className='flex items-center justify-between gap-2'>
                  <Skeleton
                    className='w-1/2 !rounded-full'
                    height={35}
                    variant='rounded'
                  ></Skeleton>

                  <Skeleton
                    className='w-1/2 !rounded-full'
                    height={35}
                    variant='rounded'
                  ></Skeleton>
                </div>
              ) : (
                <div className='flex items-center justify-between gap-2'>
                  <Button
                    type='button'
                    disabled={formik.isSubmitting}
                    colour='outline'
                    size='large'
                    className={`w-1/2 px-3 ${
                      props.parentWrapper !== 'questionnaire' ? 'order-2' : ''
                    }`}
                    halfWidth={true}
                    onClick={() => {
                      props.goToPrevTab()
                      formik.setTouched({}, false)
                    }}
                  >
                    {props.parentWrapper === 'questionnaire'
                      ? intl.formatMessage({id: 'ACTIONS.BACK'})
                      : intl.formatMessage({id: 'ACTIONS.DISCARD'})}
                  </Button>
                  <Button
                    type='submit'
                    disabled={formik.isSubmitting}
                    name='next'
                    colour='gradient'
                    size='large'
                    className={`w-1/2 px-3 ${
                      props.parentWrapper !== 'questionnaire' ? 'order-1' : ''
                    }`}
                    onClick={formik.handleSubmit}
                    halfWidth={true}
                  >
                    <span className='indicator-label'>
                      {props.parentWrapper === 'questionnaire' ? 'Save & Continue' : 'Save'}
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      <AddAddress
        addressType={addressType}
        show={show}
        onClose={(address: StudentAddress, isSucces: boolean) => {
          setShow(false)
          updateSelectedAddress(address)
        }}
        address={selectedAddress}
        citiesBinded={cities}
        licence_address={licenceAddress}
        billing_address={billingAddress}
        pickup_address={pickupAddress}
        licenceAddressDisabled={licenceAddressDisabled}
        parentWrapper={props.parentWrapper ?? ''}
        checkCourseUpgradeWhenPickupAddressChange={checkCourseUpgradeWhenPickupAddressChange}
        setStudentData={setStudentData}
        studentData={studentData}
      />

      <CourseUpgradeModal
        show={showCourseUpgradeModal}
        courseUpgradeData={courseUpgradeResponse}
        handleClose={() => {
          setShowCourseUpgradeModal(false)
          setShow(false)
          setPickupAddress(originalPickupAddress)
          setStudentData(studentData)
        }}
        handleSubmit={upgradeCourse}
        submitting={formik.isSubmitting}
        parentWrapper={props.parentWrapper}
      />
    </>
  )
}

export {AboutStudent}

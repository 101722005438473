/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import EmptyState from '../../shared/elements/EmptyState'
import {DocumentCheckIcon} from '@heroicons/react/24/outline'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import {Assessment} from '../../../models/assessment/assessment'
import {useCourse} from '../../../providers/CourseProvider'
import {getLastAssessment} from '../../../services/assessment.service'
import {checkHasPrevLessons} from '../../../services/lesson.service'
import {OverallProgress} from './progress/OverallProgress'
import {ProgressBreakdown} from './progress/ProgressBreakdown'
import toast from 'react-hot-toast'

const ProgressTab: FC = () => {
  const intl = useIntl()
  const [progress, setProgress] = useState<Assessment>(new Assessment())
  const [dealId, setDealId] = useState<string>()
  const [haveLessons, setHaveLessons] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(false)
  const {Course} = useCourse()

  useEffect(() => {
    setDealId(Course.deal_id)
  }, [Course.deal_id])

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const response = await getLastAssessment().catch((error) => {
        if (!error?.errorMessage) {
          toast.error('Failed to get last assessment')
        }
      })
      setLoading(false)
      if (response?.results) setProgress(response?.results)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const response = await checkHasPrevLessons(dealId).catch((error) => {
        if (!error?.errorMessage) {
          toast.error('Failed to check previous lessons')
        }
      })
      setLoading(false)
      setHaveLessons(response?.results)
    }
    if (dealId) {
      fetchData()
    }
  }, [dealId])

  return (
    <>
      <div className='w-full py-4 block'>
        {/* START:: Empty progress placeholder */}
        {haveLessons == false && (
          <>
            {/* <img className='w90px mb-10' src={toAbsoluteUrl('/assets/media/svg/progress.svg')} /> */}
            <EmptyState
              title={intl.formatMessage({id: 'LESSONS.MY_PROGRESS.CHECK_AFTER_LESSONS'})}
              Icon={DocumentCheckIcon}
            />
          </>
        )}
        {haveLessons == true && progress.score == 0 && (
          <>
            <EmptyState
              title={intl.formatMessage({id: 'LESSONS.MY_PROGRESS.TRACK_PROPGRESS_HERE_P1'})}
              description={intl.formatMessage({id: 'LESSONS.MY_PROGRESS.TRACK_PROPGRESS_HERE_P2'})}
              Icon={DocumentCheckIcon}
            />
          </>
        )}
        {/* END:: Empty progress placeholder */}

        {haveLessons == true && progress.score > 0 && (
          <>
            <OverallProgress percentage={progress.score} date={progress.date} loading={loading} />
            {/* start:: update Progress button */}
            <div className='flex items-center justify-between mt-6 mb-5'>
              <h2 className='text-xl'>
                {intl.formatMessage({id: 'LESSONS.MY_PROGRESS.BREAKDOWN'})}
              </h2>
            </div>
            {/* end:: update Progress button */}

            <ProgressBreakdown sections={progress.sections} loading={loading} />
          </>
        )}
      </div>

      {/* {loading == true ? <h3>Loading...</h3> : <></>} */}
    </>
  )
}

export default ProgressTab

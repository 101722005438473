import React from "react";
import { toAbsoluteUrl } from "../../../helpers";
import Skeleton from "@mui/material/Skeleton";

export interface ActionCentreCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  // data: {
  icon?: string;
  title?: string;
  description?: string | React.ReactNode;
  pmfIcon?: boolean;
  status?: {
    title: string;
    color: string;
  };
  pillTitle?: string | null;
  actions?: any;
  showAction?: boolean;
  imageUrl?: string;
  children?: React.ReactNode;
  showButtonAction?: boolean;
  loading?: boolean;
}

export default function ActionCentreCard({
  icon,
  title,
  description,
  pmfIcon = false,
  status,
  pillTitle,
  actions,
  showAction = true,
  children,
  imageUrl,
  showButtonAction,
  loading,
  ...props
}: ActionCentreCardProps) {
  return (
    <>
      <div className="bg-transparent border-2 rounded-[10px] py-3 px-4 text-white h-full flex flex-col justify-between">
        <div className="flex min-w-0 gap-x-4 mb-3">
          <div className="min-w-0 flex-auto">
            <div className="flex items-center gap-x-1.5">
              <div className="flex-none rounded-full">
                <div
                  className={`bg-yellow h-1.5 w-1.5 rounded-full`}
                />
              </div>
              <p className="text-sm">Action needed</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between align-items-center gap-x-6">
          <div className="flex min-w-0 gap-x-4">
            {loading ? (
              <Skeleton width={60} height={60} animation="wave"></Skeleton>
            ) : (
              <>
                {icon && (
                  <div className=" h-9 w-9 flex items-center justify-center rounded-full bg-gray-50">
                    <i className={`${icon} text-base`}></i>
                  </div>
                )}
                {imageUrl && (
                  <img
                    className="h-12 w-12 flex-none bg-gray-50"
                    src={toAbsoluteUrl(imageUrl)}
                    alt=""
                  />
                )}
              </>
            )}
            <div className="min-w-0 flex-auto">
              <p className="text-base font-bold">
                {loading ? (
                  <Skeleton width={130} height={30} animation="wave"></Skeleton>
                ) : (
                  <> {title} </>
                )}
              </p>
              <p className="text-sm font-semibold mt-2 mb-4">
                {loading ? (
                  <Skeleton width={200} height={30} animation="wave"></Skeleton>
                ) : (
                  <> {description} </>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-between align-items-center gap-x-6 pt-3">
          {loading ? (
            <div className="flex item-center">
              <Skeleton width={14} height={14} className="me-2" />
              <Skeleton width={100} animation="wave" />
            </div>
          ) : (
            status && (
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <div className="flex items-center gap-x-1.5">
                    <div className="flex-none rounded-full">
                      <div
                        className={`
                        ${
                          {
                            darkGreen: "bg-green",
                            yellow: "bg-yellow",
                            darkRed: "bg-danger",
                          }[status?.color]
                        } 
                        h-1.5 w-1.5 rounded-full`}
                      />
                    </div>
                    <p className="text-sm">Action needed</p>

                    {pmfIcon ? (
                      <img
                        src={toAbsoluteUrl("/assets/media/logos/pmf.svg")}
                        className="h-4"
                        alt="pmf"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
          <div className="flex flex-col grow items-end mb-3">
            {loading ? <Skeleton width={150}  animation="wave"></Skeleton> :
            <div className="w-full">{children}</div>
        
            }
          </div>
        </div>
      </div>
    </>
  );
}

import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Range, getTrackBackground} from 'react-range'
import {formatPriceIntoTwoDigits} from '../../helpers/PriceHelper'
import {useCourse} from '../../providers/CourseProvider'
import {getAdditionalHoursCustomPricing} from '../../services/booking.service'
import Button from '../shared/elements/Button'
import Skeleton from '@mui/material/Skeleton'
import Alert from '../shared/overlays/Alert'
import toast from 'react-hot-toast'

type Props = {
  cost: number
  hours: number
  updateCart: Function
  updateHoursInCart: Function
  hasAdi: boolean
  min?: number | undefined
  hoursChanged?: Function | undefined
  hideAddButton?: boolean
  updateHourCost: Function
  loading?: boolean
  disabled?: boolean
  firstStep?: number
}
const STEP = 1
const MIN = 3
const MAX = 30
const AdditionalHours: FC<Props> = ({
  cost,
  hours,
  updateCart,
  updateHoursInCart,
  updateHourCost,
  hasAdi,
  min = 3,
  hoursChanged,
  hideAddButton = false,
  loading,
  disabled,
  firstStep = STEP,
}) => {
  const intl = useIntl()
  const [additionalHrsRange, setAdditionalHrsRange] = useState([hours > 0 ? hours : min])
  const [isActive, setActive] = useState(hours > 0)
  const [customPricing, setCustomPricing] = useState<any>()

  const {Course} = useCourse()
  const onHoursChanged = (e: any) => {
    setAdditionalHrsRange(e)
    if (hoursChanged) hoursChanged!(e[0])
  }
  useEffect(() => {
    if (isActive && hours !== additionalHrsRange[0]) {
      updateCart(
        null,
        isActive,
        getPerHourCost(additionalHrsRange) * additionalHrsRange[0],
        additionalHrsRange[0] + ' Hour Course',
        1
      )
      updateHoursInCart(additionalHrsRange[0])
    }
  }, [additionalHrsRange])
  useEffect(() => {
    ;(async () => {
      if (Course?.order?.id) {
        const response = await getAdditionalHoursCustomPricing(Course?.order?.id).catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get additional hours pricing')
        })
        if (response) {
          setCustomPricing(response)
        }
      }
    })()
  }, [Course?.order?.id])
  const getPerHourCost = (hours: any) => {
    var perHourCost = 0
    if (cost) {
      perHourCost = cost
      updateHourCost(cost)
      return cost
    } else {
      if (customPricing) {
        const keys = Object.keys(customPricing[Course?.order?.transmission]).map((key) =>
          parseInt(key)
        )
        if (hours < keys[0]) {
          perHourCost = customPricing[Course?.order?.transmission][keys[0]]
        } else {
          keys.forEach((hour, i) => {
            if (hours >= hour && hours < keys[i + 1]) {
              perHourCost = customPricing[Course?.order?.transmission][hour]
            }
          })
        }
      }
    }
    updateHourCost(perHourCost)
    return perHourCost
  }
  useEffect(() => {
    getPerHourCost(additionalHrsRange)
  }, [customPricing])
  return (
    <div className=''>
      <p className='font-size-16 mt-0 mb-14'>
        {loading ? (
          <Skeleton width={100} />
        ) : (
          intl.formatMessage({id: 'ADDONS.ADDITIONAL_HRS_QUES'})
        )}
      </p>
      <div className=''>
        {/* £25  */}
        {loading ? (
          <Skeleton width='100%' />
        ) : (
          <Range
            values={additionalHrsRange}
            step={STEP}
            min={min}
            max={MAX}
            onChange={(e) => {
              if (firstStep > STEP && e[0] < firstStep && e[0] > 0) {
                onHoursChanged([firstStep])
                getPerHourCost([firstStep])
              } else {
                onHoursChanged(e)
                getPerHourCost(e)
              }
            }}
            renderTrack={({props, children}) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: '14px',
                  marginTop: '0px',
                  display: 'flex',
                  width: '98%',
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: '9px',
                    width: '100%',
                    borderRadius: '4px',
                    background: getTrackBackground({
                      values: additionalHrsRange,
                      colors: ['#060E3D', '#ccc', '#ccc'],
                      min: min,
                      max: MAX,
                    }),
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({index, props, isDragged}) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '18px',
                  width: '18px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: 'rgb(193 193 193) 0px 0px 3px 1px',
                  transform: 'translate(409.625px, -8.5px)',
                  backgroundColor: '#060E3D',

                  outline: '4px solid rgba(6, 14, 61, 0.40)',
                  border: '4px solid rgb(255, 255, 255)',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-36px',
                    backgroundColor: '#060e3d',
                    color: '#fff',
                    padding: '2px 8px',
                    width: 'max-content',
                    fontWeight: '400',
                    borderRadius: '50px',
                    fontSize: '13px',
                  }}
                >
                  {additionalHrsRange[0]} Hours
                </div>
              </div>
            )}
          />
        )}
      </div>
      {hideAddButton ? (
        <></>
      ) : (
        <div className='flex items-center justify-center mt-4'>
          {loading ? (
            <Skeleton width='50%' />
          ) : (
            <span className='text-base'>
              {additionalHrsRange[0]} Hours -
              <span className='font-bold ms-1'>
                £
                {formatPriceIntoTwoDigits(
                  getPerHourCost(additionalHrsRange[0]) * additionalHrsRange[0]
                )}
              </span>
            </span>
          )}
        </div>
      )}
      {hideAddButton ? (
        <></>
      ) : (
        <div className='flex justify-content-between mt-5'>
          {loading ? (
            <Skeleton className=' !rounded-full' height={35} animation='wave' variant='rounded' />
          ) : (
            <Button
              fullWidth
              loading={loading}
              disabled={disabled}
              onClick={() => {
                if (isActive) {
                  onHoursChanged([min])
                  updateHoursInCart(0)
                } else {
                  updateHoursInCart(additionalHrsRange[0])
                }
                updateCart(
                  null,
                  !isActive,
                  (getPerHourCost(additionalHrsRange[0]) * additionalHrsRange[0]).toString(),
                  additionalHrsRange[0] + ' Hour Course',
                  1
                )
                setActive(!isActive)
              }}
            >
              {' '}
              <span className='flex items-center justify-between'>
                {!isActive ? <>Add Hours</> : <>Remove Hours</>}
              </span>
            </Button>
          )}
        </div>
      )}

      {loading ? (
        <Skeleton width={50} />
      ) : (
        hasAdi && (
          <Alert
            description={intl.formatMessage({id: 'ADDONS.ADDITIONAL_HRS_ALERT_MSG'})}
            colour='green'
          />
        )
      )}
    </div>
  )
}
export {AdditionalHours}

import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import Modal from '../overlays/Modal'
import {useNavigate} from 'react-router-dom'

type Props = {
  show: boolean
  onHide: () => void
  imageURL: string
}

const ReferFriend: FC<Props> = ({show, onHide, imageURL}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const hide = () => {
    onHide()
  }

  return (
    <>
      <Modal
        open={show}
        onClose={hide}
        title={intl.formatMessage({id: 'REFER_FRIEND.HEADER'})}
        submitText={intl.formatMessage({id: 'REFER_FRIEND.REFER_BTN'})}
        closeText={intl.formatMessage({id: 'REFER_FRIEND.MAYBE_LATER'})}
        onSubmit={() => {
          navigate('/refer-a-friend')
        }}
      >
        <div className='pt-4 pb-8'>
          <div className='d-flex flex-column'>
            <img
              src={toAbsoluteUrl(`/assets/media/refer-friend/${imageURL}`)}
              className='mt-3'
              alt={intl.formatMessage({id: 'REFER_FRIEND.REFER_FRIEND'})}
            ></img>
          </div>
        </div>
      </Modal>
    </>
  )
}

export {ReferFriend}

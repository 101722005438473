import {FC} from 'react'
import {useIntl} from 'react-intl'
import PageHeader from '../../components/shared/elements/PageHeader'
import { CancelCourse } from '../../components/account-settings/cancel-course/CancelCourse'

const CancelCourseWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader
          title={intl.formatMessage({id: 'MENU.CANCEL_BOOKING'})}
          backToURL='/profile'
          isCenter
        />
      </div>
      <div className='px-6 py-4'>
        <CancelCourse />
      </div>
    </>
  )
}

export {CancelCourseWrapper}

import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {
  getStudentCommunication,
  updateStudentCommunication,
} from '../../../services/student.service'
import {Skeleton} from '@mui/material'
import Alert from '../../shared/overlays/Alert'
import Toggler from '../../shared/forms/Toggler'
import Button from '../../shared/elements/Button'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import toast from 'react-hot-toast'

export function Communication() {
  const navigate = useNavigate()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)
  const [studentCommunication, setStudentCommunication] = useState<{
    is_sms_lesson_reminder_enabled: boolean
    is_email_lesson_reminder_enabled: boolean
  }>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await getStudentCommunication().catch((error) => {
        if (!error?.errorMessage) toast.error('Something went wrong while fetching your settings')
        setLoading(false)
      })
      if (res) {
        setStudentCommunication({
          is_sms_lesson_reminder_enabled: res.results.is_sms_lesson_reminder_enabled,
          is_email_lesson_reminder_enabled: res.results.is_email_lesson_reminder_enabled,
        })
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const formik = useFormik({
    initialValues: {
      is_sms_lesson_reminder_enabled: studentCommunication?.is_sms_lesson_reminder_enabled,
      is_email_lesson_reminder_enabled: studentCommunication?.is_email_lesson_reminder_enabled,
    },
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setSaved(false)
      await updateStudentCommunication({
        is_sms_lesson_reminder_enabled: values.is_sms_lesson_reminder_enabled,
        is_email_lesson_reminder_enabled: values.is_email_lesson_reminder_enabled,
      }).catch((error) => {
        setSubmitting(false)
        setSaved(false)
        if (!error?.errorMessage) {
          toast.error('Sorry, an error has occurred')
        }
      })
      setSubmitting(false)
      setSaved(true)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='bg-white rounded-lg px-4 py-5'>
        <div>{saved && <Alert colour='green' description='Successfully Updated!' />}</div>
        {formik.status ? <Alert colour='red' description={formik.status} /> : <></>}
        <div className='mb-6 flex flex-wrap '>
          <div className='d-flex align-items-center justify-content-between mb-5 rounded border px-6 pt-4 pb-3 w-full'>
            {loading ? (
              <Skeleton width='100%' height={40} />
            ) : (
              <>
                <Toggler
                  label='SMS Lessons Reminder'
                  {...formik.getFieldProps('is_sms_lesson_reminder_enabled')}
                  checked={!!formik.values.is_sms_lesson_reminder_enabled}
                  onChange={() => {
                    formik.setFieldValue(
                      'is_sms_lesson_reminder_enabled',
                      +!formik.values.is_sms_lesson_reminder_enabled
                    )
                  }}
                  disabled={formik.isSubmitting}
                />
                <span
                  className={formik.isSubmitting ? 'slider round' : 'slider round cursor-pointer'}
                ></span>
              </>
            )}
          </div>
          <div className='d-flex align-items-center justify-content-between mb-5 rounded border px-6 pt-4 pb-3 w-full'>
            {loading ? (
              <Skeleton width='100%' height={40} />
            ) : (
              <>
                <Toggler
                  label='Email Lessons Reminder'
                  {...formik.getFieldProps('is_email_lesson_reminder_enabled')}
                  checked={!!formik.values.is_email_lesson_reminder_enabled}
                  onChange={() => {
                    formik.setFieldValue(
                      'is_email_lesson_reminder_enabled',
                      +!formik.values.is_email_lesson_reminder_enabled
                    )
                  }}
                  disabled={formik.isSubmitting}
                />
                <span
                  className={formik.isSubmitting ? 'slider round' : 'slider round cursor-pointer'}
                ></span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='py-5'>
        {loading ? (
          <div className='flex items-center justify-between gap-2'>
            <Skeleton className='w-1/2 !rounded-full' height={50} variant='rounded'></Skeleton>
            <Skeleton className='w-1/2 !rounded-full' height={50} variant='rounded'></Skeleton>
          </div>
        ) : (
          <div className='flex items-center justify-between gap-2'>
            <Button
              type='submit'
              name='next'
              disabled={formik.isSubmitting}
              colour='gradient'
              size='large'
              halfWidth={true}
            >
              {intl.formatMessage({id: 'ACTIONS.SUBMIT'})}
            </Button>
            <Button
              disabled={formik.isSubmitting}
              onClick={() => {
                navigate('/')
              }}
              colour='outline'
              size='large'
              halfWidth={true}
            >
              {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
            </Button>
          </div>
        )}
      </div>
    </form>
  )
}

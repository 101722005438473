import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  ArrowRightIcon,
  XMarkIcon
} from "@heroicons/react/20/solid";
import { toAbsoluteUrl } from "../../../helpers/AssetHelpers";

export type AlertProps = {
  title?: string;
  colour?: "pink" | "green" | "orange" | "red" | "gray" | "white";
  description?: string;
  buttons?: {
    title: string;
    onClick: () => void;
  }[];
  list?: string[];
  icon?: string;
  customDesc?: React.ReactNode
  children?: React.ReactNode;
  loading?: boolean;
  imgSrc?: string;
  hasClose?: boolean;
  basicAlert?: boolean;
};
export default function Alert({
  title,
  list,
  colour = "pink",
  description,
  buttons,
  icon = "icon-info",
  customDesc,
  children,
  loading = false,
  imgSrc,
  hasClose = false,
  basicAlert = false
}: AlertProps) {
  const [show, setShow] = useState(true);
  return (
    <>
      {show &&
      <div className="py-2">
         <div
          className={`text-darkBlue  clear-both ${basicAlert ? 'px-3 py-4' : 'rounded-[10px] p-4'} ${colour
              && {
                pink: "bg-gradient-to-tr from-pink to-yellow !p-[1.5px]",
                green: " bg-gradient-to-br from-green to-blue !p-[1.5px]",
                orange: "bg-orange-100 border-yellow border-[1.5px]",
                red: "bg-red-100 border-[1px] border-danger",
                gray: "bg-gray-500 border-[1px] border-gray-700",
                white: "bg-white text-darkBlue !p-0",
              }[colour]
            }`}
        >
          <div
            className={`flex z-10 items-start 
            ${(colour === "pink") && "bg-white bg-opacity-90 rounded-[10px] p-4"}
            ${(colour === "green") && "bg-white bg-opacity-90 rounded-[10px] p-4"}
            ${!description && !list
                ? buttons && buttons.length <= 1
                  ? "items-start"
                  : "items-start sm:items-start"
                : ""
              }`}
          >
            {
              loading ? (
                <Skeleton width={15} height={15} variant="circular" className="me-2" />
              ) : (imgSrc ? <img src={toAbsoluteUrl(`/assets/media/${imgSrc}`)} alt="" className="w-[30px] h-[30px]" /> : 
              <i className={`${colour
                ? {
                  pink: "text-pink",
                  green: "text-green",
                  orange: "text-yellow",
                  red: "text-danger",
                  gray: "text-darkBlue",
                  white: "text-darkBlue",
                }[colour]
                : "text-darkBlue"
              }
              ${icon ?? 'icon-info'} text-lg leading-none mt-[2px]`}></i>)
            }
            <div
              className={`ml-2 flex flex-grow ${buttons && buttons.length == 1
                  ? !list && !description
                    ? "flex-row justify-between"
                    : "flex-col"
                  : "flex-col"
                }`}
            >
              {title &&
              <h3 className="leading-3">
                {
                  loading ? (
                    <Skeleton width={40} className="me-2" />
                  ) : (
                    <span className="text-sm"> {title}</span>
                  )
                }
              </h3> }
              {customDesc && <>
                {
                  loading ? (
                    <Skeleton width={100} className="me-2 text-sm" />
                  ) : (<>{customDesc}</>
                  )
                }
              </>}
              {children && <>
                {
                  loading ? (
                    <Skeleton width={70} className="me-2" />
                  ) : (<>{children}</>
                  )
                }
              </>}
              {description && (
                <div className="-mt-[1px] text-sm">
                  {loading ? (
                    <Skeleton width={100} className="me-2" />
                  ) : (
                    <>{description}</>
                  )}
                </div>
              )}
              {list && (
                <div className="mt-2 text-sm">
                  <ul role="list" className="list-disc space-y-1 pl-5">
                    {list.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              )}
              {buttons && (
                <div
                  className={`${!description && !list
                      ? buttons && buttons.length == 1
                        ? "mt-0"
                        : "mt-3"
                      : "mt-4"
                    }`}
                >
                  <div className="-mx-2 -my-1.5 flex space-x-2">
                    {buttons.map((button, i) => (
                      <button
                        type="button"
                        key={i}
                        onClick={() => button.onClick()}
                        className={`${basicAlert ? 'rounded-sm py-1 sm:mt-0 mt-2 me-1' : 'rounded-md py-1.5'} flex items-center space-x-2 px-2 text-sm font-medium focus:outline-none focus:ring-2 transition-all focus:ring-offset-2
                      ${colour
                            ? {
                              pink: "focus:ring-blue-600 focus:ring-offset-blue-50 bg-blue-100 text-blue-800 hover:bg-blue-200",
                              green:
                                "focus:ring-green-600 focus:ring-offset-green-50 bg-green-100 text-green-800 hover:bg-green-200",
                              orange:
                                "focus:ring-orange-600 focus:ring-offset-orange-50 bg-orange-100 text-orange-800 hover:bg-orange-200",
                              red: "focus:ring-red-600 focus:ring-offset-red-50 text-red-100 bg-red-700",
                              gray: "focus:ring-gray-600 focus:ring-offset-gray-50 bg-gray-100  hover:bg-gray-200",
                              white: "focus:ring-gray-600 focus:ring-offset-gray-50 bg-gray-100  hover:bg-gray-200",
                            }[colour]
                            : "focus:ring-gray-600 focus:ring-offset-gray-50 bg-gray-100  hover:bg-gray-200"
                          }
                    `}
                      >
                        <span>{button.title}</span>
                        {buttons.length == 1 && (
                          <ArrowRightIcon className="h-4 w-4" />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {hasClose && <XMarkIcon className="w-4 h-4 -mt-2.5 -mr-2 ml-2 cursor-pointer" onClick={() => setShow(false)} />}
          </div>
        </div>
      </div>
       
      }
    </>
  );
}
import {Routes, Route, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {PublicRoutes} from './PublicRoutes'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../setup/redux/RootReducer'
import {useEffect, useState} from 'react'
import {getSavedSteps} from '../content/services/student.service'
import * as auth from '../../setup/redux/AuthRedux'
import {Toaster} from 'react-hot-toast'

const BaseRoutes = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [inQuestionnaire, setInQuestionnaire] = useState<boolean | null>(null)
  const dispatch = useDispatch()
  const location = useLocation()
  let returnUrl = location.search

  useEffect(() => {
    if (isAuthorized) {
      ;(async () => {
        try {
          let savedStepsResult = (await getSavedSteps()).results
          setInQuestionnaire(savedStepsResult.steps_completed_at == null)
          if (savedStepsResult.steps_completed_at) {
            dispatch(auth.actions.setIsStepsCompleted(true))
          } else {
            dispatch(auth.actions.setIsStepsCompleted(false))
          }
          dispatch(auth.actions.setIsInactiveDeal(savedStepsResult?.inactive_deal ?? false))
          dispatch(auth.actions.setStageId(savedStepsResult?.stage_id ?? false))
        } catch (e) {}
      })()
    }
  }, [isAuthorized])

  return (
    <>
      <Toaster />
      <Routes>
        {/*
        {isAuthorized && (inQuestionaire && inQuestionaire == true) && (
          <Redirect from='/auth' to={'/questionnaire' + returnUrl} />

        )}
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />*/}

        {/* /*Redirect to `/auth` when user is not authorized */}
        <Route path='/*' element={isAuthorized ? <PrivateRoutes /> : <PublicRoutes />} />
      </Routes>
    </>
  )
}

export {BaseRoutes}

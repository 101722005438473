import {RadioGroup} from '@headlessui/react'
import clsx from 'clsx'
import {CheckCircleIcon} from '@heroicons/react/20/solid'
import {toAbsoluteUrl} from '../../../helpers'
import {ReactNode} from 'react'

interface IProps {
  label: string
  name: string
  options: Array<{
    value: any
    label: string | ReactNode
    img?: string
    nonselectImg?: string | undefined
    description?: string
  }>
  onChange: (value: any) => void
  value: string | number | undefined | null | any
  disabled: boolean
  error?: boolean
  fullWidth?: boolean
  errorMsg?: string
  required?: boolean
}

export default function RadioInputGroup({
  label,
  name,
  value,
  options = [],
  onChange,
  disabled = false,
  error = false,
  fullWidth = false,
  errorMsg = 'This field is required',
  required
}: IProps) {
  return (
    <>
      <RadioGroup value={value} onChange={onChange} name={name} className='mb-[2px]'>
        <span>
          <span className='text-base font-bold text-darkBlue mb-3'>
            {label}
            {required && <span className='text-sm text-red-500'>*</span>}
          </span>
        </span>

        <div
          className={`${options.length > 2 ? 'grid-cols-3' : 'grid-cols-2'}
          ${fullWidth && ' !grid-cols-1'} mt-1 grid gap-2`}
        >
          {options.map((option, index) => (
            <div key={index} className='relative'>
              <RadioGroup.Option
                key={option.value}
                value={option.value}
                disabled={disabled}
                as='input'
                type='radio'
                name={name}
                className='absolute z-[1] opacity-0 h-full w-full cursor-pointer'
              />
              <RadioGroup.Label
                as='label'
                htmlFor={name}
                className={`${
                  option.img || option.nonselectImg
                    ? 'sm:p-3.5 px-4 py-3.5 h-full'
                    : 'px-3.5 py-2.5 min-h-[50px]'
                } 
                ${fullWidth && ' !h-auto'}
                ${
                  !(value === option?.value) 
                    ? 'bg-transparent  border-gray-600 '
                    : 'bg-gray-200 border-darkBlue '
                } relative flex focus:outline-none cursor-pointer items-center rounded-[10px] border-2`}
              >
                <span
                  className={`${
                    option.img || option.nonselectImg ? 'items-center text-center' : 'items-start'
                  } flex flex-1 flex-col justify-center flex-shrink w-40 pe-5 ps-3 text-center`}
                >
                  {(option.img || option.nonselectImg) && (
                    <img
                      alt={label}
                      src={toAbsoluteUrl(
                        !(value === option?.value) && option?.nonselectImg
                          ? option?.nonselectImg
                          : option.img
                            ? option.img
                            : ''
                      )}
                      className='px-0 w-[30px] h-auto mb-2 flex-shrink'
                    />
                  )}

                  <span
                    className={`'text-darkBlue block text-sm font-semibold`}
                  >
                    {option.label}
                  </span>
                </span>
                {/* <CheckCircleIcon
                  className={clsx(
                    !(value === option?.value) ? 'invisible' : 'visible',
                    option?.nonselectImg || option?.img ? 'absolute right-2 top-1' : '',
                    'h-6 w-6 text-darkBlue'
                  )}
                  aria-hidden='true'
                /> */}
                {/* {!(option?.nonselectImg || option?.img) && (
                  <span
                    className={clsx(
                      value === option?.value
                        ? 'bg-darkBlue border-transparent ring-2 ring-offset-2 ring-darkBlue'
                        : 'bg-white border-transparent ring-1 ring-offset-1 ring-gray-300',
                      'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden='true'
                  >
                    <span className='rounded-full bg-white w-2 h-2' />
                  </span>
                )}
                <span
                  className={clsx(
                    value === option?.value ? 'border-darkBlue' : 'border-gray-300',
                    'pointer-events-none border w-full h-full appearance-none absolute top-0 -bottom-px left-0 -right-px rounded-sm'
                  )}
                  aria-hidden='true'
                /> */}
                {option.description && <RadioGroup.Description>
                  <span className={`${
                      !(value === option?.value) || option.img || option.nonselectImg
                        ? 'text-darkBlue'
                        : 'text-white'
                    } block text-base leading-0 ms-2 me-5`}>{option.description}</span>
                </RadioGroup.Description>}
              </RadioGroup.Label>
              
            </div>
          ))}
        </div>
      </RadioGroup>

      {error && (
        <p className='text-sm text-red-600' id='error'>
          {errorMsg}
        </p>
      )}
    </>
  )
}

import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  seeAllIcon?: boolean;
  url: string;
  children?: React.ReactNode;
}

export default function LinkItem({
  seeAllIcon= false,
  title,
  url,
  children,
  ...props
}: ButtonProps) {
  // types: primary, secondary, warning, danger
  return(
  <>
    <Link to={url} className="flex items-center text-sm text-pink">
      {children}
      {seeAllIcon && <ArrowSmallRightIcon className="h-5 w-5 text-darkBlue ml-2" /> }
      
    </Link>
  </>
  )
}


import { Auth, Amplify } from 'aws-amplify';
import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

const amplifyConfigurations = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

  }
};
export const mobileLogin = (idToken: string, refreshToken: string, username: string, accessToken: string) => {
  Amplify.configure(amplifyConfigurations);


  const localSession = new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: idToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
  });

  const localUser = new CognitoUser({
    Username: username,
    Pool: new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? "",
      ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID ?? ""
    }),
  });
  localUser.setSignInUserSession(localSession);
  Auth.currentAuthenticatedUser = async () => localSession;

}
import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {AdiData} from '../models/adi/adi-data'
import {BaseResponse} from '../models/base-response'

const JOB_URL = process.env.REACT_APP_JOB_BASE_URL

const GET_ADI = `${JOB_URL}/jobs/get-adi-from-job`

export async function getAdiBasicData(job_id: any): Promise<BaseResponse<AdiData>> {
  return await modifiedFetch({
    url: `${GET_ADI}/${job_id}`,
    method: 'GET',
  })
}

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useCourse} from '../../../providers/CourseProvider'
import {RootState} from '../../../../../setup'
import {getAddons, purchaseRequest} from '../../../services/addons.service'
import {getPaymentUrl} from '../../../helpers/PaymentHelper'
import {AdditionalHours} from '../../add-ons/AdditionalHours'
import Modal from '../../shared/overlays/Modal'
import {rescheduleTestWithAdditionalHours} from '../../../services/booking.service'
import {getProduct} from '../../../services/student.service'
import {getAdiBasicData} from '../../../services/adi.service'
import toast from 'react-hot-toast'

type Props = {
  hide: any
  show: boolean
  test: any
}

const AdditionalHoursModal: FC<Props> = ({hide, show, test}) => {
  const intl = useIntl()
  const {Course} = useCourse()
  const [dealId, setDealId] = useState<string>('')
  const [additionalHours, setAdditionalHours] = useState<any>(process.env.MIN_ADDITIONAL_HOURS ?? 3)
  const [total, setTotal] = useState<number>(0)
  const [addonsAndProducts, setAddonsAndProducts] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [purchasing, setPurchasing] = useState<boolean>(false)
  const [adiData, setAdiData] = useState<any>(null)
  const [orderId, setOrderId] = useState<any>(null)
  const [product, setProduct] = useState<any>(null)
  const [contactId, setContactId] = useState<any>(null)
  const [postcode, setPostcode] = useState<any>(null)
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true
  const [perHourCost, setPerHourCost] = useState<any>(0)
  useEffect(() => {
    ;(async () => {
      if (Course.deal_id && test && show) {
        setLoading(true)

        setDealId(Course.deal_id)
        // Get Addons
        let addonsResponse = await getAddons(Course.deal_id).catch((error) => {
          if (!error?.errorMessage) {
            toast.error('Failed to get addons')
          }
          setLoading(false)
        })
        if (addonsResponse) {
          setAddonsAndProducts(addonsResponse.results)
          setPerHourCost(
            addonsAndProducts?.per_hour_cost > 0 ? addonsAndProducts?.per_hour_cost : perHourCost
          )
        }

        // Get ADI data
        let adiBasicDataResponse = await getAdiBasicData(Course.deal_id).catch((error) => {
          setLoading(false)
          if (!error?.errorMessage) toast.error('Something went wrong while getting adi data')
        })
        if (adiBasicDataResponse) {
          setAdiData(adiBasicDataResponse.results)
          // Get reschedule product
          let productResponse = await getProduct({
            test_type_id: test.type,
            is_rebook: false,
            deal_id: Course.deal_id,
          }).catch((error) => {
            if (!error?.errorMessage) toast.error('Failed to get product')
          })
          if (productResponse) {
            setOrderId(productResponse.results.order_id)
            setProduct(productResponse.results.product)
            setContactId(productResponse.results.zendesk_contact_id)
            setPostcode(productResponse.results.postcode)
          }
        }
        setLoading(false)
      }
    })()
  }, [Course.deal_id, test, show])

  const getRedirectURL = async (token: any) => {
    let successUrl =
      process.env.REACT_APP_WEBSITE_BASE_URL +
      'transiant-payment?test_id=' +
      test.id +
      '&is_rebook=0'
    const purchaseResponse = await purchaseRequest(
      total,
      test.deal_id,
      token,
      successUrl ?? '',
      'Reschedule Test with additional hours Payment'
    ).catch((error) => {
      setPurchasing(false)
      if (!error?.errorMessage) toast.error('Something went wrong while sending purchase request')
    })
    if (!purchaseResponse) return
    window.open(
      getPaymentUrl(purchaseResponse.uuid, 'Reschedule Test with additional hours Payment'),
      isMobile ? '_blanck' : '_self'
    )
  }

  const purchaseHours = async () => {
    let testType = test.type.charAt(0).toUpperCase() + test.type.slice(1)
    let source = `Reschedule ${testType} Test with additional hours`
    let cartData = {
      hours: additionalHours,
      product_id: product?.id,
      order_id: orderId,
      deal_id: dealId,
      postcode: postcode,
      zendesk_contact_id: contactId,
      source: source,
      custom_price: perHourCost,
    }

    setPurchasing(true)
    let bookingResponse = await rescheduleTestWithAdditionalHours(cartData).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to reschedule test with additional hours ')
      setPurchasing(false)
    })
    if (!bookingResponse) return
    let cart = bookingResponse.data
    getRedirectURL(cart?.token)
  }

  useEffect(() => {
    if (additionalHours && perHourCost) {
      setTotal(additionalHours * perHourCost)
    }
  }, [additionalHours, perHourCost])

  return (
    <>
      <Modal
        open={show}
        onClose={hide}
        title={intl.formatMessage({id: 'RESCHEDULE_TEST.TITLE'})}
        submitText={intl.formatMessage({id: 'ACTIONS.PAY'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        disabled={purchasing || loading}
        onSubmit={purchaseHours}
      >
        <div className='px-2'>
          <AdditionalHours
            cost={addonsAndProducts?.per_hour_cost ?? 0}
            hours={additionalHours}
            hasAdi={adiData !== null && adiData !== undefined}
            updateCart={() => {}}
            updateHoursInCart={() => {}}
            hideAddButton={true}
            hoursChanged={(newHours: any) => {
              setAdditionalHours(newHours)
            }}
            loading={loading}
            updateHourCost={(cost: any) => {
              setPerHourCost(cost)
            }}
          />
          {total > 0 && !loading && (
            <div className='border-2 rounded-[10px] border-gray-600 mb-6 p-4'>
              <div className='flex justify-between items-center'>
                <span>Total </span>
                <b>£{total}</b>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default AdditionalHoursModal

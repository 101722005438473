import {Action, Reducer} from '@reduxjs/toolkit'
import {User} from '@sentry/browser'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {AuthModel} from '../../app/content/models/auth/AuthModel'
import {getUserByToken, getUserPersonalData} from '../../app/content/services/auth.service'
import {updateLastLoginDate} from '../../app/content/services/student.service'
import {PersistPartial} from 'redux-persist/es/persistReducer'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetIsLogin: '[Set IsLogin] Action',
  SetIsMobile: '[Set IsMobile] Action',
  ForgotPassword: '[ForgotPassword] Action',
  ResetPassword: '[ResetPassword] Action',
  SetHasUnVerifiedData: '[Set HasUnVerifiedData] Action',
  PersonalDataRequested: '[PersonalDataRequested] Action',
  SetPersonalData: '[Set personal] Action',
  SetIsStepsCompleted: '[Set isStepsCompleted] Action',
  SetIsInactiveDeal: '[Set isInactiveDeal] Action',
  SetStageId: '[Set stageId] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  email: undefined,
  isLogin: false,
  isMobile: false,
  hasUnVerifiedData: undefined,
  isStepsCompleted: false,
  isInactiveDeal: false,
  stageId: undefined,
}

export interface IAuthState {
  user?: AuthModel
  personalData?: User
  accessToken?: string
  email?: string
  isLogin?: boolean
  isMobile?: boolean
  hasUnVerifiedData?: boolean
  isStepsCompleted?: boolean
  isInactiveDeal?: boolean
  stageId: undefined
}

export const reducer: Reducer<IAuthState & PersistPartial, Action> = persistReducer(
  {
    storage,
    key: 'v100-demo1-auth',
    whitelist: [
      'user',
      'email',
      'password',
      'isLogin',
      'isMobile',
      'personalData',
      'hasUnVerifiedData',
      'isStepsCompleted',
      'isInactiveDeal',
      'stageId',
    ],
  },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        return {...state, user: undefined}
      }

      case actionTypes.ForgotPassword: {
        const email = action.payload?.email
        return {...state, email}
      }

      case actionTypes.ResetPassword: {
        return {...state, email: undefined}
      }

      case actionTypes.Register: {
        return {...state, user: undefined}
      }

      case actionTypes.Logout: {
        localStorage.clear()
        sessionStorage.clear()
        return {
          ...state,
          password: undefined,
          user: undefined,
          isLogin: false,
          personalData: undefined,
          hasUnVerifiedData: false,
          isStepsCompleted: false,
          isInactiveDeal: false,
          stageId: undefined,
        }
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user: user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user: user}
      }

      case actionTypes.SetIsLogin: {
        const isLogin = action.payload?.isLogin
        return {...state, isLogin: isLogin}
      }
      case actionTypes.SetIsMobile: {
        const isMobile = action.payload?.isMobile
        return {...state, isMobile: isMobile}
      }
      case actionTypes.SetHasUnVerifiedData: {
        const hasUnVerified = action.payload?.hasUnVerifiedData
        return {...state, hasUnVerifiedData: hasUnVerified}
      }
      case actionTypes.SetPersonalData: {
        const personalData = action.payload?.personalData
        return {...state, personalData: personalData}
      }
      case actionTypes.SetIsStepsCompleted: {
        const isStepsCompleted = action.payload?.isStepsCompleted
        return {...state, isStepsCompleted: isStepsCompleted}
      }
      case actionTypes.SetIsInactiveDeal: {
        const isInactiveDeal = action.payload?.isInactiveDeal
        return {...state, isInactiveDeal: isInactiveDeal}
      }
      case actionTypes.SetStageId: {
        const stageId = action.payload?.stageId
        return {...state, stageId: stageId}
      }
      default:
        return state
    }
  }
)

export const actions = {
  login: () => ({type: actionTypes.Login}),
  setIsStepsCompleted: (isStepsCompleted: boolean) => ({
    type: actionTypes.SetIsStepsCompleted,
    payload: {isStepsCompleted},
  }),
  setIsInactiveDeal: (isInactiveDeal: boolean) => ({
    type: actionTypes.SetIsInactiveDeal,
    payload: {isInactiveDeal},
  }),
  setStageId: (stageId: any) => ({type: actionTypes.SetStageId, payload: {stageId}}),
  setIsLogin: (isLogin: boolean) => ({type: actionTypes.SetIsLogin, payload: {isLogin}}),
  setIsMobile: (isMobile: boolean) => ({type: actionTypes.SetIsMobile, payload: {isMobile}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: AuthModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: AuthModel) => ({type: actionTypes.SetUser, payload: {user}}),
  forgotPassword: (email: string) => ({
    type: actionTypes.ForgotPassword,
    payload: {email},
  }),
  resetPassword: () => ({
    type: actionTypes.ResetPassword,
  }),
  requestPersonalData: () => ({
    type: actionTypes.PersonalDataRequested,
  }),
  setPersonalData: (personalData: User) => ({
    type: actionTypes.SetPersonalData,
    payload: {personalData},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    updateLastLoginDate()
    yield put(actions.fulfillUser(yield getUserByToken()))
  })

  yield takeLatest(actionTypes.UserLoaded, function* personalDataRequested() {
    yield put(actions.setPersonalData(yield getUserPersonalData()))
  })

  yield takeLatest(actionTypes.UserRequested, function* personalDataRequested() {
    yield put(actions.setPersonalData(yield getUserPersonalData()))
  })

  yield takeLatest(actionTypes.PersonalDataRequested, function* personalDataRequested() {
    yield put(actions.setPersonalData(yield getUserPersonalData()))
  })
}

import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {Lesson} from '../../../../models/lessons/lesson'
import {Job} from '../../../../models/payments/job'
import Button from '../../../shared/elements/Button'
import EmptyState from '../../../shared/elements/EmptyState'

type Props = {
  paymentInfo: Job | null
  lesson: Lesson | undefined
  hasPendingLessons: boolean
  hasPrevLessons: boolean
}

const Lessons: FC<Props> = ({paymentInfo, lesson, hasPendingLessons, hasPrevLessons}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <>
      {/* Start:: lessons */}
      <div className='flex flex-col px-0'>
        {lesson ? (
          <>
            <div className='flex flex-grow items-center'>
              <strong className='brand mb-2'>Upcoming lesson:</strong>
            </div>

            <ul className='list-group list-group-flush w-100 mb-0 border rounded p-5 pb-3'>
              <li className='list-group-item mx-n5 pb-5 pt-0'>
                <div className='flex justify-content-between'>
                  <span className=' fw-black'>
                    {intl.formatMessage({id: 'STUDENT_TEST.TESTDATE'})}:
                  </span>
                  <span className=' fw-bolder'>{lesson?.lesson_date_str}</span>
                </div>
              </li>
              <li className='list-group-item mx-n5  pt-4'>
                <div className='flex justify-content-between'>
                  <span className=' fw-black'>
                    {' '}
                    {intl.formatMessage({id: 'LESSONS.DURATION'})}:
                  </span>
                  <span className=' fw-bolder'>
                    {Math.round(lesson?.duration ?? 0)}{' '}
                    {lesson?.duration == 1
                      ? intl.formatMessage({id: 'DASHBOARD.HOUR'})
                      : intl.formatMessage({id: 'DASHBOARD.HOURS'})}
                  </span>
                </div>
              </li>
            </ul>
          </>
        ) : (
          <>
            <EmptyState
              title={
                hasPrevLessons
                  ? intl.formatMessage({id: 'DASHBOARD.EMPTY_UPCOMING_LESSON'})
                  : intl.formatMessage({id: 'DASHBOARD.EMPTY_LESSON'})
              }
            />
            {/* End:: Empty lessons placeholder */}
          </>
        )}

        <div
          className={` ${
            hasPendingLessons ? 'grid-cols-1 md:grid-cols-2 gap-3' : 'grid-cols-1'
          } grid`}
        >
          {hasPendingLessons ? (
            <Button
              colour='pink'
              type='button'
              onClick={() => {
                navigate('/lessons')
              }}
            >
              {intl.formatMessage({id: 'ACTIONS.RESPOND'})}
            </Button>
          ) : null}
          <Button
            colour='outline'
            type='button'
            onClick={() => {
              navigate('/addons')
            }}
          >
            {intl.formatMessage({id: 'STUDENT_TEST.NEEDPRACTICE'})}
          </Button>
        </div>
      </div>
      {/* End:: lessons */}
    </>
  )
}

export {Lessons}

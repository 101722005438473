import {FC, useState, useEffect} from 'react'
import {DashboardHeader} from '../../components/dashboard/DashboardHeader'
import {DashboardTabs} from '../../components/dashboard/DashboardTabs'
import {getOrder} from '../../services/booking.service'
import {useCourse} from '../../providers/CourseProvider'
import toast from 'react-hot-toast'

const DashboardWrapper: FC = () => {
  const {Course} = useCourse()
  const [isPto, setIsPto] = useState<boolean>(false)
  const [order, setOrder] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      if (Course.deal_id) {
        try {
          setIsLoading(true)
          const orderResponse = await getOrder(Course.deal_id)
          setIsPto(orderResponse?.is_pto)
          setOrder(orderResponse ?? [])
          setIsLoading(false)
        } catch (error: any) {
          if (!error?.errorMessage) toast.error('Failed to get order')
          setIsLoading(false)
        }
      }
    })()
  }, [Course.deal_id])

  return (
    <>
      <DashboardHeader isLoading={isLoading} order={order} isPto={isPto} />
      <DashboardTabs isPto={isPto} />
      {/* <GradientButton colour="gradient" size="large" fullWidth>
        Submit
      </GradientButton> */}
    </>
  )
}

export {DashboardWrapper}

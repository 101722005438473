import Badge from '../elements/Badge'
import Button from '../elements/Button'
import {toAbsoluteUrl} from '../../../helpers'
import {capitalizeFirstLetter} from '../../../helpers/textHelper'
import {ClickAwayListener, Skeleton, Tooltip} from '@mui/material'
import InfoList, {InfoListData} from '../data/InfoList'
import {PencilIcon} from '@heroicons/react/24/outline'
import {useState} from 'react'

export type TestCardProps = {
  name?: string
  pmfIcon?: boolean
  loading?: boolean
  actions?: any
  showAction?: boolean
  subTitle?: {
    text: string
    color: 'yellow' | 'red' | 'green' | 'blue'
    icon: any
  }
  data?: InfoListData[]
  buttons?: {
    text: React.ReactNode
    onClick: () => void
    color?:
      | 'pink'
      | 'darkBlue'
      | 'outline'
      | 'gray'
      | 'link'
      | 'dangerLink'
      | 'lightGray'
      | 'transparent'
      | 'darkGray'
    disabled: boolean
  }[]
  status?: {
    title: string
    color?:
      | 'darkBlue'
      | 'green'
      | 'darkGreen'
      | 'yellow'
      | 'red'
      | 'outlineRed'
      | 'gray'
      | 'darkRed'
  }
  editAction?: {
    onClick: () => void
    text: string
  }
  children?: React.ReactNode
}

export default function TestCard({
  name,
  pmfIcon = false,
  subTitle,
  loading = false,
  data,
  buttons,
  status,
  showAction = false,
  editAction,
}: TestCardProps) {
  const [openDesc, setOpenDesc] = useState(false)

  return (
    <div className='h-full'>
      <div className='bg-white rounded-lg shadow h-full flex flex-col justify-between px-4 py-5 sm:px-6 sm:ps-4'>
        <div>
          <div className='flex flex-col'>
            <div className='min-w-0 flex-1 flex items-center'>
              <div className='shrink-0 flex flex-col items-end'>
                {loading ? (
                  <Skeleton width={150} animation='wave'></Skeleton>
                ) : (
                  <p className='text-lg font-bold text-darkBlue'>{name}</p>
                )}
              </div>
              {pmfIcon && (
                <img src={toAbsoluteUrl('/assets/media/logos/pmf.svg')} className='h-4 ms-2' />
              )}
              <div className='w-full flex justify-between'>
                <div>
                  {subTitle ? (
                    <div className='flex w-full items-center'>
                      {subTitle.icon ?? ''}
                      <p className={`text-lg font-bold ml-2 ${subTitle.color}-text-500`}>
                        {capitalizeFirstLetter(subTitle.text)}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className='flex flex-1 justify-end'>
                  {showAction ? (
                    <div className='ml-4 flex flex-shrink-0'>
                      {editAction && (
                        <ClickAwayListener onClickAway={() => setOpenDesc(false)}>
                          <Tooltip
                            title={editAction.text}
                            arrow
                            placement='top'
                            open={openDesc}
                            componentsProps={{
                              tooltip: {
                                style: {
                                  fontSize: '10px',
                                  lineHeight: '18px',
                                  fontFamily: 'Poppins, sans-serif',
                                  maxWidth: '250px',
                                  marginRight: '6px',
                                },
                              },
                            }}
                          >
                            <span
                              className='cursor-pointer'
                              onClick={() => {
                                setOpenDesc(!openDesc)
                                editAction.onClick()
                              }}
                            >
                              <PencilIcon className='h-4 w-4 mr-1 text-darkBlue' />
                            </span>
                          </Tooltip>
                        </ClickAwayListener>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <InfoList data={data} loading={loading}></InfoList>
        </div>

        {buttons && buttons.length > 0 && (
          <div className='flex space-x-2 align-items-center justify-content-center mt-2'>
            {buttons.map((button, i) => (
              <Button
                onClick={() => button.onClick()}
                colour={button.color}
                key={i}
                disabled={button.disabled}
                size='large'
              >
                <span className='text-sm font-semibold'>{button.text}</span>
              </Button>
            ))}
          </div>
        )}

        {loading ? (
          <div className=' bg-gray-100 w-full rounded-ss-none rounded-se-none rounded-md py-0 leading-[20px] flex relative items-center justify-center font-semibold text-sm transition-all'>
            <Skeleton className='!my-2' width={150} animation='wave'></Skeleton>
          </div>
        ) : (
          status && (
            <div>
              <Badge
                roundedT={false}
                roundedPill={false}
                size='md'
                fullWidth
                colour={status.color}
                title={capitalizeFirstLetter(status.title)}
              />
            </div>
          )
        )}
      </div>
    </div>
  )
}

import Amplify, { Hub } from "aws-amplify";

const amplifyConfigurations = {
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_REGION,
        identityPoolRegion: process.env.REACT_IDENTITY_POOL_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_OAUTH_DOMAIN,
            redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
            redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
            responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE
        }
    }
};

const listener = (data: any) => {
    const userExistsMsg = "Already+found+an+entry+for+username+";
    if (data.payload.event === 'signIn_failure') {
        if (data.payload.data.message.includes(userExistsMsg + "facebook")) {
          //  federatedSignIn("Facebook");
        } else if (data.payload.data.message.includes(userExistsMsg + "google")) {
         //   federatedSignIn("Google");
        } else if (data.payload.data.message.includes(userExistsMsg + "signinwithapple")) {
         //   federatedSignIn("SignInWithApple");
        }  else {
          // window.location.href = '../'; 
        }
    }
}

const initializeAmplify = () => {
    Amplify.configure(amplifyConfigurations);
    Hub.listen('auth', listener);
}

export default initializeAmplify;

import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import {LicenseDocumentData} from '../models/student/licence-document-data'
import {UpdateLicenseDocumentModel} from '../models/student/update-license-document-model'

const DOCUMENTS_URL = process.env.REACT_APP_DOCUMENTS_BASE_URL
const ADD_UPDATE_lICENCE_URL = `${DOCUMENTS_URL}/licence/add-update-licence`
const GEt_lICENCE_DOCUMENT = `${DOCUMENTS_URL}/licence/get-student-licence`
const DELETE_lICENCE_DOCUMENT = `${DOCUMENTS_URL}/licence/delete-student-licence`

export async function addUpdateLicence(
  data: UpdateLicenseDocumentModel
): Promise<BaseResponse<UpdateLicenseDocumentModel>> {
  return await modifiedFetch({
    url: `${ADD_UPDATE_lICENCE_URL}`,
    method: 'POST',
    data: data,
  })
}

export async function getLicenceByStudentIDFromDcument(): Promise<
  BaseResponse<LicenseDocumentData>
> {
  return await modifiedFetch({
    url: `${GEt_lICENCE_DOCUMENT}`,
    method: 'GET',
  })
}

export async function deleteLicenceByStudentIDFromDcument(): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${DELETE_lICENCE_DOCUMENT}`,
    method: 'GET',
  })
}

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {PassprotectWithRedemptionRemaining} from '../../../../models/student/pass-protect-with-redemption-remaining'
import {Test} from '../../../../models/tests/test'
import {Card} from '../../Card'
import {TestStatusTypeEnum} from '../../../../models/enums/test-status-type'
import {useCourse} from '../../../../providers/CourseProvider'
import {TestTypeEnum} from '../../../../models/enums/test-type'
import Button from '../../../shared/elements/Button'
import EmptyState from '../../../shared/elements/EmptyState'
import {DocumentCheckIcon} from '@heroicons/react/24/outline'
import AddTest from '../../../tests/_modals/AddTest'

type Props = {
  passedTheoryTest: boolean
  hasPracticalTest: boolean
  test: Test | undefined
  getStudentBookingList: any
  testRejectionReason: any
  theoryTestCenters: any
  practicalTestCenters: any
  disableAction?: any
  dealId: string
  hasPendingTest: boolean | null
  canAddPassProtect: any
  preferredTestCenters: Array<any>
  isPto?: boolean
  leadTime?: number
  stepHasContent: boolean
  passProtectOption: PassprotectWithRedemptionRemaining | undefined
}

const ArrangePracticalTest: FC<Props> = ({
  passedTheoryTest,
  hasPracticalTest,
  test,
  getStudentBookingList,
  practicalTestCenters,
  testRejectionReason,
  theoryTestCenters,
  disableAction,
  dealId,
  hasPendingTest,
  canAddPassProtect,
  preferredTestCenters,
  isPto,
  leadTime,
  stepHasContent,
  passProtectOption,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [showTestModal, setShowTestModal] = useState(false)
  const [testCenters, setTestCenters] = useState<Array<string>>([])
  const {Course} = useCourse()
  useEffect(() => {
    if (preferredTestCenters) {
      const testCenterNames = preferredTestCenters
        .filter((item) => item.deleted_at === null)
        .map((item) => item.test_centre.test_center_name)
      setTestCenters(testCenterNames)
    }
  }, [preferredTestCenters])

  return (
    <>
      <AddTest
        onTestSubmit={getStudentBookingList}
        theoryTestCenters={[]}
        practicalTestCenters={practicalTestCenters}
        initialTestType={TestTypeEnum.Practical}
        show={showTestModal}
        booking_id={+dealId}
        onHide={() => {
          setShowTestModal(false)
        }}
        saveTest={true}
        hidePassed={false}
        title={intl.formatMessage(
          {id: 'STUDENT_TEST.TEST_DETAILS_HAVE_BEEN_BOOKED'},
          {type: 'practical'}
        )}
        leadTime={leadTime}
        isPto={isPto}
      />

      {!passedTheoryTest && hasPracticalTest ? (
        <>
          <div className='py-[1px]'>
            <p className='text-base text-darkBlue'>
                {intl.formatMessage({ id: 'STUDENT_TEST.CANNOT_START_FAST' })}
            </p>
          </div>
        </>
      ) : null}

      {!passedTheoryTest && !hasPracticalTest && (
        <div className=''>
          <EmptyState
            Icon={DocumentCheckIcon}
            title={intl.formatMessage({id: 'STUDENT_TEST.PASS_YOUR_THEORY_TEST_FIRST'})}
            description={intl.formatMessage({id: 'STUDENT_TEST.YOU_NEED_TO_PASS'})}
          />
        </div>
      )}

      {passedTheoryTest && !hasPracticalTest && stepHasContent && (
        <>
          <h3 className='text-base font-semibold mb-0 mt-2'>
            {intl.formatMessage({id: 'STUDENT_TEST.BOOKTESTYOURSELF'}, {type: 'practical'})}
          </h3>
          <div className='flex mt-3'>
            <Button
              id='add_test_btn'
              disabled={disableAction}
              type='button'
              colour='pink'
              onClick={() => {
                setShowTestModal(true)
              }}
            >
              {intl.formatMessage({id: 'STUDENT_TEST.ADDYOURTEST'})}
            </Button>
          </div>
        </>
      )}

      {passedTheoryTest &&
        hasPracticalTest &&
        (test === undefined ||
          (test &&
            (test.status === TestStatusTypeEnum.Rejected ||
              test.status === TestStatusTypeEnum.PendingReschedule ||
              test.status === TestStatusTypeEnum.NotApplicable ||
              test?.status === TestStatusTypeEnum.Rebooked ||
              test?.status === TestStatusTypeEnum.Cancelled))) &&
        !hasPendingTest && (
          <>
            <div className='py-[1px]text-base text-darkBlue'>
              <p>{intl.formatMessage({ id: 'STUDENT_TEST.LOOKING_FOR_PRACTICAL' })}</p>
              <br />
              <p>{intl.formatMessage({ id: 'STUDENT_TEST.TEST_CENTERS_MESSAGE' })}:</p>
              <ul>
                  {testCenters.map((testCenter, i) => {
                      return <b key={i}><li className='mb-1 font-size-16' key={i}>{testCenter}</li></b>
                  })}
              </ul>
              <br />
              <p>{intl.formatMessage({ id: 'STUDENT_TEST.TEST_CENTER_MESSAGE_2' })}</p>
            </div>
          </>
        )}

      {passedTheoryTest &&
        hasPracticalTest &&
        test &&
        test.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking && (
          <>
            <Card
              parentPage='journey'
              test={test}
              getStudentBookingList={getStudentBookingList}
              practicalTestCenters={practicalTestCenters}
              testRejectionReason={testRejectionReason}
              theoryTestCenters={theoryTestCenters}
              disableAction={disableAction}
              passProtectOption={passProtectOption}
            />
          </>
        )}


      {hasPendingTest && (
        <div className='py-[1px]'>
          <p className='text-base text-darkBlue'>
              {intl.formatMessage({ id: 'STUDENT_TEST.PENDING_ADI' })}
          </p>
       </div>
      )}

      {stepHasContent && canAddPassProtect && !Course?.currentCourse?.inActiveStage && (
        <div className='mt-3'>
          <Button
            colour='outline'
            type='button'
            fullWidth
            onClick={() => {
              navigate('/addons')
            }}
          >
            {intl.formatMessage({id: 'STUDENT_TEST.ADDPASSPROTECT'})}
          </Button>
        </div>
      )}
    </>
  )
}

export {ArrangePracticalTest}

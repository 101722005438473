import {FC} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import { AboutStudent } from '../../components/account-settings/about-student/AboutStudent'
import {useNavigate} from 'react-router-dom'
const AboutStudentWrapper: FC = () => {
  const navigate = useNavigate()
  const redirectToDashboard = () => {
    navigate('/dashboard')
  }
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='About You' backToURL='/profile' isCenter></PageHeader>
      </div>

      <div className='px-5 py-6 p-lg-0'>
        <AboutStudent
          parentWrapper='accountsettings'
          goToPrevTab={redirectToDashboard}
          goToNextTab={redirectToDashboard}
        ></AboutStudent>
      </div>
    </>
  )
}

export default AboutStudentWrapper

import {FC} from 'react'
import {useIntl} from 'react-intl'
import Modal from '../shared/overlays/Modal'
import Alert from '../shared/overlays/Alert'

type Props = {
  show: any
  handleClose: any
  handleSubmit: any
}

const BookNewCourseModal: FC<Props> = ({show, handleClose, handleSubmit}) => {
  const intl = useIntl()

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        title={'Book New Course'}
        submitText='Get Your Discount'
        onSubmit={handleSubmit}
      >
        <div className='pt-4 pb-8'>
          <h4 className='font-size-15 mb-3'>
            {intl.formatMessage({id: 'BOOK_NEW_COURSE.DESC_1'})}
          </h4>
          <Alert
            description={intl.formatMessage({id: 'BOOK_NEW_COURSE.DESC_2'})}
            colour='green'
          ></Alert>
        </div>
      </Modal>
    </>
  )
}

export {BookNewCourseModal}

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {getStudentBookingsForHistory, updateSelectedBooking} from '../../services/student.service'
import {useCourse} from '../../providers/CourseProvider'
import {BookingHistory} from '../../models/student/booking-history'
import Skeleton from '@mui/material/Skeleton'
import {useNavigate} from 'react-router-dom'
import Button from '../shared/elements/Button'
import InfoList from '../shared/data/InfoList'
import {ArrowPathIcon} from '@heroicons/react/24/outline'
import {BookingReceiptModal} from './_modals/BookingReceiptModal'
import {BookingReceiptTemplate} from './_modals/BookingReceiptTemplate'
import toast from 'react-hot-toast'
type Props = {}
const CourseHistory: FC<Props> = () => {
  const intl = useIntl()
  const [bookings, setBookings] = useState<Array<BookingHistory>>([])
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const [dealId, setDealId] = useState<string>('')
  const [showViewReceiptModal, setShowViewReceiptModal] = useState(false)
  const [showPdfViewReceiptModal, setShowPdfViewReceiptModal] = useState(false)
  const [selectedReceiptDeal, setSelectedReceiptDeal] = useState()
  const openViewReceiptModal = (id: any) => {
    setShowViewReceiptModal(true)
    setSelectedReceiptDeal(id)
  }

  const openViewPdfReceiptModal = (id: any) => {
    setShowPdfViewReceiptModal(true)
    setSelectedReceiptDeal(id)
  }

  const {Course, getCourses, getCurrentCourse, updateCourse} = useCourse()

  useEffect(() => {
    setDealId(Course.deal_id)
  }, [Course.deal_id])

  useEffect(() => {
    const bookingsHistory = async () => {
      const result = await getStudentBookingsForHistory().catch((err) => {
        if (!err?.errorMessage) toast.error('Failed to get student booking')
        setLoading(false)
      })
      if (result) {
        setBookings(result.results)
        setLoading(false)
      }
    }
    bookingsHistory()
  }, [])

  const switchBooking = async (deal_id: any) => {
    setLoading(true)
    const result = await updateSelectedBooking({deal_id: deal_id}).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to update selected course')
      setLoading(false)
    })
    if (result) {
      updateCourse('deal_id', deal_id)
      setLoading(false)
      getCourses()
      getCurrentCourse()
      navigate('/')
    }
  }
  var bookingsRows = bookings.map(function (booking: BookingHistory, key: number) {
    return (
      <div
        className='rounded shadow border-l-8 border-l-blue bg-white px-4 py-5 sm:px-6 sm:ps-4 h-full mb-4'
        key={key}
      >
        <div className='flex mb-4'>
          <div className='flex flex-col items-center justify-center h-[80px] w-[90px] bg-[#E9FCFF] rounded-full px-4 sm:px-6 py-2'>
            <span className='text-base'>{booking.hours}</span>
            <span className='text-sm font-normal'>Hours</span>
          </div>

          <div className=' flex-col px-4 sm:px-6 w-full'>
            <div
              className={
                booking.deal_id !== dealId
                  ? ' flex-1 justify-end flex '
                  : 'flex items-center gap-x-1.5'
              }
            >
              {booking.deal_id !== dealId ? (
                <div>
                  <Button
                    size='icon'
                    onClick={() => switchBooking(booking.deal_id)}
                    colour='gray'
                    fullWidth={false}
                  >
                    <span className='p-1 flex content-center'>
                      <ArrowPathIcon className='w-4 h-4' />
                    </span>
                  </Button>
                </div>
              ) : (
                <div className='flex items-center gap-x-1.5'>
                  <div className='bg-green h-1.5 w-1.5 rounded-full'></div>
                  <p className='text-sm font-bold'>
                    {intl.formatMessage({id: 'COURSE_HISTORY.ACTIVE'})}
                  </p>
                </div>
              )}
            </div>
            <InfoList
              data={[
                {
                  icon: 'icon-suitcase',
                  value: booking.deal_id ?? '-',
                },
                {
                  icon: 'icon-currency-pound',
                  value: `£ ${booking.total_amount}` ?? '-',
                },
              ]}
            />
          </div>
        </div>

        <div className='flex space-x-2 d-flex align-items-center justify-content-center'>
          <Button type='button' onClick={() => openViewReceiptModal(booking.deal_id)} colour='pink'>
            <i className='fas fa-eye'></i>
            <span className='d-flex d-lg-none'>View Receipt</span>
          </Button>
          <Button
            type='button'
            onClick={() => openViewPdfReceiptModal(booking.deal_id)}
            colour='outline'
          >
            <i className='fas fa-download'></i>
            <span className='d-flex d-lg-none'>Download Receipt</span>
          </Button>
        </div>
      </div>
    )
  })
  return (
    <>
      {loading ? (
        <Skeleton width='100%' height={100} />
      ) : (
        <div>
          {/* START:: "Modals Area" */}
          <BookingReceiptModal
            deal_id={selectedReceiptDeal}
            handleClose={() => setShowViewReceiptModal(false)}
            show={showViewReceiptModal}
          />

          <BookingReceiptTemplate
            show={showPdfViewReceiptModal}
            deal_id={selectedReceiptDeal}
            handleClose={() => setShowPdfViewReceiptModal(false)}
            billingAddress={Course?.billingAddress}
          />
          {/* END:: "Modals Area" */}

          {bookingsRows}
        </div>
      )}
    </>
  )
}

export {CourseHistory}

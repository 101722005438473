import {FC, useEffect, useState} from 'react'
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/20/solid'

import {format, addDays, subDays, setHours, setMinutes} from 'date-fns'
import moment from 'moment'
import {useSharedData} from '../../../providers/SharedDataProvider'
import LessonDetailsModal from '../_modal/LessonDetailsModal'

type Props = {
  highlightDates: any
  selectedDayInCalendar: Date
  setSelectedDayInCalendar: any
}

const DailyView: FC<Props> = ({
  highlightDates,
  selectedDayInCalendar,
  setSelectedDayInCalendar,
}) => {
  const {updateSharedData} = useSharedData()
  // Create an array of hours from 0 to 23
  const hoursArray = Array.from({length: 24}, (_, index) => index)
  // Get the current date to set the minutes and seconds to 0
  const now = new Date()
  const currentDay = setMinutes(setHours(now, 0), 0)
  const [showLessonDetails, setShowLessonDetails] = useState<boolean>(false)
  const [selectedLesson, setSelectedLesson] = useState<any>(null)
  const [currentDate, setCurrentDate] = useState(selectedDayInCalendar)

  const handlePrevClick = () => {
    setCurrentDate((prevDate) => subDays(prevDate, 1))
    setSelectedDayInCalendar(subDays(currentDate, 1))
  }

  const handleNextClick = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1))
    setSelectedDayInCalendar(addDays(currentDate, 1))
  }

  const eventClickFun = (event: any) => {
    setShowLessonDetails(true)
    setSelectedLesson(event)
  }

  useEffect(() => {
    updateSharedData('selectedDate', currentDate)
  }, [currentDate])

  return (
    <>
      <LessonDetailsModal
        show={showLessonDetails}
        onHide={() => setShowLessonDetails(false)}
        showLesson={selectedLesson}
      />
      <div className='flex h-full flex-col sm:px-4'>
        <header className='flex flex-none items-center justify-between border-b border-gray-200 py-6'>
          <div>
            <h2 className='text-base font-bold leading-6'>
              <time dateTime={format(currentDate, 'MMMM dd, yyyy')} className='block'>
                {format(currentDate, 'MMMM dd, yyyy')}
              </time>
            </h2>
            <p className='mt-1 text-sm'>{format(currentDate, 'EEEE')}</p>
          </div>
          <div className='flex items-center'>
            <div className='relative flex rounded-md bg-white shadow-sm items-stretch'>
              <button
                type='button'
                onClick={() => handlePrevClick()}
                className='flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50'
              >
                <span className='sr-only'>Previous day</span>
                <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
              </button>
              <button
                type='button'
                onClick={() => setCurrentDate(new Date())}
                className='border-y border-gray-300 md:px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative block'
              >
                Today
              </button>
              <button
                type='button'
                onClick={() => handleNextClick()}
                className='flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50'
              >
                <span className='sr-only'>Next day</span>
                <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
              </button>
            </div>
          </div>
        </header>
        <div className='isolate flex flex-auto bg-white max-h-[68vh] overflow-y-auto overflow-x-hidden'>
          <div className='flex flex-auto flex-col overflow-auto'>
            <div className='flex w-full flex-auto'>
              <div className='w-12 flex-none bg-white ring-1 ring-gray-100' />
              <div className='grid flex-auto grid-cols-1 grid-rows-1'>
                {/* Horizontal lines */}
                <div
                  className='col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100'
                  style={{gridTemplateRows: 'repeat(24, minmax(4.5rem, 1fr))'}}
                >
                  <div className='row-end-1 h-7'></div>
                  {hoursArray.map((hour) => {
                    const hourOfDay = setHours(currentDay, hour)
                    return (
                      <div key={hour}>
                        <div>
                          <div className='sticky left-0 z-20 -ml-12 -mt-2.5 w-12 pr-2 text-right text-xs leading-5 text-gray-400'>
                            {format(hourOfDay, 'h a')}
                          </div>
                        </div>
                        <div />
                      </div>
                    )
                  })}
                </div>

                {/* Events */}
                <ol
                  className='col-start-1 col-end-2 row-start-1 grid grid-cols-1'
                  style={{
                    gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto',
                  }}
                >
                  {highlightDates
                    .filter((elem: any) => moment(elem.date).isSame(moment(currentDate), 'day'))
                    .map((item: any, i: number) => (
                      <li
                        key={i}
                        className='relative mt-px flex col-start-1'
                        style={{
                          gridRow: `${
                            2 +
                            moment(item.date).get('hour') * 12 +
                            moment(item.date).get('minute') / 5
                          } / span ${12 * item.duration}`,
                        }}
                      >
                        <p
                          onClick={() => eventClickFun(item)}
                          className='group absolute inset-1 flex items-center rounded-lg py-1 px-2 text-xs leading-5 border border-white bg-[#2BCD5A]'
                        >
                          <span className='text-white hidden md:inline mb-0'>
                            <time
                              className=' leading-none text-xs'
                              dateTime={moment(item.date).toISOString()}
                            >
                              {moment(item.date).format('hh:mm a')} - {''}
                            </time>
                          </span>
                          <span className='order-1 font-semibold text-white text-xs '>
                            {item.title}
                          </span>
                        </p>
                      </li>
                    ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {DailyView}

import React, {FC} from 'react'
import {useLang} from './Custom18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/fr'


import enMessages from './messages/en.json'

var flatten = require('flat');

const allMessages: any = {
  en: flatten(enMessages),
}

const I18nProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}

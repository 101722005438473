import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {MedicalConditionItem} from '../../../models/medical-condition-item'
import {MedicalConditionDataModel} from '../../../models/medical-condition-data-model'
import {IQuationnaireTabProps} from '../../../models/quationnaire-tab-props'
import {scrollToFirstError, scrollToTop} from '../../../helpers/ScrollHelper'
import {getMedicalConditions} from '../../../services/medical-condition.service'
import {addMedicalConditions, getStudentMedicalConditions} from '../../../services/student.service'
import {Skeleton} from '@mui/material'
import Button from '../../shared/elements/Button'
import RadioInput from '../../shared/forms/RadioInput'
import Alert from '../../shared/overlays/Alert'
import CreatableDropdown from '../../shared/forms/CreatableDropdown'
import CheckboxInput from '../../shared/forms/CheckboxInput'
import {TrashIcon} from '@heroicons/react/24/outline'
import toast from 'react-hot-toast'

const MedicalConditions: FC<IQuationnaireTabProps> = (props) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [initialMedicalConditions, setInitialMedicalConditions] =
    useState<MedicalConditionDataModel>({
      hasMedicalConditions: false,
      medicalConditions: [new MedicalConditionItem()],
    })
  const [medicalConditionsData, setMedicalConditionData] = useState<Array<MedicalConditionItem>>([])
  const [medicalConditionFormData, setMedicalConditionsFormData] =
    useState<MedicalConditionDataModel>({
      hasMedicalConditions: false,
      medicalConditions: [new MedicalConditionItem()],
    })

  const validationSchema = Yup.object().shape({
    hasMedicalCondition: Yup.boolean().required(
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'QUESTIONNAIRE.MEDICALCONDITIONS.MEDICAL_CONDITION'})}
      )
    ),
    medicalConditions: Yup.array().when('hasMedicalCondition', {
      is: (hasMedicalCondition: any) => {
        const condition = hasMedicalCondition === true
        return condition
      },
      then: (schema) =>
        schema.min(1, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 1})).of(
          Yup.object().shape({
            medical_condition_id: Yup.string().nullable(),
            medical_condition_name: Yup.string()
              .required(
                intl.formatMessage(
                  {id: 'VALIDATION.REQUIRED'},
                  {
                    field: intl.formatMessage({
                      id: 'QUESTIONNAIRE.MEDICALCONDITIONS.MEDICAL_CONDITION',
                    }),
                  }
                )
              )
              .max(255, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 255})),
          })
        )
    }),
  })
  const formik = useFormik({
    initialValues: {
      hasMedicalCondition: medicalConditionFormData.hasMedicalConditions,
      medicalConditions: medicalConditionFormData.medicalConditions,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, {setSubmitting}) => {
      setSubmitting(true)
      submitForm(values , setSubmitting)
    },
  })

  useEffect(() => {
    scrollToFirstError(formik)
  }, [formik.errors, formik.isSubmitting, formik.isValidating])

  const submitForm = async (values: any, setSubmitting: any) => {
    if (!values.hasMedicalCondition) {
      delete values.medicalConditions
    } else {
      values.medicalConditions.forEach((m: any) => {
        if (!m.medical_condition_id || m.medical_condition_id === m.medical_condition_name) {
          delete m.medical_condition_id
        }
      })
    }
    const addResponse = await addMedicalConditions(values).catch((error) => {
      setSubmitting(false)
      scrollToTop()
      if (!error.errorMessage) {
        toast.error(intl.formatMessage({id: 'GENERAL.ERROR'}))
      }
    })
    setSubmitting(false)
    if (addResponse) {
      props.goToNextTab()
    }
  }
  useEffect(() => {
    if (medicalConditionFormData.medicalConditions.length === 0) {
      setMedicalConditionsFormData({
        ...medicalConditionFormData,
        medicalConditions: initialMedicalConditions.medicalConditions,
      })
    }
  }, [medicalConditionFormData.medicalConditions, medicalConditionsData])

  const medicalConditionsBinded = medicalConditionsData
    .filter(
      (a) =>
        medicalConditionFormData.medicalConditions?.findIndex(
          (b) => b.medical_condition_id === a.medical_condition_id
        ) === -1
    )
    .map((medicalCondition) => ({
      label: medicalCondition.medical_condition_name,
      value: medicalCondition.medical_condition_id,
    }))

  const addMedicalCondition = () => {
    let tempMedicalConditions = formik.values.medicalConditions
    const newMedicalCondition: MedicalConditionItem = new MedicalConditionItem()
    tempMedicalConditions.push(newMedicalCondition)
    formik.setFieldValue('medicalConditions', tempMedicalConditions)
  }

  const onMedicalConditionChanged = (e: any, key: number) => {
    if (formik.isSubmitting) {
      return
    }
    const updatedMedicalConditions = formik.values.medicalConditions
    updatedMedicalConditions[key].medical_condition_id = e.value
    updatedMedicalConditions[key].medical_condition_name = e.label
    formik.setFieldValue('medicalConditions', updatedMedicalConditions)
  }

  const deleteMedicalCondition = (index: number) => {
    const temp = formik.values.medicalConditions
    temp.splice(index, 1)
    formik.setFieldValue('medicalConditions', temp)
  }
  const onIsReportableMedicalConditionChanged = (e: any, key: number) => {
    if (formik.isSubmitting) {
      return
    }
    const updatedMedicalConditions = formik.values.medicalConditions
    updatedMedicalConditions[key].is_reportable = !updatedMedicalConditions[key].is_reportable
    if (updatedMedicalConditions[key].is_reportable) {
      updatedMedicalConditions[key].is_adi = true
    }

    formik.setFieldValue('medicalConditions', updatedMedicalConditions)
  }
  const onIsAdieMedicalConditionChanged = (e: any, key: number) => {
    if (formik.isSubmitting) {
      return
    }
    const updatedMedicalConditions = formik.values.medicalConditions
    updatedMedicalConditions[key].is_adi = !updatedMedicalConditions[key].is_adi
    formik.setFieldValue('medicalConditions', updatedMedicalConditions)
  }

  var medicalConditionRows = formik.values.medicalConditions?.map(function (
    medicalCondition: MedicalConditionItem,
    key: number
  ) {
    return (
      <div key={key} className='mx-0 my-2'>
        <div className='flex justify-between'>
          <CreatableDropdown
            name={'dropdown-' + key}
            placeholder='Select a Medical Condition'
            required={true}
            value={{
              value: medicalCondition.medical_condition_id ?? undefined,
              label: medicalCondition.medical_condition_name,
            }}
            isSearchable={true}
            onChange={(e) => onMedicalConditionChanged(e, key)}
            options={medicalConditionsBinded}
            disabled={formik.isSubmitting}
            error={
              formik.errors.medicalConditions &&
              formik.errors.medicalConditions[key] &&
              formik.touched.medicalConditions?.find((a) => a.medical_condition_name === true)
                ? true
                : false
            }
            errorMsg={
              formik?.errors?.medicalConditions
                ? (formik.errors?.medicalConditions[key] as any)?.medical_condition_name
                : undefined
            }
          />

          {(key !== 0 || formik.values.medicalConditions.length > 1) && (
            <div className='ms-3'>
              <Button
                className='my-3'
                colour='transparent'
                Icon={TrashIcon}
                onClick={() => deleteMedicalCondition(key)}
                key={'BTN-delete' + key}
                disabled={formik.isSubmitting}
                fitWidth={true}
                size='fit'
              ></Button>
            </div>
          )}
        </div>

        <CheckboxInput
          onChange={(e) => onIsReportableMedicalConditionChanged(e, key)}
          name={'isReportable' + key}
          required={false}
          label={
            <>
              {intl.formatMessage({
                id: 'QUESTIONNAIRE.MEDICALCONDITIONS.REPORT_DVSA_1',
              })}
              <br />
              {intl.formatMessage({
                id: 'QUESTIONNAIRE.MEDICALCONDITIONS.REPORT_DVSA_2',
              })}
            </>
          }
          disabled={formik.isSubmitting}
          loading={loading}
          checked={medicalCondition.is_reportable}
          isSingleCheckbox={true}
        />

        <CheckboxInput
          onChange={(e) => onIsAdieMedicalConditionChanged(e, key)}
          name={'isAdi' + key}
          required={false}
          label={intl.formatMessage({
            id: 'QUESTIONNAIRE.MEDICALCONDITIONS.REPORT_ADI',
          })}
          disabled={formik.isSubmitting || medicalCondition.is_reportable}
          loading={loading}
          checked={medicalCondition.is_adi}
          isSingleCheckbox={true}
        />
      </div>
    )
  })

  //#region api calls
  useEffect(() => {
    setLoading(true)
    const fetch = async () => {
      const res = await getMedicalConditions().catch((reason) => {
        if (!reason?.errorMessage) {
          toast.error('Failed to get medical conditions')
        }
      })
      setMedicalConditionData(
        res?.results?.map((a: any) => {
          let med = new MedicalConditionItem()
          med.medical_condition_id = a.id
          med.medical_condition_name = a.name
          return med
        })
      )
      setMedicalConditionsFormData({
        medicalConditions: [],
        hasMedicalConditions: false,
      })
      const result = await getStudentMedicalConditions().catch((error) => {
        if (!error?.errorMessage) {
          toast.error('Failed to get medical conditions')
        }
      })
      setInitialMedicalConditions({
        ...initialMedicalConditions,
        hasMedicalConditions: !!Number(result?.results.hasMedicalConditions),
        medicalConditions: result?.results.medicalConditions ?? [],
      })
      setLoading(false)
    }
    fetch()
  }, [])
  //#endregion

  useEffect(() => {
    setMedicalConditionsFormData({
      ...medicalConditionFormData,
      hasMedicalConditions: initialMedicalConditions.hasMedicalConditions,
      medicalConditions:
        initialMedicalConditions.medicalConditions.length === 0
          ? [
              {
                medical_condition_name: '',
                is_adi: false,
                is_reportable: false,
              },
            ]
          : initialMedicalConditions.medicalConditions,
    })
  }, [initialMedicalConditions])

  const SetMedicalCond = (has: boolean) => {
    formik.setFieldValue('hasMedicalCondition', has)
    formik.setFieldValue('medicalConditions', [])
    setMedicalConditionsFormData({
      hasMedicalConditions: has,
      medicalConditions: [new MedicalConditionItem()],
    })
  }

  return (
    <>
      <div
        className={`w-full m-auto ${
          props.parentWrapper === 'questionnaire' ? 'lg:w-3/4' : 'lg:w-full'
        }`}
      >
        {/* {props.parentWrapper === 'questionnaire' ? <AlertCompleteProfile /> : <></>} */}
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='bg-white relative flex flex-col min-w-0 rounded break-words border border-1 border-gray-300 card--form-container px-5 py-6'>
            <div className={props.parentWrapper === 'questionnaire' ? '' : 'hidden'}>
              <h1 className='text-darkBlue font-bold text-2xl my-3'>
                {' '}
                {loading ? <Skeleton width={150} /> : <>Medical Conditions</>}
              </h1>
            </div>
            {loading ? (
              <>
                <Skeleton width='100%' className='py-1' /> <Skeleton width={150} />
              </>
            ) : (
              <Alert colour='green'>
                <p>{intl.formatMessage({id: 'QUESTIONNAIRE.MEDICALCONDITIONS.PRIVACY_1'})}</p>
                <p>{intl.formatMessage({id: 'QUESTIONNAIRE.MEDICALCONDITIONS.PRIVACY_2'})}</p>
              </Alert>
            )}

            {/* start::First Name form group */}
            <RadioInput
              name='hasMedicalCondition'
              options={[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]}
              onClick={(e: any) => SetMedicalCond(e)}
              value={formik.values.hasMedicalCondition}
              required={true}
              disabled={formik.isSubmitting}
              label={intl.formatMessage({
                id: 'QUESTIONNAIRE.MEDICALCONDITIONS.DO_YOU_HAVE_MEDICAL',
              })}
              error={
                formik.touched.hasMedicalCondition && formik.errors.hasMedicalCondition
                  ? true
                  : false
              }
              errorMsg={formik.errors.hasMedicalCondition}
              loading={loading}
            ></RadioInput>

            {/* end::First Name form group */}

            {/* start::First Name form group */}
            {(formik.values.hasMedicalCondition || loading) && (
              <div>
                <div>
                  {loading ? (
                    <>
                      <Skeleton width={200} />
                    </>
                  ) : (
                    <div className='flex flex-wrap justify-between'>
                      <label htmlFor='first_name' className='control-label'>
                        {intl.formatMessage({
                          id: 'QUESTIONNAIRE.MEDICALCONDITIONS.SELECT_MEDICAL_CONDITION',
                        })}
                        <span className='required'></span>
                      </label>
                      {formik.touched.medicalConditions &&
                        formik.errors.medicalConditions &&
                        formik.values.medicalConditions.length === 0 && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>Select at least one</span>
                            </div>
                          </div>
                        )}
                    </div>
                  )}

                  <div className='w-full p-0 mt-2'>
                    {loading ? (
                      <>
                        <div className='row mx-0 my-2 test-center-dropdown'>
                          <div className={'form-group row my-1'}>
                            <div className='w-full px-2'>
                              <div className='clearfix row'>
                                <div className='flex flex-col md:flex-row col p-4 relative bg-light rounded'>
                                  <div className='flex flex-col grow'>
                                    <Skeleton width='70%' height={40} />

                                    <ul className='mt-4 mt-md-2 col flex flex-col order-3 order-md-2'>
                                      <li className='py-0 border-0 bg-transparent px-0'>
                                        <div className='form-check m-0 mb-4 mb-md-2'>
                                          <Skeleton width={200} />
                                        </div>
                                      </li>
                                      <li className='py-0 border-0 bg-transparent px-0'>
                                        <div className='form-check m-0'>
                                          <Skeleton height={20} width={20} />
                                          <Skeleton width={200} />
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      medicalConditionRows
                    )}
                    {loading ? (
                      <>
                        <Skeleton width={200} height={40} />
                      </>
                    ) : (
                      <Button
                        colour='link'
                        onClick={() => addMedicalCondition()}
                        loading={loading}
                        disabled={formik.isSubmitting}
                        className='mb-4'
                      >
                        <span className='text-base'>
                          +
                          {intl.formatMessage({
                            id: 'QUESTIONNAIRE.MEDICALCONDITIONS.ADD_MEDICAL_CONDITIONS',
                          })}
                        </span>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}

            <span className='text-base'>
              {loading ? (
                <>
                  <Skeleton width='100%' />
                  <Skeleton width='50%' />
                </>
              ) : (
                <>
                  <strong>
                    {intl.formatMessage({id: 'QUESTIONNAIRE.MEDICALCONDITIONS.IMPORTANT'})}
                  </strong>
                  {intl.formatMessage(
                    {id: 'QUESTIONNAIRE.MEDICALCONDITIONS.TELL_DVSA'},
                    {
                      link: (
                        <a
                          target='_blank'
                          href='https://www.driving-medical-condition.service.gov.uk/report'
                          rel='noreferrer'
                        >
                          {intl.formatMessage({
                            id: 'QUESTIONNAIRE.MEDICALCONDITIONS.DVSA_SITE',
                          })}
                        </a>
                      ),
                    }
                  )}
                </>
              )}
            </span>

            {/* end::First Name form group */}
          </div>

          {/*START:: Form Actions */}
          <div className='py-5'>
            <>
              {loading ? (
                <div className='flex items-center justify-between gap-2'>
                  <Skeleton
                    className={
                      props.parentWrapper !== 'questionnaire'
                        ? 'w-1/2 !rounded-full'
                        : 'w-full !rounded-full'
                    }
                    height={50}
                    animation='wave'
                    variant='rounded'
                  ></Skeleton>
                  {props.parentWrapper !== 'questionnaire' && (
                    <Skeleton
                      className='w-1/2 !rounded-full'
                      height={50}
                      animation='wave'
                      variant='rounded'
                    ></Skeleton>
                  )}
                </div>
              ) : (
                <div className='flex items-center justify-between gap-2'>
                  <Button
                    disabled={formik.isSubmitting}
                    onClick={() => props.goToPrevTab()}
                    colour='outline'
                    size='large'
                    halfWidth={true}
                    className={`w-1/2 px-3 ${
                      props.parentWrapper !== 'questionnaire' ? 'order-2' : ''
                    }`}
                  >
                    {props.parentWrapper === 'questionnaire'
                      ? intl.formatMessage({id: 'ACTIONS.BACK'})
                      : intl.formatMessage({id: 'ACTIONS.DISCARD'})}
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    colour='gradient'
                    size='large'
                    halfWidth={props.parentWrapper !== 'questionnaire'}
                    type='submit'
                    className={`w-1/2 px-3 ${
                      props.parentWrapper !== 'questionnaire' ? 'order-1' : ''
                    }`}
                  >
                    {!loading && (
                      <span className='indicator-label'>
                        {props.parentWrapper === 'questionnaire' ? 'Save & Continue' : 'Save'}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                </div>
              )}
            </>
          </div>
          {/*END:: Form Actions */}
        </form>
      </div>
    </>
  )
}

export {MedicalConditions}

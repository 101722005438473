import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import {DashboardWrapper} from '../content/pages/dashboard/DashboardWrapper'
import {MasterLayout} from '../content/layout/MasterLayout'
import {PaymentHistoryWrapper} from '../content/pages/payment/PaymentHistoryWrapper'
import {MoreWrapper} from '../content/pages/more/MoreWrapper'
import {TestsWrapper} from '../content/pages/tests/TestsWrapper'
import {LessonsWrapper} from '../content/pages/lessons/LessonsWrapper'
import {AccountSettingsWrapper} from '../content/pages/account-settings/AccountSettingsWrapper'
import {HelpCentreWrapper} from '../content/pages/helpCentre/HelpCentreWrapper'
import {CourseProvider} from '../content/providers/CourseProvider'
import {RootState} from '../../setup'
import {useSelector, shallowEqual} from 'react-redux'
import {SharedDataProvider} from '../content/providers/SharedDataProvider'
import {AddonsWrapper} from '../content/pages/Add-ons/AddonsWrapper'
import ReferFriendWrapper from '../content/components/more/refer-a-friend/ReferFriendWrapper'
import GoShorty from '../content/components/more/passperks/GoShorty'
import VideosPage from '../content/components/more/passperks/VideosPage'
import {CancelCourseWrapper} from '../content/pages/course/CancelCourseWrapper'
import {lazy} from 'react'
import CourseHistoryWrapper from '../content/pages/course-history/CourseHistoryWrapper'
import {Toaster} from 'react-hot-toast'
import AuthInit from '../../setup/redux/AuthInit'

export function PrivateRoutes() {
  const isStepsCompleted = useSelector<RootState>(({auth}) => auth.isStepsCompleted, shallowEqual)
  const QuestionnaireWrapper = lazy(
    () => import('../content/pages/student-questionnaire/CompleteStudentQuestionnairePage')
  )
  const location = useLocation()
  let routes = isStepsCompleted ? (
    <>
      <SharedDataProvider>
        <MasterLayoutWrapper />
      </SharedDataProvider>
    </>
  ) : (
    <>
      <Routes>
        <Route path='/questionnaire' Component={QuestionnaireWrapper} />
        <Route path='/' element={<Navigate replace to={'/questionnaire' + location.search} />} />
        <Route path='/*' element={<Navigate replace to={'/questionnaire' + location.search} />} />
      </Routes>
    </>
  )
  return (
    <AuthInit>
      <CourseProvider>
        {routes}
      </CourseProvider>
    </AuthInit>
  )
}
export function MasterLayoutWrapper() {
  return (
    <>
      <div className='h-screen  min-h-screen'>
        <Routes>
          <Route path='/' element={<MasterLayout />}>
            <Route path='dashboard' element={<DashboardWrapper />} />
            <Route path='tests' element={<TestsWrapper />} />
            <Route path='lessons' element={<LessonsWrapper />} />
            <Route path='more' element={<MoreWrapper />} />
            <Route path='help-centre' element={<HelpCentreWrapper />} />
            <Route path='addons' element={<AddonsWrapper />} />
            <Route path='payment-history' element={<PaymentHistoryWrapper />} />
            <Route path='cancel-course' element={<CancelCourseWrapper />} />
            <Route path='profile/*' element={<AccountSettingsWrapper />} />
            {/* <Route path='' element={<Navigate to='/dashboard' replace />} /> */}

            {/* missed routes */}
            {/* <Route path='/faqs' element={<HelpWrapper />} />
            <Route path='/account-info' element={<ProfileWrapper/>} />
            <Route path='/pass-perks' element={<PassperksWrapper/>} />
            <Route path='/transiant-payment' element={<PaymentdWrapper/>} />*/}
            <Route path='/course-history' element={<CourseHistoryWrapper />} />
            <Route path='/refer-a-friend' element={<ReferFriendWrapper />} />
            <Route path='/goShorty' element={<GoShorty />} />
            <Route path='/practical-driving-tips-videos' element={<VideosPage />} />
            <Route path='/' element={<Navigate replace to='/dashboard' />} />
            <Route path='/*' element={<Navigate replace to='/dashboard' />} />
          </Route>
        </Routes>
      </div>
    </>
  )
}

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import Modal from '../../shared/overlays/Modal'
import {getRebookPreferredDates} from '../../../services/test.service'
import {RootState} from '../../../../../setup'
import {addAddons, createCart, purchaseRequest} from '../../../services/addons.service'
import {Test} from '../../../models/tests/test'
import {getPaymentUrl} from '../../../helpers/PaymentHelper'
import {getAdiBasicData} from '../../../services/adi.service'
import {getProduct} from '../../../services/student.service'
import {formatPriceIntoTwoDigits} from '../../../helpers/PriceHelper'
import Alert from '../../shared/overlays/Alert'
import toast from 'react-hot-toast'

type Props = {
  test: Test | undefined
  show: boolean
  onHide: any
  isRebook: boolean
}

const RescheduleTestModal: FC<Props> = ({test, show, onHide, isRebook}) => {
  const intl = useIntl()
  const [showAdiWarning, setShowAdiWarning] = useState(false)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState<any>(null)
  const [orderId, setOrderId] = useState<number>(0)
  const [contactId, setContactId] = useState('')
  const [postcode, setPostcode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [rebookPreferredDates, setRebookPreferredDates] = useState<any>()
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true
  const hide = () => {
    onHide()
  }
  const rescheduleTest = async () => {
    setErrorMessage('')
    // 1- create cart
    setLoading(true)
    if (test) {
      let testType = test.type.charAt(0).toUpperCase() + test.type.slice(1)
      let source = isRebook ? `Rebook ${testType} Test` : `Reschedule ${testType} Test`
      const cartRes = await createCart(
        postcode,
        orderId,
        test?.deal_id.toString(),
        contactId,
        source
      ).catch((error) => {
        if(!error?.errorMessage)
        toast.error('Something went wrong while creating cart')
      })
      if (!cartRes) return

      // 2- add product to cart
      let token = cartRes.cart?.token
      const addOnRes = await addAddons(token, product?.id).catch((error) => {
        if(!error?.errorMessage)
        toast.error('Something went wrong while adding product')
      })
      if (!addOnRes) return
      // check that product added to cart
      if (addOnRes.addons.length == 1) {
        // 3- create payment request
        let successUrl =
          process.env.REACT_APP_WEBSITE_BASE_URL +
          'transiant-payment?test_id=' +
          test.id +
          '&is_rebook=' +
          (isRebook ? '1' : '0')
        const purchaseRes = await purchaseRequest(
          product?.price,
          test?.deal_id.toString(),
          token,
          successUrl ?? '',
          source
        ).catch((error) => {
          if(!error?.errorMessage)
          toast.error('Something went wrong while sending purchase request')
        })
        if (!purchaseRes) return
        // 4- go to payment app
        window.open(getPaymentUrl(purchaseRes.uuid, source), isMobile ? '_blanck' : '_self')
      } 
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!show || !test) return
      setErrorMessage('')
      setLoading(true)
      const adiRes = await getAdiBasicData(test.deal_id).catch((error) => {
        if(!error?.errorMessage)
        toast.error('Something went wrong while getting adi data')
      })
      if (adiRes) {
        if (adiRes.results && !isRebook) {
          setShowAdiWarning(true)
        }
        const productRes = await getProduct({
          test_type_id: test.type,
          is_rebook: isRebook,
          deal_id: test.deal_id,
        }).catch((error) => {
          if(!error?.errorMessage)
          toast.error('Failed to get product')
        })
        if (productRes) {
          setOrderId(productRes.results.order_id)
          setProduct(productRes.results.product)
          setContactId(productRes.results.zendesk_contact_id)
          setPostcode(productRes.results.postcode)
          setLoading(false)
        }
      }

      if (test.type === 'practical') {
        const fetchData = async () => {
          const rebookRes = await getRebookPreferredDates(test.deal_id, test.type).catch(
            (error) => {
              if(!error?.errorMessage)
              toast.error('Something went wrong while getting Preferred dates')
            }
          )
          if (rebookRes) {
            setRebookPreferredDates(rebookRes)
          }
        }
        fetchData()
      }
    })()
  }, [show, test])


  return (
    <>
      <Modal
        open={show}
        onClose={hide}
        onSubmit={() => rescheduleTest()}
        title={intl.formatMessage({id: isRebook ? 'REBOOK_TEST.TITLE' : 'RESCHEDULE_TEST.TITLE'})}
        submitText={intl.formatMessage({id: 'ACTIONS.CONTINUETXT'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        disabled={loading}
      >
        {loading ? (
          <h5>Loading...</h5>
        ) : (
          <>
            {errorMessage !== '' && <span role='alert'>{errorMessage}</span>}
            {isRebook ? (
              <Alert
                description={intl.formatMessage(
                  {id: 'REBOOK_TEST.FEE_WARNING'},
                  {amount: formatPriceIntoTwoDigits(product?.price)}
                )}
                colour='pink'
              ></Alert>
            ) : (
              <>
                <div className='font-semibold'>
                  {test?.type === 'theory' && (
                    <span>{intl.formatMessage({id: 'RESCHEDULE_TEST.DATE_WARNING'})}</span>
                  )}

                  {test?.type === 'practical' && (
                    <span>
                      {intl.formatMessage({id: 'RESCHEDULE_TEST.DATE_WARNING_PRACTICAL'})}{' '}
                      <span className='font-bold'>
                        {rebookPreferredDates?.preferred_date_from}{' '}
                        {intl.formatMessage({id: 'RESCHEDULE_TEST.DATE_WARNING_TO'})}{' '}
                        {rebookPreferredDates?.preferred_date_to}.
                      </span>
                    </span>
                  )}
                </div>
                {showAdiWarning && (
                  <div className='flex justify-start mb-4'>
                    <span className='font-semibold'>
                      {intl.formatMessage({id: 'RESCHEDULE_TEST.ADI_WARNING'})}
                    </span>
                  </div>
                )}
                <Alert
                  description={intl.formatMessage(
                    {id: 'RESCHEDULE_TEST.FEE_WARNING'},
                    {amount: formatPriceIntoTwoDigits(product?.price)}
                  )}
                  colour='pink'
                ></Alert>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  )
}

export {RescheduleTestModal}

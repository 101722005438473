import {FC} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import ListView from '../../components/tests/ListView'
const TestsWrapper: FC = () => {
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='Tests' backToURL='/profile' isCenter />
      </div>
      <ListView />
    </>
  )
}

export {TestsWrapper}

import moment from 'moment'
import {getMonths} from '../services/date.service'
export const formatDate = (year: string, month: string, day: string) => {
  if (!year || month == undefined || month == null || !day) return null
  const dateStr = moment(
    new Date(
      Number(year),
      getMonths().findIndex((a) => a.value === month),
      Number(day)
    )
  ).format('YYYY-MM-DD')
  return dateStr
}

export const formatDateTime = (
  year: number,
  month: number,
  day: number,
  hour: number = 0,
  minutes: number = 0
) => {
  if (!year || month == undefined || month == null || !day) return undefined
  const dateStr = moment(new Date(year, month, day, hour, minutes)).format('YYYY-MM-DD HH:mm:ss')
  return dateStr
}

export const getMonthLastDay = (year: string, monthStr: string) => {
  if (!year || !monthStr) return null
  var month = getMonths().findIndex((a) => a.value === monthStr) + 1
  var day = new Date(Number(year), month, 0).getDate()
  if (!day) return null
  return year + '-' + month + '-' + day
}

export const getUpcomingDateTime = (year: string, month: number, day: string, time: string) => {
  if (!year || !(month >= 0) || !day) return undefined
  const a = time.split(':') // split it at the colons
  // convert time to seconds.
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + (a[2] ? +a[2] : 0)
  let newDate = new Date(Number(year), month, Number(day))
  return moment(newDate).add(seconds, 's').format('YYYY-MM-DD HH:mm:ss')
}

export const getUpcomingYearMonths = () => {
  const months = []
  const monthNames = getMonths().map((x) => x.label)
  const now = new Date()
  const isLastDay = (dt: Date) => {
    var test = new Date(dt.getTime())
    test.setDate(test.getDate() + 1)
    return test.getDate() === 1
  }
  const lastDate = isLastDay(now)
  let i = 0
  if (lastDate) {
    i = 1
  }
  for (i; i < 12; i++) {
    const future = new Date(now.getFullYear(), now.getMonth() + i, 1)
    const month = monthNames[future.getMonth()]
    const year = future.getFullYear()
    months.push({
      label: `${month} ${year}`,
      value: JSON.stringify({month: future.getMonth(), year}),
    })
  }

  return months
}

export const getSelectedUpcomingYearMonths = (date: Date | null, list: Array<any>) => {
  let selected = {label: '', value: ''}
  if (date) {
    const monthNames = getMonths().map((x) => x.label)
    const year = date.getFullYear()
    const month = monthNames[date.getMonth()]
    const label = `${month} ${year}`
    selected = list.find((a) => a.label === label)
  }
  return selected
}

export const getPreviousYearMonths = () => {
  const months = []
  const monthNames = getMonths().map((x) => x.label)
  const now = new Date()
  let i = 0
  for (i; i < 12; i++) {
    const future = new Date(now.getFullYear(), now.getMonth() - i, 1)
    const month = monthNames[future.getMonth()]
    const year = future.getFullYear()
    months.push({
      label: `${month} ${year}`,
      value: JSON.stringify({month: future.getMonth(), year}),
    })
  }

  return months
}

export const convertToTimeFormat = (totalMinutes: any) => {
  var hours = Math.floor(totalMinutes / 60)
  var minutes = totalMinutes % 60
  return (
    (Number(hours) == 0
      ? ''
      : hours +
        (Number(hours) == 1
          ? Number(minutes) == 0
            ? ' Hour '
            : ' hr '
          : Number(minutes) == 0
            ? ' Hours '
            : ' hrs ')) + (Number(minutes) == 0 ? '' : String(minutes).padStart(2, '0') + ' mins')
  )
}

export const formatTestDateTime = (date: any) => {
  return moment.utc(date).format('DD/MM/YYYY HH:MM')
}

import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import jsPDF from 'jspdf'
import {getStudentReceipt} from '../../../services/booking.service'
import {StudentReceipt} from '../../../models/payments/student-receipt'
import {StudentAddress} from '../../../models/questionnaire/student-address'
import Modal from '../../shared/overlays/Modal'
import {toAbsoluteUrl} from '../../../helpers'
import {formatPriceIntoTwoDigits} from '../../../helpers/PriceHelper'
import Skeleton from '@mui/material/Skeleton'
import toast from 'react-hot-toast'
type Props = {
  deal_id: any
  show: any
  handleClose: any
  billingAddress: StudentAddress
}

const BookingReceiptTemplate: FC<Props> = ({deal_id, show, handleClose, billingAddress}) => {
  const intl = useIntl()
  const reportTemplateRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [studentReceipt, setStudentReceipt] = useState<StudentReceipt>()
  useEffect(() => {
    if (deal_id) {
      setLoading(true)
      const studentReceipt = async () => {
        const result = await getStudentReceipt(deal_id).catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get order')
          setLoading(false)
        })
        if (result) {
          setLoading(false)
          setStudentReceipt(result)
        }
      }
      studentReceipt()
    }
  }, [deal_id])

  const downloadPdf = () => {
    setSubmitting(true)
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    })
    doc.html(reportTemplateRef.current ?? '', {
      async callback(doc) {
        await doc.save(`${deal_id}-booking-receipt`)
        setSubmitting(false)
      },
      x: 32.5,
      y: 10,
      width: 340, //target width in the PDF document
      windowWidth: 550, //window width in CSS pixels,
    })
  }

  return (
    <>
    <Modal
      open={show}
      onClose={handleClose}
      title='Booking Receipts'
      hideSubmit={true}
      icon='receiptPercent'
    >
      <div className='p-4'>
        {loading ? (
          <div>
            <Skeleton width='100%' className='my-2' />
            <Skeleton width='100%' className='my-2' />
            <Skeleton width='100%' className='my-2' />
          </div>
        ) : (
          <>
            {' '}
            <div className='md:h-[40vh] overflow-y-auto'>
              <table className='table-auto table-list  text-gray-900 w-full'>
                <tbody>
                  {/* <!--Practical_Test_Fee--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Practical_Test_Fee'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.practicalTestFees) ?? '-'}
                    </td>
                  </tr>
                  {/* <!--Theory_Test_Fee--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Theory_Test_Fee'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.theoryTestFees) ?? '-'}
                    </td>
                  </tr>
                  {/* <!--PassProtect_Fee--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.PassProtect_Fee'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.passProtectTestFees) ?? '-'}
                    </td>
                  </tr>
                  {/* <!--Driving_Instructor_Fee--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Driving_Instructor_Fee'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.drivingInstructorFees) ?? '-'}
                    </td>
                  </tr>

                  {/* <!--Total_Non_Vatable--> */}
                  <tr className='border-b border-gray-400 h-10 fw-bolder'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Total_Non_Vatable'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.totalNonVatableChargesIncome) ??
                        '-'}
                    </td>
                  </tr>
                  {/* <!--Processing_Fee--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Processing_Fee'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.processingFee) ?? '-'}
                    </td>
                  </tr>
                  {/* <!--Fast-Tracking&Service_Costs--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Fast-Tracking&Service_Costs'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.fastTrackingAndServiceCosts) ??
                        '-'}
                    </td>
                  </tr>
                  {/* <!--Processing_Fee_Discount--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Processing_Fee_Discount'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.processingFeeDiscount) ?? '-'}
                    </td>
                  </tr>
                  {/* <!--PassProtect--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.PassProtect'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.passProtectServiceCosts) ?? '-'}
                    </td>
                  </tr>

                  {/* <!--Driving_Instructor_Fee--> */}
                  <tr className='border-b border-gray-400 h-10'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Driving_Instructor_Fee'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.drivingInstructorFeesVatable) ??
                        '-'}
                    </td>
                  </tr>

                  {/* <!--Total_Vatable--> */}
                  <tr className='border-b border-gray-400 h-10 fw-bolder'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Total_Vatable'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.totalVatableChargesIncome) ?? '-'}
                    </td>
                  </tr>
                  {/* <!--VAT--> */}
                  <tr className='border-b border-gray-400 h-10 fw-bolder'>
                    <td className='text-left'>{intl.formatMessage({id: 'RECEIPT.VAT'})}</td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.vATCharge) ?? '-'}
                    </td>
                  </tr>
                  {/* <!--Total_Vatable_Charges_inc_VAT--> */}
                  <tr className='border-b border-gray-400 h-10 fw-bolder'>
                    <td className='text-left'>
                      {intl.formatMessage({id: 'RECEIPT.Total_Vatable_Charges_inc_VAT'})}
                    </td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(
                        studentReceipt?.totalVatableChargesIncome + studentReceipt?.vATCharge
                      ) ?? '-'}
                    </td>
                  </tr>
                  {/* <!--Total--> */}
                  <tr className='border-b border-gray-400 h-10 fw-bolder'>
                    <td className='text-left'>{intl.formatMessage({id: 'RECEIPT.Total'})}</td>
                    <td className='text-right'>
                      {formatPriceIntoTwoDigits(studentReceipt?.totalPayment) ?? '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table className='w-100 mt-6'>
              <tbody>
                <tr>
                  <td className='text-sm text-gray-700 italic'>
                    {intl.formatMessage({id: 'RECEIPT.NOTE'})}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </Modal>
  </>
  )
}

export {BookingReceiptTemplate}

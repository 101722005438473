import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import {CancelJob} from '../models/jobs/cancel-job'
import {CancellationReason} from '../models/jobs/cancellation-reason'
import {JobStage} from '../models/jobs/job-stage'

const JOB_URL = process.env.REACT_APP_JOB_BASE_URL

const GET_All_CANCELLATION_REASON = `${JOB_URL}/get-all-cancellation-reasons`
const CANCEL_JOB = `${JOB_URL}/jobs/student-cancel-job`

export async function getAllCancellationReason(): Promise<BaseResponse<Array<CancellationReason>>> {
  return await modifiedFetch({
    url: `${GET_All_CANCELLATION_REASON}`,
    method: 'GET',
  })
}
export async function CancelStudentJob(data: CancelJob): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${CANCEL_JOB}`,
    method: 'POST',
    data: data,
  })
}
export async function checkDisAbleAction(deal_id: any): Promise<any> {
  return await modifiedFetch({
    url: `${JOB_URL}/check-disable-action/${deal_id}`,
    method: 'GET',
  })
}
export async function getJobstage(deal_id: any): Promise<BaseResponse<JobStage>> {
  return await modifiedFetch({
    url: `${JOB_URL}/jobs/deal-Stage/${deal_id}`,
    method: 'GET',
  })
}
export async function getHasAssignedADI(deal_id: any): Promise<any> {
  return await modifiedFetch({
    url: `${JOB_URL}/jobs/has-assigned-adi/${deal_id}`,
    method: 'GET',
  })
}

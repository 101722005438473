import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {postalCodeExpression} from '../../../models/enums/patterns-enum'
import {CreatableOption} from '../../../models/creatable-option'
import {StudentAddress} from '../../../models/questionnaire/student-address'
import {AddressType} from '../../../models/enums/address-type-enum'
import {Hint} from 'react-autocomplete-hint'
import {StudentPersonalData} from '../../../models/questionnaire/student-personal-data'
import Modal from '../../shared/overlays/Modal'
import Input from '../../shared/forms/Input'
import CheckboxInput from '../../shared/forms/CheckboxInput'
import InputHint from '../../shared/forms/InputHint'

const validPostalCode = new RegExp(postalCodeExpression + '$')
const pickupPostcodeRegex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/i
const validPickupPostcode = new RegExp(pickupPostcodeRegex)

type Props = {
  addressType: any
  show: any
  onClose: any
  address: StudentAddress
  licence_address: StudentAddress
  billing_address: StudentAddress
  pickup_address: StudentAddress
  citiesBinded: any[]
  parentWrapper?: string
  licenceAddressDisabled: boolean
  setStudentData?: any
  studentData?: StudentPersonalData
  checkCourseUpgradeWhenPickupAddressChange?: any
}

const AddAddress: FC<Props> = ({
  addressType,
  show,
  onClose,
  address,
  citiesBinded,
  licence_address,
  billing_address,
  parentWrapper,
  licenceAddressDisabled,
  studentData,
  setStudentData,
  checkCourseUpgradeWhenPickupAddressChange,
}) => {
  const intl = useIntl()
  const [disabled, setDisabled] = useState(false)
  const [copyFromLicence, setCopyFromLicence] = useState(false)
  const [selectedCity, setSelectedCity] = useState<CreatableOption>({value: '', label: ''})

  const addressHasData = (address: StudentAddress) => {
    if (address.street_address && address.postal_code && address.city_name) return true
    return false
  }
  const enableCopy = (addressType: AddressType): boolean => {
    if (addressType === AddressType.Licence) {
      if (
        formik.values.address_type_id !== AddressType.Licence &&
        addressHasData(licence_address)
      ) {
        return true
      }
    }
    if (addressType === AddressType.Billing) {
      if (
        formik.values.address_type_id !== AddressType.Billing &&
        addressHasData(billing_address)
      ) {
        return true
      }
    }
    return false
  }

  const copyAddress = (addressType: AddressType) => {
    if (addressType === AddressType.Licence) {
      formik.setFieldValue('street_address', licence_address.street_address)
      formik.setFieldValue('postal_code', licence_address.postal_code)
      formik.setFieldValue('city_name', licence_address.city_name)
    }
  }

  const copyAddressChanged = (addressType: AddressType) => {
    if (addressType === AddressType.Licence) {
      if (!copyFromLicence) {
        formik.resetForm()
        formik.setFieldValue('is_copied_from_licence', true)
        setCopyFromLicence(true)
        setDisabled(true)
        copyAddress(AddressType.Licence)
      } else {
        formik.setFieldValue('is_copied_from_licence', false)
        setCopyFromLicence(false)
        setDisabled(false)
      }
    }
  }

  const validationSchema = Yup.object().shape({
    street_address: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.STREETADDRESS'})}
        )
      ),
    postal_code: Yup.string()
      .matches(
        address.address_type_id === AddressType.Pickup ? validPickupPostcode : validPostalCode,
        'Must be a valid postal code'
      )
      .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.POSTCODE'})}
        )
      ),
    city_name: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.CITY'})}
        )
      ),
    address_type_id: Yup.number().nullable(),
    is_copied_from_licence: Yup.boolean().default(false),
    city_id: Yup.string().nullable(),
  })

  const formik = useFormik({
    initialValues: {
      street_address: '',
      postal_code: '',
      city_name: '',
      address_type_id: 0,
      is_copied_from_licence: false,
      city_id: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting}) => {
      setSubmitting(true)

      let selectedAddress: StudentAddress = {
        address_type_id: address.address_type_id,
        street_address: values.street_address,
        postal_code: values.postal_code,
        city_name: values.city_name,
        city_id: selectedCity.value,
        id: address.id,
        is_copied_from_licence: values.is_copied_from_licence,
      }
      if (parentWrapper === 'accountsettings' && studentData?.addresses) {
        studentData.addresses.forEach((address: any, index: any) => {
          if (address.address_type_id === selectedAddress.address_type_id) {
            studentData.addresses![index] = selectedAddress
          }
        })
        const res = await checkCourseUpgradeWhenPickupAddressChange(
          setSubmitting,
          selectedAddress
        )
        if (res) {
          setStudentData(studentData)
          resetComponent()
          onClose(selectedAddress)
          setSubmitting(false)
          return
        }
      } else {
        resetComponent()
        onClose(selectedAddress)
        setSubmitting(false)
      }
    },
  })

  const resetComponent = () => {
    formik.setFieldTouched('street_address', false)
    formik.setFieldTouched('postal_code', false)
    formik.setFieldTouched('city_name', false)
    address = new StudentAddress(0)
    setDisabled(false)
    setCopyFromLicence(false)
    setSelectedCity({value: '', label: ''})
  }

  const handleClose = () => {
    resetComponent()
    onClose(null)
  }

  const toInputUppercase = (e: any) => {
    e.target.value = ('' + e.target.value).toUpperCase()
  }

  const handlePostcodeInput = (value: any) => {
    if (value.length > 7) {
      return
    }
    var parts = value.match(pickupPostcodeRegex)
    formik.setFieldValue('postal_code', value.replace(/\W*/gi, '').toUpperCase())
    if (parts) {
      parts.shift()
      formik.setFieldValue('postal_code', parts.join(' ').toUpperCase())
    }
  }

  useEffect(() => {
    if (address && show) {
      formik.setValues({
        street_address: address.street_address,
        postal_code: address.postal_code,
        city_name: address.city_name,
        address_type_id: address.address_type_id ?? 0,
        is_copied_from_licence: address.is_copied_from_licence,
        city_id: address.city_id ?? '',
      })
      if (licenceAddressDisabled) {
        setDisabled(false)
        setCopyFromLicence(false)
        formik.setFieldValue('is_copied_from_licence', false)
      } else if (address.is_copied_from_licence) {
        setDisabled(true)
        setCopyFromLicence(true)
      }
    }
  }, [address, show])

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        submitText={intl.formatMessage({id: 'ACTIONS.SAVE'})}
        title={`${addressType} address`}
        onSubmit={() => formik.handleSubmit()}
        disabled={formik.isSubmitting}
      >
        <form onSubmit={formik.handleSubmit} className='flex flex-col justify-between h-full'>
          {address.address_type_id !== AddressType.Licence && (
            <div className='relative block mb-2'>
              <CheckboxInput
                label='Copy from licence address'
                {...formik.getFieldProps('is_copied_from_licence')}
                checked={formik.values.is_copied_from_licence}
                onChange={copyAddressChanged.bind(this, AddressType.Licence)}
                name='email_check'
                disabled={!enableCopy(AddressType.Licence)}
                required={false}
                isSingleCheckbox={true}
              />
            </div>
          )}
          <div className='w-full'>
            <Input
              label={intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.STREETADDRESS'})}
              required={true}
              {...formik.getFieldProps('street_address')}
              type='text'
              name='address.street_address'
              id='address.street_address'
              readonly={formik.isSubmitting}
              disabled={disabled}
              onBlur={() => formik.setFieldTouched('street_address', true)}
              onChange={(e) => formik.setFieldValue('street_address', e?.target?.value)}
              error={formik.touched.street_address && formik.errors.street_address ? true : false}
              errorMsg={formik.errors.street_address}
              hasNote={address.address_type_id === AddressType.Licence ? true : false}
              note={
                address.address_type_id === AddressType.Licence
                  ? intl.formatMessage({
                      id: 'QUESTIONNAIRE.STUDENTDETAILS.STREET_ADDRESS_NOTE',
                    })
                  : ''
              }
            />
          </div>
          <div className='w-full'>
              <Input
                label={intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.POSTCODE'})}
                {...formik.getFieldProps('postal_code')}
                type='text'
                required={true}
                name='provisional_license_address.postal_code'
                className='form-control col-md-12'
                id='provisional_license_address.postal_code'
                readonly={formik.isSubmitting}
                disabled={disabled}
                onBlur={() => formik.setFieldTouched('postal_code', true)}
                onChange={(e) => handlePostcodeInput(e?.target?.value)}
                onInput={toInputUppercase}
                error={formik.touched.postal_code && formik.errors.postal_code ? true : false}
                errorMsg={formik.errors.postal_code}
                hasNote={address.address_type_id === AddressType.Licence ? true : false}
                note={intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.CITY_NOTE'})}
              />
          </div>

          <div className='w-full'>
            <div className='clearfix text-field form-control-autocomplete'>
              <InputHint
              required={true}
              options={citiesBinded}
              type='text'
              hasNote={true}
              note={address.address_type_id === AddressType.Licence?intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.CITY_NOTE'}):''}
              label={intl.formatMessage({id: 'QUESTIONNAIRE.STUDENTDETAILS.CITY'})}
              {...formik.getFieldProps('city_name')}
              error={formik.touched.city_name && formik.errors.city_name?true:false}
              errorMsg={formik.errors.city_name}
              name='city_name'
              disabled={disabled}
                  id="city_name"
                  onChange={(e) => formik.setFieldValue('city_name', e?.target?.value)}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export {AddAddress}

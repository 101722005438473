/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import moment from 'moment'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {JourneyStepsEnum} from '../../../models/enums/journey-steps-enum'
import {TestCentreTypeEnum} from '../../../models/enums/test-centre-type'
import {TestStatusTypeEnum} from '../../../models/enums/test-status-type'
import {TestTypeEnum} from '../../../models/enums/test-type'
import {ZendeskStages} from '../../../models/enums/zendesk-stages-enum'
import {Lesson} from '../../../models/lessons/lesson'
import {Job} from '../../../models/payments/job'
import {PassprotectWithRedemptionRemaining} from '../../../models/student/pass-protect-with-redemption-remaining'
import {StudentBooking} from '../../../models/student/student-booking'
import {RejectionReason} from '../../../models/tests/rejection-reason'
import {Test} from '../../../models/tests/test'
import {useCourse} from '../../../providers/CourseProvider'
import {canAddFeedback} from '../../../services/feedback.service'
import {getTestCentresByTypeID} from '../../../services/lookup.service'
import {validatePayment} from '../../../services/payment.service'
import {getLeadTime} from '../../../services/product.service'
import {
  getStudentsQuestionnaireFlags,
  updateReferFriend,
  getDashboardData,
  syncJobs,
} from '../../../services/student.service'
import {getLatestTest, getTestRejectionReasons} from '../../../services/test.service'
import {Link, useNavigate} from 'react-router-dom'
import {PersonalDataModel} from '../../../models/personal-data-model'
import {formatPriceIntoTwoDigits} from '../../../helpers/PriceHelper'
import {AllocatingInstructor} from './journey/AllocatingInstructor'
import {ArrangePracticalTest} from './journey/ArrangePracticalTest'
import {ArrangeTheoryTest} from './journey/ArrangeTheoryTest'
import {Balance} from './journey/Balance'
import {Instructor} from './journey/Instructor'
import {JourneyItem} from './journey/JourneyItem'
import {Lessons} from './journey/Lessons'
import {PaymentAmount} from '../../add-payment/PaymentAmount'
import {PracticalTest} from './journey/PracticalTest'
import {TheoryTest} from './journey/TheoryTest'
import EmptyState from '../../shared/elements/EmptyState'
import {ReferFriend} from '../../shared/modals/ReferFriend'
import toast from 'react-hot-toast'

interface IProps {
  isPto: boolean
  isShowShimmer?: boolean
}

const JourneyTab: FC<IProps> = ({isPto, isShowShimmer = false}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [steps, setSteps] = useState([
    {
      id: JourneyStepsEnum.ArrangingTheory,
      label: 'arranging',
      name: 'Arranging your theory test',
      status: 'upcoming',
      hasContent: true,
      isOpen: false,
      hide: false,
    },
    {
      id: JourneyStepsEnum.TheoryTest,
      label: 'theory',
      name: 'Your theory test',
      status: 'upcoming',
      hasContent: false,
      isOpen: false,
      hide: false,
    },
    {
      id: JourneyStepsEnum.ArrangingPractical,
      label: 'arranging',
      name: 'Arranging your practical test',
      status: 'upcoming',
      hasContent: true,
      isOpen: false,
      hide: false,
    },
    {
      id: JourneyStepsEnum.AllocatingInstructor,
      label: 'allocating',
      name: 'Allocating your instructor',
      status: 'upcoming',
      hasContent: true,
      isOpen: false,
      hide: false,
    },
    {
      id: JourneyStepsEnum.Balance,
      label: 'balance',
      name: 'Your balance',
      status: 'pending',
      hasContent: true,
      isOpen: false,
      hide: false,
    },
    {
      id: JourneyStepsEnum.Instructor,
      label: 'instructor',
      name: 'Your instructor',
      status: 'upcoming',
      hasContent: false,
      isOpen: false,
      hide: false,
    },
    {
      id: JourneyStepsEnum.Lessons,
      label: 'lessons',
      name: 'Your lessons',
      status: 'upcoming',
      hasContent: true,
      isOpen: false,
      hide: false,
    },
    {
      id: JourneyStepsEnum.PracticalTest,
      label: 'practical',
      name: 'Your practical test',
      status: 'upcoming',
      hasContent: false,
      isOpen: false,
      hide: false,
    },
  ])

  let stepObj = steps.map((step: any) => {
    return {
      label: step.name,
      id: step.id,
      status: step.status,
      img: step.label + '-' + step.status + '.svg',
      hasContent: step.hasContent,
      isOpen: step.isOpen,
      hide: step.hide,
    }
  })

  const [loading, setLoading] = useState<boolean>(false)
  const [adiData, setAdiData] = useState<any>(null)
  const [lessonData, setLessonData] = useState<any>(null)
  const [theoryTest, setTheoryTest] = useState<Test | undefined>(undefined)
  const [practicalTest, setPracticalTest] = useState<Test | undefined>(undefined)
  const [testRejectionReason, setRejectionReason] = useState<RejectionReason[]>([])
  const [testFlags, setTestFlags] = useState<any>({})
  const [dealId, setDealId] = useState<any>()
  const [paymentInfo, setPaymentInfo] = useState<Job>(new Job())
  const [showPaymentAmountModal, setShowPaymentAmountModal] = useState(false)
  const [theoryTestCenterList, setTheoryTestCenterList] = useState<Array<any>>([])
  const [practicalTestCenterList, setPracticalTestCenterList] = useState<Array<any>>([])
  //LESSONS
  const [hasPrevLessons, setHasPrevLessons] = useState<boolean>(false)
  const [hasUpcommingLessons, setHasUpcommingLessons] = useState<boolean>(false)
  const [hasPendingLessons, setHasPendingLessons] = useState<boolean>(false)
  const [lessons, setLessons] = useState<Lesson>()

  const [studentBooking, setStudentBooking] = useState<StudentBooking>()
  const [disableAction, setDisableAction] = useState<boolean>(false)
  const [canProvideFeedbackLessonCondition, setCanProvideFeedbackLessonCondition] =
    useState<boolean>(false)
  const [canProvideFeedback, setCanProvideFeedback] = useState<boolean>(false)
  const [readyToPassPracticalTest, setReadyToPassPracticalTest] = useState<boolean>(false)
  const [studentCanAddFeedback, setStudentCanAddFeedback] = useState<boolean | null>(null)
  const [canAddLessonFeedback, setCanAddLessonFeedback] = useState<boolean>(false)
  const [dealExpired, setDealExpired] = useState<boolean>(false)
  const [cancelledDeal, setCancelledDeal] = useState<boolean>(false)
  const [dealOnHold, setDealOnHold] = useState<boolean>(false)
  const [hasPendingAdiTest, setHasPendingAdiTest] = useState<boolean>(false)
  const [passprotectWithRedemptionRemaining, setPassprotectWithRedemptionRemaining] =
    useState<PassprotectWithRedemptionRemaining>()
  const [preferredPracticalTestCenters, setPreferredPracticalTestCenters] = useState<Array<any>>([])
  const [preferredTheoryTestCenters, setPreferredTheoryTestCenters] = useState<Array<any>>([])
  const {Course} = useCourse()
  const [leadTime, setLeadTime] = useState<number | undefined>()
  const [showReferFriendModal, setShowReferFriendModal] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      if (Course.pickupAddress?.postal_code && Course.order?.transmission && !isShowShimmer) {
        const response = await getLeadTime(
          Course.pickupAddress.postal_code,
          Course.order.transmission
        ).catch((error) => {
          if (!error?.errorMessage) {
            toast.error('Failed to get lead time')
          }
        })
        setLeadTime(response.lead_time)
      }
    }
  }, [Course.pickupAddress?.postal_code, Course.order?.transmission])

  useEffect(() => {
    setDealId(Course.deal_id)
  }, [Course.deal_id])

  useEffect(() => {
    setStudentBooking(Course.currentCourse)
  }, [Course.currentCourse])

  const checkCanAddFeedback = async (adi_id: string, deal_id: any) => {
    if (adi_id && deal_id && !isShowShimmer) {
      const res = await canAddFeedback({adi_id: adi_id, zendesk_deal_id: deal_id}).catch(
        (error) => {
          if (!error?.errorMessage) {
            toast.error('Failed to check ability to add feedback')
          }
        }
      )
      setStudentCanAddFeedback(res?.results ?? false)
    }
  }

  useEffect(() => {
    if (studentCanAddFeedback != null && !isShowShimmer) {
      setCanProvideFeedback(canProvideFeedbackLessonCondition && studentCanAddFeedback)
    }
  }, [canProvideFeedbackLessonCondition, studentCanAddFeedback])

  const personalData = useSelector<RootState>(
    ({auth}) => auth.personalData,
    shallowEqual
  ) as PersonalDataModel

  async function getStudentTests() {
    const theoryTestRes = await getLatestTest(dealId, TestTypeEnum.Theory).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get latest theory test')
    })
    const theoryTest = theoryTestRes ?? undefined
    setTheoryTest(theoryTest)
    const practicalTestRes = await getLatestTest(dealId, TestTypeEnum.Practical).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get latest practical test')
    })
    const practicalTest = practicalTestRes ?? undefined
    setPracticalTest(practicalTest)
    await getFlags()
    buildSteps(paymentInfo, adiData, theoryTest, practicalTest, testFlags)
  }
  async function getFlags() {
    const flags = await getStudentsQuestionnaireFlags().catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get student questionnaire flags')
    })
    setTestFlags(flags?.results)
  }
  async function updateReferFriendDate() {
    await updateReferFriend().catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to update refer friend')
    })
  }

  async function getTestCenterByType(typeId: any) {
    const testCentresRes = await getTestCentresByTypeID(typeId).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get test centers')
    })
    if (!testCentresRes) return

    if (typeId === TestCentreTypeEnum.Practical) {
      setPracticalTestCenterList(
        testCentresRes.results.map((item) => ({
          label: item.test_center_name,
          value: item.id,
        }))
      )
    } else {
      setTheoryTestCenterList(
        testCentresRes.results.map((item) => ({
          label: item.test_center_name,
          value: item.id,
        }))
      )
    }
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const rejectionReason = await getTestRejectionReasons().catch((error) => {
        if (!error?.errorMessage) {
          toast.error('Failed to get rejection reason')
        }
      })
      setRejectionReason(rejectionReason ?? [])
      await getTestCenterByType(TestCentreTypeEnum.Practical).catch((error) => {
        if (!error?.errorMessage) {
          toast.error('Failed to get practical test centers')
        }
      })
      await getTestCenterByType(TestCentreTypeEnum.Theory).catch((error) => {
        if (!error?.errorMessage) {
          toast.error('Failed to get theory test centers')
        }
      })
    }
    if (!isShowShimmer) fetchData()
  }, [])
  const fetchDashboardData = async (dealId: string, courseId: string) => {
    try {
      const response = await getDashboardData(dealId, courseId)
      const questionnaireFlagData = response.results.abilities
      setTestFlags(questionnaireFlagData)

      const paymentInfoData = response.results.job
      setPaymentInfo(paymentInfoData ?? new Job())
      if (paymentInfoData === null) {
        syncJobs().catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get sync job data')
        })
      }
      const theoryTest = response.results.theory_test
      setTheoryTest(theoryTest)
      const practicalTest = response.results.practical_test
      setPracticalTest(practicalTest)
      const adiInfoData = response.results.adi
      setAdiData(adiInfoData)
      setPassprotectWithRedemptionRemaining(response?.results?.pass_protect_options)
      setPreferredPracticalTestCenters(response.results.pt_preferred_test_centres)
      setPreferredTheoryTestCenters(response.results.tt_preferred_test_centres)
      checkCanAddFeedback(adiInfoData?.id, dealId)
      setHasPrevLessons(response.results.lessons.has_prev)
      setHasUpcommingLessons(response.results.lessons.has_upcoming)
      setHasPendingLessons(response.results.lessons.pending_action)
      setLessons(response.results.lessons.next_upcomming)
      setCancelledDeal(response.results.deal.stage_id == ZendeskStages.Cancelled)
      setDealExpired(response.results.deal.stage_id == ZendeskStages.Expired)
      setDealOnHold(
        response.results.deal.stage_id == ZendeskStages.OnHold &&
          moment().diff(moment(response.results.deal.last_stage_change_at), 'days') >= 7
      )
      setDisableAction(response.results.deal.is_action_disabled)
      setCanProvideFeedbackLessonCondition(response?.results?.abilities.canAddFeedback ?? false)
      setHasPendingAdiTest(response?.results?.abilities.has_test_pending_adi_approval ?? false)
      setReadyToPassPracticalTest(response.results.abilities.ready_to_pass_practical_test ?? false)
      buildSteps(paymentInfoData, adiInfoData, theoryTest, practicalTest, questionnaireFlagData)
      setCanAddLessonFeedback(
        response?.results?.abilities.canAddLessonFeedback.canAddLessonFeedback
      )
      setLessonData(response?.results?.abilities.canAddLessonFeedback.lessonData ?? null)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }
  const refreshDashboardData = () => {
    fetchDashboardData(dealId, Course.order?.id)
  }
  useEffect(() => {
    if (dealId && Course.order?.id && !isShowShimmer) {
      setLoading(true)
      fetchDashboardData(dealId, Course.order?.id)
    }
  }, [dealId, Course.order?.id])

  const pay = async () => {
    let amount =
      paymentInfo?.grp_next_amount && paymentInfo.grp_next_amount > 0
        ? paymentInfo.grp_next_amount
        : paymentInfo?.remaining_amount && paymentInfo?.remaining_amount > 0
          ? paymentInfo.remaining_amount
          : 0
    const res = await validatePayment(amount).catch((error) => {
      if (!error?.errorMessage) {
        toast.error('Failed to get validate payment')
      }
    })
    if (res?.results) {
      setShowPaymentAmountModal(true)
    } else {
      window.location.reload()
    }
  }

  async function updateSteps(steps: Array<any>) {
    let x = 0
    for (let i = 0; i < steps.length - 1; i++) {
      if (steps[i]?.status !== 'complete') {
        x = i
        break
      }
    }
    steps[x].status = 'current'
    // if (steps.length > x) steps[x + 1].status = 'pending'
    setSteps(steps)
    return await [...steps]
  }

  const redirectToLicence = () => {
    navigate('/profile/licence-details')
  }

  const buildSteps = (
    paymentInfo: any,
    adiData: any,
    theoryTest: Test | undefined,
    practicalTest: Test | undefined,
    questionnaireflags: any
  ) => {
    if (isPto) {
      steps
        .filter(
          (x) =>
            x.id === JourneyStepsEnum.Balance ||
            x.id === JourneyStepsEnum.Instructor ||
            x.id === JourneyStepsEnum.AllocatingInstructor ||
            x.id === JourneyStepsEnum.Lessons
        )
        .map((x: any) => (x.hide = true))
    }
    if (paymentInfo?.paid_in_full) {
      steps[4].status = 'complete'
      steps[4].hasContent = true
      steps[4].isOpen = false
    }

    if (paymentInfo?.paid_in_full && adiData?.id) {
      steps[6].status = 'pending'
      steps[6].hasContent = true
      steps[6].isOpen = false
    } else {
      steps[6].hasContent = false
    }

    if (adiData?.id) {
      steps[3].status = 'complete'
      steps[3].hasContent = false
      if (practicalTest?.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking) {
        //Update instructor step
        steps[5].status = 'upcoming'
        steps[5].hasContent = false
        steps[5].isOpen = false
      } else {
        steps[5].status = 'complete'
        steps[5].hasContent = true
        steps[5].isOpen = true
      }
    } else {
      if (
        theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.PendingResult ||
        theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
        practicalTest?.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking
      ) {
        //Update allocating step
        steps[3].status = 'pending'
        steps[3].hasContent = true
        steps[3].isOpen = false
      }
      if (
        !questionnaireflags.has_practical_test ||
        practicalTest?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed
      ) {
        steps[3].isOpen = true
        steps[3].hasContent = true
        steps[3].status = 'pending'
      }
    }

    //#region "Update steps with test"
    if (theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking) {
      steps[0].status = 'complete'
      steps[1].status = 'current'
      steps[0].hasContent = false
      steps[1].hasContent = true
      steps[1].isOpen = true
    }
    if (theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.Passed) {
      steps[0].status = 'complete'
      steps[1].status = 'complete'
      steps[0].hasContent = false
      steps[1].hasContent = false
    }
    if (theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.Failed) {
      steps[0].status = 'complete'
      steps[1].status = 'pending'
      steps[0].hasContent = false
      steps[1].hasContent = true
      steps[1].isOpen = false
    }
    if (
      theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.Rejected &&
      theoryTest.abilities.can_accept
    ) {
      steps[0].status = 'complete'
      steps[1].status = 'pending'
      steps[0].hasContent = false
      steps[1].hasContent = true
      steps[1].isOpen = false
    }
    if (
      theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
      theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.PendingResult
    ) {
      steps[0].status = 'complete'
      steps[0].hasContent = false
      steps[1].isOpen = false
      steps[1].status = 'current'
      steps[1].hasContent = true
      steps[2].status = 'pending'
    }
    if (
      theoryTest?.status?.toLowerCase() === TestStatusTypeEnum.Passed &&
      questionnaireflags.has_practical_test &&
      (practicalTest === undefined ||
        (practicalTest &&
          (practicalTest.status === TestStatusTypeEnum.Rejected ||
            practicalTest.status === TestStatusTypeEnum.PendingReschedule)))
    ) {
      steps[2].status = 'current'
      steps[2].hasContent = true
      steps[2].isOpen = true
      steps[7].status = 'upcoming'
      steps[7].hasContent = false
      steps[7].isOpen = false
    }
    if (
      practicalTest?.status?.toLowerCase() === TestStatusTypeEnum.Passed ||
      practicalTest?.status?.toLowerCase() === TestStatusTypeEnum.Failed
    ) {
      steps[2].status = 'complete'
      steps[7].status = 'complete'
      steps[2].hasContent = false
      steps[7].hasContent = true
    }

    if (practicalTest?.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking) {
      steps[2].status = 'current'
      steps[2].hasContent = true
      steps[2].isOpen = false
      steps[7].status = 'upcoming'
      steps[7].hasContent = false
      steps[7].isOpen = false
    }

    if (
      practicalTest?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
      practicalTest?.status?.toLowerCase() === TestStatusTypeEnum.PendingResult
    ) {
      steps[2].status = 'complete'
      steps[7].status = 'pending'
      steps[2].hasContent = false
      steps[7].hasContent = true
      steps[7].isOpen = true
    }
    //#endregion "Update steps with test"

    const awaitupdateSteps = async () => {
      await updateSteps(steps)
    }

    awaitupdateSteps()
  }

  if (dealOnHold) {
    return (
      <>
        <EmptyState
          ImgPath={toAbsoluteUrl('/assets/media/svg/on-hold.svg')}
          title={intl.formatMessage({id: 'DASHBOARD.COURSE_ON_HOLD'})}
          description={intl.formatMessage({id: 'DASHBOARD.COURSE_ON_HOLD_DESC'})}
        />
      </>
    )
  } else if (dealExpired) {
    return (
      <>
        <EmptyState
          ImgPath={toAbsoluteUrl('/assets/media/svg/expired.svg')}
          title={intl.formatMessage({id: 'DASHBOARD.DEAL_EXPIRED'})}
          description=''
        />
      </>
    )
  } else if (cancelledDeal) {
    return (
      <>
        <EmptyState
          ImgPath={toAbsoluteUrl('/assets/media/svg/course-canceled.svg')}
          title={intl.formatMessage({id: 'DASHBOARD.CANCELLED_DEAL'})}
          description=''
        />
      </>
    )
  } else if (testFlags?.have_licence === false || testFlags?.have_licence === 0) {
    return (
      <>
        <div
          onClick={() => {
            redirectToLicence()
          }}
        >
          <EmptyState
            ImgPath={toAbsoluteUrl('/assets/media/svg/license.svg')}
            title={
              <>
                {intl.formatMessage({id: 'DASHBOARD.LICENCE_NOTE'})}
                <Link className='btn-link  fs-2 fw-700' to={''}>
                  {intl.formatMessage({id: 'DASHBOARD.LINK_TO_PROVISIONAL_lICENCE'})}
                </Link>
              </>
            }
            description=''
          />
        </div>
      </>
    )
  } else {
    return (
      <>
        <PaymentAmount
          show={showPaymentAmountModal}
          onHide={() => {
            setShowPaymentAmountModal(false)
          }}
          loading={loading}
          hasAdi={adiData !== null && adiData !== undefined}
          remainingBalance={formatPriceIntoTwoDigits(+paymentInfo?.remaining_amount, false)}
          adiData={adiData}
        />

        <nav aria-label='Dashboard' className='journey-stepper'>
          <ol role='list' className=''>
            {stepObj
              .filter((x) => !x.hide)
              .map((step, index) => (
                <JourneyItem
                  key={step.label}
                  title={step.label}
                  status={step.status}
                  imgPath={step.img}
                  id={step.id}
                  hasContent={step.hasContent}
                  expanded={step.isOpen}
                  stepIdx={index}
                  loading={loading}
                >
                  <>
                    {/* steps content */}
                    {step.id === JourneyStepsEnum.ArrangingTheory && (
                      <ArrangeTheoryTest
                        hasTheoryTest={testFlags.has_theory_test}
                        test={Object.keys(theoryTest ?? {}).length > 0 ? theoryTest : undefined}
                        getStudentBookingList={getStudentTests}
                        theoryTestCenters={theoryTestCenterList}
                        disableAction={disableAction}
                        dealId={dealId?.toString() ?? ''}
                        canAddPassMeTheory={testFlags?.can_add_pass_me_theory}
                        stepHasContent={step.hasContent}
                        preferredTestCenters={preferredTheoryTestCenters}
                      />
                    )}
                    {step.id === JourneyStepsEnum.TheoryTest && (
                      <TheoryTest
                        test={Object.keys(theoryTest ?? {}).length > 0 ? theoryTest : undefined}
                        getStudentBookingList={getStudentTests}
                        theoryTestCenters={theoryTestCenterList}
                        testRejectionReason={testRejectionReason.filter((x) => x.type === 'theory')}
                        practicalTestCenters={practicalTestCenterList}
                        disableAction={disableAction}
                        dealId={dealId?.toString() ?? ''}
                        canAddPassMeTheory={testFlags?.can_add_pass_me_theory}
                        passProtectOption={passprotectWithRedemptionRemaining}
                      />
                    )}
                    {step.id === JourneyStepsEnum.ArrangingPractical && (
                      <ArrangePracticalTest
                        passedTheoryTest={theoryTest?.status === TestStatusTypeEnum.Passed}
                        hasPracticalTest={testFlags.has_practical_test}
                        test={
                          Object.keys(practicalTest ?? {}).length > 0 ? practicalTest : undefined
                        }
                        getStudentBookingList={getStudentTests}
                        theoryTestCenters={theoryTestCenterList}
                        testRejectionReason={testRejectionReason.filter(
                          (x) => x.type === 'practical'
                        )}
                        practicalTestCenters={practicalTestCenterList}
                        disableAction={disableAction}
                        dealId={dealId?.toString() ?? ''}
                        hasPendingTest={hasPendingAdiTest}
                        passProtectOption={passprotectWithRedemptionRemaining}
                        canAddPassProtect={testFlags?.can_add_pass_protect}
                        preferredTestCenters={preferredPracticalTestCenters}
                        leadTime={leadTime}
                        isPto={isPto}
                        stepHasContent={step.hasContent}
                      />
                    )}
                    {step.id === JourneyStepsEnum.AllocatingInstructor && !adiData && (
                      <AllocatingInstructor
                        practicalTest={practicalTest}
                        studentBooking={studentBooking}
                      />
                    )}
                    {step.id === JourneyStepsEnum.Balance && (
                      <Balance
                        adiData={adiData}
                        paidInFull={paymentInfo?.paid_in_full}
                        pay={pay}
                        remainingBalance={paymentInfo?.remaining_amount}
                        totalAmount={paymentInfo?.total_amount}
                        disableAction={disableAction}
                      />
                    )}

                    {step.id === JourneyStepsEnum.Instructor && adiData && (
                      <Instructor
                        canProvideFeedback={canProvideFeedback}
                        adiData={adiData}
                        paymentInfo={paymentInfo}
                        hasLessons={hasPrevLessons || hasUpcommingLessons}
                        zendeskContactId={personalData?.zendesk_contact_id ?? ''}
                        dealId={dealId?.toString() ?? ''}
                        checkCanAddFeedback={checkCanAddFeedback}
                      />
                    )}
                    {step.id === JourneyStepsEnum.Lessons && (
                      <Lessons
                        hasPrevLessons={hasPrevLessons}
                        lesson={lessons}
                        paymentInfo={paymentInfo}
                        hasPendingLessons={hasPendingLessons}
                      />
                    )}
                    {step.id === JourneyStepsEnum.PracticalTest && (
                      <PracticalTest
                        test={
                          Object.keys(practicalTest ?? {}).length > 0 ? practicalTest : undefined
                        }
                        getStudentBookingList={getStudentTests}
                        theoryTestCenters={theoryTestCenterList}
                        testRejectionReason={testRejectionReason.filter(
                          (x) => x.type === 'practical'
                        )}
                        practicalTestCenters={practicalTestCenterList}
                        disableAction={disableAction}
                        passProtectOption={passprotectWithRedemptionRemaining}
                        canAddPassProtect={testFlags?.can_add_pass_protect}
                        leadTime={leadTime}
                        isPto={isPto}
                      />
                    )}
                  </>
                </JourneyItem>
              ))}
          </ol>
        </nav>
        <ReferFriend
          imageURL='booking.jpg'
          onHide={() => {
            setShowReferFriendModal(false)
            updateReferFriendDate()
          }}
          show={showReferFriendModal}
        />
      </>
    )
  }
}

export default JourneyTab

import {FC, useState} from 'react'
import Modal from '../../shared/overlays/Modal'
import RadioInputGroup from '../../shared/forms/RadioInputGroup'
import {Lesson} from '../../../models/lessons/lesson'
import {LessonRejectionReason} from '../../../models/lessons/lesson-rejection-reason'
import {LessonStatusEnum} from '../../../models/enums/lesson-status-enum'
import * as Yup from 'yup'
import {UpdateLessonStatusRequest} from '../../../models/student/update-lesson-status-request'
import {updateLessonStatus} from '../../../services/lesson.service'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {convertToTimeFormat} from '../../../helpers/DateHelper'
import {toAbsoluteUrl} from '../../../helpers'
import Alert from '../../shared/overlays/Alert'
import InfoList from '../../shared/data/InfoList'

type Props = {
  lesson: Lesson
  dealId: string
  statusId: number | null
  rejectionReasons: Array<LessonRejectionReason>
  show: boolean
  onHide: any
  onStatusSubmit: any
  showReferFriend: any
}

const UpdateLessonStatusModal: FC<Props> = ({
  lesson,
  rejectionReasons,
  dealId,
  statusId,
  show,
  onHide,
  onStatusSubmit,
  showReferFriend,
}) => {
  const intl = useIntl()
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [selectedReason, setSelectedReason] = useState<string | null>(null)

  const validationSchema = Yup.object().shape({
    rejection_reason:
      statusId === LessonStatusEnum.Rejected
        ? Yup.string()
            .required(
              intl.formatMessage(
                {id: 'VALIDATION.REQUIRED'},
                {field: intl.formatMessage({id: 'LESSONS.REJECTION_REASON'})}
              )
            )
            .nullable()
        : Yup.string().nullable(),
  })

  const formik = useFormik({
    initialValues: {
      rejection_reason: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (_values: any, {setSubmitting, resetForm}: any) => {
      setErrorMessage('')
      setShowError(false)
      let data: UpdateLessonStatusRequest = {
        deal_id: dealId,
        id: lesson.id ?? '',
        status_id: statusId,
        rejection_reason_id: statusId === LessonStatusEnum.Rejected ? selectedReason : null,
      }
      if (data.rejection_reason_id == null) delete data.rejection_reason_id
      if (data.status_id === LessonStatusEnum.Confirmed) {
        showReferFriend(true)
      }
      const response = await updateLessonStatus(data).catch(
        (error: {errorMessage: any; errors: string | any[]}) => {
          if (!error?.errorMessage) {
            setErrorMessage(intl.formatMessage({id: 'GENERAL.ERROR'}))
            setShowError(true)
          }
        }
      )

      setSubmitting(false)
      if (!response) {
        return
      }
      onStatusSubmit()
      resetForm()
      onHide()
    },
  })

  const hide = () => {
    formik.resetForm()
    formik.setTouched({...formik.touched}, false)
    onHide()
  }

  return (
    <Modal
      open={show}
      onClose={hide}
      title={
        statusId === LessonStatusEnum.Confirmed
          ? intl.formatMessage({id: 'LESSONS.ACCEPT_LESSON'})
          : intl.formatMessage({id: 'LESSONS.REJECT_LESSON'})
      }
      submitText={
        statusId === LessonStatusEnum.Rejected
          ? intl.formatMessage({id: 'ACTIONS.REJECT'})
          : intl.formatMessage({id: 'ACTIONS.ACCEPT'})
      }
      disabled={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
    >
      <form onSubmit={formik.handleSubmit} className='flex flex-col justify-between h-full'>
        <div className='pt-0 pb-8'>
          {showError && <Alert description={errorMessage} colour='red' icon='pmf-icon-info' />}
          <div className='bg-white border border-gray-600 rounded-[10px] pt-1 px-4 pb-1.5 mb-8'>
            <InfoList
            data={[
              {
                icon: 'icon-calendar',
                value: lesson.lesson_date_str ?? '-',
              },
              {
                icon: 'icon-clock',
                value: convertToTimeFormat(parseFloat(lesson.duration.toString()) * 60),
              },
            ]}
            />
          </div>


          <div
            className={
              statusId === LessonStatusEnum.Rejected
                ? formik.errors.rejection_reason && formik.touched.rejection_reason
                  ? 'is-invalid mt-8'
                  : 'mt-8'
                : 'hidden'
            }
          >
            {/* begin::Form group */}
            <div
              className={
                formik.errors.rejection_reason && formik.touched.rejection_reason
                  ? 'mb-5 is-invalid'
                  : 'mb-5'
              }
            >
              <label className='text-base font-bold text-darkBlue mb-0' htmlFor='reasons'>
                {intl.formatMessage({id: 'LESSONS.REASONOFREJECTION'})}
              </label>
              <RadioInputGroup
                fullWidth
                options={rejectionReasons?.map((reason: LessonRejectionReason, index: number) => {
                  return {
                    value: reason.id,
                    label: (
                      <>
                        <div className='flex items-center'>
                          {reason?.id === 1 || reason?.id === 4 ? (
                            <img
                              src={toAbsoluteUrl('/assets/media/svg/rejection-reason/cancel-lesson.svg')}
                              className='w-24px'
                              alt=''
                            />
                          ) : reason?.id === 2 || reason?.id === 5 ? (
                            <img
                              src={toAbsoluteUrl('/assets/media/svg/rejection-reason/adi-canceled.svg')}
                              className='w-24px'
                              alt=''
                            />
                          ) : (
                            <img
                              src={toAbsoluteUrl('/assets/media/svg/rejection-reason/conversation.svg')}
                              className='w-24px'
                              alt=''
                            />
                          )}
                          <span className='documentt_type_name ml-3'>{reason.name}</span>
                        </div>
                      </>
                    ),
                  }
                })}
                disabled={formik.isSubmitting}
                label={''}
                name={''}
                onChange={(reason: any) => {
                  setSelectedReason(reason)
                  formik.setFieldValue('rejection_reason', reason)
                }}
                value={selectedReason}
              ></RadioInputGroup>

              {formik.errors.rejection_reason && formik.touched.rejection_reason && (
                <Alert description={formik.errors.rejection_reason} colour='red' />
              )}
            </div>
            {/* end::Form group */}
          </div>

          {statusId === LessonStatusEnum.Confirmed && (
            <>
              <Alert
                description={intl.formatMessage({id: 'LESSONS.BY_ACCEPTING_LESSON_NOTE'})}
                colour='green'
                icon='pmf-icon-info'
              />

              <Alert
                description={
                  lesson.adi_cancellation_policy
                    ? intl.formatMessage(
                        {id: 'LESSONS.ADI_CANCELLATION_POLICY_NOTE'},
                        {cancellation_policy: lesson.adi_cancellation_policy}
                      )
                    : intl.formatMessage({id: 'LESSONS.ADI_GENERAL_CANCELLATION_POLICY_NOTE'})
                }
                colour='green'
                icon='pmf-icon-info'
              />
            </>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default UpdateLessonStatusModal

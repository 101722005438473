import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import { GetInTouchModel } from '../models/get-in-touch-model'
import { SendEmailNotifications } from '../models/send-email-notification'

const API_URL = process.env.REACT_APP_NOTIFICATION_SERVICE_URL
const API_KEY = process.env.REACT_APP_API_KEY

export async function getNotificationSettings(): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${API_URL}/student/notification-setting`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function updateNotificationSettings(
  id: string,
  data: {is_enabled: boolean}
): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${API_URL}/student/notification-setting/${id}`,
    method: 'PATCH',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function sendNotification(
  request: SendEmailNotifications<GetInTouchModel>
): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${API_URL}`,
    method: 'POST',
    data: JSON.stringify(request),
    xApiKey: API_KEY,
  })
}
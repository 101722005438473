import {FC} from 'react'
import {useIntl} from 'react-intl'
import Modal from '../../shared/overlays/Modal'
import Alert from '../../shared/overlays/Alert'

type Props = {
  yesSelected: any
  noSelected: any
  hide: any
  show: boolean
}

const ConfirmReschedulePracticalTestModal: FC<Props> = ({yesSelected, noSelected, hide, show}) => {
  const intl = useIntl()
  return (
    <>
      <Modal
        open={show}
        onClose={() => {
          noSelected()
          hide()
        }}
        onSubmit={() => yesSelected()}
        title={intl.formatMessage({id: 'RESCHEDULE_TEST.TITLE'})}
        submitText={intl.formatMessage({id: 'GENERAL.YES'})}
        closeText={intl.formatMessage({id: 'GENERAL.NO'})}
      >
          <h4 className='text-xl text-darkBlue mb-3 font-semibold'>
            {intl.formatMessage({id: 'RESCHEDULE_TEST.NEED_MORE_HOURS'})}
          </h4>
          <div className='mb-5'>
            <Alert
              description={intl.formatMessage({id: 'RESCHEDULE_TEST.FREE_RESCHEDULE'})}
              colour='pink'
            ></Alert>
          </div>
      </Modal>
    </>
  )
}

export default ConfirmReschedulePracticalTestModal

import { Route, Routes } from 'react-router-dom'
import { AuthPage } from '../content/pages/auth/AuthPage'
import { MobileLogin } from '../content/pages/auth/MobileLogin'
import { Toaster } from 'react-hot-toast'


export function PublicRoutes() {
  return (
    <>
      <Routes>
        <Route path='mobile-login' element={<MobileLogin />} />
        <Route path="*" element={<AuthPage />} />
      </Routes>
    </>

  )
}

import {FC, useEffect} from 'react'
import './soreto-style.css'
import PageHeader from '../../shared/elements/PageHeader'

const ReferFriendWrapper: FC = () => {
  useEffect(() => {
    if (window.SoretoJS) {
      const SoretoJS = window.SoretoJS
      SoretoJS.default.showLightbox()
    }
  }, [window.SoretoJS])

  return (
    <>
      <div className="bg-white pt-6">
        <PageHeader title="Refer a friend" backToURL="/more" isCenter />
      </div>
      <div id='soretoStaticPageDiv' className='w-100 h-100 flex'>
        <div id='soretosharewidget' className='reverb-static-lightbox'></div>
      </div>
    </>
  )
}

export default ReferFriendWrapper

import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {Assessment} from '../models/assessment/assessment'
import {BaseResponse} from '../models/base-response'

const API_URL = `${process.env.REACT_APP_ASSESSMENT_BASE_URL}/cognito`
const ASSESSMENT_API_KEY = process.env.REACT_APP_API_KEY

export async function getLastAssessment(): Promise<BaseResponse<Assessment>> {
  return await modifiedFetch({
    url: `${API_URL}/progress`,
    method: 'GET',
    xApiKey: ASSESSMENT_API_KEY,
  })
}


import { FC } from 'react'
import { useIntl } from 'react-intl'
import { TestStatusTypeEnum } from '../../../../models/enums/test-status-type'
import { StudentBooking } from '../../../../models/student/student-booking'
import { Test } from '../../../../models/tests/test'

type Props = {
    practicalTest : Test|undefined,
    studentBooking : StudentBooking | undefined
  
}
const AllocatingInstructor: FC<Props> = ({ practicalTest , studentBooking }) => {
    const intl = useIntl()
    return (
        <>
            {(!studentBooking?.has_practical_test || practicalTest?.status === TestStatusTypeEnum.Confirmed) ?
                <>
                    {/*Case1 : For lessons only or practical test has been confirmed */}
                    <p className='text-base text-darkBlue'>
                        {intl.formatMessage({ id: 'DASHBOARD.ARRANGING_INSTRUCTOR.WE_ARE_ASSIGNING_ADI' })} {intl.formatMessage({ id: 'DASHBOARD.ARRANGING_INSTRUCTOR.NO_ADI_FOUND_YET' })}
                    </p>
                    {/*end Case1 */}
                </>
                :
                <>
                    {/*Case2 : No ADI has been assgined and no confirmed */}
                    <p className='text-base text-darkBlue'>{intl.formatMessage({ id: 'DASHBOARD.ARRANGING_INSTRUCTOR.NO_ADI_HAS_BEEN_ASSIGNED' })} </p>
                    {/*end Case2 */}
                </>
            }
        </>
    )
}

export { AllocatingInstructor }

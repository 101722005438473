import {Skeleton} from '@mui/material'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {scrollToTop, scrollToFirstError} from '../../../helpers/ScrollHelper'
import {IntensityEnum} from '../../../models/enums/intensity-enum'
import {IQuationnaireTabProps} from '../../../models/quationnaire-tab-props'
import {
  AboutCourseDataModel,
  UpdateAboutCourseDataModel,
} from '../../../models/student/about-course-data-model'
import {AvailabilityModel} from '../../../models/student/availability-modal'
import {DayModel} from '../../../models/student/day-model'
import {Intensity} from '../../../models/student/intensity'
import {useCourse} from '../../../providers/CourseProvider'
import {dateCompare} from '../../../services/date.service'
import {getIntensity} from '../../../services/intensity.service'
import {getHasAssignedADI} from '../../../services/job.service'
import {
  checkRestrictedAvailability,
  getAboutCourse,
  addAboutCourse,
} from '../../../services/student.service'
import {AvailabilityOptionEnum} from '../../../models/enums/availability-options-enum'
import {getDays} from '../../../services/days.service'
import {AvailabilityWarningModal} from './AvailabilityWarningModal'
import Input from '../../shared/forms/Input'
import RadioInputGroup from '../../shared/forms/RadioInputGroup'
import Button from '../../shared/elements/Button'
import {Availability} from './Availability'
import {toAbsoluteUrl} from '../../../helpers'
import Alert from '../../shared/overlays/Alert'
import toast from 'react-hot-toast'

const AboutCourse: FC<IQuationnaireTabProps> = (props) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const [intensity, setIntensity] = useState<Array<Intensity>>([])
  const [weekDays, setWeekDays] = useState<Array<DayModel>>([])
  const [overLap, setOverLap] = useState<Array<boolean>>([])
  const [studentBooking, setStudentBooking] = useState<any>({})
  const startDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    6,
    0,
    0
  )
  const endDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    21,
    0,
    0
  )
  const [initalValues, setInitialValues] = useState<AboutCourseDataModel>({
    availability_option_id: -1,
    intensity_id: -1,
    previous_driving_hours: 0,
    other_lesson_availability: '',
    availability: [],
  })
  const [isLondonStudent, setIsLondonStudent] = useState(false)

  const [intensityNote, setIntensityNote] = useState('')
  const {Course} = useCourse()
  const [hasAssignedADI, setHasAssignedADI] = useState(false)
  const [showIntensityWarning, setShowIntensityWarning] = useState(false)
  const [showAvailabilityWarning, setShowAvailabilityWarning] = useState(false)
  const [availabilities, SetAvailabilities] = useState([])

  useEffect(() => {
    setStudentBooking(Course.currentCourse)
  }, [Course.currentCourse])

  useEffect(() => {
    if (Course.currentCourse.deal_id) {
      ;(async () => {
        const hasAssignedADIResponse = await getHasAssignedADI(Course.currentCourse.deal_id).catch(
          (error) => {
            if (!error?.errorMessage) toast.error('Failed to check assigned adi')
          }
        )
        setHasAssignedADI(hasAssignedADIResponse.results.hasAssignedADI)
      })()
    }
  }, [Course.currentCourse.deal_id])

  const validationSchema = Yup.object().shape({
    previous_driving_hours: Yup.number()
      .integer(intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.VALID_INT'}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.PLURAL_REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.PREVIOUS_DRIVING_HOURS'})}
        )
      )
      .min(0, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 0}))
      .nullable()
      .max(999, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 999}))
      .nullable(),
    intensity_id: Yup.number()
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.INTENSITY'})}
        )
      )
      .nullable(),
    availability_option_id: Yup.number().nullable(),
    availability: Yup.array().min(
      1,
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.AVAILABILITY_OPTION'})}
      )
    ),
    is_london_student: Yup.boolean().default(false),
    is_able_to_reallocate: Yup.boolean()
      .when('is_london_student', {
        is: true,
        then: (schema) => schema.required().nullable(),
      })
      .nullable(),
  })
  const formik = useFormik({
    initialValues: {
      previous_driving_hours: initalValues.previous_driving_hours ?? '',
      intensity_id: initalValues.intensity_id ?? 0,
      availability_option_id:
        initalValues.availability_option_id ?? Number(AvailabilityOptionEnum.Other),
      other_lesson_availability: initalValues.other_lesson_availability ?? '',
      availability: initalValues.availability ?? [],
      days: weekDays,
      is_london_student: initalValues.is_london_student ?? false,
      is_able_to_reallocate: initalValues.is_able_to_reallocate ?? null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: any, {setSubmitting}: any) => {
      setSubmitting(true)
      let hasOverLap = checkOverLap()
      if (hasOverLap) {
        setSubmitting(false)
        scrollToTop()
        return
      }
      let hasAvailabilityError = checkAvailabilityError()
      if (hasAvailabilityError) {
        setSubmitting(false)
        scrollToTop()
        return
      }
      const restrictedAvailability = await checkAvailabilityRestricted(values.availability)
      if (restrictedAvailability) {
        setShowAvailabilityWarning(true)
        setSubmitting(false)
        return
      }
      updateAboutCourse(values, setSubmitting)
    },
  })
  const checkAvailabilityError = (): boolean => {
    if (formik.values.availability.length == 0) {
      return true
    }
    if (
      formik.values.availability.findIndex(
        (a) =>
          a.from_time == '' || a.to_time == '' || a.day_id == null || !a.from_time || !a.to_time
      ) > -1
    ) {
      return true
    }

    let x = formik.values.availability.filter((slot) => {
      if (slot.to_time && slot.from_time && slot.to_time <= slot.from_time) {
        return slot
      }
    })
    if (x.length > 0) {
      return true
    }
    let check = formik.values.availability.filter((slot) => {
      if (
        slot.to_time &&
        slot.from_time &&
        (dateCompare(slot.from_time, startDate) == -1 ||
          dateCompare(slot.to_time, endDate) == 1 ||
          dateCompare(slot.from_time, endDate) == 1 ||
          dateCompare(slot.to_time, startDate) == -1)
      ) {
        return slot
      }
    })
    if (check.length > 0) {
      return true
    }
    return false
  }
  const overlapping = (a: any, b: any) => {
    const getMinutes = (s: any) => {
      const p = s.split(':').map(Number)
      return p[0] * 60 + p[1]
    }
    return (
      getMinutes(a.to_time) > getMinutes(b.from_time) &&
      getMinutes(b.to_time) > getMinutes(a.from_time)
    )
  }
  const isOverlapping = (arr: any) => {
    let i, j
    for (i = 0; i < arr.length - 1; i++) {
      for (j = i + 1; j < arr.length; j++) {
        if (overlapping(arr[i], arr[j])) {
          return true
        }
      }
    }
    return false
  }
  const checkOverLap = () => {
    setOverLap([])
    let overLapExists: Array<boolean> = []
    weekDays.forEach((day) => {
      let daySlots = formik.values.availability.filter((slot) => {
        if (slot.day_id == day.id) {
          return slot
        }
      })
      overLapExists.push(isOverlapping(daySlots))
    })
    setOverLap(overLapExists)

    if (overLapExists.indexOf(true) > -1) {
      return true
    }
  }

  const checkAvailabilityRestricted = async (availabilities: AvailabilityModel[]) => {
    const response = await checkRestrictedAvailability({availability: availabilities}).catch(
      (error) => {
        if (!error?.errorMessage) toast.error('Failed to check restricted availability')
      }
    )
    return response.results.hasRestrictedAvailability
  }

  //#region hoursRange
  const onhoursRangeChanged = (id: any) => {
    setShowIntensityWarning(id < (initalValues.intensity_id ?? 0) && hasAssignedADI)
    formik.setFieldValue('intensity_id', id)
  }
  const getIntensityRows = intensity.map((range) => ({
    value: range.id,
    label: range.name,
    description: range.description,
    img:
      range?.id == 1
        ? toAbsoluteUrl('/assets/media/svg/rejection-reason/intensive.svg')
        : range?.id == 2
          ? toAbsoluteUrl('/assets/media/svg/rejection-reason/semi.svg')
          : toAbsoluteUrl('/assets/media/svg/rejection-reason/relaxed.svg'),
  }))

  //#endregion

  //#region setIntensityNote
  const getNote = () => {
    if (formik.values.intensity_id == IntensityEnum.Intensive) {
      setIntensityNote(intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.Intensive'}))
    } else if (formik.values.intensity_id == IntensityEnum.SemiIntensive) {
      setIntensityNote(intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.Semi-Intensive_NOTE'}))
    } else {
      setIntensityNote(intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.RELAXED_NOTE'}))
    }
  }
  useEffect(() => {
    getNote()
  }, [formik.values.intensity_id])
  //#endregion

  //#region api calls
  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const intensityRes = await getIntensity().catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get intensity')
      })
      if (intensityRes) {
        setIntensity(intensityRes.results)
      }
      const getAllDaysRes = await getDays()
      if (getAllDaysRes) {
        setWeekDays(getAllDaysRes.results ?? [])
      }
      if (intensityRes && getAllDaysRes) {
        let aboutCourseRes = await getAboutCourse().catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get about course')
          setLoading(false)
        })
        if (aboutCourseRes) {
          let mappedAvailability = aboutCourseRes.results.availability.map((item) => {
            return {
              ...item,
              from_time: item.from_time.slice(0, 5),
              to_time: item.to_time.slice(0, 5),
            }
          })
          aboutCourseRes.results.availability = mappedAvailability
          setInitialValues(aboutCourseRes.results)
          SetAvailabilities(Object.assign(availabilities, aboutCourseRes.results.availability))
          setLoading(false)
        }
      }
    }

    fetchData()
  }, [])

  //#endregion

  useEffect(() => {
    formik.setFieldValue('availability_option_id', initalValues.availability_option_id)
    formik.setFieldValue('intensity_id', initalValues.intensity_id)
    formik.setFieldValue('previous_driving_hours', initalValues.previous_driving_hours ?? '')
    formik.setFieldValue('availability', initalValues.availability ?? [])
    formik.setFieldValue('is_able_to_reallocate', initalValues.is_able_to_reallocate)
    formik.setFieldValue('is_london_student', initalValues.is_london_student ?? false)
    setIsLondonStudent(initalValues.is_london_student ?? false)
  }, [initalValues])

  useEffect(() => {
    scrollToFirstError(formik)
  }, [formik.errors, formik.isSubmitting, formik.isValidating])

  const updateAboutCourse = async (values: any, setSubmitting: any) => {
    setSubmitting(true)
    const updateAboutCourseDataModel: UpdateAboutCourseDataModel = {
      availability_option_id: values.availability_option_id ?? Number(AvailabilityOptionEnum.Other),
      intensity_id: values.intensity_id,
      previous_driving_hours: values.previous_driving_hours,
      other_lesson_availability: values.other_lesson_availability,
      deal_id: studentBooking.deal_id,
      availability: values.availability,
      is_able_to_reallocate: values.is_london_student ? values.is_able_to_reallocate : false,
    }
    let addCourseRes = await addAboutCourse(updateAboutCourseDataModel).catch((error) => {
      setSubmitting(false)
      scrollToTop()
      if (!error?.errorMessage) {
        toast.error('Failed to update course data')
      }
    })
    if (addCourseRes) {
      props.goToNextTab()
      setSubmitting(false)
    }
  }

  return (
    <>
      <AvailabilityWarningModal
        show={showAvailabilityWarning}
        hasAssignedADI={hasAssignedADI}
        onclose={() => {
          setShowAvailabilityWarning(false)
          formik.setFieldValue('availability', availabilities)
        }}
        onSubmit={() => {
          setShowAvailabilityWarning(false)
          updateAboutCourse(formik.values, formik.setSubmitting)
        }}
      ></AvailabilityWarningModal>
      <div
        className={
          `w-full m-auto ` + (props.parentWrapper === 'questionnaire' ? 'lg:w-3/4' : 'lg:w-full')
        }
      >
        {props.parentWrapper === 'questionnaire' ? (
          <Alert
            colour='green'
            description='We can’t start arranging your course until you’ve completed all steps of this form. Please
                      submit your details ASAP.'
            title='Info'
          />
        ) : (
          <></>
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='bg-white relative flex flex-col min-w-0 rounded break-words border border-1 border-gray-300 card--form-container px-5 py-6'>
            <div className={props.parentWrapper === 'questionnaire' ? '' : 'hidden'}>
              <h1 className='text-darkBlue font-bold text-2xl my-3'>
                {' '}
                {loading ? (
                  <Skeleton width={150} />
                ) : (
                  intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.TABTITLE'})
                )}
              </h1>
            </div>
            {/* start::First Name form group */}
            <div className='clearfix pb-5'>
              {loading ? (
                <Skeleton width='100%' height={40} />
              ) : (
                <>
                <h2 className='text-lg font-semibold -mb-2 text-darkBlue leading-none'>{intl.formatMessage({
                      id: 'QUESTIONNAIRE.ABOUTCOURSE.HOW_MANY_HOURS_DRIVING',
                    })}</h2>
                  <Input
                    type={'number'}
                    name={'previous_driving_hours'}
                    className={'form-control'}
                    id={'previous_driving_hours'}
                    required={true}
                    value={formik.values.previous_driving_hours}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    min={0}
                    max={999}
                    label="Hours"
                    error={
                      formik.errors.previous_driving_hours && formik.touched.previous_driving_hours
                        ? true
                        : false
                    }
                    errorMsg={formik.errors.previous_driving_hours}
                  />
                  <div className='py-[1px] -mt-5'>
                    <Alert
                      colour='green'
                      description={intl.formatMessage({
                        id: 'QUESTIONNAIRE.ABOUTCOURSE.HOW_MANY_HOURS_DRIVING_NOTE',
                      })}
                    />
                  </div>
                  
                </>
              )}
            </div>
            {/* end::First Name form group */}
            {/* start::Willing to travel for lesson form group */}
            {isLondonStudent && loading && (
              <>
                <div className='flex flex-wrap content-between'>
                  <Skeleton width={300} />
                </div>
                <div className='py-[1px]'>
                  <Alert colour='green'>
                    <Skeleton width={150} />
                  </Alert>
                  <div className='-mt-4 grid grid-cols-2 gap-4'>
                  <Skeleton height={60} /><Skeleton height={60} />
                  </div>
                </div>
              </>
            )}
            {isLondonStudent && !loading && (
              <>
                <div className='flex flex-wrap content-between'>
                  <label htmlFor='title' className='control-label'>
                    {intl.formatMessage({
                      id: 'QUESTIONNAIRE.ABOUTCOURSE.MEET_INSTRUCTOR',
                    })}
                    <span className='required'></span>
                  </label>
                </div>
                
                <div className='pb-8'>
                  <Alert
                    colour='green'
                    description={intl.formatMessage({
                      id: 'QUESTIONNAIRE.ABOUTCOURSE.SPEND_LESS_TIME',
                    })}
                  />
                  <div className='form-check'>
                    <RadioInputGroup
                      name={'is_able_to_reallocate'}
                      options={[
                        {value: true, label: 'Yes'},
                        {value: false, label: 'No'},
                      ]}
                      label={''}
                      onChange={(value: any) => {
                        if (value == true) {
                          formik.setFieldValue('is_able_to_reallocate', true)
                        } else {
                          formik.setFieldValue('is_able_to_reallocate', false)
                        }
                      }}
                      value={
                        formik.values.is_able_to_reallocate != null &&
                        formik.values.is_able_to_reallocate == true
                          ? true
                          : false
                      }
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched.is_able_to_reallocate && formik.errors.is_able_to_reallocate
                          ? true
                          : false
                      }
                      errorMsg={formik.errors.is_able_to_reallocate}
                    />
                  </div>
                </div>
              </>
            )}
            {/* end::Willing to travel for lesson form group */}
            {/* start::First Name form group */}
            <>
              {showIntensityWarning && (
                <div className='py-[1px]'>
                  <Alert
                    colour='green'
                    description='Your instructor has taken on the course based on your existing course
                      intensity. Please discuss any changes with them to ensure they can still
                      provide lessons.'
                  />
                </div>
              )}
              <div className='clearfix mb-3'>
                {loading ? (
                  <div className='flex w-100 form-check radio rad o--big radio--with-check mb-4'>
                    <label
                      htmlFor='color'
                      className={
                        'shadow-none border-solid py-4 px-5 rounded-lg border content-start pe-2 ps-5 m-0 radio-job-box form-check-label w-100 bg-pmfGray'
                      }
                    >
                      <div className='flex items-start'>
                        <Skeleton width={80} height={50} />
                        <div className='ms-3'>
                          <Skeleton width={150} />
                          <Skeleton width={500} />
                          <Skeleton width={500} />
                        </div>
                      </div>
                    </label>
                  </div>
                ) : (
                  <RadioInputGroup
                    options={getIntensityRows}
                    label={intl.formatMessage({
                      id: 'QUESTIONNAIRE.ABOUTCOURSE.HOW_FAST_DO_YOU_WANT_YOUR_COURSE',
                    })}
                    name={'Range'}
                    onChange={(e) => {
                      onhoursRangeChanged(e)
                    }}
                    value={formik.values.intensity_id}
                    disabled={formik.isSubmitting}
                    error={formik.errors.intensity_id && formik.touched.intensity_id ? true : false}
                    errorMsg={formik.errors.intensity_id}
                    required={true}
                    fullWidth
                  />
                )}
              </div>
            </>
            {/* end::First Name form group */}
            {/* start::First Name form group */}
            <div
              className={
                formik.errors.availability && formik.touched.availability
                  ? 'form-group  is-invalid'
                  : 'form-group '
              }
            >
              <div className='flex flex-wrap content-between py-3'>
                <label htmlFor='first_name' className='control-label font-semibold'>
                  {loading ? (
                    <Skeleton width={300} />
                  ) : (
                    <>
                      {intl.formatMessage({id: 'QUESTIONNAIRE.ABOUTCOURSE.AVAILABILITY'})}
                      <span className='text-red-500'>*</span>
                    </>
                  )}
                </label>
              </div>
              <div className='clearfix'>
                {loading ? (
                  <Skeleton width={150} height={30} />
                ) : (
                  <>
                  
                  <div className='py-[1px]'>
                    <Alert
                      colour='green'
                      description={
                        intl.formatMessage({
                          id: 'QUESTIONNAIRE.ABOUTCOURSE.AVAILABILITY_WARNING',
                        }) +
                        ' ' +
                        intensityNote
                      }
                    />
                  </div>
                    
                  </>
                )}

                <Availability
                  formik={formik}
                  submitted={formik.isSubmitting}
                  weekDays={weekDays}
                  overLap={overLap}
                  availabilityList={formik.values.availability}
                  loading={loading}
                />
              </div>
            </div>
            {/* end::First Name form group */}
          </div>


          <div className='py-5'>
            {loading ? (
              <div className='flex items-center justify-between gap-2'>
                <Skeleton className='w-1/2 !rounded-full' height={50} variant='rounded'></Skeleton>
                <Skeleton className='w-1/2 !rounded-full' height={50} variant='rounded'></Skeleton>
              </div>
            ) : (
              <div className='flex items-center justify-between gap-2'>
                <Button
                  type='button'
                  disabled={formik.isSubmitting}
                  name='next'
                  className={`w-1/2 ${props.parentWrapper !== 'questionnaire' ? 'order-2': ''}` }
                  size='large'
                  halfWidth={true}
                  colour='outline'
                  onClick={() => props.goToPrevTab()}
                >
                  {props.parentWrapper === 'questionnaire'
                    ? intl.formatMessage({id: 'ACTIONS.BACK'})
                    : intl.formatMessage({id: 'ACTIONS.DISCARD'})}
                </Button>
                <Button
                  type='submit'
                  disabled={formik.isSubmitting}
                  className={`btn btn-primary px-3 ${props.parentWrapper !== 'questionnaire' ? 'order-1': ''}` }
                  name='next'
                  size='large'
                  halfWidth={true}
                  colour='gradient'
                >
                  {props.parentWrapper === 'questionnaire' ? 'Save & Continue' : 'Save'}
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  )
}

export {AboutCourse}

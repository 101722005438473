import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import {TestCenter} from '../models/test-center/test-center'

const API_URL = process.env.REACT_APP_BASE_URL
const TEST_CENTRE_API_URL = process.env.REACT_APP_TEST_CENTRE_SERVICE_URL
const POSTCODE_API_URL = process.env.REACT_APP_POSTCODE_BASE_URL
const POSTCODE_API_KEY = process.env.REACT_APP_API_KEY

export async function getCities(): Promise<any> {
  return await modifiedFetch({
    url: `${POSTCODE_API_URL}/cities`,
    method: 'GET',
    xApiKey: POSTCODE_API_KEY,
  })
}

export async function getTitles(): Promise<BaseResponse<Array<any>>> {
  return await modifiedFetch({
    url: `${API_URL}/titles`,
    method: 'GET',
  })
}

export async function getGenders(): Promise<BaseResponse<Array<any>>> {
  return await modifiedFetch({
    url: `${API_URL}/genders`,
    method: 'GET',
  })
}

export async function getTestCentresByTypeID(
  type: number
): Promise<BaseResponse<Array<TestCenter>>> {
  return await modifiedFetch({
    url: `${TEST_CENTRE_API_URL}/test-centers-by-type/${type}`,
    method: 'GET',
  })
}

import React from 'react'
import './global.css'
/* Sentry */
import * as Sentry from '@sentry/react'
import {CaptureConsole} from '@sentry/integrations'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
// Apps
import App from './App'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import store, { persistor } from './setup/redux/Store'
import { CustomI18nProvider } from './app/config/i18n/Custom18n'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,

  beforeSend(event, hint) {
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length == 1
    ) {
      var e = event.exception.values[0]
      if (
        e.type === 'UnhandledRejection' &&
        e.value === 'Non-Error promise rejection captured with value: '
      ) {
        return null
      }
    }
    return event
  },
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  sendDefaultPii: process.env.REACT_APP_SENTRY_SEND_DEFAULT_PII === 'true',
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Non-Error promise rejection captured with value: undefined',
    'The above error occurred in task e',
    'undefined',
    "Can't find variable: msDiscoverChatAvailable",
  ],
})

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <CustomI18nProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor}>
          <React.StrictMode>
            <App basename={''} />
          </React.StrictMode>
        </PersistGate>
      </Provider>
    </CustomI18nProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {CourseUpgradeResponse} from '../models/order/course-upgrade-response'
import {OrderPayment} from '../models/payments/order-payment'
import {StudentReceipt} from '../models/payments/student-receipt'

const BOOKING_URL = process.env.REACT_APP_BOOKING_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY

export async function isCourseEligibleForRebooking(dealId: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/deals/eligible-for-rebooking/${dealId}`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function getOrderPayments(dealId: any): Promise<Array<OrderPayment>> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/deal_id/${dealId}/payments-history`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function getStudentReceipt(dealId: any): Promise<StudentReceipt> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/deals/${dealId}/receipt`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function refundEligibility(dealId: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/deal_id/${dealId}/refund-eligibility`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function cancelOrder(dealId: any, cancelObj: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/deal_id/${dealId}/cancel`,
    method: 'POST',
    data: cancelObj,
    xApiKey: API_KEY,
  })
}

export async function getOrder(dealId: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/deal_id/${dealId}`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}
export async function checkPassprotectWithRedemptionRemaining(dealId: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/deal_id/${dealId}/pass-protect-remaining`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function rescheduleTestWithAdditionalHours(data: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/student/reschedule-pt-with-hours`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function getPreferredTestCenters(orderId: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/${orderId}/test-centres`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function checkCourseUpgrade(
  orderId: any,
  postcode: string
): Promise<CourseUpgradeResponse> {
  const data = {
    postcode: postcode,
  }
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/${orderId}/check-course-upgrade`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function upgradeStudentCourse(
  orderId: any,
  postcode: string,
  canBeUpdated: boolean
): Promise<CourseUpgradeResponse> {
  const data = {
    postcode: postcode,
    can_be_updated: canBeUpdated,
  }
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/${orderId}/upgrade-course`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function rescheduleTestForCourseUpgrade(data: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/student/reschedule-test-for-course-upgrade`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function handlePassProtect(dealId: any, data: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/deals/${dealId}/handle-pass-protect?is_private=1`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}
export async function freePaymentRequest(token: any, data: any): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKING_URL}/carts/${token}/payment/complete-with-free-payment`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}
export async function getAdditionalHoursCustomPricing(orderId: any) {
  return await modifiedFetch({
    url: `${BOOKING_URL}/orders/${orderId}/additional-hours`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

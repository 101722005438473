import {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import PageHeader from '../../shared/elements/PageHeader'
import {VideoModal} from './VideoModal'

const VideosPage: FC = () => {
  const videos = [
    {
      Id: 1,
      Title: 'Overcoming Nerves',
      SubTitle: 'Overcome those pesky test day nerves with our ten top tips',
      VimeoID: '764137328',
      ImageURL: '/assets/media/video-thumbnail/Video-1-overcoming-nerves.jpg',
    },
    {
      Id: 2,
      Title: 'Eye Scanning',
      SubTitle: 'How to avoid falling victim to one of the main reasons people fail their tests',
      VimeoID: '764139594',
      ImageURL: '/assets/media/video-thumbnail/video-2-eye-scanning.jpg',
    },
    {
      Id: 3,
      Title: 'Moving off and stopping',
      SubTitle: 'Find out everything you need to know about moving off and stopping safely',
      VimeoID: '764144805',
      ImageURL: '/assets/media/video-thumbnail/video-3-moving-off-and-stopping.jpg',
    },
    {
      Id: 4,
      Title: 'Control and Positioning',
      SubTitle: 'Learn to master basic controls so that they become a second nature',
      VimeoID: '764145801',
      ImageURL: '/assets/media/video-thumbnail/video-4-control-and-positioning.jpg',
    },
    {
      Id: 5,
      Title: 'Correct Use of Speed',
      SubTitle: 'Gain the skills required to drive confidently at the right speed',
      VimeoID: '764146700',
      ImageURL: '/assets/media/video-thumbnail/Video-5-correct-use-of-speed.jpg',
    },
    {
      Id: 6,
      Title: 'Anticipation and Planning',
      SubTitle: 'How to anticipate and respond safely to others',
      VimeoID: '775749603',
      ImageURL: '/assets/media/video-thumbnail/Video-6-anticipation-and-planning.jpg',
    },
    {
      Id: 7,
      Title: 'Road Signs and Signals',
      SubTitle: 'How to spot signs & signals, understand them and respond appropriately',
      VimeoID: '775750695',
      ImageURL: '/assets/media/video-thumbnail/Video-7-responding-to-road-signs.jpg',
    },
    {
      Id: 8,
      Title: 'Junctions, Crossroads and Roundabouts',
      SubTitle:
        'Learn how to plan ahead effectively at junctions, where you’re most likely to interact with other traffic',
      VimeoID: '775751693',
      ImageURL: '/assets/media/video-thumbnail/video-8- junctions-crossroads-roundabouts.jpg',
    },
    {
      Id: 9,
      Title: 'Manoeuvres',
      SubTitle: 'Don’t let poor observation cause you to fail your test on your manoeuvre',
      VimeoID: '775753446',
      ImageURL: '/assets/media/video-thumbnail/Video-9-manoeuvres.jpg',
    },
    {
      Id: 10,
      Title: 'Mock Tests',
      SubTitle: 'How to make your mock test as authentic and beneficial as possible',
      VimeoID: '775757432',
      ImageURL: '/assets/media/video-thumbnail/Video-10-mock-tests.jpg',
    },
  ]
  const [selectedVideo, setSelectedVideo] = useState({
    Title: '',
    VimeoID: '',
  })
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = (video: any) => {
    setSelectedVideo(video)
    setShow(true)
  }

  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='Driving Lesson' backToURL='/more' isCenter />
      </div>
      <div className='px-5 py-6 grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-8'>
        {videos.map((video, index) => (
          <div
            className={`hover:cursor-pointer hover:brightness-75 hover: translate-y-1 mb-6`}
            key={index}
          >
            <a className='VideoTip d-flex flex-column' onClick={() => handleShow(video)}>
              <div className='shadow-0'>
                <div className='VideoTip_Image position-relative'>
                  <img src={toAbsoluteUrl(video.ImageURL)} className='w-100' alt={video.Title} />
                  <div className='VideoTip_annotation__content'>
                    <div className='VideoTip_playbutton' title='Play'>
                      <i className='fas fa-play'></i>
                    </div>
                  </div>
                </div>
                <div className='VideoTip_Image py-2 px-0 text-darkBlue'>
                  <h3 className='font-bold text-2xl mb-1'>{video.Title}</h3>
                  <p className='font-size-12 lh-1'>{video.SubTitle}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>

      <VideoModal
        title={selectedVideo.Title}
        vimeoId={selectedVideo.VimeoID}
        show={show}
        onHide={handleClose}
      ></VideoModal>
    </>
  )
}

export default VideosPage

import CreatableSelect from 'react-select/creatable'
import {Skeleton} from '@mui/material'

export interface CreatableDropdownProps {
  label?: string
  id?: string
  name?: string
  className?: string
  value?: any
  options: Array<any>
  onChange?: (newValue: any, actionMeta: any) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  errorMsg?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  isSearchable?: boolean
  loading?: boolean
}

export default function CreatableDropdown({
  label,
  className = '',
  options = [],
  name,
  id = '',
  value,
  onChange,
  onBlur,
  required = false,
  disabled = false,
  isSearchable = false,
  error = false,
  errorMsg = 'This field is required',
  placeholder,
  loading = false,
  ...props
}: CreatableDropdownProps) {

  const colourStyles = {
    control: (styles: any,  state: any) => ({ 
      ...styles, 
      minHeight: '44px',
      height: '44px',
      borderRadius: '2px',
      boxShadow: 'none',
      borderColor: state.isFocused ? '#4f46e5' : (error ? "#ef4444" : "#dadde2"),
      backgroundColor: 'white' 
    }),
    option: (styles: any) => {
      return {
        ...styles,
        fontSize:'15px',
        minHeight: '36px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
      }
    },
    input: (styles: any) => ({
      ...styles,
      height: '38px',
      minWidth: '100px',
      borderColor: 'transparent'
    }),
    singleValue: (styles: any) => ({
      ...styles,
      fontSize:'14px',
      fontWeight: 400,
      color: '#163A5E'
    }),
    menuList: (styles: any) => ({
      ...styles,
      maxHeight:'140px',
      overflowX: 'hidden',
      display: 'grid',
      width: '100%'
    }),
    menu: (styles: any) => ({
      ...styles,
      position: 'absolute',
      zIndex: 20,
      marginTop: '4px',
      boxShadow: 'none',
      border: '1px solid #d1d5db'
    }),
      placeholder: (styles: any) => ({
      ...styles,
      color:'#9ca3af',
      fontSize:'14px',
    }),
  };


  return (
    <>
      <div className='relative w-full'>
        {loading ? (
          <>
            <Skeleton height={30} className='w-full ms-2' />
          </>
        ) : (
          <>
            {label && (
              <label
                htmlFor={name}
                className='absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-darkBlue z-[1]'
              >
                {label}
                {required && <span className='text-sm text-red-500'>*</span>}
              </label>
            )}
            <CreatableSelect
              {...props}
              name={name}
              id={id}
              placeholder={placeholder}
              isSearchable={isSearchable}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isDisabled={disabled}
              required={required}
              options={options}
              className={`block w-full text-darkBlue placeholder:text-gray-400 mt-1 ${className}`}
              styles={colourStyles}
            />
            {error && (
              <p className='text-sm text-red-600' id='error'>
                {errorMsg}
              </p>
            )}
          </>
        )}
      </div>
    </>
  )
}

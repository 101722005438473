/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from "@headlessui/react";
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import clsx from "clsx";
import { FC, Fragment } from "react";
import Button from "../elements/Button";
import CheckboxInput from "./CheckboxInput";

interface ICustomDropdownProp {
  options: {
    title: string | null
    id: number
    isChecked: boolean
    isDisabled: boolean
  }[];
  sourceId: number
  handleSelect: (dayIndex: number) => void
  applyCopy: (dayId: number) => void
  onClose: () => void
  disabled?: boolean
}

const CustomDropdown: FC<ICustomDropdownProp> = ({ options, sourceId, disabled, handleSelect, applyCopy, onClose }) => {

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button onClick={onClose} className="inline-flex items-center justify-center rounded-full bg-[#F4F5F7] w-[30px] h-[30px] shadow-sm hover:bg-gray-50 text-darkBlue" disabled={disabled}>
          <DocumentDuplicateIcon className='mx-auto h-4 w-4' />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item disabled>
                <div className="text-gray-300 font-medium text-sm px-4 py-2">
                  Copy time to:
                </div>
              </Menu.Item>

              {options.map((option, i) =>
                <Menu.Item key={i} disabled={option.isDisabled}>
                  {({ active }) => (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelect(option.id);
                      }}
                      className={clsx(
                        active
                          ? "bg-gray-100 "
                          : "",
                        "flex px-4 py-1 text-sm"
                      )}
                    >
                      <CheckboxInput
                        name={'op' + i}
                        required={false}
                        label={option.title ?? ''}
                        isSingleCheckbox={true}
                        checked={option.isChecked}
                        onChange={() => handleSelect(option.id)}
                        disabled={option.isDisabled}
                      />
                    </div>
                  )}
                </Menu.Item>)}

              <Menu.Item as="div" className="px-4 py-2">
                {({ close }) => (
                  <Button
                    type='button'
                    onClick={() => {
                      applyCopy(sourceId);
                      close();
                    }}
                    fullWidth
                    size='medium'
                    colour='outline'
                  >
                    <span className='flex items-center text-darkBlue'> Apply </span>
                  </Button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export { CustomDropdown };

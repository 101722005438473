import clsx from "clsx";
import { Link } from "react-router-dom";
import MenuLinks from "./MenuLinks";

const Navigation = () => {
  return (
    <div className="bg-gray-100 h-20 fixed bottom-0 z-40 left-0 right-0">
      <div className="fixed bottom-5 left-[10px] right-[10px] z-50 w-[96vw] sm:h-[90px] bg-gray-500 rounded-full">
        <MenuLinks />
      </div>
    </div>
  );
};

export default Navigation;

import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Auth} from 'aws-amplify'
import * as auth from '../../../../setup/redux/AuthRedux'
import Button from '../shared/elements/Button'
import { LogoutModal } from '../../pages/student-questionnaire/LogoutModal'

const SignOutButton: FC = () => {
  
  const [showLogout, setShowLogout] = useState<boolean>(false)
  const dispatch = useDispatch()
  const logout = () => {
    Auth.signOut()
    dispatch(auth.actions.logout())
    sessionStorage.clear()
  }

  return (
    <>
      <div className='d-flex flex-wrap px-5 pb-6 p-lg-0'>
        {/* begin::Sign out */}
        <Button size='large' fullWidth colour='outline' onClick={() => setShowLogout(true)}>
          Sign out
        </Button>
        {/* end::Sign out */}
      </div>
      <LogoutModal show={showLogout} onClose={() => setShowLogout(false)} onSubmit={logout} />
    </>
  )
}

export {SignOutButton}

import { Skeleton } from "@mui/material";

interface IProps {
    name: string
    label?: string
    options: Array<{ value: any, label: string, description?: string }>
    onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | number | boolean |undefined | Array<any>
    required: boolean
    disabled: boolean
    error?: boolean;
    errorMsg?: string;
    loading?: boolean;
    fullWidth?: boolean
}

export default function RadioInput({
    name,
    label,
    value,
    options = [],
    onClick,
    required = false,
    disabled = false,
    error = false,
    errorMsg = "This field is required",
    loading = false,
    fullWidth = false
}: IProps) {

    return (
        <>
            <div className='flex flex-col mt-1'>
                {loading ? <div className="flex items-center">
                    <Skeleton variant="circular" width={24} height={20} />
                    <Skeleton height={30} className="w-full ms-2" />
                </div>
                    : <>
                        <label className='block text-base font-bold leading-6 text-darkBlue mb-2'>
                            {label}
                            {required && <span className='text-base text-red-500'>*</span>}
                        </label>
                        <div className={options?.length > 3 && !fullWidth ? 'grid grid-cols-2 gap-x-3' : ''}>
                        {options?.map((option, index) => {
                            return <div key={index} className='mb-3'>
                                <label htmlFor={name + index} className='flex flex-col items-start text-base font-medium text-darkBlue'>
                                    <span className={`${option?.description ? 'font-semibold' : ''} flex items-start`}>
                                        <input
                                            className='checked:accent-[#163a5e] me-1 w-4 h-4 min-w-[16px] mt-[2px]'
                                            type='radio'
                                            name={name}
                                            id={name + index}
                                            value={option.value}
                                            checked={value === option.value}
                                            onChange={() => { }}
                                            onClick={() => onClick(option.value)}
                                            data-parsley-multiple={name}
                                            disabled={disabled}
                                            required={required}
                                        />
                                        {option.label}
                                    </span>
                                    {option?.description && <span className="ml-[20px] block">{option?.description}</span>}
                                </label>
                            </div>
                        })}
                        </div>
                        {error && (
                            <p className="text-base text-red-600" id="error">
                                {errorMsg}
                            </p>
                        )}
                    </>}
            </div>
        </>
    )
}
import {BaseResponse} from '../models/base-response'
import {Job} from '../models/payments/job'
import modifiedFetch from '../../../setup/fetch/ModifiedFetch'

const API_URL = process.env.REACT_APP_BASE_URL
const JOBS_URL = process.env.REACT_APP_JOB_BASE_URL
const BOOKING_URL = process.env.REACT_APP_BOOKING_BASE_URL

const GET_JOB_PAYMENT_INFO = `${JOBS_URL}/jobs/student-job/`
const GET_REDIRECT_URL = `${API_URL}/students/payment`
const VALIDATE_PAYMENT = `${API_URL}/students/validate-payment`
const GET_REQUEST_STATUS = `${API_URL}/students/request-status`
const BOOKING_API_KEY = process.env.REACT_APP_API_KEY

export async function validatePayment(amount: number): Promise<BaseResponse<boolean>> {
  const data = {amount}
  return await modifiedFetch({
    url: VALIDATE_PAYMENT,
    method: 'POST',
    data: data,
  })
}

export async function getRedirectUrl(
  amount: number,
  remaining_balance: number
): Promise<BaseResponse<string>> {
  const data = {amount, remaining_balance}
  return await modifiedFetch({
    url: GET_REDIRECT_URL,
    method: 'POST',
    data: data,
  })
}

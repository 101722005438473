import { FC } from 'react'
import Modal from '../../components/shared/overlays/Modal';


export type LogoutModalProps = {
    show: boolean,
    onClose: () => void,
    onSubmit: () => void
};

const LogoutModal: FC<LogoutModalProps> = ({show, onClose, onSubmit}) => {
   return(
        <Modal
        open={show}
        title="Sign out"
        description="Are you sure you want to sign out?"
        submitText='Yes'
        closeText='No'
        onClose={onClose}
        onSubmit={onSubmit}
    ></Modal>
   )
}


export { LogoutModal }

import React from 'react'
import {Link} from 'react-router-dom'

export interface EmptyStateProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  data: {
    icon?: string
    name: string
    linkURL: string
    isRedirectLink?: boolean
  }[]
}

export default function ListGroup({title, data, ...props}: EmptyStateProps) {
  return (
    <>
      <div className='w-full flex flex-col' {...props}>
        {title && <h2 className='text-2xl font-normalf font-bold mb-3'>{title}</h2>}

        {data?.map((item, i) => (
          <div
            key={i}
            className='inline-flex items-center py-3 text-sm font-medium '
          >
            {item.linkURL !== '' && !item?.isRedirectLink && (
              <Link
                to={`${item.linkURL}`}
                className='flex justify-start items-center w-full text-base'
              >
                {item.icon && <><i className={`${item.icon} text-2xl me-4 leading-none`}></i></>}
                <span className='font-bold text-lg'>{item.name}</span>
              </Link>
            )}
            {item.linkURL !== '' && item?.isRedirectLink && (
              <a
                href={`${item.linkURL}`}
                target='_blank'
                rel='noopener noreferrer'
                className='flex justify-between items-center w-full text-base'
              >
                {item.name}
              </a>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {TestStatusTypeEnum} from '../../models/enums/test-status-type'
import {ZendeskStages} from '../../models/enums/zendesk-stages-enum'
import {TestCenter} from '../../models/test-center/test-center'
import {getJobstage} from '../../services/job.service'
import {getTestCentresByTypeID} from '../../services/lookup.service'
import {getStudentsQuestionnaireFlags} from '../../services/student.service'
import {toAbsoluteUrl} from '../../helpers'
import {PendingAdiTestAlert} from '../shared/elements/PendingAdiTestAlert'
import AddTest from './_modals/AddTest'
import AddTestResult from './_modals/AddTestResult'
import TestCard from '../shared/cards/TestCard'
import moment from 'moment'
import Button from '../shared/elements/Button'
import {
  bookableTests,
  getTestRejectionReasons,
  getTestsByDeal,
  pendingTest,
} from '../../services/test.service'
import {TestTypeEnum} from '../../models/enums/test-type'
import {useCourse} from '../../providers/CourseProvider'
import {RejectionReason} from '../../models/tests/rejection-reason'
import {Test} from '../../models/tests/test'
import {StudentsQuestionnaireFlags} from '../../models/student/students-questionnaire-flags'
import {TestCentreTypeEnum} from '../../models/enums/test-centre-type'
import {checkPassprotectWithRedemptionRemaining} from '../../services/booking.service'
import {PassprotectWithRedemptionRemaining} from '../../models/student/pass-protect-with-redemption-remaining'
import {getPreferredTestCenters} from '../../services/booking.service'
import {rebookCourse} from '../../services/rebook.service'
import {getLeadTime} from '../../services/product.service'
import {Skeleton} from '@mui/material'
import {InfoListData} from '../shared/data/InfoList'
import {CheckCircleIcon, XCircleIcon, ClockIcon, ArrowPathIcon} from '@heroicons/react/24/outline'
import UsePassProtectModal from '../add-ons/_modals/UsePassProtectModal'
import AcceptTest from './_modals/AcceptTest'
import RejectTest from './_modals/RejectTest'
import {RescheduleTestModal} from './_modals/RescheduleTestModal'
import {RouteActions} from '../../models/enums/route-actions-enum'
import React from 'react'
import {BookNewCourseModal} from '../rebook-new-course/BookNewCourseModal'
import AdditionalHoursModal from './_modals/AdditionalHoursModal'
import ConfirmReschedulePracticalTestModal from './_modals/ConfirmReschedulePracticalTestModal'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/RootReducer'
import toast from 'react-hot-toast'

const ListView: FC = () => {
  const intl = useIntl()
  function useQuery() {
    const {search} = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const navigate = useNavigate()
  let query = useQuery()
  const [dealId, setDealId] = useState<number>()
  const [addedTestType, setAddedTestType] = useState<any>()
  const [theoryTestCenterList, setTheoryTestCenterList] = useState<Array<any>>([])
  const [practicalTestCenterList, setPracticalTestCenterList] = useState<Array<any>>([])
  const [studentTheoryTests, setStudentTheoryTests] = useState<Array<Test>>([])
  const [studentPracticalTests, setStudentPracticalTests] = useState<Array<Test>>([])
  const [theoryTestList, setTheoryTestList] = useState<Array<TestCenter>>([])
  const [practicalTestList, setPracticalTestList] = useState<Array<TestCenter>>([])
  const [testRejectionReason, setRejectionReason] = useState<RejectionReason[]>([])
  const [abilities, setAbilities] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [dealExpired, setDealExpired] = useState<boolean>(false)
  const [cancelledDeal, setCancelledDeal] = useState<boolean>(false)
  const [dealOnHold, setDealOnHold] = useState<boolean>(false)
  const [disableAction, setDisableAction] = useState<boolean>(false)
  const [testCenters, setTestCenters] = useState<Array<any>>([])
  const [theoryTestCenters, setTheoryTestCenters] = useState<Array<any>>([])
  const [isPto, setIsPto] = useState<boolean>(false)
  const [leadTime, setLeadTime] = useState<number | undefined>()
  const {Course} = useCourse()
  const [passprotectWithRedemptionRemaining, setPassprotectWithRedemptionRemaining] =
    useState<PassprotectWithRedemptionRemaining>()
  const [showTestModal, setShowTestModal] = useState(false)
  const [showAddTestResultModal, setShowAddTestResultModal] = useState(false)
  const [currentTest, setCurrentTest] = useState<Test>()
  const [showBookNewCourseModal, setShowBookNewCourseModal] = useState(false)
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showUsePassProtectModal, setShowUsePassProtectModal] = useState(false)
  const [showRescheduleTestModal, setShowRescheduleTestModal] = useState(false)
  const [showConfirmRescheduleTestModal, setShowConfirmRescheduleTestModal] = useState(false)
  const [showRebookModal, setShowRebookModal] = useState(false)
  const [showAdditionalHoursModal, setShowAdditionalHoursModal] = useState(false)
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await getRejectionReasonList()
      await getTestCenterByType(TestCentreTypeEnum.Practical)
      await getTestCenterByType(TestCentreTypeEnum.Theory)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (Course && Course.deal_id) {
        await getStudentTests()
        const jobStageRes = await getJobstage(Course.deal_id).catch((error) => {
          setLoading(false)
          if (!error?.errorMessage) toast.error('Failed to get student stage')
        })
        if (jobStageRes && jobStageRes.results) {
          setDisableAction(
            jobStageRes.results.stage_id == ZendeskStages.Cancelled ||
              jobStageRes.results.stage_id == ZendeskStages.Expired ||
              jobStageRes.results.stage_id == ZendeskStages.OnHold ||
              jobStageRes.results.stage_id == ZendeskStages.Completed
          )
          setCancelledDeal(jobStageRes.results.stage_id == ZendeskStages.Cancelled)
          setDealExpired(jobStageRes.results.stage_id == ZendeskStages.Expired)
          setDealOnHold(
            jobStageRes.results.stage_id == ZendeskStages.OnHold &&
              moment().diff(moment(jobStageRes.results.last_stage_change_at), 'days') >= 7
          )
        }

        const checkPassprotectRes = await checkPassprotectWithRedemptionRemaining(
          Course.deal_id
        ).catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get available passprotect ')
        })

        if (checkPassprotectRes) {
          setPassprotectWithRedemptionRemaining(checkPassprotectRes)
        }
        await getPreferredTestCentersNames()
        setLoading(false)
      }
    })()
  }, [Course.deal_id])

  useEffect(() => {
    if (Course.order?.id) {
      setIsPto(Course.order.is_pto)
    }
  }, [Course.order?.id, Course.order?.is_pto])

  useEffect(() => {
    ;(async () => {
      if (Course.pickupAddress?.postal_code && Course.order?.transmission) {
        const res = await getLeadTime(
          Course.pickupAddress.postal_code,
          Course.order.transmission
        ).catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get lead time')
        })
        if (!res) return
        setLeadTime(res.lead_time)
      }
    })()
  }, [Course.pickupAddress?.postal_code, Course.order?.transmission])

  useEffect(() => {
    if (!currentTest) return
    if (
      query.get('action')?.toLocaleLowerCase() === RouteActions.Reschedule.toLocaleLowerCase() &&
      !disableAction &&
      currentTest?.abilities?.can_reschedule
    ) {
      rescheduleTest(currentTest)
    } else if (
      query.get('action')?.toLocaleLowerCase() === RouteActions.Rebook.toLocaleLowerCase() &&
      !disableAction &&
      currentTest?.abilities?.can_rebook
    ) {
      openRebookTTModal()
    }
  }, [currentTest])

  async function getStudentTests() {
    var theoryTests: Test[] = []
    var practicalTests: Test[] = []
    setLoading(true)
    const testResponse = await getTestsByDeal(Course.deal_id).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get test')
      setLoading(false)
    })
    if (testResponse) {
      theoryTests = testResponse.filter((x) => x.type === TestTypeEnum.Theory)
      practicalTests = testResponse.filter((x) => x.type === TestTypeEnum.Practical)
      setStudentTheoryTests(theoryTests)
      setStudentPracticalTests(practicalTests)
    }

    const questionnaireFlagResponse = await getStudentsQuestionnaireFlags().catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get student questionnaire flags')
      setLoading(false)
    })
    const bookableResponse = await bookableTests(Course.deal_id).catch((error) => {
      if (!error?.errorMessage) toast.error('Something went wrong while getting test')
      setLoading(false)
    })
    const pendingTestResponse = await pendingTest(Course.deal_id).catch((error) => {
      setLoading(false)
      if (!error?.errorMessage) toast.error('Failed to check previous lessons')
    })

    if (questionnaireFlagResponse && bookableResponse && pendingTestResponse) {
      checkForTestAbility(
        practicalTests,
        theoryTests,
        questionnaireFlagResponse.results,
        bookableResponse,
        pendingTestResponse
      )
    }
    setLoading(false)
  }

  async function getPreferredTestCentersNames() {
    const preferredTestCentersResponse = await getPreferredTestCenters(Course.order?.id).catch(
      (error) => {
        if (!error?.errorMessage) toast.error('Failed to get preferred test centers ')
      }
    )
    if (!preferredTestCentersResponse) return

    let preferredPracticalTestCenters = preferredTestCentersResponse?.practical
    let preferredTheoryTestCenters = preferredTestCentersResponse?.theory

    if (preferredPracticalTestCenters) {
      const testCenterNames = preferredPracticalTestCenters
        .filter((item: any) => item.deleted_at === null)
        .map((item: any) => item.test_centre.test_center_name)
      setTestCenters(testCenterNames)
    }

    if (preferredTheoryTestCenters) {
      const theoryTestCenterNames = preferredTheoryTestCenters
        .filter((item: any) => item.deleted_at === null)
        .map((item: any) => item.test_centre.test_center_name)
      setTheoryTestCenters(theoryTestCenterNames)
    }
  }

  async function getRejectionReasonList() {
    const testRejectionReasons = await getTestRejectionReasons().catch((error) => {
      if (!error?.errorMessage) {
        toast.error('Failed to get rejection reason')
      }
      setLoading(false)
    })
    if (!testRejectionReasons) return
    setRejectionReason(testRejectionReasons)
  }

  async function getTestCenterByType(typeId: any) {
    const testCentresRes = await getTestCentresByTypeID(typeId)
    if (!testCentresRes) return

    if (typeId === TestCentreTypeEnum.Practical) {
      setPracticalTestList(testCentresRes.results)
      setPracticalTestCenterList(
        testCentresRes.results.map((item) => ({
          label: item.test_center_name,
          value: item.id,
        }))
      )
    } else {
      setTheoryTestList(testCentresRes.results)
      setTheoryTestCenterList(
        testCentresRes.results.map((item) => ({
          label: item.test_center_name,
          value: item.id,
        }))
      )
    }
  }

  function checkForTestAbility(
    practicalTests: Test[],
    theoryTests: Test[],
    questionnaireFlags: StudentsQuestionnaireFlags,
    bookableFlags: any,
    pendintTestFlags: any
  ) {
    let has_passed_theory_test = theoryTests.some((a) => a.status === TestStatusTypeEnum.Passed)
    let has_failed_expired_theory_test = theoryTests.some(
      (a) =>
        a.status === TestStatusTypeEnum.Failed ||
        (moment(a.booked_for).isBefore(moment()) && a.status === TestStatusTypeEnum.InitialBooking)
    )
    let has_failed_expired_practical_test = practicalTests.some(
      (a) =>
        moment(a.booked_for).isBefore(moment()) && a.status === TestStatusTypeEnum.InitialBooking
    )
    if (questionnaireFlags != null) {
      setAbilities({
        add_fast_tracked_TT: questionnaireFlags.has_fast_track_theory_test ?? false,
        has_passed_theory_test: has_passed_theory_test || questionnaireFlags.has_passed_theory_test,
        has_practical_test: questionnaireFlags.has_practical_test,
        has_theory_test: questionnaireFlags.has_theory_test,
        can_add_practical:
          bookableFlags.practical &&
          (has_passed_theory_test || questionnaireFlags.has_passed_theory_test) &&
          (!questionnaireFlags.has_practical_test || has_failed_expired_practical_test),
        can_add_theory:
          bookableFlags.theory &&
          !questionnaireFlags.has_fast_track_theory_test &&
          !questionnaireFlags.has_passed_theory_test &&
          !questionnaireFlags.has_theory_test_booked &&
          (!questionnaireFlags.has_theory_test || has_failed_expired_theory_test),
        has_pending_adi_test: pendintTestFlags.has_test_pending_adi_approval,
      })
    }
  }

  const mapStudentTestData = (test: Test) => {
    const mappedData: InfoListData[] = []
    if (test.location != null) {
      mappedData.push({
        value: test?.location.name ?? '--',
        icon: 'icon-location',
        linkURL: test?.location
          ? `https://maps.google.com/?q=${test?.location?.latitude},${test?.location?.longitude}`
          : undefined,
      })
    }
    if (test?.booked_for_human != null && test?.booked_for_human != '') {
      const testDateTime = (test?.booked_for_human ?? '').split(' ')
      const testDate = testDateTime[0] ?? '--'
      const testTime = testDateTime[1] ?? '--'
      mappedData.push(
        {
          value: testDate,
          icon: 'icon-calendar',
        },
        {
          value: testTime,
          icon: 'icon-history',
        }
      )
    }
    return mappedData
  }

  const mapStudentTestButtons = (test: Test) => {
    const buttons: any[] = []
    if (!disableAction) {
      if (test?.abilities.can_accept) {
        buttons.push({
          text: intl.formatMessage({id: 'ACTIONS.ACCEPT'}),
          onClick: () => {
            setCurrentTest(test)
            setShowAcceptModal(true)
          },
          color: 'pink',
          disabled: false,
        })
      }
      if (
        (test?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
          test?.status?.toLowerCase() === TestStatusTypeEnum.PendingResult) &&
        (test?.abilities.can_pass || test?.abilities.can_fail)
      ) {
        buttons.push({
          text: intl.formatMessage({id: 'ACTIONS.ADDTESTRESULT'}),
          onClick: () => {
            setCurrentTest(test)
            openTestResultModal()
          },
          color: 'pink',
          disabled: false,
        })
      }

      if (test?.abilities.can_reschedule) {
        buttons.push({
          text: intl.formatMessage({id: 'ACTIONS.RESCHEDULE'}),
          onClick: () => {
            setCurrentTest(test)
            rescheduleTest(test)
          },
          color: 'pink',
          disabled: false,
        })
      }

      if (test?.abilities.can_rebook) {
        buttons.push({
          text: intl.formatMessage({id: 'ACTIONS.REBOOK'}),
          onClick: () => {
            setCurrentTest(test)
            openRebookTTModal()
          },
          color: 'pink',
          disabled: false,
        })
      }

      if (test?.abilities.can_reject) {
        buttons.push({
          text: intl.formatMessage({id: 'ACTIONS.REJECT'}),
          onClick: () => {
            setCurrentTest(test)
            setShowRejectModal(true)
          },
          color: 'outline',
          disabled: false,
        })
      }
    }
    if (
      test?.type === TestTypeEnum.Practical &&
      test?.status?.toLowerCase() === TestStatusTypeEnum.Failed &&
      (!Course.last_active_deal || Course.deal_id == Course.last_active_deal)
    ) {
      if (
        passprotectWithRedemptionRemaining?.has_pass_protect &&
        passprotectWithRedemptionRemaining.number_of_pass_protect_redemption_remaining > 0
      ) {
        buttons.push({
          text: intl.formatMessage(
            {id: 'ACTIONS.USE_PASS_PROTECT'},
            {count: passprotectWithRedemptionRemaining.number_of_pass_protect_redemption_remaining}
          ),
          onClick: () => {
            setCurrentTest(test)
            setShowUsePassProtectModal(true)
          },
          color: 'pink',
          disabled: false,
        })
      } else {
        buttons.push({
          text: intl.formatMessage({id: 'ACTIONS.REBOOK_COURSE'}),
          onClick: () => {
            setCurrentTest(test)
            openNewCourseModal()
          },
          color: 'pink',
          disabled: false,
        })
      }
    }
    return buttons
  }

  const mapStudentTestActions = (test: any) => {
    var action
    if (disableAction) return
    if (test?.abilities.can_change) {
      action = {
        text: 'Edit Test',
        onClick: () => {
          setCurrentTest(test)
          openTestModel()
        },
      }
    } else if (
      (test?.status?.toLowerCase() === TestStatusTypeEnum.Passed ||
        test?.status?.toLowerCase() === TestStatusTypeEnum.Failed) &&
      (test?.abilities.can_pass || test?.abilities.can_fail)
    ) {
      action = {
        text: 'Edit Result',
        onClick: () => {
          setCurrentTest(test)
          openTestResultModal()
        },
      }
    }
    return action
  }

  const mapStudentTestSubtitles = (test: any) => {
    const status = test?.status
      ?.toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
    var subTitle: any
    if (
      test?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
      test?.status?.toLowerCase() === TestStatusTypeEnum.Passed
    ) {
      subTitle = {
        text: status,
        color: 'green',
        icon: <CheckCircleIcon className='h-5 w-5 text-green ms-1' />,
      }
    } else if (
      test?.status?.toLowerCase() === TestStatusTypeEnum.Failed ||
      test?.status?.toLowerCase() === TestStatusTypeEnum.Rejected ||
      test?.status?.toLowerCase() === TestStatusTypeEnum.NotApplicable ||
      test?.status?.toLowerCase() === TestStatusTypeEnum.Cancelled
    ) {
      subTitle = {
        text: status,
        color: 'red',
        icon: <XCircleIcon className='h-5 w-5 text-red-600 ms-1' />,
      }
    } else if (
      test?.status?.toLowerCase() === TestStatusTypeEnum.Reschedule ||
      test?.status?.toLowerCase() === TestStatusTypeEnum.Rebooked
    ) {
      subTitle = {
        text: status,
        color: 'yellow',
        icon: <ArrowPathIcon className='h-5 w-5 text-yellow ms-1' />,
      }
    } else if (
      test?.status?.toLowerCase() === TestStatusTypeEnum.PendingAdiApproval ||
      test?.status?.toLowerCase() === TestStatusTypeEnum.PendingReschedule ||
      test?.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking ||
      test?.status?.toLowerCase() === TestStatusTypeEnum.PendingResult
    ) {
      subTitle = {
        text: status,
        color: 'blue',
        icon: <ClockIcon className='h-5 w-5 text-yellow ms-1' />,
      }
    }
    return subTitle
  }

  const openTestModel = () => {
    setShowTestModal(true)
  }

  const openTestResultModal = () => {
    setShowAddTestResultModal(true)
  }

  const openRebookTTModal = () => {
    navigate({search: 'action=' + RouteActions.Rebook})
    setShowRebookModal(true)
    setShowRescheduleTestModal(true)
  }

  const rescheduleTest = (test: Test) => {
    navigate({search: 'action=' + RouteActions.Reschedule})
    setShowRebookModal(false)
    if (test?.type === TestTypeEnum.Practical) {
      setShowConfirmRescheduleTestModal(true)
    } else {
      setShowRescheduleTestModal(true)
    }
  }

  const confirmReschedule = (confirmed: boolean) => {
    setShowConfirmRescheduleTestModal(false)
    if (confirmed) {
      setShowAdditionalHoursModal(true)
    } else {
      setShowRescheduleTestModal(true)
    }
  }

  const openNewCourseModal = () => {
    setShowBookNewCourseModal(true)
  }

  var add_test = (testTypeName: string) => {
    return !loading ? (
      <div className='flex flex-col items-center justify-center bg-white p-6 rounded-md text-center  '>
        <img src={toAbsoluteUrl('/assets/media/svg/no-test-exist.svg')} className='' alt=''></img>
        <h3 className='text-lg font-semibold text-center mb-0'>
          {testTypeName == 'practical'
            ? intl.formatMessage({id: 'STUDENT_TEST.BOOKTESTYOURSELF'}, {type: 'practical'})
            : intl.formatMessage(
                {id: 'STUDENT_TEST.TEST_DETAILS_HAVE_BEEN_BOOKED'},
                {type: 'theory'}
              )}
        </h3>
        <Button
          id='add_test_btn'
          disabled={disableAction}
          fitWidth={true}
          type='button'
          fullWidth
          size='large'
          className='pt-2'
          onClick={() => {
            setShowTestModal(true)
            setTheoryTestCenterList(
              theoryTestList.map((item) => ({
                label: item.test_center_name,
                value: item.id,
              }))
            )
            setPracticalTestCenterList(
              practicalTestList.map((item) => ({
                label: item.test_center_name,
                value: item.id,
              }))
            )

            setAddedTestType(testTypeName)
            setDealId(Course.deal_id)
          }}
        >
          {intl.formatMessage({id: 'STUDENT_TEST.ADDYOURTEST'})}
        </Button>
      </div>
    ) : (
      <></>
    )
  }
  if (dealOnHold) {
    return !loading ? (
      <>
        <div className='flex flex-col items-center bg-white pb-2 mt-0 pt-10 pb-12'>
          <img
            src={toAbsoluteUrl('/assets/media/svg/on-hold.svg')}
            className='mb-3 w-90px'
            alt='no provisional licence'
          />
          <h3 className='font-size-16 fw-600 text-center mb-0'>
            {intl.formatMessage({id: 'DASHBOARD.COURSE_ON_HOLD'})}
          </h3>
          <p className='gray-text fw-bolder text'>
            {intl.formatMessage({id: 'DASHBOARD.COURSE_ON_HOLD_DESC'})}
          </p>
        </div>
      </>
    ) : (
      <></>
    )
  } else if (dealExpired) {
    return !loading ? (
      <>
        <div className='flex flex-col items-center justify-center empty-placeholder rounded-md text-center'>
          <img
            src={toAbsoluteUrl('/assets/media/svg/expired.svg')}
            className='mb-3 w-90'
            alt='no provisional licence'
          />
          <h3 className='text-lg font-semibold text-center mb-0'>
            {intl.formatMessage({id: 'DASHBOARD.DEAL_EXPIRED'})}
          </h3>
        </div>
      </>
    ) : (
      <></>
    )
  } else if (cancelledDeal) {
    return !loading ? (
      <>
        <div className='flex flex-col align-items-center bg-white pb-2 mt-0 pt-10 pb-12'>
          <div>
            <img
              src={toAbsoluteUrl('/assets/media/svg/course-canceled.svg')}
              className='mb-3 w-90px'
              alt='cancelled deal'
            ></img>
          </div>
          <p className='text-xl font-bold w-1/2 text-center'>
            {intl.formatMessage({id: 'DASHBOARD.CANCELLED_DEAL'})}
          </p>
        </div>
      </>
    ) : (
      <></>
    )
  } else {
    return (
      <>
        <AddTest
          onTestSubmit={() => {
            setCurrentTest(undefined)
            getStudentTests()
          }}
          theoryTestCenters={theoryTestCenterList}
          practicalTestCenters={practicalTestCenterList}
          initialTestType={currentTest?.type ?? addedTestType ?? 'theory'}
          show={showTestModal}
          booking_id={currentTest?.deal_id ?? dealId ?? 0}
          onHide={() => {
            setCurrentTest(undefined)
            setShowTestModal(false)
          }}
          saveTest={true}
          booked_for={currentTest?.booked_for}
          testCenterId={currentTest?.location?.test_centre_id}
          test_location={currentTest?.location?.name}
          id={currentTest?.id}
          status={currentTest?.status}
          hidePassed={false}
          title={
            currentTest?.type === TestTypeEnum.Practical || addedTestType === TestTypeEnum.Practical
              ? intl.formatMessage(
                  {id: 'STUDENT_TEST.TEST_DETAILS_HAVE_BEEN_BOOKED'},
                  {type: 'practical'}
                )
              : intl.formatMessage(
                  {id: 'STUDENT_TEST.TEST_DETAILS_HAVE_BEEN_BOOKED'},
                  {type: 'theory'}
                )
          }
          isPto={isPto}
          leadTime={leadTime}
        />

        <UsePassProtectModal
          handleClose={() => {
            setShowUsePassProtectModal(false)
            setCurrentTest(undefined)
          }}
          show={showUsePassProtectModal}
          handleSubmit={() => {}}
          passProtectOption={passprotectWithRedemptionRemaining}
        />

        <AddTestResult
          test={currentTest}
          show={showAddTestResultModal}
          onHide={() => {
            setShowAddTestResultModal(false)
            setCurrentTest(undefined)
          }}
          setShowTestModal={setShowBookNewCourseModal}
          onStatusSubmit={() => {
            getStudentTests()
            setCurrentTest(undefined)
          }}
          passProtectOption={passprotectWithRedemptionRemaining}
        />

        <AcceptTest
          test={currentTest}
          show={showAcceptModal}
          onHide={() => {
            setShowAcceptModal(false)
            setCurrentTest(undefined)
          }}
          onStatusSubmit={() => {
            getStudentTests()
            setCurrentTest(undefined)
          }}
        />

        <RejectTest
          test={currentTest}
          rejectionReasons={testRejectionReason.filter((x) => x.type === currentTest?.type) ?? []}
          show={showRejectModal}
          onHide={() => {
            setShowRejectModal(false)
            setCurrentTest(undefined)
          }}
          onStatusSubmit={() => {
            getStudentTests()
            setCurrentTest(undefined)
          }}
        />

        <RescheduleTestModal
          show={showRescheduleTestModal}
          test={currentTest}
          onHide={() => {
            setShowRescheduleTestModal(false)
            setCurrentTest(undefined)
          }}
          isRebook={showRebookModal}
        />

        <BookNewCourseModal
          handleClose={() => {
            setShowBookNewCourseModal(false)
            setCurrentTest(undefined)
          }}
          show={showBookNewCourseModal}
          handleSubmit={() => {
            rebookCourse(currentTest?.deal_id, isMobile)
            setCurrentTest(undefined)
          }}
        />

        <ConfirmReschedulePracticalTestModal
          show={showConfirmRescheduleTestModal}
          hide={() => {
            setShowConfirmRescheduleTestModal(false)
            setCurrentTest(undefined)
          }}
          yesSelected={() => {
            confirmReschedule(true)
          }}
          noSelected={() => {
            confirmReschedule(false)
          }}
        />

        <AdditionalHoursModal
          show={showAdditionalHoursModal}
          hide={() => {
            setShowAdditionalHoursModal(false)
            setCurrentTest(undefined)
          }}
          test={currentTest}
        />

        {
          <div className='mb-5'>
            <div className='row justify-between mx-0'>
              <p className='text-lg font-bold px-4 my-3'>
                {loading ? (
                  <Skeleton width={150} />
                ) : (
                  intl.formatMessage({id: 'STUDENT_TEST.THEORY_TESTS'})
                )}{' '}
              </p>
              <div className='col-12 px-4 px-md-8 py-3 py-md-6'>
                {loading ? (
                  <TestCard loading={loading}></TestCard>
                ) : studentTheoryTests.length > 0 ? (
                  <div>
                    {studentTheoryTests?.map((test) => (
                      <div className='mb-3' key={test.id}>
                        <TestCard
                          editAction={mapStudentTestActions(test)}
                          buttons={mapStudentTestButtons(test)}
                          showAction={true}
                          actions={{options: mapStudentTestActions(test), disabled: false}}
                          data={mapStudentTestData(test)}
                          loading={loading}
                          subTitle={mapStudentTestSubtitles(test)}
                        ></TestCard>
                      </div>
                    ))}
                  </div>
                ) : (abilities.has_theory_test || abilities.add_fast_tracked_TT == 1) &&
                  theoryTestCenters.length > 0 ? (
                  <>
                    {/* Start:: Empty test placeholder */}
                    <div className='flex flex-col items-start bg-white rounded-lg shadow mb-3 h-full px-4 md:px-8 py-5 md:py-6'>
                      <p className='text-base font-semibold text-gray-650 mb-3'>
                        {intl.formatMessage({id: 'DASHBOARD.SEARCHING_FOR_TT'})}
                      </p>
                      <ul className='text-base font-semibold text-gray-650 list-disc mb-3'>
                        {theoryTestCenters.map((testCenter, i) => {
                          return (
                            <li className='mb-1 text-base' key={i}>
                              <p>{testCenter}</p>
                            </li>
                          )
                        })}
                      </ul>
                      <p className='text-base font-semibold text-gray-650'>
                        {intl.formatMessage({id: 'DASHBOARD.TEST_CENTER_MESSAGE'})}
                      </p>
                    </div>
                  </>
                ) : abilities.add_fast_tracked_TT == 0 ? (
                  <>
                    <div
                      className='flex flex-col bg-white rounded-lg shadow mb-3 h-full px-4 md:px-8 py-5 md:py-6'
                      onClick={() => {
                        if (cancelledDeal || disableAction) {
                          return
                        }
                        setDealId(Course.deal_id)
                        setShowTestModal(true)
                        setAddedTestType(TestTypeEnum.Theory)
                      }}
                    >
                      <p className='text-base font-semibold text-gray-650 mb-3'>
                        {intl.formatMessage({id: 'DASHBOARD.BOOK_OWN_TEST'})}
                      </p>
                      <Button colour='pink' disabled={disableAction} size='large' fitWidth={true}>
                        Provide Test Details
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex flex-col items-start bg-white rounded-lg shadow mb-3 h-full px-4 md:px-8 py-5 md:py-6'>
                      <div className='flex flex-col items-start'>
                        <p className='text-base font-semibold text-gray-650 mb-3'>
                          {intl.formatMessage({id: 'STUDENT_TEST.THEREISNOTESTYET'})}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {/* End:: Empty test placeholder */}
              </div>
            </div>
          </div>
        }
        <div className='mb-5'>
          <div className='row justify-between mx-0'>
            <p className='text-lg font-bold px-4 my-3'>
              {loading ? (
                <Skeleton width={150} />
              ) : (
                intl.formatMessage({id: 'STUDENT_TEST.PRACTICAL_TESTS'})
              )}{' '}
            </p>
            <div className='col-12 px-4 px-md-8 py-3 py-md-6'>
              {abilities?.has_pending_adi_test && <PendingAdiTestAlert />}
              {loading ? (
                <TestCard loading={loading}></TestCard>
              ) : studentPracticalTests.length > 0 ? (
                <div>
                  {studentPracticalTests?.map((test) => (
                    <div className='mb-3' key={test.id}>
                      <TestCard
                        editAction={mapStudentTestActions(test)}
                        buttons={mapStudentTestButtons(test)}
                        showAction={true}
                        actions={{options: mapStudentTestActions(test), disabled: false}}
                        data={mapStudentTestData(test)}
                        loading={loading}
                        subTitle={mapStudentTestSubtitles(test)}
                      ></TestCard>
                    </div>
                  ))}
                </div>
              ) : abilities.has_practical_test ? (
                abilities.has_passed_theory_test ? (
                  <>
                    {/* Start:: Empty test placeholder */}
                    <div className='col-12'>
                      <div className='flex flex-col items-start bg-white rounded-lg shadow mb-3 h-full px-4 md:px-8 py-5 md:py-6'>
                        <p className='text-base font-normal text-gray-650'>
                          {intl.formatMessage({id: 'DASHBOARD.SEARCHING_FOR_PT'})}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col-12'>
                      <div className='flex flex-col items-center justify-center bg-white p-6 rounded-md text-center'>
                        <img
                          src={toAbsoluteUrl('/assets/media/svg/doc-info.svg')}
                          className='mb-3'
                          alt={intl.formatMessage({id: 'STUDENT_TEST.YOU_NEED_TO_PASS'})}
                        ></img>
                        <h3 className='text-lg font-semibold mb-0'>
                          {intl.formatMessage({id: 'STUDENT_TEST.PASS_YOUR_THEORY_TEST_FIRST'})}
                        </h3>
                        <p className='text-gray-700 font-bold'>
                          {intl.formatMessage({id: 'STUDENT_TEST.YOU_NEED_TO_PASS'})}
                        </p>
                      </div>
                    </div>
                  </>
                )
              ) : abilities.has_passed_theory_test ? (
                <>
                  <div className='col-12'>
                    <div className='flex flex-col items-start bg-white rounded-lg shadow mb-3 h-full px-4 md:px-8 py-5 md:py-6'>
                      <p className='text-base font-semibold text-gray-650'>
                        {intl.formatMessage({id: 'STUDENT_TEST.THEREISNOTESTYET'})}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='col-12'>
                    <div className='flex flex-col items-center justify-center bg-white p-6 rounded-md text-center'>
                      <img
                        src={toAbsoluteUrl('/assets/media/svg/doc-info.svg')}
                        className='mb-3'
                        alt={intl.formatMessage({id: 'STUDENT_TEST.YOU_NEED_TO_PASS'})}
                      ></img>
                      <h3 className='font-size-16 fw-600 text-center mb-0'>
                        {intl.formatMessage({id: 'STUDENT_TEST.PASS_YOUR_THEORY_TEST_FIRST'})}
                      </h3>
                      <p className='gray-text fw-bolder text'>
                        {intl.formatMessage({id: 'STUDENT_TEST.YOU_NEED_TO_PASS'})}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {loading ||
        (studentPracticalTests.length == 0 &&
          abilities.has_practical_test &&
          abilities.has_passed_theory_test) ? (
          <div className='mb-5'>
            <div className='row justify-between mx-4'>
              <p className='text-lg font-bold px-0 my-6'>
                {loading ? (
                  <Skeleton width={150} />
                ) : (
                  intl.formatMessage({id: 'STUDENT_TEST.PRACTICAL_TEST_CENTERS'})
                )}
              </p>
              <div className='col-span-12 px-4 md:px-8 py-3 md:py-6 bg-white rounded-lg shadow mb-3 h-full'>
                <>
                  {/**Preferred test center */}
                  <div className=''>
                    <>
                      <p className='text-base font-normal mb-2'>
                        {loading ? (
                          <Skeleton width={150} />
                        ) : (
                          <>{intl.formatMessage({id: 'STUDENT_TEST.TEST_CENTERS_MESSAGE'})}:</>
                        )}
                      </p>
                      <ul className='text-sm font-semibold text-gray-650 list-disc'>
                        {loading ? (
                          <Skeleton width={50} />
                        ) : (
                          testCenters.map((testCenter, i) => {
                            return (
                              <li className='mb-1 text-sm' key={i}>
                                <b>{testCenter}</b>
                              </li>
                            )
                          })
                        )}
                      </ul>
                    </>
                    <p className='text-base font-normal mb-2'>
                      {loading ? (
                        <Skeleton width={150} />
                      ) : (
                        intl.formatMessage({id: 'STUDENT_TEST.TEST_CENTER_MESSAGE_2'})
                      )}
                    </p>
                  </div>
                </>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <>
          {!disableAction &&
          !cancelledDeal &&
          (abilities.can_add_practical || abilities.can_add_theory) ? (
            <div className='px-4 md:px-8'>
              {abilities.can_add_theory
                ? add_test(TestTypeEnum.Theory)
                : add_test(TestTypeEnum.Practical)}
            </div>
          ) : (
            <></>
          )}
        </>
      </>
    )
  }
}

export default ListView

import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import Modal from '../../shared/overlays/Modal'

type Props = {
  onHide: any
  submitForDelete: any
  show: boolean
}

const DeleteStudentAccountModal: FC<Props> = ({submitForDelete, onHide, show}) => {
  const intl = useIntl()

  return (
    <Modal
      open={show}
      onClose={onHide}
      onSubmit={submitForDelete}
      submitText={intl.formatMessage({id: 'ACTIONS.SEND_REQUEST'})}
    >
      <div className='flex flex-col items-center text-center justify-center'>
        <img loading="lazy" className='w-1/4 h-auto mb-4' src={toAbsoluteUrl('/assets/media/svg/delete.svg')} alt='' />
        <h3 className='fs-2 mb-0 mx-4'>
          {intl.formatMessage({id: 'ACCOUNT_INFO.DELETE_ACCOUNT_MESSAGE'})}
        </h3>
      </div>
    </Modal>
  )
}

export {DeleteStudentAccountModal}

import {isCourseEligibleForRebooking} from './booking.service'

export const rebookCourse = async (dealId: any, isMobile: boolean) => {
  try {
    const response = await isCourseEligibleForRebooking(dealId)
    const cart_uuid = response.cart_uuid
    const booking_url = `${process.env.REACT_APP_BOOKINGS_WEBSITE}/rebooking/${cart_uuid}`
    window.open(booking_url, isMobile ? '_blanck' : '_self')
  } catch {}
}

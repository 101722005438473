import {Skeleton} from '@mui/material'
import {FC, useEffect, useState} from 'react'
import moment from 'moment'
import {LessonStatusEnum} from '../../../models/enums/lesson-status-enum'
import Button from '../elements/Button'
import {useIntl} from 'react-intl'
import {Lesson} from '../../../models/lessons/lesson'
import InfoList from '../data/InfoList'
import {convertToTimeFormat} from '../../../helpers/DateHelper'

type IProps = {
  loading: boolean
  lesson: Lesson
  disableAction: boolean
  setSelectedStatus: any
  setSelectedLesson: any
  setShowUpdateLessonStatus: any
  setShowDisputeModal: any
  setShowCancelModal: any
}

const LessonCard: FC<IProps> = ({
  loading,
  lesson,
  disableAction,
  setSelectedStatus,
  setSelectedLesson,
  setShowUpdateLessonStatus,
  setShowDisputeModal,
  setShowCancelModal,
}) => {
  const intl = useIntl()
  const [day, setDay] = useState('')
  const [date, setDate] = useState('')
  const [month, seMonth] = useState('')
  const [year, seYear] = useState('')
  const [hoursAndMinutes, setHoursAndMinutes] = useState('')
  const [status, setStatus] = useState({
    color: '',
    title: '',
  })

  const getStatusColor = (statusId: any): 'darkGreen' | 'yellow' | 'darkRed' => {
    switch (statusId) {
      case LessonStatusEnum.Confirmed:
        return 'darkGreen'
      case LessonStatusEnum.Pending:
        return 'yellow'
      default:
        return 'darkRed'
    }
  }

  useEffect(() => {
    if (!lesson?.lesson_date) {
      return
    }
    const lessonDate = moment(lesson.lesson_date)
    setDay(lessonDate.format('ddd'))
    setDate(lessonDate.format('DD'))
    seMonth(lessonDate.format('MMM'))
    seYear(lessonDate.format('YYYY'))
    setHoursAndMinutes(lessonDate.format('HH:mm'))
    setStatus({
      title: lesson.status ?? '',
      color: getStatusColor(lesson.status_id),
    })
  }, [lesson])

  const updateLesson = (status: number) => {
    setSelectedLesson(lesson)
    setSelectedStatus(status)
    setShowUpdateLessonStatus(true)
  }

  return (
    <>
      {loading ? (
        <div className='flex justify-center'>
          <Skeleton width={80} />
        </div>
      ) : (
        <div
          className={
            `rounded shadow border-l-8 border-l-blue bg-white px-4 py-5 sm:px-6 sm:ps-4 h-full ` +
            (lesson?.rejected_at ? 'bg-pmfGray-50' : '')
          }
        >
          <div className='flex'>
            <div className='flex flex-col items-center justify-center h-[90px] w-[90px] bg-[#E9FCFF] rounded-full px-4 sm:px-6 py-2 '>
              <p className='text-xs font-bold'>{day}</p>
              <h2 className='leading-none text-3xl font-bold'>{date}</h2>
              <p className='text-xs font-bold'>
                {month}
                <span className='ms-1'>{year}</span>
              </p>
            </div>

            <div className=' px-4 sm:px-6'>
              <div className='flex items-center gap-x-1.5'>
                <div
                  className={`
                        ${
                          {
                            darkGreen: 'bg-green',
                            yellow: 'bg-yellow',
                            darkRed: 'bg-danger',
                          }[status?.color]
                        } 
                        h-1.5 w-1.5 rounded-full`}
                />
                <p className='text-base font-bold'>{status?.title}</p>
              </div>
              <InfoList
                data={[
                  {
                    icon: 'icon-instructor',
                    value: lesson.adi_name ?? '-',
                  },
                  {
                    icon: 'icon-history',
                    value: hoursAndMinutes ?? '-',
                  },
                  {
                    icon: 'icon-clock',
                    value:
                      convertToTimeFormat(parseFloat(lesson?.duration.toString() ?? '') * 60) ??
                      '-',
                  },
                ]}
              />
            </div>
          </div>

          {!disableAction && (
            <div className='flex space-x-2 items-center justify-center'>
              {lesson?.abilities?.can_confirm === true && (
                <Button
                  className='mt-5'
                  colour='pink'
                  fullWidth
                  onClick={() => updateLesson(LessonStatusEnum.Confirmed)}
                >
                  <span className='text-sm font-semibold'>
                    {intl.formatMessage({id: 'ACTIONS.ACCEPT'})}
                  </span>
                </Button>
              )}
              {lesson?.abilities?.can_reject === true && (
                <Button
                  className='mt-5'
                  colour='outline'
                  fullWidth
                  onClick={() => updateLesson(LessonStatusEnum.Rejected)}
                >
                  <span className='text-sm font-semibold'>
                    {intl.formatMessage({id: 'ACTIONS.REJECT'})}
                  </span>
                </Button>
              )}
              {lesson?.abilities?.can_cancel === true && (
                <Button
                  className='mt-5'
                  colour='outline'
                  fullWidth
                  onClick={() => {
                    setShowCancelModal(true)
                    setSelectedLesson(lesson)
                  }}
                >
                  <span className='text-sm font-semibold'>
                    {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
                  </span>
                </Button>
              )}
              {lesson?.abilities?.can_dispute === true && (
                <Button
                  className='mt-5'
                  colour='outline'
                  fullWidth
                  onClick={() => {
                    setShowDisputeModal(true)
                    setSelectedLesson(lesson)
                  }}
                >
                  <span className='text-sm font-semibold'>
                    {intl.formatMessage({id: 'ACTIONS.DISPUTE'})}
                  </span>
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default LessonCard

import React, {FC, useEffect, useState} from 'react'

import Dropdown from '../../shared/forms/Dropdown'
import Button from '../../shared/elements/Button'
import {PlusSmallIcon, TrashIcon} from '@heroicons/react/24/outline'
import CheckboxInput from '../../shared/forms/CheckboxInput'
import Skeleton from '@mui/material/Skeleton'
import {DayModel} from '../../../models/student/day-model'
import {AvailabilityModel} from '../../../models/student/availability-modal'
import {CustomDropdown} from '../../shared/forms/CustomDropdown'
import {getTimes} from '../../../services/date.service'
import {DaysEnum} from '../../../models/enums/days-enum'
import {TimeEnum} from '../../../models/enums/time-enum'
import {PlusCircleIcon, MinusCircleIcon} from '@heroicons/react/24/outline'

import moment from 'moment'

interface IProps {
  weekDays?: Array<DayModel>
  availabilityList: Array<AvailabilityModel>
  formik?: any
  submitted?: boolean
  overLap: Array<boolean>
  loading?: boolean
}
const Availability: FC<IProps> = ({
  weekDays,
  availabilityList,
  formik,
  submitted,
  overLap,
  loading = false,
}) => {
  const [selectedDays, setSelectedDays] = useState<Array<number>>([])
  const [autoFillOptions, setAutoFillOptions] = useState([
    {
      label: 'Add weekdays',
      isActive: false,
      selected_days: [
        DaysEnum.Monday,
        DaysEnum.Tuesday,
        DaysEnum.Wednesday,
        DaysEnum.Thursday,
        DaysEnum.Friday,
      ],
      time_from: TimeEnum.StartDayTime,
      time_to: TimeEnum.EndDayTime,
    },
    {
      label: 'Add evenings',
      isActive: false,
      selected_days: [
        DaysEnum.Monday,
        DaysEnum.Tuesday,
        DaysEnum.Wednesday,
        DaysEnum.Thursday,
        DaysEnum.Friday,
      ],
      time_from: TimeEnum.StartEveningTime,
      time_to: TimeEnum.EndEveningTime,
    },
    {
      label: 'Add weekends',
      isActive: false,
      selected_days: [DaysEnum.Saturday, DaysEnum.Sunday],
      time_from: TimeEnum.StartDayTime,
      time_to: TimeEnum.EndDayTime,
    },
  ])

  function onDayChanged(e: React.ChangeEvent<HTMLInputElement>, dayId: number): void {
    let list = formik.values.availability
    if (!e.target.checked) {
      let dataWithoutRemoved = list.filter(function (a: AvailabilityModel) {
        return a.day_id != dayId
      })
      formik.setFieldValue('availability', dataWithoutRemoved)
    } else {
      addAvailabilitySlot(dayId, null, null)
    }
  }

  const addAvailabilitySlot = (dayId: number, fromTime: any, toTime: any) => {
    let tempSlot = formik.values.availability
    let merged = tempSlot.filter(function (a: AvailabilityModel) {
      return (
        a.day_id == dayId &&
        (moment(fromTime, 'HH:mm').format('HH:mm') == moment(a.to_time, 'HH:mm').format('HH:mm') ||
          moment(toTime, 'HH:mm').format('HH:mm') == moment(a.from_time, 'HH:mm').format('HH:mm'))
      )
    })
    // check if the end of exist slot equal start of added
    // start of exist slot equal end of added one merge the 2 in one
    if (merged.length > 0) {
      if (
        moment(merged[0].to_time, 'HH:mm').format('HH:mm') ==
        moment(fromTime, 'HH:mm').format('HH:mm')
      ) {
        merged[0].to_time = toTime
      } else {
        merged[0].from_time = fromTime
      }
    } else {
      let newSlot = new AvailabilityModel()
      newSlot.day_id = dayId
      newSlot.from_time = fromTime
      newSlot.to_time = toTime
      tempSlot.push(newSlot)
    }

    formik.setFieldValue('availability', tempSlot)
  }

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

  const onFromTimeChanged = (e: any, slot: AvailabilityModel) => {
    if (formik.isSubmitting) {
      return
    }
    if (e.value != null) {
      let availabilityData = formik.values.availability
      let updatedSlotIndex = availabilityData.indexOf(slot)
      availabilityData[updatedSlotIndex].from_time = e.value
      formik.setFieldValue('availability', availabilityData)
    }
    checkActiveOption()

  }

  const onToTimeChanged = (e: any, slot: AvailabilityModel) => {
    if (formik.isSubmitting) {
      return
    }
    if (e.value != null) {
      let availabilityData = formik.values.availability
      let updatedSlotIndex = availabilityData.indexOf(slot)
      availabilityData[updatedSlotIndex].to_time = e.value
      formik.setFieldValue('availability', availabilityData)
    }
    checkActiveOption()
  }
  const exsitAvailabilitySlotsCheck = (days: number[], fromTime: string, toTime: string) => {
    return formik.values.availability.filter(
      (slot: any) =>
        days.includes(slot.day_id) &&
        moment(slot.from_time, 'HH:mm').isSame(moment(fromTime, 'HH:mm')) &&
        moment(slot.to_time, 'HH:mm').isSame(moment(toTime, 'HH:mm'))
    )
  }

  const removeAvailabilitySlot = (slot: AvailabilityModel) => {
    if (formik.isSubmitting) {
      return
    }
    const temp = formik.values.availability
    let deletedIndex = temp.indexOf(slot)

    temp.splice(deletedIndex, 1)
    formik.setFieldValue('availability', temp)
    checkActiveOption()
  }

  const copy = (dayId: number) => {
    handleCopy(selectedDays, dayId)
    setSelectedDays([])
  }

  useEffect(() => {
    checkActiveOption()
  }, [formik.values.availability])


  const checkActiveOption = () => {
    autoFillOptions.forEach((option) => {
      let autoFilledDays = exsitAvailabilitySlots(
        option.selected_days,
        option.time_from,
        option.time_to
      )
      option.isActive = autoFilledDays.length === option.selected_days.length
    })

    setAutoFillOptions(autoFillOptions)
  }
  const handleSelectedOption = (option: any) => {
    if (option.isActive) {
      removeAutoFilledSlots(option)
    } else {
      autoFillSlots(option)
    }
    option.isActive = !option.isActive
    setAutoFillOptions([...autoFillOptions])
  }
  const autoFillSlots = (option: any) => {
    option.selected_days.forEach((dayId: number) => {
      let isExistSlot = exsitAvailabilitySlots([dayId], option.time_from, option.time_to).length > 0
      if (!isExistSlot) addAvailabilitySlot(dayId, option.time_from, option.time_to)
    })
  }
  const removeAutoFilledSlots = (option: any) => {
    if (
      (option.label == 'Add evenings' &&
        autoFillOptions.filter((a) => a.isActive && a.label == 'Add weekdays').length > 0) ||
      (option.label == 'Add weekdays' &&
        autoFillOptions.filter((a) => a.isActive && a.label == 'Add evenings').length > 0)
    ) {
      availabilityList
        .filter(function (item) {
          return (
            moment(item.from_time, 'HH:mm').isSame(moment(TimeEnum.StartDayTime, 'HH:mm')) &&
            moment(item.to_time, 'HH:mm').isSame(moment(TimeEnum.EndEveningTime, 'HH:mm'))
          )
        })
        .forEach(function (a: any) {
          if (option.label == 'Add evenings') a.to_time = TimeEnum.EndDayTime
          else a.from_time = TimeEnum.StartEveningTime
        })
      formik.setFieldValue('availability', availabilityList)
    } else {
      let deletedSlots = new Set(
        exsitAvailabilitySlotsCheck(option.selected_days, option.time_from, option.time_to)
      )
      let updatedAvailability = availabilityList.filter((slot: any) => {
        return !deletedSlots.has(slot)
      })
      formik.setFieldValue('availability', updatedAvailability)
    }
  }
  const exsitAvailabilitySlots = (days: number[], fromTime: string, toTime: string) => {
    return formik.values.availability.filter(
      (slot: any) =>
        days.includes(slot.day_id) &&
        moment(slot.from_time, 'HH:mm').isSameOrBefore(moment(fromTime, 'HH:mm')) &&
        moment(slot.to_time, 'HH:mm').isSameOrAfter(moment(toTime, 'HH:mm'))
    )
  }
  const handleCopy = (selectedDaysToCopy: Array<number>, dayId: number) => {
    if (formik.isSubmitting) {
      return
    }

    let listData = formik.values.availability

    let sourceDayData = listData.filter(function (a: AvailabilityModel) {
      return a.day_id == dayId
    })
    selectedDaysToCopy.forEach((dayId) => {
      sourceDayData.forEach((availability: AvailabilityModel) => {
        let newCopySlot = new AvailabilityModel()
        newCopySlot.day_id = dayId
        newCopySlot.from_time = availability.from_time
        newCopySlot.to_time = availability.to_time

        let exists =
          listData.findIndex(
            (b: AvailabilityModel) =>
              b.day_id == dayId &&
              (b.from_time == newCopySlot.from_time || b.to_time == newCopySlot.to_time)
          ) > -1

        if (!exists) {
          listData.push(newCopySlot)
        }
      })
    })

    formik.setFieldValue('availability', listData)
  }

  const handleSelect = (dayIndex: number) => {
    if (selectedDays.filter((selectedDay) => selectedDay == dayIndex).length == 0) {
      setSelectedDays([...selectedDays, dayIndex])
    } else {
      setSelectedDays(selectedDays.filter((selectedDay) => selectedDay != dayIndex))
    }
    console.log(selectedDays)
  }

  var daysList = weekDays?.map(function (day, key) {
    return (
      <div key={key}>
        <div className='flex justify-between items-center'>
          <CheckboxInput
            loading={loading}
            name={day.day_name ?? ''}
            required={false}
            label={day.day_name ?? ''}
            isSingleCheckbox={true}
            checked={availabilityList.findIndex((b) => b.day_id == day.id) > -1}
            onChange={(e: any) => onDayChanged(e, day.id)}
            disabled={formik.isSubmitting}
          />

          {availabilityList?.filter(function (a) {
            return a.day_id == day.id
          }).length > 0 ? (
            <div key={key + 'btnCopy'} className='flex'>
              {
                <Button
                  type='button'
                  onClick={() => addAvailabilitySlot(day.id, null, null)}
                  size='fit'
                  fitWidth={true}
                  colour='transparent'
                  Icon={PlusSmallIcon}
                ></Button>
              }

              <div className='ms-2'>
                {loading ? (
                  <Skeleton width={30} height={30} variant='circular' className='me-2' />
                ) : (
                  <CustomDropdown
                    options={(weekDays ?? []).map((item) => {
                      return {
                        title: item.day_name,
                        id: item.id,
                        isChecked: item.day_name == day.day_name || selectedDays.includes(item.id),
                        isDisabled: item.day_name == day.day_name,
                      }
                    })}
                    sourceId={day.id}
                    applyCopy={copy}
                    handleSelect={handleSelect}
                    onClose={() => setSelectedDays([])}
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {loading ? (
          <div className='grid grid-cols-11 space-x-2 items-center'>
            <div className='col-span-5'>
              <Skeleton height={40} />
            </div>
            <div className='col-span-5'>
              <Skeleton height={40} />
            </div>
            <Skeleton width={30} height={30} variant='circular' className='me-2' />
          </div>
        ) : availabilityList.filter(function (a) {
            return a.day_id == day.id
          }).length > 0 ? (
          <div className='mt-3 mb-2'>
            {availabilityList
              .filter(function (a) {
                return a.day_id === day.id
              })
              .map(function (slot, i) {
                return (
                  <div key={key + i + 'slot'}>
                    <div className='flex items-start justify-between mb-4'>
                      {loading ? (
                        <>
                          <div className='grid grid-cols-11 space-x-2 items-center'>
                            <div className='col-span-5'>
                              <Skeleton height={40} />
                            </div>
                            <div className='col-span-5'>
                              <Skeleton height={40} />
                            </div>
                            <Skeleton width={30} height={30} variant='circular' className='me-2' />
                          </div>
                        </>
                      ) : (
                        <div>
                          <div className='grid grid-cols-11 space-x-2 items-start'>
                            <div className='-my-5 col-span-5'>
                              <Dropdown
                                options={getTimes()}
                                onChange={(newValue) => onFromTimeChanged(newValue, slot)}
                                value={
                                  getTimes().filter((time) => time.value === slot.from_time)[0]
                                }
                                error={!slot.from_time && formik.touched.availability}
                                errorMsg='From time is required'
                              />
                            </div>

                            <div className='-my-5 col-span-5'>
                              <Dropdown
                                options={getTimes()}
                                onChange={(newValue) => onToTimeChanged(newValue, slot)}
                                value={getTimes().filter((time) => time.value === slot.to_time)[0]}
                                error={!slot.to_time && formik.touched.availability}
                                errorMsg='To time is required'
                              />
                            </div>

                            <div
                              className={
                                (!slot.from_time || !slot.to_time) && submitted ? 'mb-4' : ''
                              }
                            >
                              <Button
                                type='button'
                                size='fit'
                                fitWidth={true}
                                colour='transparent'
                                Icon={TrashIcon}
                                onClick={() => removeAvailabilitySlot(slot)}
                              ></Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {slot.to_time && slot.from_time && slot.to_time <= slot.from_time && (
                      <p className='text-sm text-red-600 -mt-2 mb-3' id='error'>
                        From time must be less than until time
                      </p>
                    )}
                    
                  </div>
                )
              })}
            {overLap[key] == true && (
              <p className='text-red-600' id='error'>
                Overlap exists
              </p>
            )}
            
          </div>
        ) : (
          <p className='text-sm font-medium leading-6 text-darkBlue'>Not available</p>
        )}

        <hr className='my-3' />
      </div>
    )
  })

  return (
    <>
      <div className=''>
        <div className='flex flex-wrap'>
          {autoFillOptions.map((option, index) =>
            loading ? (
              <>
                <Skeleton width={150} height={40} className='me-3 mb-2' />
              </>
            ) : (
              <div className='h-fit w-fit'>
                <Button
                  key={`Button-${index}`}
                  type='button'
                  disabled={formik.isSubmitting}
                  onClick={() => handleSelectedOption(option)}
                  className={classNames(
                    'btn btn-icon rounded-1 me-3 px-3 py-0 h-fit mb-4 flex items-center w-fit'
                  )}
                  colour={option.isActive ? 'darkBlue' : 'darkGray'}
                  Icon={option.isActive ? MinusCircleIcon : PlusCircleIcon}
                  size='medium'
                >
                  <span
                    className={
                      option.isActive
                        ? 'font-bold text-sm text-white'
                        : 'font-bold text-sm text-black'
                    }
                  >
                    {option.label}
                  </span>
                </Button>
              </div>
            )
          )}
        </div>

        <div>
          <label htmlFor='day_time_available' className='text-sm font-medium text-darkBlue'>
            {loading ? (
              <Skeleton width={120} />
            ) : (
              <>
                What days/times are you available? <span className='text-sm text-red-500'>*</span>
              </>
            )}
          </label>

          {formik.touched.availability && formik.errors.availability && (
            <p className='text-sm text-red-600' id='error'>
              {formik.errors.availability}
            </p>
          )}

          {daysList}
        </div>
      </div>
    </>
  )
}

export {Availability}

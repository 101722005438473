import {FC, useEffect, useState} from 'react'
import Skeleton from '@mui/material/Skeleton'
import {
  startOfToday,
  format,
  parse,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  isSameMonth,
  isToday,
  add,
} from 'date-fns'
import {useSharedData} from '../../../providers/SharedDataProvider'
import moment from 'moment'
import {ChevronLeftIcon, ChevronRightIcon, ExclamationCircleIcon} from '@heroicons/react/24/outline'
import {capitalizeFirstLetter} from '../../../helpers/textHelper'
import {Lesson} from '../../../models/lessons/lesson'
import StackedCardWithActionsMenu from '../../shared/data/StackedCardWithActionsMenu'
import EmptyState from '../../shared/elements/EmptyState'
import {LessonStatusEnum} from '../../../models/enums/lesson-status-enum'

type IProps = {
  loading: boolean
  highlightDates: Array<any>
  selectedDayInCalendar: Date
  setSelectedDayInCalendar: any
}

const MonthView: FC<IProps> = ({
  loading,
  highlightDates,
  selectedDayInCalendar,
  setSelectedDayInCalendar,
}) => {
  const [clickedDay, setClickedDay] = useState(selectedDayInCalendar ?? new Date())
  const [events, setEvents] = useState<Array<any>>([])
  const {updateSharedData} = useSharedData()
  const today = selectedDayInCalendar ?? startOfToday()
  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const [currentMonth, setCurrentMonth] = useState(() => format(today, 'MMM-yyyy'))
  const firstDayOfMonth = parse(currentMonth, 'MMM-yyyy', new Date())

  const getEvents = (selectedDate: Date) => {
    let eventsToShow = highlightDates.filter((e: any) => {
      return e?.date ? new Date(e?.date).toDateString() === selectedDate.toDateString() : false
    })
    setEvents(eventsToShow)
  }

  useEffect(() => {
    getEvents(selectedDayInCalendar)
  }, [selectedDayInCalendar])

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth),
    end: endOfWeek(endOfMonth(firstDayOfMonth)),
  })

  const getPrevMonth = () => {
    const firstDayOfPrevMonth = add(firstDayOfMonth, {months: -1})
    setCurrentMonth(format(firstDayOfPrevMonth, 'MMM-yyyy'))
    setSelectedDayInCalendar(firstDayOfPrevMonth)
  }

  const getNextMonth = () => {
    const firstDayOfNextMonth = add(firstDayOfMonth, {months: 1})
    setCurrentMonth(format(firstDayOfNextMonth, 'MMM-yyyy'))
    setSelectedDayInCalendar(firstDayOfNextMonth)
  }

  const getStatusColor = (statusId: any): 'darkGreen' | 'yellow' | 'darkRed' => {
    switch (statusId) {
      case LessonStatusEnum.Confirmed:
        return 'darkGreen'
      case LessonStatusEnum.Pending:
        return 'yellow'
      default:
        return 'darkRed'
    }
  }

  return (
    <>
      <div className='md:grid md:grid-cols-2 md:divide-x md:divide-gray-200 md:px-4'>
        {loading ? (
          <div className='pt-6 mb-2'>
            <Skeleton
              className='!flex items-start my-0 py-0 !transform-none !rounded-none '
              height={1000}
            />
          </div>
        ) : (
          <>
            <div className='pt-6 md:pr-8 pb-0 sm:pb-5 mb-6'>
              <div className='flex items-center'>
                <h2 className='flex-auto text-base font-bold'>
                  {format(firstDayOfMonth, 'MMMM yyyy')}
                </h2>
                <div className='relative flex rounded-md bg-white shadow-sm items-stretch'>
                  <button
                    type='button'
                    onClick={() => getPrevMonth()}
                    className='flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50'
                  >
                    <span className='sr-only'>Previous month</span>
                    <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setCurrentMonth(format(new Date(), 'MMM-yyyy'))
                      setSelectedDayInCalendar(new Date())
                      setClickedDay(new Date())
                      updateSharedData('selectedDate', new Date())
                      getEvents(new Date())
                    }}
                    className='border-y border-gray-300 md:px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative block'
                  >
                    Today
                  </button>
                  <button
                    type='button'
                    onClick={() => getNextMonth()}
                    className='flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50'
                  >
                    <span className='sr-only'>Next month</span>
                    <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                  </button>
                </div>
              </div>
              <div className='mt-10 grid grid-cols-7 text-center text-xs leading-6 text-darkBlue'>
                {days.map((day, idx) => {
                  return <div key={idx}>{capitalizeFirstLetter(day)}</div>
                })}
              </div>
              <div className='grid grid-cols-7 mt-2 content-center'>
                {daysInMonth.map((day, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`colStartClasses[getDay(day)]
                      ${idx > 6 ? 'border-t border-gray-200' : ''} py-1 `}
                    >
                      <p
                        onClick={() => {
                          setClickedDay(day)
                          setSelectedDayInCalendar(day)
                          updateSharedData('selectedDate', day)
                          getEvents(day)
                        }}
                        className={`cursor-pointer mx-auto flex flex-col items-center justify-center w-10 h-10 font-medium rounded-full ${
                          isSameMonth(day, today) ? 'text-darkBlue' : 'text-gray-400'
                        } ${!isToday(day) && ' hover:bg-gray-300'} 
                        
                        ${
                          moment(clickedDay).isSame(moment(day), 'day')
                            ? 'bg-darkBlue text-white'
                            : ''
                        }`}
                      >
                        {format(day, 'd')}

                        <span className='flex gap-x-0.5 w-full justify-center'>
                          {highlightDates.map((highlightDate: any, i: number) =>
                            moment(highlightDate.date).isSame(moment(day), 'day') ? (
                              <span
                                key={day.toString() + i}
                                className={`bg-[#2BCD5A] h-1 w-1 rounded-full`}
                              />
                            ) : (
                              <></>
                            )
                          )}
                        </span>
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='mt-4 pt-6 md:pl-8 md:border-0 border-t'>
              <h2 className='text-base font-bold text-darkBlue mb-2'>
                {loading ? (
                  <Skeleton width={200} />
                ) : (
                  <>{moment(clickedDay).format('dddd DD MMMM')}</>
                )}
              </h2>

              {events.length > 0 ? (
                <>
                  {!loading &&
                    events?.map((item: Lesson, index: number) => (
                      <div key={index} className='my-2'>
                        <div className='pt-3 border-t'>
                          <div className='-mx-3'>
                            <StackedCardWithActionsMenu
                              title={item?.adi_name}
                              icon='pmf-icon-wheel'
                              description={item?.lesson_date_str}
                              pillTitle={item?.job_ref ? '#' + item?.job_ref : null}
                              loading={false}
                              showAction={false}
                              status={{
                                title: item?.status ?? '',
                                color: getStatusColor(item?.status_id),
                              }}
                            ></StackedCardWithActionsMenu>
                          </div>
                        </div>
                      </div>
                    ))}

                  {loading && (
                    <div className='h-fit my-2 -mx-3'>
                      <StackedCardWithActionsMenu loading={loading}></StackedCardWithActionsMenu>
                    </div>
                  )}
                </>
              ) : (
                <div className=' justify-center items-center flex w-full'>
                  <EmptyState
                    Icon={ExclamationCircleIcon}
                    description='Sorry, we have nothing to show you right now.'
                    title='No appointments on this day'
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export {MonthView}

import { FC } from "react";
import PageHeader from "../../components/shared/elements/PageHeader";
import Button from "../../components/shared/elements/Button";
import ContactInfo from "../../components/help-center/ContactInfo";

const HelpCentreWrapper: FC = () => {
  return (
    <>
      <div className="bg-white pt-6">
        <PageHeader title="HelpCentre" backToURL="/" isCenter />
      </div>
      <div className="mainBanner"></div>
      <div className='px-5 mt-6 grid grid-cols-1'>
          <ContactInfo isNotAllowed={false}></ContactInfo>
        </div>
    </>
  );
};

export { HelpCentreWrapper };

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../helpers'
import {FeedbackQuestions} from '../../../models/feedback/feedback-questions'
import {getFeedbackQuestions, addFeedback} from '../../../services/feedback.service'
import Modal from '../overlays/Modal'
import {Feedback} from '../../../models/feedback/feedback'
import Alert from '../overlays/Alert'
import HorizontalCheckboxGroup from '../forms/HorizontalCheckboxGroup'
import RadioInputGroup from '../forms/RadioInputGroup'
import TextArea from '../forms/TextArea'
import {Skeleton} from '@mui/material'
import toast from 'react-hot-toast'

interface IProps {
  dealId: any
  adi_id: any
  job_id: any
  student_contact_id: any
  adi_contact_id: any
  show: any
  onHide: any
}

const FeedbackModal: FC<IProps> = (props) => {
  const intl = useIntl()
  const [feedback, setFeedBack] = useState<Feedback | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [totalPoints, setTotalPoints] = useState(0)

  useEffect(() => {
    if (props.show === true) {
      ;(async () => {
        setLoading(true)
        const response = await getFeedbackQuestions().catch((err) => {
          if (!err?.errorMessage) toast.error('Failed to get feedback questions')
        })
        setLoading(false)
        if (!response) {
          return
        }
        setFeedBack(response.results)
        updateFormValues(response.results)
        setLoading(false)
      })()
    }
  }, [props.show])

  const updateFormValues = (feedback: Feedback) => {
    let items: Array<FeedbackQuestions> = []
    feedback.feedback_items.forEach((item) => {
      items.push({
        feedback_question_id: item.feedback_question_id,
        question_name: item.question_name,
        rate: item.rate,
      })
    })
    formik.setFieldValue('feedback_items', items)
    formik.setFieldValue('adi_contact_id', props.adi_contact_id)
    formik.setFieldValue('adi_id', props.adi_id)
    formik.setFieldValue('job_id', props.job_id)
    formik.setFieldValue('student_contact_id', props.student_contact_id)
    formik.setFieldValue('zendesk_deal_id', props.dealId)
  }

  const validationSchema = Yup.object().shape({
    adi_contact_id: Yup.string().required(),
    adi_id: Yup.string().required(),
    job_id: Yup.string().required(),
    student_contact_id: Yup.string().required(),
    zendesk_deal_id: Yup.string().required(),
    remain_anonymous: Yup.boolean().required(
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'QUESTIONNAIRE.MEDICALCONDITIONS.MEDICAL_CONDITION'})}
      )
    ),
    additional_notes: Yup.string().nullable(),
    feedback_items: Yup.array().of(
      Yup.object().shape({
        feedback_question_id: Yup.number().required(),
        rate: Yup.number()
          .required(
            intl.formatMessage(
              {id: 'VALIDATION.REQUIRED'},
              {
                field: intl.formatMessage({
                  id: 'QUESTIONNAIRE.MEDICALCONDITIONS.MEDICAL_CONDITION',
                }),
              }
            )
          )
          .min(1, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 1}))
          .max(5, intl.formatMessage({id: 'VALIDATION.MAXIMUMU'}, {max: 5})),
      })
    ),
  })

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      adi_contact_id: props.adi_contact_id,
      adi_id: props.adi_id,
      job_id: props.job_id,
      student_contact_id: props.student_contact_id,
      zendesk_deal_id: props.dealId,
      remain_anonymous: true,
      additional_notes: '',
      feedback_items: feedback?.feedback_items ?? [],
    },
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      const total = values.feedback_items.reduce(
        (partialSum: any, a: any) => partialSum + (a && a.rate ? a.rate : 0),
        0
      )
      setTotalPoints(total)

      const response = await addFeedback(values).catch((error) => {
        if (!error?.errorMessage) {
          toast.error(intl.formatMessage({id: 'GENERAL.ERROR'}))
        }
      })
      setSubmitting(false)
      if (!response) {
        return
      }
      resetForm()
      setIsSubmitted(true)
      if (total < 20) {
        setTimeout(() => {
          setIsSubmitted(false)
          props.onHide()
        }, 2000)
      }
    },
  })

  const leaveReview = () => {
    window.location.href = 'https://uk.trustpilot.com/review/passmefast.co.uk'
  }

  const rates = Array.from([1, 2, 3, 4, 5]).map((rate) => {
    return {
      value: rate,
      label: rate + '',
    }
  })

  const handleClose = () => {
    props.onHide()
  }

  const rateChanged = (checked: boolean, rate: number, key: number) => {
    if (formik.isSubmitting) {
      return
    }
    const updatedFeedbackItems = formik.values.feedback_items
    if (updatedFeedbackItems) {
      if (checked) {
        updatedFeedbackItems[key].rate = rate
      } else {
        updatedFeedbackItems[key].rate = 0
      }
    }
    formik.setFieldValue('feedback_items', updatedFeedbackItems)
  }

  return (
    <>
      <Modal
        open={props.show}
        title={intl.formatMessage({id: 'FEEDBACK.RATE'})}
        onClose={handleClose}
        onSubmit={isSubmitted ? leaveReview : formik.handleSubmit}
        submitText={isSubmitted ? 'Leave a Review' : intl.formatMessage({id: 'ACTIONS.SUBMIT'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        className='w-500px'
        disabled={formik.isSubmitting}
        hideSubmit={isSubmitted && totalPoints <= 20}
      >
        {!isSubmitted && (
          <>
            <form
              onSubmit={formik.handleSubmit}
              className='d-flex flex-column justify-content-between h-100'
            >
              <div className='flex-grow-1 pt-4 pb-8'>
                {loading ? (
                  <div className='flex justify-center'>
                    <Skeleton width={80} />
                  </div>
                ) : (
                  <>
                    <Alert colour='green' icon='pmf-icon-info'>
                      <span className='text-sm'>
                        Select from <b>1-5</b> where <b>1</b> is Poor and <b>5</b> is Excellent
                      </span>
                    </Alert>

                    {formik.values.feedback_items?.map((item: FeedbackQuestions, index: number) => {
                      return (
                        <div
                          className='mb-5 last:mb-0 px-3 last:pb-4'
                          key={item.feedback_question_id + '-' + index}
                        >
                          <p className='text-base font-semibold mb-2'>{item.question_name}</p>

                          <div className='w-full'>
                            <HorizontalCheckboxGroup
                              values={[item.rate]}
                              onChange={(e: any, value: number) => {
                                rateChanged(e.target.checked, value, index)
                              }}
                              name={item.feedback_question_id + ''}
                              options={rates}
                              disabled={formik.isSubmitting}
                            />
                          </div>
                        </div>
                      )
                    })}

                    <div className='mt-4 border-top pt-4'>
                      <label className='text-base font-bold text-darkBlue mb-0' htmlFor='reasons'>
                        {intl.formatMessage({id: 'FEEDBACK.ADDITIONAL_NOTES'})}
                      </label>
                      <TextArea
                        name='additional_notes'
                        disabled={formik.isSubmitting}
                        maxLength={255}
                        label=''
                        className='form-control'
                        onChange={(e) => {
                          formik.setFieldValue('additional_notes', e.target.value)
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.additional_notes}
                        placeholder=''
                      />
                    </div>

                    <div className='mt-4 border-top pt-4'>
                      <label className='text-base font-bold text-darkBlue mb-0' htmlFor='reasons'>
                        {intl.formatMessage({id: 'FEEDBACK.REMAIN_ANONYMOUS_2'})}
                      </label>
                      <RadioInputGroup
                        fullWidth
                        options={[
                          {value: true, label: 'Yes'},
                          {value: false, label: 'No'},
                        ]?.map((option: any) => {
                          return {
                            value: option.value,
                            label: (
                              <>
                                <div className='flex items-center'>
                                  <span className='documentt_type_name ml-3'>{option.label}</span>
                                </div>
                              </>
                            ),
                          }
                        })}
                        disabled={formik.isSubmitting}
                        label={''}
                        name={''}
                        onChange={(value: any) => {
                          formik.setFieldValue('remain_anonymous', value)
                        }}
                        value={formik.values.remain_anonymous}
                      ></RadioInputGroup>
                    </div>
                  </>
                )}
              </div>
            </form>
          </>
        )}

        {isSubmitted && (
          <div className='flex items-center flex-col mt-10 h-full'>
            <img
              className='max-w-150px h-auto mt-10 mb-6'
              src={toAbsoluteUrl('/assets/media/svg/feedback.svg')}
              alt=''
            />
            <div className='mt-6 text-center flex items-center'>
              <h4 className='text-base font-bold pb-5 mx-5'>
                Thanks for your feedback!{' '}
                {totalPoints > 20 && (
                  <span className='text-base font-bold'>
                    Earn your instructor a bonus by reviewing them on Trustpilot.
                  </span>
                )}
              </h4>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export {FeedbackModal}

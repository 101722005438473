import modifiedFetch from '../../../setup/fetch/ModifiedFetch'

const PRODUCT_URL = process.env.REACT_APP_PRODUCT_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY

export async function getLeadTime(postalCode: any, transmission: string): Promise<any> {
  return await modifiedFetch({
    url: `${PRODUCT_URL}/lead-times/${postalCode}/specific?transmission=${transmission}`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}
export async function getLeadTimes(postalCode: any, transmission: string): Promise<Array<any>> {
  return await modifiedFetch({
    url: `${PRODUCT_URL}/lead-times/${postalCode}?transmission=${transmission}`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

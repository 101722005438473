import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {TestStatusTypeEnum} from '../../../../models/enums/test-status-type'
import {TestTypeEnum} from '../../../../models/enums/test-type'
import {Test} from '../../../../models/tests/test'
// eslint-disable-next-line @typescript-eslint/no-redeclare
import {Card} from '../../Card'
import Alert from '../../../shared/overlays/Alert'
import Button from '../../../shared/elements/Button'
import AddTest from '../../../tests/_modals/AddTest'

type Props = {
  test: Test | undefined
  getStudentBookingList: any
  testRejectionReason: any
  theoryTestCenters: any
  practicalTestCenters: any
  disableAction?: any
  dealId: string
  canAddPassMeTheory: any
  passProtectOption: any
}

const TheoryTest: FC<Props> = ({
  test,
  getStudentBookingList,
  practicalTestCenters,
  testRejectionReason,
  theoryTestCenters,
  disableAction,
  dealId,
  canAddPassMeTheory,
  passProtectOption,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [showTestModal, setShowTestModal] = useState(false)

  return (
    <>
      {/** */}
      {test &&
        (test.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking ||
          test.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
          test.status?.toLowerCase() === TestStatusTypeEnum.PendingResult ||
          (test.status?.toLowerCase() === TestStatusTypeEnum.Rejected &&
            test.abilities.can_accept) ||
          (test?.status?.toLowerCase() === TestStatusTypeEnum.Failed &&
            test?.abilities.can_rebook)) && (
          <div>
            <Card
              parentPage='journey'
              test={test}
              getStudentBookingList={getStudentBookingList}
              practicalTestCenters={practicalTestCenters}
              testRejectionReason={testRejectionReason}
              theoryTestCenters={theoryTestCenters}
              disableAction={disableAction}
              passProtectOption={passProtectOption}
            />

            {test?.status === TestStatusTypeEnum.Failed && (
              <div className='border-t-2 border-gray-500 pt-1 mt-4'>
                <h3 className='text-base font-semibold mb-0 mt-2'>
                  {intl.formatMessage({id: 'STUDENT_TEST.BOOKTESTYOURSELF'}, {type: 'theory'})}
                </h3>

                <div className='flex mt-3'>
                  <Button
                    disabled={disableAction}
                    type='button'
                    colour='pink'
                    fullWidth
                    onClick={() => {
                      setShowTestModal(true)
                    }}
                  >
                    {intl.formatMessage({id: 'STUDENT_TEST.ADDYOURTEST'})}
                  </Button>
                </div>
              </div>
            )}

            {canAddPassMeTheory && (
                <div className=' mt-3 mb-4'>
                    <Button
                        colour='outline'
                        type='button'
                        fullWidth
                        onClick={() => {
                        navigate('/addons')
                        }}
                    >
                        {intl.formatMessage({id: 'STUDENT_TEST.NEEDSUPPORT'})}
                    </Button>
                </div>
            )}

            {test.type === TestTypeEnum.Theory &&
            test.status?.toLowerCase() === TestStatusTypeEnum.Failed &&
            test.status?.toLowerCase() !== TestStatusTypeEnum.Cancelled.toString() &&
            !disableAction &&
            test.abilities.can_rebook ? (
              <Alert
                colour='orange'
                customDesc={
                  <>
                    {intl.formatMessage(
                      {id: 'STUDENT_TEST.REBOKKING_MSG'},
                      {date: test?.rebook_required_by}
                    )}
                  </>
                }
              />
            ) : (
              <></>
            )}
          </div>
        )}
      {/** */}

      <AddTest
        onTestSubmit={getStudentBookingList}
        practicalTestCenters={[]}
        theoryTestCenters={theoryTestCenters}
        initialTestType={TestTypeEnum.Theory}
        show={showTestModal}
        booking_id={+dealId}
        onHide={() => {
          setShowTestModal(false)
        }}
        saveTest={true}
        hidePassed={false}
        title={intl.formatMessage(
          {id: 'STUDENT_TEST.TEST_DETAILS_HAVE_BEEN_BOOKED'},
          {type: 'theory'}
        )}
      />
    </>
  )
}
export {TheoryTest}

import {Auth} from 'aws-amplify'
import ReactGA from 'react-ga4'
import {User} from '../models/auth/User'
import {Student} from '../models/student/student'
import {getCurrentStudent} from './student.service'
import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import toast from 'react-hot-toast'
const API_URL = process.env.REACT_APP_BASE_URL

export const LOGIN_URL = `${API_URL}/login`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const VERIFY_EMAIL_URL = `${API_URL}/verify-email`
export const EMAIL_EXISTS = `${API_URL}/email-exists`
export const FORGET_PASSWORD_EMAIL_EXISTS = `${API_URL}/forget-password-email-exists`
export const IMPERSONATE_STUDENT = `${API_URL}/impersonate-student`
export const IMPERSONATOR_TOKEN = `impersonator-token`

// Server should return AuthModel
export function login(email: string, password: string) {
  return Auth.signIn({
    username: email,
    password,
  })
}

// Server should return AuthModel
export async function register(email: string, password: string, userData: any, token: any) {
  const data = await Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
    },
  })
  return addStudent(data.userSub, email, userData, token)
}

export function validateToken(token?: string) {
  return modifiedFetch({
    url: `${API_URL}/students/validate-token`,
    method: 'POST',
    data: {token},
  })
}
export function resendValidationToken(token?: string) {
  return modifiedFetch({
    url: `${API_URL}/students/resend-register-email`,
    method: 'POST',
    data: {token},
  })
}

export function addStudent(id: string, email: any = undefined, userData: any, token: any) {
  return modifiedFetch({
    url: `${API_URL}/register`,
    method: 'POST',
    data: {
      id: id,
      old_id: userData?.old_id,
      first_name: userData.first_name,
      surname: userData.surname,
      student_email: {
        email: email,
        email_id: userData?.email_id,
      },
      zendesk_contact_id: userData.zendesk_contact_id,
      student_booking: {
        booking_id: userData.booking_id,
        deal_id: userData.deal_id,
        has_theory_test: userData.has_theory_test,
        has_practical_test: userData.has_practical_test,
      },
      token: token,
    },
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return Auth.forgotPassword(email)
}

export async function resetPassword(email: string, code: string, password: string) {
  let success = true
  const res = await Auth.forgotPasswordSubmit(email, code, password).catch((err) => {
    success = false
    toast.error('Something went wrong')
  })
  if (!success) return
  return modifiedFetch({
    url: VERIFY_EMAIL_URL,
    method: 'PUT',
    data: {email},
  })
}
export function checkEmailExists(email: string): Promise<any> {
  return modifiedFetch({
    url: EMAIL_EXISTS,
    method: 'POST',
    data: {email: email},
  })
}
export async function checkForgetPasswordEmailExists(email: string) {
  const response = await fetch(FORGET_PASSWORD_EMAIL_EXISTS, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email}),
  })
  return response.json()
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const user: any = {
    username: cognitoUser?.username,
    id: cognitoUser?.attributes?.sub,
  }
  return user
}

export async function getUserPersonalData() {
  const data = await getCurrentStudent().catch((error) => {
    if (!error?.errorMessage) toast.error('Failed to get current student')
    return
  })
  let personalData = data.results as Student
  ReactGA.set({userId: personalData.zendesk_contact_id})
  return {
    name: personalData.first_name == null ? '' : personalData.first_name,
    sur_name: personalData.surname == null ? '' : personalData.surname,
    phone: personalData.phone == null ? '' : personalData.phone,
    zendesk_contact_id:
      personalData.zendesk_contact_id == null ? '' : personalData.zendesk_contact_id,
    id: personalData.id,
    request_delete_account_at: personalData?.request_delete_account_at,
  } as User
}

export function verifyRecaptcha(captchaToken: any) {
  return modifiedFetch({
    url: `${API_URL}/verify-recaptcha`,
    method: 'POST',
    data: {captchaToken},
  })
}

export async function impersonateUser(email: string, password: string) {
  const response = await modifiedFetch({
    url: IMPERSONATE_STUDENT,
    method: 'POST',
    data: {
      username: email,
      password,
    },
  })
  if (response) {
    localStorage.setItem(IMPERSONATOR_TOKEN, response.results.token)
    await login(response.results.impersonator_email, password)
  }
  return response
}

export async function updateUserEmail(email: string) {
  const user = await Auth.currentAuthenticatedUser()
  return Auth.updateUserAttributes(user, {
    preferred_username: email,
    email: email,
  })
}

export async function CockpitImpersonate(userToken: string | null) {
  const response = await fetch(`${API_URL}/get-impersonate-password`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  })
  const data = await response.json()
  if (response.ok) {
    const pwd = atob(data.results.pwd)
    await login(data.results.impersonator_email, pwd)
  } else {
    throw data?.errors?.length ? data.errors[0] : data
  }
  return response
}
export function signOut() {
  Auth.signOut()
  localStorage.removeItem(IMPERSONATOR_TOKEN)
}

import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import {HaveLicence} from '../models/questionnaire/have-licence'
import {RebookingAboutCourseModel} from '../models/questionnaire/rebooking-about-course'
import {RebookingAboutYou} from '../models/questionnaire/rebooking-about-u'

import {StudentPracticalTestData} from '../models/questionnaire/student-practical-test-data'
import {StudentTestCentreData} from '../models/questionnaire/student-test-centre-data'
import {StudentTheoryTestData} from '../models/questionnaire/student-theory-test-data'
import {
  AboutCourseDataModel,
  UpdateAboutCourseDataModel,
} from '../models/student/about-course-data-model'
import {BookingHistory} from '../models/student/booking-history'
import {CurrentStudentLicenceDetails} from '../models/student/current-student-licence-details'
import {Dashboard} from '../models/student/dashboard'
import {MedicalConditionDataModel} from '../models/student/medical-condition-data-model'
import {SavedStepsModel} from '../models/student/saved-steps-model'
import {StudentAddress} from '../models/student/student-address'
import {StudentBooking} from '../models/student/student-booking'
import {StudentsQuestionnaireFlags} from '../models/student/students-questionnaire-flags'
const STUDENT_URL = process.env.REACT_APP_BASE_URL

export function updateLastLoginDate(): Promise<any> {
  return modifiedFetch({url: `${STUDENT_URL}/students/update-last-login-date`, method: 'POST'})
}

export async function getCurrentStudent(): Promise<any> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/current-personal-data`,
    method: 'GET',
  })
}

export async function updatePersonalData(studentData: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-personal-data`,
    method: 'POST',
    data: studentData,
  })
}

export async function getCurrentStudentLicenceDetails(): Promise<
  BaseResponse<CurrentStudentLicenceDetails>
> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/current-licence-data`,
    method: 'GET',
  })
}

export async function updateLicenceData(licence_data: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-licence-data`,
    method: 'POST',
    data: licence_data,
  })
}

export async function addMedicalConditions(data: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/medical-condition`,
    method: 'POST',
    data: data,
  })
}

export async function getSavedSteps(): Promise<BaseResponse<SavedStepsModel>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-saved-steps`,
    method: 'GET',
  })
}

export async function getStudentBooking(): Promise<BaseResponse<StudentBooking>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/student-booking`,
    method: 'GET',
  })
}

export async function getStudentMedicalConditions(): Promise<
  BaseResponse<MedicalConditionDataModel>
> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-medical-condition`,
    method: 'GET',
  })
}

export async function addAboutCourse(data: UpdateAboutCourseDataModel): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-about-course`,
    method: 'POST',
    data: data,
  })
}

export async function getAboutCourse(): Promise<BaseResponse<AboutCourseDataModel>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-about-course`,
    method: 'GET',
  })
}

export async function getStudentAddress(): Promise<BaseResponse<Array<StudentAddress>>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-address`,
    method: 'GET',
  })
}

export async function finishQuestionnaire(deal_id: string): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/finish-questionnaire/${deal_id}`,
    method: 'PUT',
  })
}

export async function getTheoryTestData(): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-theory-test-data`,
    method: 'GET',
  })
}

export async function updateTheoryTestData(
  theoryData: StudentTheoryTestData
): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-theory-test`,
    method: 'PUT',
    data: theoryData,
  })
}
export async function getHaveLicence(): Promise<any> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/have-licence`,
    method: 'GET',
  })
}

export async function updateHaveLicence(data: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/have-licence`,
    method: 'POST',
    data: data,
  })
}

export async function getPracticalTestData(): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-practical-test-data`,
    method: 'GET',
  })
}

export async function updatePracticalTestData(
  practicalData: StudentPracticalTestData
): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-practical-test`,
    method: 'PUT',
    data: practicalData,
  })
}

export async function getStudentsQuestionnaireFlags(): Promise<
  BaseResponse<StudentsQuestionnaireFlags>
> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-students-questionnaire-flags`,
    method: 'GET',
  })
}

export async function syncJobs(): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/sync-jobs`,
    method: 'GET',
  })
}

export async function addLicenceAddress(data: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/add-licence-address`,
    method: 'POST',
    data: data,
  })
}
export async function updateRebookingAboutYouData(
  studentData: RebookingAboutYou
): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-student-address-phone`,
    method: 'PUT',
    data: studentData,
  })
}
export async function updateRebookingAboutCourseData(
  studentData: RebookingAboutCourseModel
): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-student-availability`,
    method: 'PUT',
    data: studentData,
  })
}
export async function completeRebookingQuestionnaire(deal_id: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/complete-rebooking-questionnaire/${deal_id}`,
    method: 'GET',
  })
}

export async function getStudentBookings(): Promise<BaseResponse<Array<StudentBooking>>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/student-bookings-list`,
    method: 'GET',
  })
}

export async function updateSelectedBooking(bookingData: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/selected-booking`,
    method: 'PUT',
    data: bookingData,
  })
}

export async function getStudentBookingsForHistory(): Promise<BaseResponse<Array<BookingHistory>>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/student-bookings-history`,
    method: 'GET',
  })
}

export async function getProduct(data: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-product`,
    method: 'POST',
    data: data,
  })
}

export async function getCurrentStudentEmail(): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/current-email`,
    method: 'GET',
  })
}
export async function updateCurrentStudentEmail(
  email: string,
  username: string
): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-current-email`,
    method: 'PUT',
    data: {
      email,
      username,
    },
  })
}

export async function deleteMyAccount(): Promise<BaseResponse<StudentAddress>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/delete-my-account`,
    method: 'DELETE',
  })
}

export async function getStudentCommunication(): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/get-student-communication`,
    method: 'GET',
  })
}
export async function updateStudentCommunication(
  studentCommunication: any
): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-student-communication`,
    method: 'PUT',
    data: studentCommunication,
  })
}

export async function getDashboardData(
  dealId: any,
  orderId: any
): Promise<BaseResponse<Dashboard>> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/dashboard/${dealId}?orderId=${orderId}`,
    method: 'GET',
  })
}
export async function updateReferFriend(): Promise<Response> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-refer-friend`,
    method: 'POST',
  })
}

export async function checkRestrictedAvailability(request: any): Promise<any> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/check-restricted-availability`,
    method: 'POST',
    data: request,
  })
}

export async function updateAgreeToProceed(): Promise<any> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/agreed-to-proceed`,
    method: 'GET',
  })
}

export async function getQueryRelatedOptions(): Promise<any> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/query-related-options`,
    method: 'GET',
  })
}

export async function addOrUpdateAddress(studentId: string, address: StudentAddress): Promise<any> {
  return await modifiedFetch({
    url: `${STUDENT_URL}/students/update-student-address/${studentId}`,
    method: 'POST',
    data: address,
  })
}

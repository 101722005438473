import {ArrowPathIcon, PencilIcon} from '@heroicons/react/24/outline'
import React from 'react'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'

import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

import {useCourse} from '../../providers/CourseProvider'
import {PassprotectWithRedemptionRemaining} from '../../models/student/pass-protect-with-redemption-remaining'
import {RejectionReason} from '../../models/tests/rejection-reason'
import {TestStatusTypeEnum} from '../../models/enums/test-status-type'
import {RouteActions} from '../../models/enums/route-actions-enum'
import {Test} from '../../models/tests/test'
import {TestTypeEnum} from '../../models/enums/test-type'
import UsePassProtectModal from '../add-ons/_modals/UsePassProtectModal'
import AddTest from '../tests/_modals/AddTest'
import AddTestResult from '../tests/_modals/AddTestResult'
import {ClickAwayListener, Skeleton, Tooltip} from '@mui/material'
import Button from '../shared/elements/Button'
import InfoList from '../shared/data/InfoList'
import {CheckCircleIcon, ClockIcon, XCircleIcon} from '@heroicons/react/20/solid'
import AcceptTest from '../tests/_modals/AcceptTest'
import RejectTest from '../tests/_modals/RejectTest'
import AdditionalHoursModal from '../tests/_modals/AdditionalHoursModal'
import {BookNewCourseModal} from '../rebook-new-course/BookNewCourseModal'
import {rebookCourse} from '../../services/rebook.service'
import {RescheduleTestModal} from '../tests/_modals/RescheduleTestModal'
import ConfirmReschedulePracticalTestModal from '../tests/_modals/ConfirmReschedulePracticalTestModal'
import Alert from '../shared/overlays/Alert'

type Props = {
  parentPage?: any
  test?: Test
  getStudentBookingList?: any
  testRejectionReason?: RejectionReason[]
  theoryTestCenters?: any
  practicalTestCenters?: any
  disableAction?: any
  hasTestPendingAdiApproval?: boolean
  passProtectOption?: PassprotectWithRedemptionRemaining | undefined
  leadTime?: number
  IsPto?: boolean
  isLoading?: boolean
}
const Card: FC<Props> = ({
  parentPage,
  test,
  getStudentBookingList,
  testRejectionReason,
  theoryTestCenters,
  practicalTestCenters,
  disableAction,
  hasTestPendingAdiApproval,
  passProtectOption,
  leadTime,
  IsPto,
  isLoading,
}) => {
  const [openDesc, setOpenDesc] = useState(false)
  const intl = useIntl()
  function useQuery() {
    const {search} = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const navigate = useNavigate()
  let query = useQuery()
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showTestModal, setShowTestModal] = useState(false)
  const [showAddTestResultModal, setShowAddTestResultModal] = useState(false)
  const [showRescheduleTestModal, setShowRescheduleTestModal] = useState(false)
  const [showAdditionalHoursModal, setShowAdditionalHoursModal] = useState(false)
  const [showConfirmRescheduleTestModal, setShowConfirmRescheduleTestModal] = useState(false)
  const [showBookNewCourseModal, setShowBookNewCourseModal] = useState(false)
  const [showUsePassProtectModal, setShowUsePassProtectModal] = useState(false)
  const [showRebookModal, setShowRebookModal] = useState(false)
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true
  const {Course} = useCourse()
  const openNewCourseModal = () => {
    setShowBookNewCourseModal(true)
  }

  const openTestModel = () => {
    setShowTestModal(true)
  }

  const openTestResultModal = () => {
    setShowAddTestResultModal(true)
  }

  const openRebookTTModal = () => {
    navigate({search: 'action=' + RouteActions.Rebook})
    setShowRebookModal(true)
    setShowRescheduleTestModal(true)
  }

  const rescheduleTest = () => {
    navigate({search: 'action=' + RouteActions.Reschedule})
    setShowRebookModal(false)
    if (test?.type === TestTypeEnum.Practical) {
      setShowConfirmRescheduleTestModal(true)
    } else {
      setShowRescheduleTestModal(true)
    }
  }

  const confirmReschedule = (confirmed: boolean) => {
    setShowConfirmRescheduleTestModal(false)
    if (confirmed) {
      setShowAdditionalHoursModal(true)
    } else {
      setShowRescheduleTestModal(true)
    }
  }

  useEffect(() => {
    if (
      query.get('action')?.toLocaleLowerCase() === RouteActions.Reschedule.toLocaleLowerCase() &&
      !disableAction &&
      test?.abilities.can_reschedule
    ) {
      rescheduleTest()
    } else if (
      query.get('action')?.toLocaleLowerCase() === RouteActions.Rebook.toLocaleLowerCase() &&
      !disableAction &&
      test?.abilities.can_rebook
    ) {
      openRebookTTModal()
    }
  }, [test])
  useEffect(() => {}, [passProtectOption])

  return (
    <>
      {/* //#region "Modals Area" */}
      <BookNewCourseModal
        handleClose={() => setShowBookNewCourseModal(false)}
        show={showBookNewCourseModal}
        handleSubmit={() => rebookCourse(test?.deal_id, isMobile)}
      />

      <UsePassProtectModal
        handleClose={() => setShowUsePassProtectModal(false)}
        show={showUsePassProtectModal}
        handleSubmit={() => {}}
        passProtectOption={passProtectOption}
      />

      <RescheduleTestModal
        show={showRescheduleTestModal}
        test={test}
        onHide={() => {
          setShowRescheduleTestModal(false)
        }}
        isRebook={showRebookModal}
      />

      <ConfirmReschedulePracticalTestModal
        show={showConfirmRescheduleTestModal}
        hide={() => {
          setShowConfirmRescheduleTestModal(false)
        }}
        yesSelected={() => {
          confirmReschedule(true)
        }}
        noSelected={() => {
          confirmReschedule(false)
        }}
      />

      <AdditionalHoursModal
        show={showAdditionalHoursModal}
        hide={() => {
          setShowAdditionalHoursModal(false)
        }}
        test={test}
      />
      <AddTest
        onTestSubmit={getStudentBookingList}
        theoryTestCenters={theoryTestCenters}
        practicalTestCenters={practicalTestCenters}
        initialTestType={test?.type ?? 'theory'}
        show={showTestModal}
        booking_id={test?.deal_id}
        onHide={setShowTestModal}
        saveTest={true}
        booked_for={test?.booked_for}
        testCenterId={test?.location?.test_centre_id}
        test_location={test?.location?.name}
        id={test?.id}
        status={test?.status}
        hidePassed={false}
        title={
          test?.type === TestTypeEnum.Practical
            ? intl.formatMessage(
                {id: 'STUDENT_TEST.TEST_DETAILS_HAVE_BEEN_BOOKED'},
                {type: 'practical'}
              )
            : intl.formatMessage(
                {id: 'STUDENT_TEST.TEST_DETAILS_HAVE_BEEN_BOOKED'},
                {type: 'theory'}
              )
        }
        leadTime={leadTime}
        isPto={IsPto}
      />

      <AcceptTest
        test={test}
        show={showAcceptModal}
        onHide={() => {
          setShowAcceptModal(false)
        }}
        onStatusSubmit={getStudentBookingList}
      />

      <RejectTest
        test={test}
        rejectionReasons={testRejectionReason?.filter((x) => x.type === test?.type) ?? []}
        show={showRejectModal}
        onHide={() => {
          setShowRejectModal(false)
        }}
        onStatusSubmit={getStudentBookingList}
      />

      <AddTestResult
        test={test}
        show={showAddTestResultModal}
        onHide={() => {
          setShowAddTestResultModal(false)
        }}
        setShowTestModal={setShowBookNewCourseModal}
        onStatusSubmit={getStudentBookingList}
        passProtectOption={passProtectOption}
      />
      {/* //#endregion */}

      <div className='flex flex-col mb-lg-3 flex-grow'>
        <div className='flex flex-col'>
          {isLoading ? (
            <Skeleton width={50} />
          ) : (
            test?.status !== TestStatusTypeEnum.InitialBooking && (
              <div className='flex justify-between items-center mb-1'>
                <p className='mb-0 flex-grow-1'>
                  <span className={`flex items-center text-base font-bold`}>
                    {test?.status?.toLowerCase() === TestStatusTypeEnum.Failed ||
                    test?.status?.toLowerCase() === TestStatusTypeEnum.Rejected ||
                    test?.status?.toLowerCase() === TestStatusTypeEnum.NotApplicable ||
                    test?.status?.toLowerCase() === TestStatusTypeEnum.Cancelled ? (
                      <>
                        <XCircleIcon className='h-5 w-5 text-red-600 me-1 -ms-[3px]' />
                      </>
                    ) : null}
                    {test?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
                    test?.status?.toLowerCase() === TestStatusTypeEnum.Passed ? (
                      <>
                        <CheckCircleIcon className='h-5 w-5 text-green me-1 -ms-[3px]' />
                      </>
                    ) : null}
                  {
                    test?.status?.toLowerCase() === TestStatusTypeEnum.PendingAdiApproval ||
                    test?.status?.toLowerCase() === TestStatusTypeEnum.PendingReschedule ||
                    test?.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking ||
                    test?.status?.toLowerCase() === TestStatusTypeEnum.PendingResult ? (
                      <>
                      <ClockIcon className='h-5 w-5 text-yellow me-1 -ms-[3px]' />
                    </>
                  ) : null}
                    
                  
                  
                    {test?.status?.toLowerCase() === TestStatusTypeEnum.Reschedule ||
                    test?.status?.toLowerCase() === TestStatusTypeEnum.Rebooked ? (
                      <>
                        <ArrowPathIcon className='h-5 w-5 text-yellow me-1 -ms-[3px]' />
                      </>
                    ) : null}
                    {/* <CheckCircleIcon
                      className={`w-6 h-6 me-2
                      ${
                        test?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
                        test?.status?.toLowerCase() === TestStatusTypeEnum.Reschedule
                          ? 'text-yellow'
                          : test?.status?.toLowerCase() === TestStatusTypeEnum.Passed
                            ? 'text-green'
                            : test?.status?.toLowerCase() === TestStatusTypeEnum.Failed
                              ? 'text-red'
                              : test?.status?.toLowerCase() === TestStatusTypeEnum.Rejected
                                ? 'text-darkBlue'
                                : 'text-pink'
                      }
                    `}
                    /> */}
                    {test?.status
                      ?.toLowerCase()
                      .split(' ')
                      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                  </span>
                </p>
                <div className='flex-shrink-0 flex'>
                  {test?.abilities.can_change && !disableAction ? (
                    <Button type='button' size='icon' colour='gray' onClick={openTestModel}>
                      <span className='p-1 flex content-center'>
                        <PencilIcon className='w-4 h-4' />
                      </span>
                    </Button>
                  ) : (test?.status?.toLowerCase() === TestStatusTypeEnum.Passed ||
                      test?.status?.toLowerCase() === TestStatusTypeEnum.Failed) &&
                    (test?.abilities.can_pass || test?.abilities.can_fail) &&
                    !disableAction ? (
                    <Button
                    type='button' size='icon' colour='gray' 
                      onClick={openTestResultModal}
                      hidden={Course.deal_id != Course.last_active_deal}
                    >
                       <span className='p-1 flex content-center'>
                        <PencilIcon className='w-4 h-4' />
                      </span>
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )
          )}
          <div className=' mb-4'>
            <InfoList
              loading={isLoading}
              data={[
                {
                  icon: 'icon-calendar',
                  value: test?.booked_for_human ?? '-',
                },

                {
                  icon: 'icon-location',
                  value: test?.location?.name ?? '--',
                  linkURL: Course?.pickupAddress
                    ? `https://www.google.de/maps/dir/${Course.pickupAddress.lat},${Course.pickupAddress.long}/${test?.location?.latitude},${test?.location?.longitude}/`
                    : `https://maps.google.com/?q=${test?.location?.latitude},${test?.location?.longitude}`,
                },
              ]}
            />
          </div>

          <div className={hasTestPendingAdiApproval ? 'flex  mt-4' : 'hidden'}>
            {isLoading ? (
              <Skeleton width={150} />
            ) : (
              intl.formatMessage({id: 'STUDENT_TEST.PENDING_ADI'})
            )}
          </div>
        </div>
        {/* START:: Actions */}

        {!disableAction ? (
          <div className='grid grid-cols-1 md:grid-cols-2 md:gap-x-5 gap-y-3 p-0 border-0 mt-1'>
            {test?.abilities.can_accept && (
              <Button
                type='button'
                fullWidth
                colour='pink'
                onClick={() => {
                  setShowAcceptModal(true)
                }}
              >
                {intl.formatMessage({id: 'ACTIONS.ACCEPT'})}
              </Button>
            )}
            {(test?.status?.toLowerCase() === TestStatusTypeEnum.Confirmed ||
              test?.status?.toLowerCase() === TestStatusTypeEnum.PendingResult) &&
              (test?.abilities.can_pass || test?.abilities.can_fail) && (
                <Button type='button' fullWidth colour='pink' onClick={openTestResultModal}>
                  {intl.formatMessage({id: 'ACTIONS.ADDTESTRESULT'})}
                </Button>
              )}

            {test?.abilities.can_reschedule && (
              <Button
                type='button'
                fullWidth
                colour='pink'
                onClick={() => {
                  rescheduleTest()
                }}
              >
                {intl.formatMessage({id: 'ACTIONS.RESCHEDULE'})}
              </Button>
            )}
            {test?.abilities.can_rebook && (
              <Button type='button' colour='pink' onClick={openRebookTTModal}>
                {intl.formatMessage({id: 'ACTIONS.REBOOK'})}
              </Button>
            )}
            {test?.abilities.can_reject && (
              <Button
                type='button'
                fullWidth
                colour='outline'
                onClick={() => {
                  setShowRejectModal(true)
                }}
              >
                {intl.formatMessage({id: 'ACTIONS.REJECT'})}
              </Button>
            )}
          </div>
        ) : null}

        {test?.type === TestTypeEnum.Practical &&
          test?.status?.toLowerCase() === TestStatusTypeEnum.Failed &&
          (!Course.last_active_deal || Course.deal_id == Course.last_active_deal) && (
            <>
              <ClickAwayListener onClickAway={() => setOpenDesc(false)}>
                <Tooltip
                  title='Book a new course'
                  arrow
                  placement='top'
                  open={openDesc}
                  componentsProps={{
                    tooltip: {
                      style: {
                        fontSize: '10px',
                        lineHeight: '18px',
                        fontFamily: 'Poppins, sans-serif',
                        maxWidth: '250px',
                        marginRight: '6px',
                      },
                    },
                  }}
                >
                  {passProtectOption?.has_pass_protect &&
                  passProtectOption.number_of_pass_protect_redemption_remaining > 0 ? (
                    <>
                      <Button
                        type='button'
                        colour='pink'
                        onClick={() => setShowUsePassProtectModal(true)}
                      >
                        {intl.formatMessage(
                          {id: 'ACTIONS.USE_PASS_PROTECT'},
                          {count: passProtectOption.number_of_pass_protect_redemption_remaining}
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type='button' colour='pink' onClick={openNewCourseModal}>
                        <ArrowPathIcon className='w-6 h-6' />{' '}
                        {intl.formatMessage({id: 'ACTIONS.REBOOK_COURSE'})}
                      </Button>
                    </>
                  )}
                </Tooltip>
              </ClickAwayListener>
            </>
          )}

        {/* END:: Actions */}

        {test?.result_required_by &&
          (test?.status === TestStatusTypeEnum.Confirmed ||
            test?.status === TestStatusTypeEnum.PendingResult) &&
          test?.type === TestTypeEnum.Theory && (
            <>
              <Alert
                colour='orange'
                customDesc={intl.formatMessage(
                  {id: 'STUDENT_TEST.WATTING_TT_RESULT'},
                  {
                    date: test?.result_required_by,
                  }
                )}
              />
            </>
          )}
        {test?.confirmation_required_by && test?.status === TestStatusTypeEnum.InitialBooking && (
          <>
          <Alert
            colour='orange'
            customDesc={intl.formatMessage(
              {id: 'STUDENT_TEST.WATTING_TT_REJECT_ACCEPT'},
              {
                date: test?.confirmation_required_by,
              }
            )}
          />
          </>
        )}
        {/* {parentPage === 'tests' && (
          <>
            {test?.rebook_required_by && test?.abilities.can_rebook && !disableAction ? (
              <div className='alert alert-warning shadow-none mt-3 mb-0'>
                <i className='fa fa-info-circle font-size-22 me-3'></i>
                <span className='font-size-16 fw-600'>
                  {intl.formatMessage(
                    {id: 'STUDENT_TEST.REBOKKING_MSG'},
                    {
                      date: test?.rebook_required_by,
                    }
                  )}
                </span>
              </div>
            ) : (
              <></>
            )}
          </>
        )} */}
      </div>
    </>
  )
}

export {Card}

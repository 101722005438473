import {FC, useState} from 'react'
import {useSharedData} from '../../providers/SharedDataProvider'
import {DailyView} from './calender/DailyView'
import {WeeklyView} from './calender/WeeklyView'
import {MonthView} from './calender/MonthView'

type IProps = {
  loading: boolean
  events: Array<any>
  updateData: any
}

const CalendarView: FC<IProps> = ({events, loading}) => {
  const [activeView, setActiveView] = useState('monthly')
  const {updateSharedData} = useSharedData()
  const [selectedDayInCalendar, setSelectedDayInCalendar] = useState(new Date())

  const switchView = (view: any) => {
    setActiveView(view)
    updateSharedData('selectedDate', selectedDayInCalendar)
  }

  return (
    <>
      <div className='w-full flex flex-col bg-white p-4'>
        <span className='isolate inline-flex rounded-md shadow-sm sm:mx-4 w-fit'>
          <button
            type='button'
            onClick={() => switchView('monthly')}
            className={`"relative rounded-l-md -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
            ${activeView === 'monthly' ? 'bg-darkBlue text-white' : 'bg-white hover:bg-gray-50 '}`}
          >
            Monthly
          </button>
          <button
            type='button'
            onClick={() => switchView('weekly')}
            className={`"relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
            ${activeView === 'weekly' ? 'bg-darkBlue text-white' : 'bg-white hover:bg-gray-50 '}`}
          >
            Weekly
          </button>
          <button
            type='button'
            onClick={() => switchView('daily')}
            className={`"relative rounded-r-md -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
            ${activeView === 'daily' ? 'bg-darkBlue text-white' : 'bg-white hover:bg-gray-50 '}`}
          >
            Daily
          </button>
        </span>

        {activeView === 'monthly' && (
          <MonthView
            loading={loading}
            highlightDates={events}
            selectedDayInCalendar={selectedDayInCalendar}
            setSelectedDayInCalendar={setSelectedDayInCalendar}
          />
        )}

        {activeView === 'weekly' && (
          <WeeklyView
            highlightDates={events}
            selectedDayInCalendar={selectedDayInCalendar}
            setSelectedDayInCalendar={setSelectedDayInCalendar}
          />
        )}
        {activeView === 'daily' && (
          <DailyView
            highlightDates={events}
            selectedDayInCalendar={selectedDayInCalendar}
            setSelectedDayInCalendar={setSelectedDayInCalendar}
          />
        )}
      </div>
    </>
  )
}

export default CalendarView

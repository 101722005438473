/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import ActionCentreCard from '../shared/cards/ActionCentreCard'
import {PassprotectWithRedemptionRemaining} from '../../models/student/pass-protect-with-redemption-remaining'
import {Test} from '../../models/tests/test'
import {AdiData} from '../../models/adi/adi-data'
import {RejectionReason} from '../../models/tests/rejection-reason'
import {Job} from '../../models/payments/job'
import Slider from 'react-slick'
import {validatePayment} from '../../services/payment.service'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {TestStatusTypeEnum} from '../../models/enums/test-status-type'
import {Link} from 'react-router-dom'
import Button from '../shared/elements/Button'
import {ArrowRightIcon} from '@heroicons/react/24/outline'
import {toast} from 'react-hot-toast'
import {Skeleton} from '@mui/material'
import AcceptTest from '../tests/_modals/AcceptTest'
import RejectTest from '../tests/_modals/RejectTest'
import UsePassProtectModal from '../add-ons/_modals/UsePassProtectModal'
import {RescheduleTestModal} from '../tests/_modals/RescheduleTestModal'
import AddTestResult from '../tests/_modals/AddTestResult'
import {BookNewCourseModal} from '../rebook-new-course/BookNewCourseModal'
import {rebookCourse} from '../../services/rebook.service'
import {FeedbackModal} from '../shared/modals/FeedbackModal'
import {PaymentAmount} from '../add-payment/PaymentAmount'
import {formatPriceIntoTwoDigits} from '../../helpers/PriceHelper'
import {LessonFeedbackModal} from '../lessons/_modal/LessonFeedbackModal'

type Props = {
  theoryTest: Test | undefined
  practicalTest: Test | undefined
  paymentInfo: Job
  hasPendingLessons: boolean
  testRejectionReason: RejectionReason[]
  adiData: AdiData
  getStudentTests: any
  hasAdi: boolean
  dealId: any
  disableAction: boolean
  canAddFeedback: boolean
  canAddLessonFeedback: boolean
  lessonData?: any
  readyToPassPracticalTest: boolean
  zendeskContactId: any
  checkCanAddFeedback: any
  loading: boolean
  hasPoorAvailability: boolean
  passProtectOption: PassprotectWithRedemptionRemaining | undefined
  lastActiveDeal: any
  refreshDashboard: () => void
}

const ActionCentre: FC<Props> = ({
  theoryTest,
  practicalTest,
  paymentInfo,
  hasPendingLessons,
  testRejectionReason,
  adiData,
  getStudentTests,
  hasAdi,
  dealId,
  disableAction,
  canAddFeedback,
  canAddLessonFeedback,
  lessonData,
  readyToPassPracticalTest,
  zendeskContactId,
  checkCanAddFeedback,
  passProtectOption,
  loading,
  lastActiveDeal,
  refreshDashboard,
  hasPoorAvailability,
}) => {
  const intl = useIntl()
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showAddTestResultModal, setShowAddTestResultModal] = useState(false)
  const [currentTest, setCurrentTest] = useState<Test>()
  const [selectedOption, setSelectedOption] = useState<TestStatusTypeEnum>()
  const canAddResult =
    theoryTest && theoryTest.booked_for
      ? moment(theoryTest.booked_for).isBefore() &&
        theoryTest.status?.toLowerCase() === TestStatusTypeEnum.PendingResult
      : false
  const [showRescheduleTestModal, setShowRescheduleTestModal] = useState(false)
  const [showRebookModal, setShowRebookModal] = useState(false)
  const [showPaymentCard, setShowPaymentCard] = useState<boolean>(false)
  const [paying, setPaying] = useState<boolean>(false)
  const [showPaymentAmountModal, setShowPaymentAmountModal] = useState(false)
  const [showBookNewCourseModal, setShowBookNewCourseModal] = useState(false)
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true
  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false)
  const [showLessonFeedbackDialog, setShowLessonFeedbackDialog] = useState<boolean>(false)
  const [showUsePassProtectModal, setShowUsePassProtectModal] = useState(false)
  const hideFeedbackDialog = () => {
    checkCanAddFeedback(adiData?.id, dealId)
    setShowFeedbackDialog(false)
  }
  const hideLessonFeedbackDialog = () => {
    setShowLessonFeedbackDialog(false)
  }
  const actions = [
    theoryTest &&
      theoryTest.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking &&
      !disableAction,
    showPaymentCard && !disableAction,
    canAddResult && !disableAction,
    practicalTest &&
      practicalTest.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking &&
      !disableAction,
    hasPendingLessons && !disableAction,
    theoryTest &&
      theoryTest.status?.toLowerCase() === TestStatusTypeEnum.Failed &&
      !theoryTest.cancelled &&
      theoryTest.abilities.can_rebook &&
      !disableAction,
    practicalTest &&
      practicalTest.status?.toLowerCase() === TestStatusTypeEnum.Failed &&
      (!lastActiveDeal || dealId == lastActiveDeal),
    canAddFeedback && !disableAction,
    canAddLessonFeedback && !disableAction,
    !disableAction,
    practicalTest && readyToPassPracticalTest && !disableAction,
    hasPoorAvailability && !disableAction,
  ]
  const actionCount = actions.filter((x) => x).length
  const [sliderCenterMode, setSliderCenterMode] = useState(false)
  const formattedDate = (dateString: any) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    return `${day}/${month}`
  }
  useEffect(() => {
    if (actionCount > 1) {
      setSliderCenterMode(true)
    }
  }, [actions])

  
  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.75,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.05,
          // adaptiveHeight: true,
        },
      },
    ],
  }

  const openRebookTTModal = () => {
    setShowRebookModal(true)
    setShowRescheduleTestModal(true)
  }

  const openPaymentModal = async () => {
    setPaying(true)
    let amount =
      paymentInfo?.grp_next_amount && paymentInfo.grp_next_amount > 0
        ? paymentInfo.grp_next_amount
        : paymentInfo?.remaining_amount && paymentInfo?.remaining_amount > 0
          ? paymentInfo.remaining_amount
          : 0
    let err = false
    const res = await validatePayment(amount).catch((error) => {
      err = true
      setPaying(false)
      if (!error?.errorMessage) {
        toast.error('Failed to get validate payment')
      }
    })
    if (!err) {
      if (res?.results) {
        setShowPaymentAmountModal(true)
      } else {
        setPaying(false)
        window.location.reload()
      }
    }
  }

  const openPracticalTestLink = () => {
    window.open('https://www.passmefast.co.uk/resources/practical-test/ready-to-pass', '_blank')
  }

  useEffect(() => {
    setShowPaymentCard(
      paymentInfo &&
        !paymentInfo.paid_in_full &&
        ((paymentInfo.grp_next_amount !== null && Number(paymentInfo.grp_next_amount) > 0) ||
          (paymentInfo.remaining_amount !== null && Number(paymentInfo.remaining_amount) > 0))
    )
  }, [paymentInfo])

  return actionCount > 0 ? (
    <>
      <div className='w-full pt-5'>
        {loading ? (
          <>
            <div className='col-12 col-md-4 mt-4'>
              <div className='border-gray-200 border-2 rounded-[10px] h-125px p-6'>
                <Skeleton width={200} />
                <Skeleton width={200} />
                <Skeleton height={40} />
              </div>
            </div>
          </>
        ) : (
          <>
            <Slider {...settings} className={actionCount > 1 ? 'multi-slider' : 'multi-slider'}>
              {theoryTest &&
              theoryTest.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking &&
              !disableAction ? (
                <div>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>
                        {intl.formatMessage({
                          id: 'DASHBOARD.ACTION_CENTRE.WATTING_TT_REJECT_ACCEPT',
                        })}
                        <span className='fw-bolder'>{theoryTest.booked_for_human}</span>
                        {intl.formatMessage(
                          {id: 'DASHBOARD.ACTION_CENTRE.AT_LOCATION'},
                          {location: theoryTest.location?.name}
                        )}
                      </>
                    }
                    children={
                      <div className='grid grid-cols-2 gap-3'>
                        <Button
                          colour='outlineWhite'
                          onClick={() => {
                            setCurrentTest(theoryTest)
                            setShowAcceptModal(true)
                          }}
                        >
                        <i className='icon-check text-green me-1' />
                          {intl.formatMessage({id: 'ACTIONS.ACCEPT'})}
                        </Button>
                        <Button
                          colour='outlineWhite'
                          onClick={() => {
                            setCurrentTest(theoryTest)
                            setShowRejectModal(true)
                          }}
                        >
                          <i className='icon-times text-danger me-1' />{' '}
                          {intl.formatMessage({id: 'ACTIONS.REJECT'})}
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {hasPoorAvailability && !disableAction ? (
                <div>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>
                        {intl.formatMessage({
                          id: 'DASHBOARD.ACTION_CENTRE.POOR_AVAILABILITY',
                        })}
                      </>
                    }
                    children={
                      <div className='row d-flex'>
                        <Link
                          to='/profile/about-course'
                          className='btn btn-primary w-100 btn-accept rounded-2'
                        >
                          {intl.formatMessage({id: 'ACTIONS.UPDATE_AVAILABILITY'})}
                        </Link>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {canAddResult && !disableAction ? (
                <div>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>
                        {intl.formatMessage({id: 'DASHBOARD.ACTION_CENTRE.WATTING_TT_RESULT'})}
                        <span className='fw-bolder'>{theoryTest?.result_required_by}</span>
                        {intl.formatMessage({
                          id: 'DASHBOARD.ACTION_CENTRE.OR_YOUR_COURSE_WILL_BE_ON_HOLD',
                        })}
                      </>
                    }
                    children={
                      <div className='row d-flex'>
                        <Button
                          colour='outlineWhite'
                          onClick={() => {
                            setCurrentTest(theoryTest)
                            setShowAddTestResultModal(true)
                          }}
                        >
                          {' '}
                          {intl.formatMessage({id: 'ACTIONS.ADDTESTRESULT'})}
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {practicalTest &&
              practicalTest.status?.toLowerCase() === TestStatusTypeEnum.InitialBooking &&
              !disableAction ? (
                <div>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>
                        {intl.formatMessage({
                          id: 'DASHBOARD.ACTION_CENTRE.WATTING_PT_REJECT_ACCEPT',
                        })}
                        <span className='fw-bolder'>{practicalTest.booked_for_human}</span>
                        {intl.formatMessage(
                          {id: 'DASHBOARD.ACTION_CENTRE.AT_LOCATION'},
                          {location: practicalTest.location?.name}
                        )}
                      </>
                    }
                    children={
                      <div className='grid grid-cols-2 gap-3'>
                          <Button
                            colour='outlineWhite'
                            onClick={() => {
                              setCurrentTest(practicalTest)
                              setShowAcceptModal(true)
                            }}
                          >
                            <i className='icon-check text-green me-1' />
                            {intl.formatMessage({id: 'ACTIONS.ACCEPT'})}
                          </Button>
                          <Button
                            colour='outlineWhite'
                            onClick={() => {
                              setCurrentTest(practicalTest)
                              setShowRejectModal(true)
                            }}
                          >
                            <i className='icon-times text-danger me-1' />{' '}
                            {intl.formatMessage({id: 'ACTIONS.REJECT'})}
                          </Button>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {hasPendingLessons && !disableAction ? (
                <div>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>{intl.formatMessage({id: 'DASHBOARD.UPCOMING_LESSONS_MESSAGE'})}</>
                    }
                    children={
                      <div className='row d-flex'>
                        <Link
                          to='/lessons/schedule'
                          className='btn btn-primary w-100 btn-accept rounded-2  mt-4'
                        >
                          {intl.formatMessage({id: 'ACTIONS.RESPOND'})}
                        </Link>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {theoryTest &&
              theoryTest.status?.toLowerCase() === TestStatusTypeEnum.Failed &&
              !theoryTest.cancelled &&
              theoryTest.abilities.can_rebook &&
              !disableAction ? (
                <div>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>
                        {intl.formatMessage({id: 'DASHBOARD.ACTION_CENTRE.REBOKKING_MSG'})}
                        <span className='fw-bolder'>{theoryTest?.rebook_required_by}</span>
                        {intl.formatMessage({
                          id: 'DASHBOARD.ACTION_CENTRE.OR_YOUR_COURSE_WILL_BE_ON_HOLD',
                        })}
                      </>
                    }
                    children={
                      <div className='row d-flex'>
                        <Button colour='outlineWhite' onClick={openRebookTTModal}>
                          {intl.formatMessage({id: 'ACTIONS.REBOOK'})}
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {practicalTest &&
              practicalTest.status?.toLowerCase() === TestStatusTypeEnum.Failed &&
              (!lastActiveDeal || dealId == lastActiveDeal) ? (
                passProtectOption?.has_pass_protect &&
                passProtectOption.number_of_pass_protect_redemption_remaining > 0 ? (
                  <div>
                    <ActionCentreCard
                      title='Pending Confirmation'
                      description={
                        <>{intl.formatMessage({id: 'DASHBOARD.ACTION_CENTRE.REBOOK_COURSE'})}</>
                      }
                      children={
                        <div className='row d-flex'>
                          <Button
                            colour='outlineWhite'
                            onClick={() => setShowUsePassProtectModal(true)}
                          >
                            {intl.formatMessage(
                              {id: 'ACTIONS.USE_PASS_PROTECT'},
                              {count: passProtectOption.number_of_pass_protect_redemption_remaining}
                            )}
                          </Button>
                        </div>
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <ActionCentreCard
                      title='Pending Confirmation'
                      description={
                        <>{intl.formatMessage({id: 'DASHBOARD.ACTION_CENTRE.REBOOK_COURSE'})}</>
                      }
                      children={
                        <div className='row d-flex'>
                          <Button
                            colour='outlineWhite'
                            onClick={() => setShowBookNewCourseModal(true)}
                          >
                            {intl.formatMessage({id: 'ACTIONS.REBOOK_COURSE'})}
                          </Button>
                        </div>
                      }
                    />
                  </div>
                )
              ) : null}
              {showPaymentCard && !disableAction ? (
                <div className='h-full'>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>
                        {hasAdi === true ? (
                          <>
                            {moment(adiData?.allocation_date)
                              .add(7, 'days')
                              .isAfter(moment.now()) ? (
                              <span>
                                {intl.formatMessage(
                                  {id: 'DASHBOARD.INSTRUCTOR.UNPAID_NOTE'},
                                  {
                                    date: moment(adiData?.allocation_date)
                                      .add(7, 'days')
                                      .format('DD/MM'),
                                  }
                                )}
                              </span>
                            ) : (
                              <span>
                                {intl.formatMessage({
                                  id: 'DASHBOARD.INSTRUCTOR.UNPAID_URGENTLY_NOTE',
                                })}
                                <b> {intl.formatMessage({id: 'DASHBOARD.PAYMENT.URGENTLY'})} </b>
                              </span>
                            )}
                          </>
                        ) : (
                          intl.formatMessage({id: 'PAYMENT_MODAL.PAYMENT_ARRANGING_WARNING'})
                        )}
                      </>
                    }
                    children={
                      <div className='row d-flex'>
                        <Button colour='outlineWhite' onClick={openPaymentModal} disabled={paying}>
                          {intl.formatMessage({id: 'DASHBOARD.PAYMENT.PAY'})}
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {canAddFeedback && !disableAction ? (
                <div className='h-full'>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>{intl.formatMessage({id: 'DASHBOARD.ACTION_CENTRE.FEEDBACK'})}</>
                    }
                    children={
                      <div className='row d-flex'>
                        <Button colour='outlineWhite' onClick={() => setShowFeedbackDialog(true)}>
                          {' '}
                          {intl.formatMessage({id: 'ACTIONS.PROVIDE_FEEDBACK'})}
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {canAddLessonFeedback && !disableAction ? (
                <div className='h-full'>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>
                        {intl.formatMessage({id: 'DASHBOARD.ACTION_CENTRE.LESSON_FEEDBACK'})}
                        <span className='fw-bolder'>{formattedDate(lessonData.lesson_date)}</span>?
                      </>
                    }
                    children={
                      <div className='row d-flex'>
                        <Button
                          colour='outlineWhite'
                          onClick={() => setShowLessonFeedbackDialog(true)}
                        >
                          {' '}
                          {intl.formatMessage({id: 'ACTIONS.PROVIDE_FEEDBACK'})}
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {!disableAction ? (
                <div className='h-full'>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={
                      <>{intl.formatMessage({id: 'DASHBOARD.ACTION_CENTRE.ADDON_MSG'})}</>
                    }
                    children={
                      <div className='row d-flex'>
                        <Link to='/addons' className='flex items-center  mt-4'>
                          {intl.formatMessage({id: 'DASHBOARD.ACTION_CENTRE.ADDON_BTN'})}{' '}
                          <ArrowRightIcon className='w-5 h-5 ms-2 mt-0.5' />
                        </Link>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {practicalTest && readyToPassPracticalTest && !disableAction ? (
                <div className='h-full'>
                  <ActionCentreCard
                    title='Pending Confirmation'
                    description={<>{intl.formatMessage({id: 'DASHBOARD.PRACTICAL_TEST.TITLE'})}</>}
                    children={
                      <div className='row d-flex'>
                        <Button
                          colour='outlineWhite'
                          onClick={openPracticalTestLink}
                          size='sm'
                          className='py-2 px-5'
                        >
                          {intl.formatMessage({id: 'DASHBOARD.PRACTICAL_TEST.FINDOUT_BTN'})}
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : null}
            </Slider>
          </>
        )}
      </div>

      {/* //#region "Modals Area" */}
      <BookNewCourseModal
        handleClose={() => setShowBookNewCourseModal(false)}
        show={showBookNewCourseModal}
        handleSubmit={() => rebookCourse(dealId, isMobile)}
      />

      <UsePassProtectModal
        handleClose={() => setShowUsePassProtectModal(false)}
        show={showUsePassProtectModal}
        handleSubmit={() => {}}
        passProtectOption={passProtectOption}
      />

      <RescheduleTestModal
        show={showRescheduleTestModal}
        test={theoryTest}
        onHide={() => {
          setShowRescheduleTestModal(false)
        }}
        isRebook={showRebookModal}
      />

      <AcceptTest
        test={currentTest}
        show={showAcceptModal}
        onHide={() => {
          setShowAcceptModal(false)
        }}
        onStatusSubmit={getStudentTests}
      />

      <RejectTest
        test={currentTest}
        rejectionReasons={testRejectionReason?.filter((x) => x.type === currentTest?.type) ?? []}
        show={showRejectModal}
        onHide={() => {
          setShowRejectModal(false)
        }}
        onStatusSubmit={getStudentTests}
      />

      <AddTestResult
        test={currentTest}
        show={showAddTestResultModal}
        onHide={() => {
          setShowAddTestResultModal(false)
        }}
        setShowTestModal={setShowBookNewCourseModal}
        onStatusSubmit={getStudentTests}
        passProtectOption={passProtectOption}
      />

      <FeedbackModal
        adi_contact_id={adiData?.zendesk_contact_id}
        adi_id={adiData?.id}
        dealId={dealId}
        job_id={paymentInfo?.id}
        show={showFeedbackDialog}
        student_contact_id={zendeskContactId}
        onHide={() => {
          hideFeedbackDialog()
        }}
      />

      {paymentInfo?.remaining_amount > 0 && (
        <PaymentAmount
          show={showPaymentAmountModal}
          onHide={() => {
            setShowPaymentAmountModal(false)
            setPaying(false)
          }}
          loading={loading}
          hasAdi={adiData !== null && adiData !== undefined}
          remainingBalance={formatPriceIntoTwoDigits(+paymentInfo?.remaining_amount, false)}
          adiData={adiData}
        />
      )}
      <LessonFeedbackModal
        adi_contact_id={adiData?.zendesk_contact_id}
        adi_id={adiData?.id}
        dealId={dealId}
        job_id={paymentInfo?.id}
        show={showLessonFeedbackDialog}
        student_contact_id={zendeskContactId}
        lesson_data={lessonData}
        refreshDashboard={refreshDashboard}
        onHide={() => {
          hideLessonFeedbackDialog()
        }}
        adiName={adiData?.name}
      />

      {/* //#endregion */}
    </>
  ) : null
}

export {ActionCentre}

import { StudentAddress } from "./student-address";
import { StudentPhone } from "./student-phone";

export class StudentPersonalData {
    phone: StudentPhone = { id: null, phone: "" };
    title_id?: number | null = 0;
    gender_id?: number | null = 0;
    other_title?: string | null = "";
    first_name: string = "";
    surname: string = "";
    date_of_birth?: string | null = null;
    day?: string = "";
    month?: string = "";
    year?: string | null = "";
    zendesk_contact_id?: string | null = null;
    id?: string | null = null;
    addresses?: StudentAddress[];
    student_phones?: StudentPhone[];
    have_licence?: boolean | null = null;
    pickup_required?: boolean | null = null;
    request_delete_account_at?: string | null = null;
    cart_token?: string | null = null;
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Tab} from '@headlessui/react'
import {Skeleton} from '@mui/material'
import {Journey} from './Journey'
import ProgressTab from './tabs/ProgressTab'
import JourneyTab from './tabs/JourneyTab'
import {getOrder} from '../../services/booking.service'
import {useCourse} from '../../providers/CourseProvider'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
interface DashboardTabsProps {
  isPto: boolean
}
const DashboardTabs: FC<DashboardTabsProps> = ({isPto}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const {Course} = useCourse()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const DashboardView = () => {
    return (
      <div className='ps-6 pe-5'>
        <JourneyTab isPto={isPto} />
      </div>
    )
  }

  const ProgressView = () => {
    return (
      <div className=' px-5'>
        <ProgressTab />
      </div>
    )
  }

  const tabs = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      component: DashboardView(),
    },
    {
      key: 'progress',
      title: 'My progress',
      component: ProgressView(),
    },
  ]

  return (
    <>
      <div
        className={` 
        flex flex-col pb-3 `}
      >
        {isPto ? (
          <div className='grid grid-cols-1 gap-0 '>
             <div className='flex w-full bg-white sm:mt-0 pt-12 mb-8'>
            
                <div
                  className='w-full py-2.5 text-xl leading-5 font-bold ring-white ring-opacity-60 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-none border-b-4 border-green text-darkBlue'
                >
                 
                    <div className='px-10 mb-3'>Dashboard</div>
                </div>
            </div>
            <div className='ps-6 pe-5 '>
               <JourneyTab isPto={isPto} />
            </div>
            
          </div>
        ) : (
          <Tab.Group
            selectedIndex={selectedIndex}
            onChange={(index) => {
              setSelectedIndex(index)
            }}
          >
            <Tab.List className='flex w-full bg-white sm:mt-0 pt-12 mb-8'>
              {tabs.map((tab) => (
                <Tab
                  key={tab.key}
                  className={({selected}) =>
                    classNames(
                      'w-full py-2.5 text-base leading-5 font-semibold',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-none',
                      selected
                        ? ' border-b-4 border-green text-darkBlue'
                        : 'border-b-[3px] border-gray-700 text-gray-700 hover:brightness-75'
                    )
                  }
                >
                  {/* {isLoading ? (
                    <div className='flex justify-center'>
                      <Skeleton width={80} />
                    </div>
                  ) : ( */}
                    <>{tab.title}</>
                  {/* )} */}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className=''>
              {tabs.map((tab) => (
                <Tab.Panel
                  key={tab.key}
                  className={classNames(
                    'grid grid-cols-1 gap-0  overflow-y-hidden ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none'
                  )}
                >
                  {tab.component}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>
    </>
  )
}

export {DashboardTabs}

import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import * as auth from '../../../../setup/redux/AuthRedux'
import {useIntl} from 'react-intl'
import {RootState} from '../../../../setup'
import {resetPassword, login, requestPassword} from '../../services/auth.service'
import Alert from '../../components/shared/overlays/Alert'
import Input from '../../components/shared/forms/Input'
import Button from '../../components/shared/elements/Button'
import {useNavigate} from 'react-router'
import toast from 'react-hot-toast'

export function ResetPassword() {
  const intl = useIntl()

  const resetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage(
          {id: 'VALIDATION.EMAIL'},
          {field: intl.formatMessage({id: 'RESET_PASSWORD.EMAIL'})}
        )
      )
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'RESET_PASSWORD.EMAIL'})}
        )
      ),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'VALIDATION.MINUMUM'}, {min: 8}))
      .matches(new RegExp('.*\\d.*'), intl.formatMessage({id: 'RESET_PASSWORD.NUMBER_REQUIRED'}))
      .matches(
        new RegExp('.*[a-z].*'),
        intl.formatMessage({id: 'RESET_PASSWORD.LOWERCASE_REQUIRED'})
      )
      .matches(
        new RegExp('.*[A-Z].*'),
        intl.formatMessage({id: 'RESET_PASSWORD.UPPERCASE_REQUIRED'})
      )
      .matches(new RegExp('.*\\W.*'), intl.formatMessage({id: 'RESET_PASSWORD.SYMBOL_REQUIRED'}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'RESET_PASSWORD.PASSWORD'})}
        )
      ),
    code: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION.REQUIRED'},
        {field: intl.formatMessage({id: 'RESET_PASSWORD.CODE'})}
      )
    ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {field: intl.formatMessage({id: 'RESET_PASSWORD.CONFIRM_PASSWORD'})}
        )
      )
      .when('password', ([password], schema) => {
        return password && password.length > 0
          ? schema.oneOf(
              [Yup.ref('password')],
              intl.formatMessage({id: 'VALIDATION.PASSWORD_CONFIRM_MATCH'})
            )
          : schema
      }),
  })
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const email: string = useSelector<RootState, string>(({auth}) => String(auth.email))
  const navigate = useNavigate()
  const initialValues = {
    email: email,
    code: '',
    password: '',
    confirmPassword: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const response = await resetPassword(values.email, values.code, values.password).catch(
        (err) => {
          if (!err?.errorMessage) {
            toast.error('Failed to get lead time')
          }
          setLoading(false)
          setSubmitting(false)
        }
      )
      if (response) {
        const response = await login(values.email, values.password)
        if (response) {
          dispatch(auth.actions.setIsLogin(true))
          dispatch(auth.actions.login())
          dispatch(auth.actions.resetPassword())
        }
      }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-darkBlue mb-3'>
            {intl.formatMessage({id: 'RESET_PASSWORD.SET_PASSWORD'})}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'RESET_PASSWORD.ENTER_CODE'})}
          </div>
          {/* end::Link */}
        </div>

        <Input
          label='Email address'
          placeholder='you@example.com'
          type='email'
          required={true}
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMsg={formik.errors.email}
          disabled={formik.isSubmitting}
          {...formik.getFieldProps('email')}
          readonly
        />
        <Input
          type='text'
          placeholder='000000'
          {...formik.getFieldProps('code')}
          disabled={formik.isSubmitting}
          label='Code'
          error={formik.touched.code && formik.errors.code ? true : false}
          errorMsg={formik.errors.code}
          required={true}
          name='code'
          id='code'
        />
        <Button colour='link' onClick={() => requestPassword(formik.getFieldProps('email').value)}>
          {intl.formatMessage({id: 'RESET_PASSWORD.RESEND_CODE'})}
        </Button>

        {/* end::Form group */}

        <Input
          type='text'
          {...formik.getFieldProps('password')}
          disabled={formik.isSubmitting}
          label='Password'
          placeholder='********'
          error={formik.touched.password && formik.errors.password ? true : false}
          errorMsg={formik.errors.password}
          required={true}
          name='password'
          id='password'
          eyeIcon={true}
        />

        <Input
          type='text'
          {...formik.getFieldProps('confirmPassword')}
          disabled={formik.isSubmitting}
          label={intl.formatMessage({id: 'RESET_PASSWORD.CONFIRM_PASSWORD'})}
          placeholder='********'
          error={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
          errorMsg={formik.errors.confirmPassword}
          required={true}
          name='confirmPassword'
          id='confirmPassword'
          eyeIcon={true}
        />

        {/* begin::Form group */}
        <div className='flex flex-wrap items-center items-center space-x-2 lg:pb-0'>
          <Button
            type='submit'
            id='kt_password_reset_submit'
            disabled={formik.isSubmitting}
            size='large'
            halfWidth={true}
          >
            {!loading && (
              <span className='indicator-label'>{intl.formatMessage({id: 'ACTIONS.SUBMIT'})}</span>
            )}
            {loading && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'ACTIONS.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
          <Button
            colour='gray'
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            disabled={formik.isSubmitting}
            size='large'
            onClick={() => navigate('/auth/login')}
          >
            {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
          </Button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}

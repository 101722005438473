import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../helpers";

// Home - Lessons - HelpCentre - Addons - More
const navigation = [
  {
    name: "Home",
    href: "/dashboard",
    icon: "icon-home",
  },
  {
    name: "Lessons",
    href: "/lessons",
    icon: "icon-lesson",
  },
  {
    name: "Addons",
    href: "/addons",
    icon: "icon-addons",
  },{
    name: "HelpCentre",
    href: "/help-centre",
    icon: "icon-helpCentre",
  },{
    name: "More",
    href: "/more",
    icon: "icon-more",
  },
];

const MenuLinks = () => {
  
  const { pathname } = useLocation()
  const [navigationLinks, setNavigationLink] = useState(navigation)


  return (
    <>
        <div className={`${navigationLinks.length === 6 ? "grid-cols-6" : "grid-cols-5"}
              lg:flex grid h-full max-w-lg lg:flex-col lg:items-start lg:mx-0 lg:gap-y-4 mx-auto`
      }>
        {navigationLinks.map((item, i) => (
          <NavLink
            key={i}
            className={`${checkIsActive(pathname, item.href) ? "text-darkBlue lg:text-pink lg:border-b-0 border-b-4 border-pink sm:mx-0 mx-6" : ""}
            text-darkBlue hover:text-pink
              group flex gap-x-3 sm:py-2 py-5 text-sm leading-6 font-semibold lg:flex-row flex-col items-center justify-center  whitespace-nowrap`
            }
            // to={item.href}
            to={item.href}
          >
            <i
              className={`${item.icon} text-[26px]`}></i>
            <span className="lg:text-base text-xs hidden lg:block">{item.name}</span>
          </NavLink>
        ))}
      </div>
      <div className="hidden text-darkBlue order-2 text-center text-sm lg:block">
        <span className="font-normal">
          &copy; {new Date().getFullYear()} owned by Star Genie Limited
        </span>
      </div>
    </>
  );
};

export default MenuLinks;

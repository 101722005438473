import {FC, useEffect, useRef, useState} from 'react'
import Modal from '../../shared/overlays/Modal'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getAllDays, getMonths, getYears} from '../../../services/date.service'
import {toAbsoluteUrl} from '../../../helpers'
import {getUpcomingDateTime} from '../../../helpers/DateHelper'
import moment from 'moment'
import {TestRequest} from '../../../models/tests/add-test'
import {TestTypeEnum} from '../../../models/enums/test-type'
import {TestStatusTypeEnum} from '../../../models/enums/test-status-type'
import {scrollToTop} from '../../../helpers/ScrollHelper'
import {addTest, updateTest} from '../../../services/test.service'
import Input from '../../shared/forms/Input'
import RadioInputGroup from '../../shared/forms/RadioInputGroup'
import DropDown from '../../shared/forms/Dropdown'
import Alert from '../../shared/overlays/Alert'
import toast from 'react-hot-toast'

type Props = {
  show: any
  onHide: any
  booking_id: number | undefined
  initialTestType: 'practical' | 'theory'
  theoryTestCenters: Array<any>
  practicalTestCenters: Array<any>
  onTestSubmit: any
  saveTest: boolean
  hidePassed: boolean
  title: string
  id?: number
  booked_for?: string
  testCenterId?: string
  test_location?: string
  status?: TestStatusTypeEnum
  isPto?: boolean
  leadTime?: number
}

const AddTest: FC<Props> = ({
  show,
  onHide,
  initialTestType,
  booking_id,
  theoryTestCenters,
  practicalTestCenters,
  onTestSubmit,
  saveTest,
  hidePassed,
  title,
  id,
  booked_for,
  testCenterId,
  test_location,
  status,
  isPto,
  leadTime,
}) => {
  const intl = useIntl()
  const usageRef = useRef<null | HTMLDivElement>(null)
  const [months, setMonths] = useState<Array<any>>([])
  const [isResultRequired, setIsResultRequired] = useState<boolean>(
    booked_for ? moment(booked_for).isBefore() : false
  )
  useEffect(() => {
    setIsResultRequired(booked_for ? moment(booked_for).isBefore() : false)
    if (hidePassed && show) formik.setFieldValue('status', TestStatusTypeEnum.Failed)
  }, [show])
  const years =
    initialTestType === TestTypeEnum.Theory ? getYears(4, 2, true) : getYears(1, 0, true)
  const [days, setDays] = useState<Array<any>>([])
  const today = moment().format('YYYY-MM-DD')
  const [theoryOptions, setTheoryOptions] = useState<Array<any>>([])
  const [practicalOptions, setPracticalOptions] = useState<Array<any>>([])
  const [isTestSoon, setIsTestSoon] = useState(false)

  if (theoryTestCenters?.findIndex((a) => a.label === 'Other') === -1) {
    theoryTestCenters.unshift({label: 'Other', value: 'Other'})
    setTheoryOptions(theoryTestCenters)
  }
  if (practicalTestCenters?.findIndex((a) => a.label === 'Other') === -1) {
    practicalTestCenters?.unshift({label: 'Other', value: 'Other'})
    setPracticalOptions(practicalTestCenters)
  }
  useEffect(() => {
    setTheoryOptions(theoryTestCenters)
    setPracticalOptions(practicalTestCenters)
    setIsResultRequired(booked_for ? moment(booked_for).isBefore() : false)
  }, [practicalTestCenters, theoryTestCenters, booked_for])

  const updateAboutSchema = Yup.object().shape({
    test_type: Yup.string().required('Test type is required').nullable(),
    month: Yup.string().required('Month is required').nullable(),
    day: Yup.string().required('Day is required').nullable(),
    time: Yup.string().required('Time is required').nullable(),
    test_location: Yup.string().required('Test centre is required').nullable(),
    testCenterId: Yup.string().nullable(),
    formatedtime: Yup.string().nullable(),
    year: Yup.string().required('Year is required').nullable(),
    monthIndex: Yup.string().nullable(),
    test_date: Yup.string()
      .when(['day', 'month', 'year'], {
        is: (day: string, month: string, year: string) => {
          return formik.values.day && formik.values.monthIndex && formik.values.year
        },
        then: (schema) =>
          schema
            .test(
              'ValidateDate',
              intl.formatMessage(
                {id: 'VALIDATION.INVALID'},
                {field: intl.formatMessage({id: 'STUDENT_TEST.VALID_TEST_DATE'})}
              ),
              (values) => {
                const date: string = `${formik.values.year}-${Number(formik.values.monthIndex)}-${
                  formik.values.day
                }`
                const isValid = moment(date, 'YYYY-M-D', true).isValid()
                return isValid
              }
            )
            .test(
              'ValidateDate',
              intl.formatMessage({id: 'STUDENT_TEST.PAST_TEST_DATE'}),
              (values) => {
                const date: string = `${formik.values.year}-${Number(formik.values.monthIndex)}-${
                  formik.values.day
                }`
                return initialTestType === TestTypeEnum.Theory
                  ? moment(date, 'YYYY-M-D', true) >= moment(today).subtract(2, 'years') &&
                      moment(date, 'YYYY-M-D', true) <= moment(today).add(2, 'years')
                  : moment(date, 'YYYY-M-D', true) >= moment(today).subtract(2, 'years')
              }
            ),
      })
      .when(['day', 'month', 'year', 'test_type'], {
        is: (day: string, month: string, year: string, test_type: TestTypeEnum) => {
          return (
            formik.values.day &&
            formik.values.monthIndex &&
            formik.values.year &&
            formik.values.test_type === TestTypeEnum.Practical
          )
        },
        then: (schema) =>
          schema.test(
            'ValidateDate',
            intl.formatMessage({id: 'STUDENT_TEST.PT_FUTURE_ONLY'}),
            (values) => {
              const date: string = `${formik.values.year}-${Number(formik.values.monthIndex)}-${
                formik.values.day
              }`
              return moment(date, 'YYYY-M-D', true) > moment(today)
            }
          ),
      })
      .nullable(),
    status: isResultRequired ? Yup.string().required('Test result is required') : Yup.string(),
  })
  const checkValidDate = (date: any) => {
    let isvalid = true
    let isReqired = isResultRequired
    let res = moment(date).isBefore(moment().subtract(2, 'years'))
    if (res) {
      isvalid = false
    } else {
      isReqired = checkDate(
        formik.values.year,
        formik.values.monthIndex,
        formik.values.day,
        formik.values.formatedtime
      )
      if (isReqired && !formik.values.status) {
        isvalid = false
      }
    }
    return {isvalid, isReqired}
  }

  const formik: any = useFormik({
    initialValues: {
      test_type: initialTestType,
      month: booked_for ? getMonths()[moment(booked_for).toDate().getMonth()]?.value ?? '' : '',
      day: booked_for ? moment(booked_for).toDate().getDate().toString() ?? '' : '',
      time: booked_for ? moment(booked_for).local().format('HH:mm') : '',
      test_location: test_location,
      testCenterId: testCenterId,
      formatedtime: booked_for ? moment(booked_for).utc().format('HH:mm') : '',
      id: id,
      monthIndex: booked_for ? (moment(booked_for).toDate().getMonth() + 1).toString() : '',
      year: booked_for ? moment(booked_for).toDate().getFullYear().toString() : '',
      test_date: '',
      status: status,
      isStatusRequired: booked_for ? moment(booked_for).isBefore() : false,
    },

    enableReinitialize: true,
    validationSchema: updateAboutSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      const testRequest: TestRequest = {
        deal_id: booking_id,
        datetime: dateTimeformating() ?? '',
        booked_for: dateTimeformating() ?? '',
        id: values.id,
        type: values.test_type,
        is_private: true,
        is_suitable: true,
        test_centre_id: values.testCenterId,
        test_centre_name: values.test_location,
        created_on: 'student_portal',
        status: values.status,
        no_days_behind: NoOfDays(),
      }
      if (isResultRequired) {
        testRequest.pass_instantly = values.status === TestStatusTypeEnum.Passed
        testRequest.fail_instantly = values.status === TestStatusTypeEnum.Failed
      }

      let res = checkValidDate(testRequest.datetime)

      if (
        !res.isvalid ||
        (res.isReqired &&
          formik.values.status !== TestStatusTypeEnum.Passed &&
          formik.values.status !== TestStatusTypeEnum.Failed)
      ) {
        formik.validateForm()
        setSubmitting(false)
        return
      }
      const isTestSoon = isTestSooner()
      if (!saveTest) {
        onTestSubmit(testRequest)
        setSubmitting(false)
        resetForm()
        onHide(true)
      } else {
        if (!isTestSoon) {
          if (testRequest.id) {
            const response = await updateTest(testRequest).catch((error) => {
              scrollToTop()
              setSubmitting(false)
              if (!error?.message) toast.error('Failed to update test')
              usageRef.current?.scrollIntoView({behavior: 'smooth'})
            })
            if (!response) return
            await onTestSubmit()
            setSubmitting(false)
            resetForm()
            onHide()
          } else {
            const response = await addTest(testRequest).catch((error) => {
              scrollToTop()
              setSubmitting(false)
              if (!error?.message) toast.error('Unable to add test')
              usageRef.current?.scrollIntoView({behavior: 'smooth'})
            })
            if (!response) return
            await onTestSubmit()
            setSubmitting(false)
            resetForm()
            onHide()
          }
        } else {
          setSubmitting(false)
        }
      }
      onHide()
    },
  })

  const dateTimeformating = () => {
    const dateStr = getUpcomingDateTime(
      formik.values.year,
      Number(formik.values.monthIndex),
      formik.values.day,
      formik.values.formatedtime
    )
    return dateStr
  }

  const NoOfDays = () => {
    const testDate = moment([
      formik.values.year,
      Number(formik.values.monthIndex),
      formik.values.day,
    ])
    return testDate.diff(moment(), 'days')
  }

  const hide = () => {
    formik.resetForm({values: formik.initialValues})
    setIsTestSoon(false)
    onHide()
  }

  const checkDate = (year: any, month: any, day: any, time: any) => {
    let required = false
    if (day && month > 0 && year) {
      const date: string = `${year}-${Number(month)}-${day}`
      const isValid = moment(date, 'YYYY-M-D', true).isValid()
      if (isValid) {
        let today = new Date()
        let current = moment(
          `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          'YYYY-M-D'
        )
        let test_date = moment(date, 'YYYY-M-D', true)
        if (current.diff(test_date) > 0) {
          required = true
        } else if (current.diff(test_date) == 0 && time) {
          const dateStr = getUpcomingDateTime(year, Number(month) - 1, day, time)
          const currentdateTime = moment().format('YYYY-MM-DD HH:mm:ss')
          if (moment(dateStr ?? '').diff(moment(currentdateTime)) <= 0) {
            required = true
          }
        }
        if (required && hidePassed) {
          formik.setFieldValue('status', TestStatusTypeEnum.Failed)
        }
      }
      if (
        required &&
        formik.values.status !== TestStatusTypeEnum.Passed &&
        formik.values.status !== TestStatusTypeEnum.Failed
      ) {
        formik.setFieldValue('status', '')
      }
    }

    setIsResultRequired(required)
    return required
  }

  const isTestSooner = () => {
    if (leadTime !== undefined && isPto !== undefined && saveTest) {
      const noOfDays = NoOfDays()
      const isTestSoon = !isPto && initialTestType === TestTypeEnum.Practical && noOfDays < leadTime
      setIsTestSoon(isTestSoon)
      return isTestSoon
    }
    return false
  }

  useEffect(() => {
    prepareDays()
  }, [formik.values.monthIndex, formik.values.year])

  const prepareDays = () => {
    let days = getAllDays()
    setDays(days)
  }

  useEffect(() => {
    prepareMonths()
  }, [formik.values.year])

  const prepareMonths = () => {
    setMonths(getMonths())
  }

  return (
    <>
      <Modal
        open={show}
        onClose={hide}
        onSubmit={formik.handleSubmit}
        title={id ? 'Edit Test' : 'Add Test'}
        submitText={intl.formatMessage({id: 'ACTIONS.SAVE'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        disabled={formik.isSubmitting}
      >
        <h4 className='font-bold text-xl mb-6 text-darkBlue'>{title}</h4>
        <div>
          {formik.status && (
            <div ref={usageRef}>
              <Alert description={formik.status} colour='red'></Alert>
            </div>
          )}
          {isTestSoon ? (
            <Alert
              description={intl.formatMessage({id: 'STUDENT_TEST.SOONER_TEST'})}
              colour='red'
            ></Alert>
          ) : (
            <></>
          )}
          {formik.errors.test_date && (
            <div className='fv-plugins-message-container'>
              <Alert description={formik.errors.test_date} colour='red'></Alert>
            </div>
          )}
          <div className='flex flex-wrap items-end justify-between'>
            <label htmlFor='test_date' className='control-label'>
              {intl.formatMessage({id: 'STUDENT_TEST.TESTDATE'})}
              <span className='required'></span>
            </label>
          </div>

          <div className='col-span-12 -mt-5'>
            <div className='flex flex-row space-x-3'>
              <div className='flex flex-col flex-1'>
                <DropDown
                  options={days}
                  placeholder={intl.formatMessage({id: 'STUDENT_TEST.DAY'})}
                  name='day'
                  id='day'
                  disabled={formik.isSubmitting}
                  value={{
                    value: formik.values.day,
                    label: formik.values.day.length === 0 ? 'Day' : formik.values.day,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue('day', e?.value)
                    checkDate(
                      formik.values.year,
                      formik.values.monthIndex,
                      e?.value,
                      formik.values.formatedtime
                    )
                  }}
                  isSearchable={true}
                  error={formik.touched.day && formik.errors.day}
                  errorMsg={formik.errors.day}
                />
              </div>
              <div className='flex flex-col flex-1'>
                <DropDown
                  name='month'
                  id='month'
                  disabled={formik.isSubmitting}
                  value={{
                    value: formik.values.month,
                    label: formik.values.month.length === 0 ? 'Month' : formik.values.month,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'monthIndex',
                      getMonths().findIndex((a) => a.value === e?.value)
                    )
                    const index = getMonths().findIndex((a) => a.value === e?.value)
                    formik.setFieldValue('monthIndex', index + 1)
                    formik.setFieldValue('month', e?.value)
                    checkDate(
                      formik.values.year,
                      index + 1,
                      formik.values.day,
                      formik.values.formatedtime
                    )
                  }}
                  placeholder={intl.formatMessage({id: 'STUDENT_TEST.MONTH'})}
                  isSearchable={true}
                  options={months}
                  error={formik.touched.month && formik.errors.month}
                  errorMsg={formik.errors.month}
                />
              </div>
              <div className='flex flex-col flex-1'>
                <DropDown
                  name='year'
                  id='year'
                  value={{
                    value: formik.values.year,
                    label: formik.values.year.length === 0 ? 'Year' : formik.values.year,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue('year', e?.value)
                    checkDate(
                      e?.value,
                      formik.values.monthIndex,
                      formik.values.day,
                      formik.values.formatedtime
                    )
                  }}
                  placeholder={intl.formatMessage({id: 'STUDENT_TEST.YEAR'})}
                  isSearchable={true}
                  options={years}
                  disabled={formik.isSubmitting}
                  error={formik.touched.year && formik.errors.year}
                  errorMsg={formik.errors.year}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mb-2'>
          <div className='flex flex-wrap items-end justify-between -mb-5'>
            <label htmlFor='test_date' className='control-label'>
              {intl.formatMessage({id: 'STUDENT_TEST.TEST_TIME'})}
            </label>
            <div className='w-full -mt-6'>
              <Input
                id='time'
                name='test_time'
                disabled={formik.isSubmitting}
                className='form-group col-12 w-full bg-transparent'
                value={formik.values.time}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    'formatedtime',
                    moment(moment().format('YYYY-MM-DD') + ' ' + e.target.value)
                      .utc()
                      .format('HH:mm:ss')
                  )
                  formik.setFieldValue('time', e.target.value)
                  checkDate(
                    formik.values.year,
                    formik.values.monthIndex,
                    formik.values.day,
                    moment(moment().format('YYYY-MM-DD') + ' ' + e.target.value)
                      .utc()
                      .format('HH:mm:ss')
                  )
                }}
                placeholder='--:-- --'
                type='time'
                error={formik.touched.time && formik.errors.time}
                errorMsg={formik.errors.time}
              />
            </div>
          </div>
        </div>
        <div>
          <label htmlFor='test_location' className='control-label'>
            {intl.formatMessage({id: 'STUDENT_TEST.TEST_CENTER'})}{' '}
            <span className='required'></span>
          </label>
          <div className='col-12 -mt-5'>
            <div className='clearfix'>
              <div className='flex flex-row input-icons w-full'>
                <div className='form-group m-0 w-full'>
                  <DropDown
                    options={
                      formik.values.test_type === TestTypeEnum.Practical
                        ? practicalOptions
                        : theoryOptions
                    }
                    placeholder='Test Centres'
                    name='testCenter'
                    id='testCenter'
                    disabled={formik.isSubmitting}
                    value={{
                      label: formik.values.test_location,
                      value: formik.values.testCenterId,
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue('test_location', e.label)
                      formik.setFieldValue('testCenterId', e.value)
                    }}
                    error={
                      !formik.values.test_location &&
                      formik.touched.test_location &&
                      formik.errors.test_location
                    }
                    errorMsg={formik.errors.test_location}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Result section*/}
        {isResultRequired ? (
          <div>
            <label htmlFor='test_result' className='control-label'>
              {intl.formatMessage({id: 'STUDENT_TEST.RESULT'})} <span className='required'></span>
            </label>
            <div className='col-12 '>
              <div className='clearfix'>
                <div className='flex row input-icons w-full'>
                  <div className='form-group m-0 w-full'>
                    <div className='flex flex-column w-full'>
                      <div className='row m-0 justify-start w-full'>
                        {' '}
                        {hidePassed ? (
                          <>
                            <RadioInputGroup
                              label=''
                              name='test-result'
                              options={[
                                {
                                  value: TestStatusTypeEnum.Failed,
                                  label: TestStatusTypeEnum.Failed,
                                  img: toAbsoluteUrl('/assets/media/svg/fail-icon.svg'),
                                },
                              ]}
                              onChange={(value: any) => {
                                formik.setFieldValue('status', value)
                              }}
                              value={formik.values.status}
                              disabled={formik.isSubmitting}
                              error={formik.touched.status && formik.errors.status}
                              errorMsg={formik.errors.status}
                            />
                          </>
                        ) : (
                          <RadioInputGroup
                            label=''
                            name='test-result'
                            options={[
                              {
                                value: TestStatusTypeEnum.Passed,
                                label: TestStatusTypeEnum.Passed,
                                img: toAbsoluteUrl('/assets/media/svg/pass-icon.svg'),
                              },
                              {
                                value: TestStatusTypeEnum.Failed,
                                label: TestStatusTypeEnum.Failed,
                                img: toAbsoluteUrl('/assets/media/svg/fail-icon.svg'),
                              },
                            ]}
                            onChange={(value: any) => {
                              formik.setFieldValue('status', value)
                            }}
                            value={formik.values.status}
                            disabled={formik.isSubmitting}
                            error={formik.touched.status && formik.errors.status}
                            errorMsg={formik.errors.status}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </>
  )
}

export default AddTest

import moment from 'moment'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../helpers'
import Button from '../../../shared/elements/Button'
import Alert from '../../../shared/overlays/Alert'

type Props = {
  adiData: any
  paidInFull: any
  remainingBalance: any
  totalAmount: any
  pay: any
  disableAction: any
}

const Balance: FC<Props> = ({
  adiData,
  paidInFull,
  remainingBalance,
  totalAmount,
  pay,
  disableAction,
}) => {
  const intl = useIntl()

  return (
    <>
      {paidInFull ? (
        <>
          <div className='flex flex-col items-start pt-3'>
            <div className='flex items-center justify-start'>
              <div className=' flex-shrink'>
                <img
                  className=' w-8'
                  src={toAbsoluteUrl('/assets/media/dashboard/wallet-check.svg')}
                />
              </div>
              <div className='flex-grow ms-3 font-bold'>
                <h4 className='text-sm mb-1'>
                  {intl.formatMessage({id: 'DASHBOARD.PAYMENT.PAY_FULL'})}
                </h4>
                <p className='mb-0 text-base text-green'>{'£' + totalAmount}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='flex items-center justify-start py-3'>
            <div className=' flex-shrink'>
              <img className=' w-8' src={toAbsoluteUrl('/assets/media/dashboard/hold-card.svg')} />
            </div>
            <div className='flex-grow ms-3 font-bold'>
              <h4 className='text-sm mb-1'>
                {intl.formatMessage({id: 'DASHBOARD.PAYMENT.REMAINING_BALANCE'})}
              </h4>
              <p className='mb-0 text-base text-pink'>{'£' + remainingBalance}</p>
            </div>
          </div>
          <div className='flex my-[1px]'>
            <Alert
              customDesc={
                adiData ? (
                  moment(adiData?.allocation_date)
                    .add(7, 'days')
                    .isAfter(moment.now()) ? (
                    <span className='font-size-16 fw-600'>
                      {intl.formatMessage(
                        {id: 'DASHBOARD.PAYMENT.BALANCE_WARNING'},
                        {
                          date: moment(adiData?.allocation_date)
                            .add(7, 'days')
                            .format('DD/MM'),
                        }
                      )}
                    </span>
                  ) : (
                    <span className='font-size-16 fw-600'>
                      {intl.formatMessage({id: 'DASHBOARD.PAYMENT.BALANCE_ADI_ASSIGNED_WARNING_1'})}
                      <b> {intl.formatMessage({id: 'DASHBOARD.PAYMENT.URGENTLY'})} </b>
                      {intl.formatMessage({id: 'DASHBOARD.PAYMENT.BALANCE_ADI_ASSIGNED_WARNING_2'})}
                    </span>
                  )
                ) : (
                  <span className='font-size-16 fw-600'>
                    {intl.formatMessage({id: 'DASHBOARD.PAYMENT.BALANCE_ADI_WARNING'})}
                  </span>
                )
              }
            />
          </div>

          <div className='flex'>
            <Button colour='pink' type='button' onClick={pay} disabled={disableAction}>
              {intl.formatMessage({id: 'DASHBOARD.PAYMENT.PAY'})}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export {Balance}


export const getPaymentUrl = (requestUuid: string, source: string) => {
    let campaign = getCampaignFromSource(source)
    return `${process.env.REACT_APP_PAYMENT_WEBSITE}${requestUuid}?utm_campaign=student-portal&campaign=${campaign}`
}

const getCampaignFromSource = (source: string):string => {
    if (source.toLowerCase().includes("rebook")){
        return "rebook_test";
    }
    if (source.toLowerCase().includes("reschedule")) {
        return "reschedule_test";
    }
    return "addon_payment"    
}
import {Test} from '../models/tests/test'
import {RejectionReason} from '../models/tests/rejection-reason'
import {TestRequest} from '../models/tests/add-test'
import {UnsuitableDateReason} from '../models/tests/unsuitable-date-reason'
import modifiedFetch from '../../../setup/fetch/ModifiedFetch'

const API_URL = process.env.REACT_APP_TEST_SERVICE_URL
const API_KEY = process.env.REACT_APP_API_KEY

export async function addTest(request: TestRequest): Promise<any> {
  return await modifiedFetch({
    url: `${API_URL}/create`,
    method: 'POST',
    data: request,
    xApiKey: API_KEY,
  })
}
export async function updateTest(request: TestRequest): Promise<any> {
  return await modifiedFetch({
    url: `${API_URL}/${request.id}`,
    method: 'PATCH',
    data: request,
    xApiKey: API_KEY,
  })
}

export async function getPrivateTest(deal_id: number, type: string): Promise<Test[]> {
  return await modifiedFetch({
    url: `${API_URL}/deals/${deal_id}?type=${type}&is_private=1`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function getTestsByDeal(deal_id: number): Promise<Test[]> {
  return await modifiedFetch({
    url: `${API_URL}/deals/${deal_id}?is_suitable=1&hide_pending_adi_approval=1`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function getTestRejectionReasons(): Promise<RejectionReason[]> {
  return await modifiedFetch({
    url: `${API_URL}/rejection-reasons?is_adi_reason=0`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function acceptTest(id: number) {
  return await modifiedFetch({
    url: `${API_URL}/${id}/accept`,
    method: 'POST',
    xApiKey: API_KEY,
  })
}

export async function rejectTest(id: number, rejectionReasonBody: any) {
  return await modifiedFetch({
    url: `${API_URL}/${id}/reject`,
    method: 'POST',
    xApiKey: API_KEY,
    data: rejectionReasonBody,
  })
}

export async function passTest(id: number) {
  return await modifiedFetch({
    url: `${API_URL}/${id}/passed`,
    method: 'POST',
    xApiKey: API_KEY,
  })
}

export async function failTest(id: number) {
  return await modifiedFetch({
    url: `${API_URL}/${id}/failed`,
    method: 'POST',
    xApiKey: API_KEY,
  })
}

export async function getLatestTest(deal_id: number, type: string): Promise<Test> {
  return await modifiedFetch({
    url: `${API_URL}/deals/${deal_id}/latest?type=${type}&is_suitable=1&hide_pending_adi_approval=1`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function bookableTests(
  deal_id: number
): Promise<{practical: boolean; theory: boolean; pass_me_theory: boolean}> {
  return await modifiedFetch({
    url: `${API_URL}/deals/${deal_id}/bookable`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function deleteTest(id: number): Promise<Test> {
  return await modifiedFetch({
    url: `${API_URL}/${id}`,
    method: 'DELETE',
    xApiKey: API_KEY,
  })
}

export async function pendingTest(
  dealId: number
): Promise<{has_test_pending_adi_approval: boolean; has_test_pending_student_approval: boolean}> {
  return await modifiedFetch({
    url: `${API_URL}/deals/${dealId}/pending-approval`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function getTestUnsuitableDateReasons(): Promise<UnsuitableDateReason[]> {
  return await modifiedFetch({
    url: `${API_URL}/unsuitable-date-reasons`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}
export async function getRebookPreferredDates(dealId: number, type: string): Promise<Response> {
  return await modifiedFetch({
    url: `${API_URL}/deals/${dealId}/rebook-preferred-dates/${type}`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

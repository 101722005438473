export class Job {
  paid_in_full: boolean = false
  grp_next_amount: number | null = null
  remaining_amount: number = 0
  last_stage_change_at: Date | null = null
  stage_id: number | null = null
  hours: string | null = null
  transmission_type: string | null = null
  total_amount: string | null = null
  id: string | null = null
}

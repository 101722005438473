import {ChevronRightIcon} from '@heroicons/react/24/outline'
import React from 'react'
import Badge from '../elements/Badge'
import {toAbsoluteUrl} from '../../../helpers'
import Skeleton from '@mui/material/Skeleton'

export interface InfoListData {
  icon:
    | 'icon-history'
    | 'icon-clock'
    | 'icon-instructor'
    | 'icon-calendar'
    | 'icon-location'
    | 'icon-currency-pound'
    | 'icon-suitcase'
    | undefined
  value: string | React.ReactNode | undefined
  badge?: boolean
  linkURL?: string
  pmfIcon?: boolean
}

export interface InfoListProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean
  data?: InfoListData[]
}

export default function InfoList({
  title = 'Nothing To See Here',
  loading = false,
  data,
  ...props
}: InfoListProps) {
  return (
    <>
      <div className='w-full flex flex-col mt-1' {...props}>
        <ul className='list-none'>
          {loading ? (
            <>
              <li className='mb-2 last:mb-0 w-full flex justify-between'>
                <span className='flex items-center mt-0'>
                  <Skeleton
                    width={14}
                    height={14}
                    variant='circular'
                    className='me-3'
                    animation='wave'
                  />
                  <Skeleton width={150} className='me-2' animation='wave' />
                </span>
              </li>
              <li className='mb-2 last:mb-0 w-full flex justify-between'>
                <span className='flex items-center mt-0'>
                  <Skeleton
                    width={14}
                    height={14}
                    variant='circular'
                    className='me-3'
                    animation='wave'
                  />
                  <Skeleton width={150} className='me-2' animation='wave' />
                </span>
              </li>
              <li className='mb-2 last:mb-0 w-full flex justify-between'>
                <span className='flex items-center mt-0'>
                  <Skeleton
                    width={14}
                    height={14}
                    variant='circular'
                    className='me-3'
                    animation='wave'
                  />
                  <Skeleton width={150} className='me-2' animation='wave' />
                </span>
              </li>
            </>
          ) : (
            data?.map((item, i) => (
              <li key={i} className='mb-1 last:mb-0 w-full flex items-center'>
                <span className='flex items-center mt-0 grow'>
                  <i className={`${item.icon} mr-1 text-darkBlue text-base`}></i>
                  {item.badge ? (
                    <Badge roundedPill={false} title={item.value} colour='gray'></Badge>
                  ) : (
                    <>
                      <span className='flex text-sm text-darkBlue grow '>
                        {item.value}
                        {item.linkURL !== undefined && (
                          <a href={`${item.linkURL}`} target='_blank' rel='noopener noreferrer'>
                            <ChevronRightIcon className=' w-4 h-4 text-gray-600 ml-3' />
                          </a>
                        )}
                      </span>
                      {item?.pmfIcon ? (
                        <img
                          src={toAbsoluteUrl('/assets/media/logos/pmf.svg')}
                          className='h-4 ms-1'
                          alt='pmfIcon'
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </span>
              </li>
            ))
          )}
        </ul>
      </div>
    </>
  )
}

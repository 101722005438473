import {FC} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import {MedicalConditions} from '../../components/account-settings/medical-conditions/MedicalConditions'
import {useNavigate} from 'react-router-dom'

const MedicalConditionWrapper: FC = () => {
  const navigate = useNavigate()
  const redirectToDashboard = () => {
    navigate('/dashboard')
  }
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='Medical Conditions' backToURL='/profile' isCenter></PageHeader>
      </div>

      <div className='px-5 py-6 p-lg-0'>
        <MedicalConditions
          parentWrapper='accountsettings'
          goToPrevTab={redirectToDashboard}
          goToNextTab={redirectToDashboard}
        ></MedicalConditions>
      </div>
    </>
  )
}

export default MedicalConditionWrapper

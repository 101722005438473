import {FC} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import {AboutCourse} from '../../components/account-settings/about-course/AboutCourse'
import { useNavigate } from 'react-router-dom'

const AboutCourseWrapper: FC = () => {
   const navigate = useNavigate()
   const redirectToDashboard = () => {
     navigate('/dashboard')
   }
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='Availability' backToURL='/profile' isCenter />
      </div>
      <div className='px-5 py-6 p-lg-0'>
        <AboutCourse
          parentWrapper='accountsettings'
          goToPrevTab={redirectToDashboard}
          goToNextTab={redirectToDashboard}
        />
      </div>
    </>
  )
}

export {AboutCourseWrapper}

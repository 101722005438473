import React from 'react'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  colour?:
    | 'pink'
    | 'gradient'
    | 'darkBlue'
    | 'outline'
    | 'gray'
    | 'link'
    | 'dangerLink'
    | 'lightGray'
    | 'transparent'
    | 'darkGray'
    | 'outlineWhite'
  size?: 'icon' | 'medium' | 'large' | 'fit' | string
  rounded?: 'full' | 'sm' | 'none'
  className?: string
  Icon?: any
  fullWidth?: boolean
  halfWidth?: boolean
  fitWidth?: boolean
  onClick?: (e?: any) => void
  loading?: boolean
  disabled?: boolean
  children?: React.ReactNode
  type?: 'button' | 'submit'
}

export default function Button({
  colour = 'pink',
  className = '',
  size = 'medium',
  rounded = 'full',
  Icon,
  children,
  fullWidth,
  halfWidth = false,
  fitWidth = false,
  onClick,
  loading,
  disabled,
  type = 'button',
  ...props
}: ButtonProps) {
  // types: primary, secondary, warning, danger
  return (
    <>
      <button
        type={type}
        className={`${className} ${
          fullWidth ? 'w-full' : halfWidth ? 'w-1/2' : fitWidth ? 'w-fit' : 'flex-1'
        } ${
          {
            pink: 'bg-pink  border-[0.8px] border-pink text-white hover:brightness-75',
            gradient:
              'bg-gradient-to-r bg-gradient-to-30 from-blue to-green text-white hover:brightness-75 !p-1',
            darkBlue: 'bg-darkBlue border-[0.8px] border-darkBlue text-white hover:brightness-75',
            outline:
              'bg-transparent border-[0.8px] border-darkBlue text-darkBlue hover:brightness-75',
            gray: 'bg-gray-300 border-[0.8px] border-gray-600  hover:brightness-75',
            lightGray:
              'bg-[#F4F5F7] border-[0.8px] border-[#F4F5F7] text-darkBlue hover:brightness-75',
            darkGray:
              'bg-[#eceff1] border-[0.8px] border-[#eceff1] text-darkBlue hover:brightness-75',
            link: 'bg-transparent border-0 text-darkBlue hover:brightness-100',
            dangerLink: 'bg-transparent border-0 text-danger hover:brightness-100',
            transparent: 'bg-transparent border-transparent hover:brightness-75',
            outlineWhite:
              'bg-transparent border-[0.8px] border-white text-white hover:brightness-75',
          }[colour]
        } ${
          {
            icon: 'p-0 ms-2',
            medium: 'py-2 px-4',
            large: 'py-3 px-2 text-base',
            fit: 'p-0 h-fit',
          }[size]
        } ${
          {
            full: 'rounded-full',
            sm: 'rounded',
            none: 'rounded-none',
          }[rounded]
        } flex relative items-center justify-center ${
          disabled ? 'opacity-50' : 'opacity-100'
        } relative font-semibold text-xs outline-none transition-all`}
        onClick={onClick}
        disabled={disabled ? disabled : loading ? true : undefined}
        {...props}
      >
        <div
          className={`flex items-center ${
            colour === "gradient" &&
            "bg-darkBlue flex relative items-center justify-center rounded-full w-full h-[43px]"
          }
          ${loading ? 'opacity-0' : ''} transition-all ${
            !children && Icon
              ? 'inline-flex items-center justify-center rounded-full bg-[#F4F5F7] w-[30px] h-[30px] shadow-sm hover:bg-gray-50'
              : ''
          }`}
        >
          {Icon && (
            <Icon className={`h-4 w-4 ${!children ? 'mx-auto h-4 w-4 text-darkBlue' : 'mr-3'}`} />
          )}
          <div
            className={`font-semibold
        ${
          {
            icon: '',
            medium: 'text-sm',
            large: 'text-base',
            fit: 'text-sm',
          }[size]
        }
        ${colour === 'link' || colour === 'dangerLink' ? 'mt-2 underline' : ''}`}
          >
            {children}
          </div>
        </div>
        {loading && (
          <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center'>
            <svg
              className='animate-spin h-5 w-5'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          </div>
        )}
      </button>
    </>
  )
}

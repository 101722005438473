import {FC, useState, useEffect} from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {getAdiBasicData} from '../../services/adi.service'
import {
  addAddons,
  addHours,
  createCart,
  getAddons,
  getCart,
  purchaseRequest,
  removeAddons,
  removeHours,
} from '../../services/addons.service'
import {checkDisAbleAction} from '../../services/job.service'
import {getPaymentUrl} from '../../helpers/payment-helper'
import {useIntl} from 'react-intl'
import Alert from '../../components/shared/overlays/Alert'
import DisclosureItem from '../../components/shared/elements/DisclosureItem'
import {useCourse} from '../../providers/CourseProvider'
import {toAbsoluteUrl} from '../../helpers'
import {AdditionalHours} from '../../components/add-ons/AdditionalHours'
import Button from '../../components/shared/elements/Button'
import Skeleton from '@mui/material/Skeleton'
import {formatPriceIntoTwoDigits} from '../../helpers/PriceHelper'
import {ArrowRightIcon , ShoppingBagIcon} from '@heroicons/react/24/outline'
import toast from 'react-hot-toast'

const AddonsWrapper: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(true)
  const [dealId, setDealId] = useState<string>('')
  const [updatingAddons, setUpdatingAddons] = useState<boolean>(false)
  const [addonsAndProducts, setAddonsAndProducts] = useState<any>(null)
  const [cart, setCart] = useState<any>([])
  const [additionalHoursInCart, setAdditionalHoursInCart] = useState<any>(0)
  const [perHourCost, setPerHourCost] = useState<any>(0)
  const [total, setTotal] = useState(0)
  const [purchasing, setPurchasing] = useState<boolean>(false)
  const [disableAction, setDisableAction] = useState<boolean>(false)
  const [adiData, setAdiData] = useState<any>(null)
  const {Course} = useCourse()
  const isMobile: boolean = useSelector<RootState>(({auth}) => auth.isMobile) == true

  useEffect(() => {
    const fetchData = async () => {
      setDealId(Course.deal_id)
      if (Course.deal_id) {
        let error = false
        const response = await getAdiBasicData(Course.deal_id).catch(() => {
          error = true
        })
        error ? setAdiData(null) : setAdiData(response?.results)
        error = false
        const addons = await getAddons(Course.deal_id).catch((error) => {
          if (!error?.errorMessage) {
            toast.error('Failed to get addons')
          }
          error = true
        })
        setAddonsAndProducts(addons?.results)
        await initializeCart(addons?.results, Course.deal_id).catch(() => {})
        const checkDisable = await checkDisAbleAction(Course.deal_id).catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to check student ability to take action')
        })
        setDisableAction(checkDisable?.results)
        setLoading(false)
      }
    }
    fetchData()
  }, [Course.deal_id])

  const initializeCart = async (data: any, deal_id: string) => {
    let token = localStorage.getItem('cart-token')
    if (!token) {
      createNewCart(data, deal_id)
    } else {
      const response = await getCart(token).catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get cart')

        setLoading(false)
      })
      if (response?.amount_paid != null || response?.amount_paid > 0) {
        localStorage.removeItem('cart-token')
        createNewCart(data, deal_id)
      } else {
        let tempTotal = 0
        let tempCart: any = []

        response?.products.forEach((product: any) => {
          if (product?.category === 'Course') {
            setAdditionalHoursInCart(product?.hours)
            tempCart.push({price: product?.price, title: product?.name, is_course: 1})
          } else {
            tempCart.push({price: product?.price, title: product?.name, is_course: 0})
          }
          tempTotal += product?.price
        })

        setCart(tempCart ?? [])
        setTotal(parseFloat(tempTotal.toFixed(2)))
        setLoading(false)
      }
      setLoading(false)
    }
  }

  const createNewCart = async (data: any, deal_id: string) => {
    let source = 'Addons Payment'
    const result = await createCart(
      data?.postcode,
      data?.order_id,
      deal_id,
      data?.zendesk_contact_id,
      source
    ).catch((error) => {
      if (!error?.errorMessage) toast.error('Something went wrong while creating cart')
      setLoading(false)
    })
    localStorage.setItem('cart-token', result?.cart?.token)
    setLoading(false)
  }

  const updateCart = (
    productId: any,
    isAdded: boolean,
    price: any,
    title: string,
    is_course: number
  ) => {
    if (isAdded) {
      let updateCourse = false
      if (is_course === 1) {
        let newCart = cart.map((item: any) => {
          if (item?.is_course === 1) {
            updateCourse = true
            setTotal(parseFloat((total - item?.price + parseFloat(price)).toFixed(2)))
            return {price: price, title: title, is_course: 1}
          } else {
            return item
          }
        })
        setCart(newCart)
      }
      if (is_course === 0 || !updateCourse) {
        setCart([...cart, {price: price, title: title, is_course: is_course}])
        setTotal(parseFloat((total + parseFloat(price)).toFixed(2)))
      }

      if (is_course === 0) updateAddons(productId, true)
    } else {
      let newCart = cart.filter((item: any) => {
        if (item?.title === title || (item?.is_course === is_course && is_course === 1)) {
          setTotal(parseFloat((total - parseFloat(item?.price)).toFixed(2)))
          return false
        }
        return true
      })
      setCart(newCart)
      if (is_course === 0) updateAddons(productId, false)
    }
  }

  const updateAddons = async (productId: number, add: boolean) => {
    let token = localStorage.getItem('cart-token')
    setUpdatingAddons(true)
    let error = false
    if (token) {
      if (add) {
        const result = await addAddons(token, productId).catch((err) => {
          if (!err?.errorMessage) toast.error('Something went wrong while adding product')
          setUpdatingAddons(false)
          error = true
        })
        if (error) return
        setUpdatingAddons(false)
      } else {
        await removeAddons(token, productId).catch((err) => {
          if (!err?.errorMessage) toast.error('Something went wrong while creating cart')
          setUpdatingAddons(false)
          error = true
        })
        if (error) return
        setUpdatingAddons(false)
      }
    }
  }

  const purchase = async () => {
    let token = localStorage.getItem('cart-token')
    if (token) {
      setPurchasing(true)
      let error = false
      if (additionalHoursInCart > 0) {
        await addHours(token, additionalHoursInCart, perHourCost).catch((err) => {
          setPurchasing(false)
          error = true
          if (!err?.errorMessage) toast.error('Something went wrong while adding hours')
        })
        if (error) return
        if (token) getRedirctURL(token)
      } else {
        await removeHours(token).catch((error) => {
          if (!error?.errorMessage) toast.error('Something went wrong while removing hours')
          setPurchasing(false)
          error = true
        })
        if (error) return
        if (token) getRedirctURL(token)
      }
    }
  }

  const getRedirctURL = async (token: any) => {
    let successUrl = process.env.REACT_APP_PAYMENT_REDIRECT_URL
    let error = false
    const result = await purchaseRequest(
      total,
      dealId,
      token,
      successUrl ?? '',
      'Addons Payment'
    ).catch((err) => {
      if (!err?.errorMessage) toast.error('Something went wrong while sending purchase request')
      setPurchasing(false)
      error = true
    })
    if (error) {
      return
    }
    window.open(getPaymentUrl(result.uuid, 'Addons'), isMobile ? '_blanck' : '_self')
    setPurchasing(false)
  }
  return (
    <>
      <div className='bg-white pt-6'>
        <PageHeader title='Addons' backToURL='/' isCenter />
      </div>
      <>
        {
          <>
            <div className='p-6 p-lg-0'>
              <p className='font-size-16 fw-bold mb-4 mt-0 mt-lg-n4'>
                <Alert
                  description='Turbo charge your course! Take your pick from our Add-ons to boost your odds of
                    passing first time.'
                  loading={loading}
                  colour='pink'
                />
              </p>
              <div className='grid grid-cols-1 md:grid-cols-12 gap-5'>
                <div className='col-span-9 grid grid-cols-1 gap-y-5 mb-6'>
                  {disableAction && (
                    <div className='alert alert-warning mb-4 shadow-none d-flex'>
                      <Alert
                        colour='pink'
                        description={intl.formatMessage({id: 'ADDONS.COMPLETE_COURSE_ALERT_MSG'})}
                        loading={loading}
                        icon='icon-info'
                      />
                    </div>
                  )}
                  {/*Additional hours*/}
                  {
                    <DisclosureItem
                      title={intl.formatMessage({id: 'ADDONS.ADDITIONAL_HRS'})}
                      loading={loading}
                      disclosureCard={true}
                      disclosureImg='/assets/media/svg/addons/clock.svg'
                      description='The DVSA recommends at least 45 hours of professional driving tuition before sitting your test.'
                    >
                      <div className='px-4 pb-5 py-4'>
                        <div className='border-t-2 border-gray-200'>
                          <AdditionalHours
                            cost={addonsAndProducts?.per_hour_cost}
                            hours={additionalHoursInCart}
                            updateCart={updateCart}
                            updateHoursInCart={(hours: string) => setAdditionalHoursInCart(hours)}
                            hasAdi={adiData !== null && adiData !== undefined}
                            updateHourCost={(cost: any) => {
                              setPerHourCost(cost)
                            }}
                            loading={loading || updatingAddons}
                            disabled={loading || updatingAddons}
                          />
                        </div>
                      </div>
                    </DisclosureItem>
                  }
                  {/*Theory test*/}
                  {addonsAndProducts?.addons?.theory_test && (
                    <DisclosureItem
                      title='Fast-Track Theory Test'
                      loading={loading}
                      disclosureCard={true}
                      disclosureImg={'/assets/media/svg/addons/fast-track-theory-test.svg'}
                      description=''
                      imageBadge={
                        <div className='px-4'>
                          £{parseFloat(addonsAndProducts?.addons?.theory_test?.price)}
                        </div>
                      }
                      imageBadgeColour='darkBlue'
                    >
                      <div className='px-4 pb-5 '>
                        <div className='border-t-2 border-gray-200'>
                          {loading ? (
                            <Skeleton width={150} className='me-2' />
                          ) : (
                            <p className='py-5'>
                              Get a fast-tracked theory test and pass in as little as 2 weeks.
                            </p>
                          )}

                          <Button
                            fullWidth
                            onClick={() => {
                              updateCart(
                                addonsAndProducts?.addons?.theory_test?.id,
                                !(
                                  cart.filter(
                                    (item: any) =>
                                      item?.title === addonsAndProducts?.addons?.theory_test?.name
                                  ).length > 0
                                ),
                                parseFloat(addonsAndProducts?.addons?.theory_test?.price),
                                addonsAndProducts?.addons?.theory_test?.name,
                                0
                              )
                            }}
                            loading={loading || updatingAddons}
                            disabled={loading || updatingAddons}
                          >
                            {' '}
                            {cart.filter(
                              (item: any) =>
                                item?.title === addonsAndProducts?.addons?.theory_test?.name
                            ).length > 0
                              ? 'Remove Theory Test'
                              : 'Add Theory Test'}
                          </Button>
                        </div>
                      </div>
                    </DisclosureItem>
                  )}
                  {/*Practical test*/}
                  {addonsAndProducts?.addons?.practical_test && (
                    <DisclosureItem
                      title='Fast-Track Practical Test'
                      loading={loading}
                      disclosureCard={true}
                      disclosureImg={'/assets/media/svg/addons/fast-track-theory-test.svg'}
                      imageBadge={
                        '£' + parseFloat(addonsAndProducts?.addons?.practical_test?.price)
                      }
                      imageBadgeColour='darkBlue'
                    >
                      <div className='px-4 pb-5 '>
                        <div className='border-t-2 border-gray-200'>
                          {loading ? (
                            <Skeleton width={150} className='me-2' />
                          ) : (
                            <p className='py-5'></p>
                          )}
                          <Button 
                            fullWidth
                            onClick={() => {
                              updateCart(
                                addonsAndProducts?.addons?.practical_test?.id,
                                !(
                                  cart.filter(
                                    (item: any) =>
                                      item?.title ===
                                      addonsAndProducts?.addons?.practical_test?.name
                                  ).length > 0
                                ),
                                parseFloat(addonsAndProducts?.addons?.practical_test?.price),
                                addonsAndProducts?.addons?.practical_test?.name,
                                0
                              )
                            }}
                            loading={loading || updatingAddons}
                            disabled={loading || updatingAddons}
                          >
                            {' '}
                            {cart.filter(
                              (item: any) =>
                                item?.title === addonsAndProducts?.addons?.practical_test?.name
                            ).length > 0
                              ? 'Remove Practical Test'
                              : 'Add Practical Test'}
                          </Button>
                        </div>
                      </div>
                    </DisclosureItem>
                  )}
                  {/*Pass me theory*/}
                  {addonsAndProducts?.addons?.pass_me_theory && (
                    <DisclosureItem
                      title='PassMeTheory'
                      loading={loading}
                      disclosureCard={true}
                      disclosureImg='/assets/media/svg/addons/pass-me-theory.svg'
                      description='Don’t know the difference between a Toucan and a Pegasus crossing? Or just need to brush up on the Highway Code? '
                      imageBadge={
                        '£' + parseFloat(addonsAndProducts?.addons?.pass_me_theory?.price)
                      }
                      imageBadgeColour='darkBlue'
                    >
                      <div className='px-4 pb-5 '>
                        <div className='border-t-2 border-gray-200'>
                          <div className='pt-5'>
                            <Button
                              fullWidth
                              onClick={() => {
                                updateCart(
                                  addonsAndProducts?.addons?.pass_me_theory?.id,
                                  !(
                                    cart.filter(
                                      (item: any) =>
                                        item?.title ===
                                        addonsAndProducts?.addons?.pass_me_theory?.name
                                    ).length > 0
                                  ),
                                  parseFloat(addonsAndProducts?.addons?.pass_me_theory?.price),
                                  addonsAndProducts?.addons?.pass_me_theory?.name,
                                  0
                                )
                              }}
                              loading={loading || updatingAddons}
                              disabled={loading || updatingAddons}
                            >
                              {' '}
                              {cart.filter(
                                (item: any) =>
                                  item?.title === addonsAndProducts?.addons?.pass_me_theory?.name
                              ).length > 0
                                ? 'Remove PassMeTheory'
                                : 'Add PassMeTheory'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </DisclosureItem>
                  )}
                  {/*Pass protect*/}
                  {addonsAndProducts?.addons?.pass_protect && (
                    <DisclosureItem
                      title='PassProtect'
                      loading={loading}
                      disclosureCard={true}
                      disclosureImg={toAbsoluteUrl('/assets/media/svg/addons/pass-protect.svg')}
                      description='The simpler, cheaper alternative to “guaranteed pass” courses.  '
                      imageBadge={'£' + parseFloat(addonsAndProducts?.addons?.pass_protect?.price)}
                      imageBadgeColour='darkBlue'
                    >
                      <div className='px-4 pb-5 '>
                        <div className='border-t-2 border-gray-200'>
                          <div className='py-5'>
                            <p>
                              At just £150, PassProtect gives you two extra fast-tracked practical
                              tests for the price of one.
                            </p>
                            <p>
                              Nearly 9/10 of people pass their driving test within 3 attempts — if
                              at first you don’t succeed, you can try and try again with
                              PassProtect.
                            </p>
                          </div>

                          <Button 
                            fullWidth
                            onClick={() => {
                              updateCart(
                                addonsAndProducts?.addons?.pass_protect?.id,
                                !(
                                  cart.filter(
                                    (item: any) =>
                                      item?.title === addonsAndProducts?.addons?.pass_protect?.name
                                  ).length > 0
                                ),
                                parseFloat(addonsAndProducts?.addons?.pass_protect?.price),
                                addonsAndProducts?.addons?.pass_protect?.name,
                                0
                              )
                            }}
                            loading={loading || updatingAddons}
                            disabled={loading || updatingAddons}
                          >
                            {' '}
                            {cart.filter(
                              (item: any) =>
                                item?.title === addonsAndProducts?.addons?.pass_protect?.name
                            ).length > 0
                              ? 'Remove PassProtect'
                              : 'Add PassProtect'}
                          </Button>
                        </div>
                      </div>
                    </DisclosureItem>
                  )}
                </div>

                {/*START:: Cart */}
                
                <div
                  className='col-span-3 bg-pmf-image bg-cover bg-center ms-5
                fixed md:relative rounded-xl bottom-24 md:bottom-auto md:mx-0 md:end-4 right-6 left-6 md:right-auto md:left-auto p-0 z-[100]'
                >
                  <div className='p-4 md:p-5'>
                    <>
                      {/************** START:: Empty cart  **************/}
                      {total === 0 && (
                        <div className='hidden md:flex flex-col w-full overflow-y-hidden h-full justify-center'>
                          <div className='text-white'>
                            {loading ? (
                              <>
                                <Skeleton width={100} height={100} variant='circular' />
                              </>
                            ) : (
                              <>
                                <div className='p-8 flex items-center justify-center'>
                                  <ShoppingBagIcon className='w-14 h-14' />
                                </div>
                              </>
                            )}

                            <div className='flex flex-col items-center justify-center text-center px-5 pb-10'>
                              {loading ? 
                              <>
                                <Skeleton width={50} /> 
                                <Skeleton width={200} />
                              </>
                              : <>
                                <h4 className=' text-xl font-bold'>Your cart is empty</h4>
                                <p className='mt-2'>Start by selecting your course to see it in your cart.</p>
                              </>
                              }
                            </div>
                            
                          </div>
                        </div>
                      )}
                      {/************* Desktop Empty Cart ************/}
                      {total === 0 && (
                        <div className='flex  md:hidden '>
                          <span>
                            {loading ? (
                              <Skeleton width={35} height={35} />
                            ) : (
                              <img
                                alt='PassMeFast logo'
                                className='h-35px logo'
                                src={toAbsoluteUrl('/assets/media/logos/PassMeFast-logo.svg')}
                              />
                            )}
                          </span>
                          <h4 className='text-xl ms-4 font-semibold text-white'>
                            {loading ? <Skeleton width={120} /> : <>PassMeFast</>}
                          </h4>
                        </div>
                      )}
                      {/************* Mobile Empty Cart ************/}
                      {/************** END:: Empty cart **************/}
                    </>

                    {total > 0 && (
                      <>
                        <div>
                          <div className='hidden md:flex align-items-center'>
                            <span>
                              <img
                                alt='PassMeFast logo'
                                className='h-35px logo'
                                src={toAbsoluteUrl('/assets/media/logos/PassMeFast-logo.svg')}
                              />
                            </span>
                            <h4 className='text-xl ms-4 font-semibold text-white'>Cart Summary</h4>
                          </div>

                          {cart?.map((item: any, index: number) => (
                            <div
                              className='flex mt-10 hidden md:flex  justify-between align-center'
                              key={index}
                            >
                              <h4 className='text-white text-opacity-50 font-bold'>
                                {item?.title}
                              </h4>
                              <p className='text-right'>
                                <span className='text-white text-opacity-60 ml-4'>
                                  £{formatPriceIntoTwoDigits(item?.price)}
                                </span>
                              </p>
                            </div>
                          ))}

                          <div className='mt-10 mb-8 hidden md:flex justify-between align-items-center'>
                            <h4 className='text-white text-opacity-50 fw-bold'>Total</h4>
                            <div>
                              <span className='text-white font-semibold'>
                                £{formatPriceIntoTwoDigits(total)}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className=' flex justify-between '>
                          <div className='md:hidden justify-between align-items-center'>
                            <div className=''>
                              <i className='fas fa-shopping-cart font-size-18'></i>
                            </div>
                            <div className='pl-2'>
                              <p className='text-xs uppercase font-bold text-white text-opacity-80'>
                                {intl.formatMessage({id: 'MENU.ADDONS'})}
                              </p>
                              <div className='d-flex items-center text-white'>
                                <p className='font-semibold'>£{total}</p>
                              </div>
                            </div>
                          </div>
                          <div className='pl-4 '>
                            <Button
                              colour='outlineWhite'
                              onClick={purchase}
                              disabled={
                                purchasing || updatingAddons || total === 0 || disableAction
                              }
                            >
                              <span className='flex items-center'>
                                Purchase <ArrowRightIcon className='w-4 h-4' />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/*END:: Cart */}
              </div>
            </div>
          </>
        }
      </>
    </>
  )
}

export {AddonsWrapper}

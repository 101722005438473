import {FC, useState} from 'react'
import {
  format,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  setHours,
  setMinutes,
  addWeeks,
  subWeeks,
} from 'date-fns'
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/outline'
import moment from 'moment'
import {useSharedData} from '../../../providers/SharedDataProvider'
import LessonDetailsModal from '../_modal/LessonDetailsModal'

type Props = {
  highlightDates: any
  selectedDayInCalendar: Date
  setSelectedDayInCalendar: any
}

const WeeklyView: FC<Props> = ({
  highlightDates,
  selectedDayInCalendar,
  setSelectedDayInCalendar,
}) => {
  const {updateSharedData} = useSharedData()
  const [currentWeekStart, setCurrentWeekStart] = useState(
    startOfWeek(selectedDayInCalendar ?? new Date(), {weekStartsOn: 1})
  )
  // Create an array of hours from 0 to 23
  const hoursArray = Array.from({length: 24}, (_, index) => index)
  // Get the current date to set the minutes and seconds to 0
  const now = new Date()
  const currentDay = setMinutes(setHours(now, 0), 0)
  const [showLessonDetails, setShowLessonDetails] = useState<boolean>(false)
  const [selectedLesson, setSelectedLesson] = useState<any>(null)

  const generateWeeklyDates = () => {
    // Generate a date range for the current week
    const weekStartDate = startOfWeek(currentWeekStart, {weekStartsOn: 1})
    const weekEndDate = endOfWeek(currentWeekStart, {weekStartsOn: 1})
    return eachDayOfInterval({start: weekStartDate, end: weekEndDate})
  }

  const getMonthName = () => {
    // Extract the month name from the first day of the week
    return format(currentWeekStart, 'MMMM yyyy')
  }

  const handlePrevWeek = () => {
    setCurrentWeekStart((prevWeekStart) => subWeeks(prevWeekStart, 1))
    setSelectedDayInCalendar(startOfWeek(subWeeks(currentWeekStart, 1), {weekStartsOn: 1}))
    updateSharedData('selectedDate', startOfWeek(subWeeks(currentWeekStart, 1), {weekStartsOn: 1}))
  }

  const handleNextWeek = () => {
    setCurrentWeekStart((prevWeekStart) => addWeeks(prevWeekStart, 1))
    setSelectedDayInCalendar(startOfWeek(addWeeks(currentWeekStart, 1), {weekStartsOn: 1}))
    updateSharedData('selectedDate', startOfWeek(addWeeks(currentWeekStart, 1), {weekStartsOn: 1}))
  }

  const eventClickFun = (event: any) => {
    setShowLessonDetails(true)
    setSelectedLesson(event)
  }

  return (
    <>
      <LessonDetailsModal
        show={showLessonDetails}
        onHide={() => setShowLessonDetails(false)}
        showLesson={selectedLesson}
      />
      <div className='flex h-full flex-col sm:px-4'>
        <header className='flex flex-none items-center justify-between border-b border-gray-200 py-6'>
          <div>
            <h2 className='flex-auto text-base font-bold'>{getMonthName()}</h2>
          </div>
          <div className='flex items-center'>
            <div className='relative flex rounded-md bg-white shadow-sm items-stretch'>
              <button
                type='button'
                onClick={() => handlePrevWeek()}
                className='flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50'
              >
                <span className='sr-only'>Previous day</span>
                <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
              </button>
              <button
                type='button'
                onClick={() => {
                  setCurrentWeekStart(startOfWeek(new Date(), {weekStartsOn: 1}))
                  setSelectedDayInCalendar(new Date())
                }}
                className='border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative block'
              >
                Today
              </button>
              <button
                type='button'
                onClick={() => handleNextWeek()}
                className='flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50'
              >
                <span className='sr-only'>Next day</span>
                <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
              </button>
            </div>
          </div>
        </header>

        <div className='isolate flex flex-auto flex-col overflow-auto bg-white  max-h-[69vh] overflow-y-auto overflow-x-hidden'>
          <div
            style={{width: '165%'}}
            className='flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full'
          >
            <div className='sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8'>
              <div className='-mr-px grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 grid'>
                <div className='col-end-1 w-12' />
                {generateWeeklyDates().map((day, index) => (
                  <div key={index} className='flex items-center justify-center py-3'>
                    <span className='flex flex-col sm:flex-row items-center justify-center py-0'>
                      <span className='hidden sm:inline'>{format(day, 'E')} </span>
                      <span className='inline sm:hidden'>{format(day, 'E')[0]} </span>

                      <span
                        className={`${
                          moment(selectedDayInCalendar).isSame(moment(day), 'day')
                            ? 'text-center ms-0 sm:ms-2 rounded-full bg-darkBlue text-white'
                            : ''
                        }  w-8 h-8 inline-flex items-center justify-center font-semibold`}
                      >
                        {format(day, 'd')}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className='flex flex-auto'>
              <div className='sticky left-0 z-10 w-12 flex-none bg-white ring-1 ring-gray-100' />
              <div className='grid flex-auto grid-cols-1 grid-rows-1'>
                {/* Horizontal lines */}
                <div
                  className='col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100'
                  style={{
                    gridTemplateRows: 'repeat(24, minmax(4.5rem, 1fr))',
                  }}
                >
                  <div className='row-end-1 h-7'></div>
                  {hoursArray.map((hour) => {
                    const hourOfDay = setHours(currentDay, hour)
                    return (
                      <div key={hour}>
                        <div>
                          <div className='sticky left-0 z-20 -ml-12 -mt-2.5 w-12 pr-2 text-right text-xs leading-5 text-gray-400'>
                            {format(hourOfDay, 'h a')}
                          </div>
                        </div>
                        <div />
                      </div>
                    )
                  })}
                </div>

                {/* Vertical lines */}
                <div className='col-start-1 col-end-2 row-start-1 grid-cols-7 grid-rows-1 divide-x divide-gray-100 grid'>
                  <div className='col-start-1 row-span-full' />
                  <div className='col-start-2 row-span-full' />
                  <div className='col-start-3 row-span-full' />
                  <div className='col-start-4 row-span-full' />
                  <div className='col-start-5 row-span-full' />
                  <div className='col-start-6 row-span-full' />
                  <div className='col-start-7 row-span-full' />
                  <div className='col-start-8 row-span-full w-8 hidden sm:inline' />
                </div>

                {/* Events */}
                <ol
                  className='col-start-1 col-end-2 row-start-1 grid grid-cols-7 sm:pr-8'
                  style={{
                    gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto',
                  }}
                >
                  {highlightDates
                    .filter((elem: any) =>
                      moment(elem.date).isBetween(
                        moment(startOfWeek(currentWeekStart, {weekStartsOn: 1})),
                        moment(endOfWeek(currentWeekStart, {weekStartsOn: 1})),
                        'day',
                        '[]'
                      )
                    )
                    .map((item: any, i: number) => (
                      <>
                        <li
                          className={`relative mt-px flex]`}
                          key={i}
                          style={{
                            gridRow: `${
                              2 +
                              moment(item.date).get('hour') * 12 +
                              moment(item.date).get('minute') / 5
                            } / span ${12 * item.duration}`,
                            gridColumnStart:
                              moment(item.date).diff(moment(currentWeekStart), 'days') + 1,
                          }}
                        >
                          <p
                            onClick={() => eventClickFun(item)}
                            className='group absolute inset-1 flex items-center rounded-lg py-1 px-2 text-xs leading-5 border border-white bg-[#2BCD5A]'
                          >
                            <span className='text-white hidden md:inline mb-0'>
                              <time
                                className=' leading-none text-xs'
                                dateTime={moment(item.date).toISOString()}
                              >
                                {moment(item.date).format('hh:mm a')} - {''}
                              </time>
                            </span>
                            <span className='order-1 font-semibold text-white text-xs leading-none'>
                              {item.title}
                            </span>
                          </p>
                        </li>
                      </>
                    ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {WeeklyView}

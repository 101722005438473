import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import {CanAddFeedbackRequest} from '../models/feedback/can-add-feedback-request'
import {Feedback} from '../models/feedback/feedback'
import {LessonFeedback} from '../models/feedback/lesson-feedback'

const API_URL = `${process.env.REACT_APP_FEEDBACKS_BASE_URL}/cognito`
const API_KEY = process.env.REACT_APP_API_KEY

export async function getFeedbackQuestions(): Promise<BaseResponse<Feedback>> {
  return await modifiedFetch({
    url: `${API_URL}/feedback-questions`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function getLessonFeedbackQuestions(): Promise<BaseResponse<LessonFeedback>> {
  return await modifiedFetch({
    url: `${API_URL}/lessons-feedback-questions`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function canAddFeedback(data: CanAddFeedbackRequest): Promise<BaseResponse<boolean>> {
  return await modifiedFetch({
    url: `${API_URL}/can-add-feedback`,
    method: 'POST',
    xApiKey: API_KEY,
    data: data,
  })
}

export async function addFeedback(data: Feedback): Promise<BaseResponse<Feedback>> {
  return await modifiedFetch({
    url: `${API_URL}/add-feedback`,
    method: 'POST',
    xApiKey: API_KEY,
    data: data,
  })
}

export async function addLessonFeedback(data: LessonFeedback): Promise<LessonFeedback> {
  return await modifiedFetch({
    url: `${API_URL}/add-lesson-feedback`,
    method: 'POST',
    xApiKey: API_KEY,
    data: data,
  })
}

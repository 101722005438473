import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'

const API_URL = process.env.REACT_APP_BASE_URL
const BOOKINGS_URL = process.env.REACT_APP_BOOKING_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const PAYMENT_URL = process.env.REACT_APP_PAYMENT_BASE_URL

export async function getAddons(id: string): Promise<any> {
  return await modifiedFetch({
    url: `${API_URL}/students/${id}/addons`,
    method: 'POST',
  })
}

export async function getCart(token: string): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKINGS_URL}/carts/${token}`,
    method: 'GET',
    xApiKey: API_KEY,
  })
}

export async function createCart(
  postcode: string,
  id: number,
  dealId: string,
  zendeskId: string,
  source: string
): Promise<any> {
  const data = {
    postcode: postcode,
    order_id: id,
    deal_id: dealId,
    source: source,
    created_on: 'Student Portal',
    zendesk_contact_id: zendeskId,
  }
  return await modifiedFetch({
    url: `${BOOKINGS_URL}/carts/create`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function addHours(token: string, hours: number, perHourCost: any): Promise<any> {
  const data = {
    hours: hours,
    custom_price: perHourCost,
  }
  return await modifiedFetch({
    url: `${BOOKINGS_URL}/carts/${token}/hours`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function removeHours(token: string): Promise<any> {
  return await modifiedFetch({
    url: `${BOOKINGS_URL}/carts/${token}/hours`,
    method: 'DELETE',
    xApiKey: API_KEY,
  })
}

export async function addAddons(token: string, productId: number): Promise<any> {
  const data = {
    product_id: productId,
  }
  return await modifiedFetch({
    url: `${BOOKINGS_URL}/carts/${token}/addons`,
    method: 'POST',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function removeAddons(token: string, productId: number): Promise<any> {
  const data = {
    product_id: productId,
  }
  return await modifiedFetch({
    url: `${BOOKINGS_URL}/carts/${token}/addons`,
    method: 'DELETE',
    data: data,
    xApiKey: API_KEY,
  })
}

export async function purchaseRequest(
  amount: number,
  dealId: string,
  token: string,
  successUrl: string,
  source: string
): Promise<any> {
  const data = {
    amount: amount,
    deal_id: dealId,
    cart_token: token,
    success_url: successUrl,
    source: source,
  }
  return await modifiedFetch({
    url: `${PAYMENT_URL}`,
    method: 'POST',
    xApiKey: API_KEY,
    data: data,
  })
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {UserMenu} from '../../layout/UserMenu'
import {ActionCentre} from './ActionCentre'
import {toAbsoluteUrl} from '../../helpers'
import {useCourse} from '../../providers/CourseProvider'
import {getOrder} from '../../services/booking.service'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {User} from '../../models/auth/User'
import {Skeleton} from '@mui/material'
import {getLatestTest, getTestRejectionReasons} from '../../services/test.service'
import {Test} from '../../models/tests/test'
import {RejectionReason} from '../../models/tests/rejection-reason'
import {TestTypeEnum} from '../../models/enums/test-type'
import {Job} from '../../models/payments/job'
import {PassprotectWithRedemptionRemaining} from '../../models/student/pass-protect-with-redemption-remaining'
import {canAddFeedback} from '../../services/feedback.service'
import {getDashboardData, syncJobs} from '../../services/student.service'
import {ZendeskStages} from '../../models/enums/zendesk-stages-enum'
import moment from 'moment'
import {TestCentreTypeEnum} from '../../models/enums/test-centre-type'
import {getTestCentresByTypeID} from '../../services/lookup.service'
import toast from 'react-hot-toast'

interface DashboardHeaderProps {
  isPto: boolean
  isLoading: boolean
  order: any
}

const DashboardHeader: FC<DashboardHeaderProps> = ({isPto, order, isLoading}) => {
  const date = new Date()
  const [loading, setLoading] = useState<boolean>(false)
  const {Course} = useCourse()
  const personalData = useSelector<RootState>(({auth}) => auth.personalData, shallowEqual) as User

  {/*START:: Action Centre */}
  const [adiData, setAdiData] = useState<any>(null)
  const [theoryTest, setTheoryTest] = useState<Test | undefined>(undefined)
  const [practicalTest, setPracticalTest] = useState<Test | undefined>(undefined)
  const [testRejectionReason, setRejectionReason] = useState<RejectionReason[]>([])
  const [dealId, setDealId] = useState<any>()
  const [testFlags, setTestFlags] = useState<any>({})
  const [disableAction, setDisableAction] = useState<boolean>(false)
  const [lessonData, setLessonData] = useState<any>(null)
  const [canAddLessonFeedback, setCanAddLessonFeedback] = useState<boolean>(false)
  const [canProvideFeedbackLessonCondition, setCanProvideFeedbackLessonCondition] =
    useState<boolean>(false)
  const [paymentInfo, setPaymentInfo] = useState<Job>(new Job())
  const [hasPendingLessons, setHasPendingLessons] = useState<boolean>(false)
  const [canProvideFeedback, setCanProvideFeedback] = useState<boolean>(false)
  const [readyToPassPracticalTest, setReadyToPassPracticalTest] = useState<boolean>(false)
  const [studentCanAddFeedback, setStudentCanAddFeedback] = useState<boolean | null>(null)
  const [passprotectWithRedemptionRemaining, setPassprotectWithRedemptionRemaining] =
    useState<PassprotectWithRedemptionRemaining>()

  useEffect(() => {
    setDealId(Course.deal_id)
  }, [Course.deal_id])

  const checkCanAddFeedback = async (adi_id: string, deal_id: any) => {
    if (adi_id && deal_id && !loading) {
      const res = await canAddFeedback({adi_id: adi_id, zendesk_deal_id: deal_id}).catch(
        (error) => {
          if (!error?.errorMessage) {
            toast.error('Failed to check if student can add feedback')
          }
        }
      )
      setStudentCanAddFeedback(res?.results ?? false)
    }
  }

  useEffect(() => {
    if (studentCanAddFeedback != null && !loading) {
      setCanProvideFeedback(canProvideFeedbackLessonCondition && studentCanAddFeedback)
    }
  }, [canProvideFeedbackLessonCondition, studentCanAddFeedback])

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const rejectionReason = await getTestRejectionReasons().catch((error) => {
        if (!error?.errorMessage) {
          toast.error('Failed to get test rejection reasons')
        }
      })
      setRejectionReason(rejectionReason ?? [])
    }
    if (!loading) fetchData()
  }, [])

  const fetchDashboardData = async (dealId: string, courseId: string) => {
    try {
      const response = await getDashboardData(dealId, courseId).catch((error) => {
        if (!error?.errorMessage) toast.error('Failed to get dashboard data')
      })
      if (!response) return
      const questionnaireFlagData = response?.results?.abilities
      setTestFlags(questionnaireFlagData)

      const paymentInfoData = response?.results.job
      setPaymentInfo(paymentInfoData ?? new Job())
      if (paymentInfoData === null) {
        syncJobs().catch((error) => {
          if (!error?.errorMessage) toast.error('Failed to get sync job data')
        })
      }
      const theoryTest = response.results.theory_test
      setTheoryTest(theoryTest)
      const practicalTest = response.results.practical_test
      setPracticalTest(practicalTest)
      const adiInfoData = response.results.adi
      setAdiData(adiInfoData)
      setPassprotectWithRedemptionRemaining(response?.results?.pass_protect_options)
      checkCanAddFeedback(adiInfoData?.id, dealId)
      setHasPendingLessons(response.results.lessons.pending_action)

      setDisableAction(response.results.deal.is_action_disabled)
      setCanProvideFeedbackLessonCondition(response?.results?.abilities.canAddFeedback ?? false)
      setReadyToPassPracticalTest(response.results.abilities.ready_to_pass_practical_test ?? false)
      setCanAddLessonFeedback(
        response?.results?.abilities.canAddLessonFeedback.canAddLessonFeedback
      )
      setLessonData(response?.results?.abilities.canAddLessonFeedback.lessonData ?? null)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const refreshDashboardData = () => {
    fetchDashboardData(dealId, Course.order?.id)
  }
    useEffect(() => {
      if (dealId && Course.order?.id) {
        setLoading(true)
        fetchDashboardData(dealId, Course.order?.id)
      }
    }, [dealId, Course.order?.id])

  async function getStudentTests() {
    const theoryTestRes = await getLatestTest(dealId, TestTypeEnum.Theory).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get latest theory test')
    })
    const theoryTest = theoryTestRes
    setTheoryTest(theoryTest ?? undefined)
    const practicalTestRes = await getLatestTest(dealId, TestTypeEnum.Practical).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get latest practical test')
    })
    const practicalTest = practicalTestRes
    setPracticalTest(practicalTest ?? undefined)
  }

  {/*END:: Action Centre */}

  return (
    <>
      <div
        className={`pt-10 flex flex-col pb-3 bg-pmf-image bg-cover bg-center bg-gradient-to-br bg-gradient-to-30 from-green via-darkBlue to-green sm:mt-0 -mt-6`}
      >
        <div className='px-5'>
          <UserMenu date={date} personalData={personalData} />
          
          <ActionCentre
            theoryTest={theoryTest}
            practicalTest={practicalTest}
            paymentInfo={paymentInfo}
            hasPendingLessons={hasPendingLessons}
            testRejectionReason={testRejectionReason}
            adiData={adiData}
            getStudentTests={getStudentTests}
            hasAdi={adiData !== null && adiData !== undefined}
            dealId={dealId}
            disableAction={disableAction}
            canAddFeedback={canProvideFeedback}
            readyToPassPracticalTest={readyToPassPracticalTest}
            zendeskContactId={personalData?.zendesk_contact_id}
            checkCanAddFeedback={checkCanAddFeedback}
            passProtectOption={passprotectWithRedemptionRemaining}
            loading={loading}
            lastActiveDeal={Course.last_active_deal}
            canAddLessonFeedback={canAddLessonFeedback}
            lessonData={lessonData}
            refreshDashboard={refreshDashboardData}
            hasPoorAvailability={testFlags.has_poor_availability}
          />

          {/* START: Course Data */}
          <div className='relative w-full -mb-12'>
            <div className=' font-semibold mt-6 pb-24'>
              {isLoading ? (
                <>
                  <Skeleton width={150} />
                  <Skeleton width={150} />
                </>
              ) : (
                <>
                  {!isPto ? (
                    <>
                      <h3 className='text-base font-normal text-green uppercase'>
                        {' '}
                        {order?.transmission} Car
                      </h3>
                      <h4 className='text-4xl text-white uppercase'>
                        {' '}
                        {order?.course_hours} hours
                      </h4>
                    </>
                  ) : (
                    <>
                      <h3 className='text-base font-normal text-green uppercase'>
                        {' '}
                        Practical Test Only
                      </h3>
                      <h4 className='text-4xl text-white uppercase'>
                        {' '}
                        {order?.course_hours} hours
                      </h4>
                    </>
                  )}
                </>
              )}
            </div>
            <img
              src={toAbsoluteUrl('/assets/media/car-with-shadow 2.png')}
              className='px-0 w-24px absolute -right-3 top-2'
            />
          </div>

          {/* END: Course Data */}
        </div>
      </div>
    </>
  )
}

export {DashboardHeader}

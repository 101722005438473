import {useState} from 'react'
import Navigation from './Navigation'
import {Sidebar} from './Sidebar'
import {Outlet} from 'react-router-dom'
import Alert from '../components/shared/overlays/Alert'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {RootState} from '../../../setup'
import {User} from '../models/auth/User'
import {Auth} from 'aws-amplify'
import * as auth from '../../../setup/redux/AuthRedux'
import Button from '../components/shared/elements/Button'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

const MasterLayout = () => {
  const personalData: User = useSelector<RootState>(
    ({auth}) => auth.personalData,
    shallowEqual
  ) as User
  const impersonatorToken = localStorage.getItem(`impersonator-token`)
  const dispatch = useDispatch()
  const [modalParam, setModalParam] = useState(0)

  const refreshParent = () => {
    setModalParam(modalParam === 1 ? 0 : 1)
  }

  const logout = () => {
    Auth.signOut()
    dispatch(auth.actions.logout())
  }

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col'>
          <Sidebar />
        </div>

        <div className='lg:pl-60'>
          {impersonatorToken && (
            <>
              <div className='bg-pink text-white py-3 px-6'>
                <div className='flex justify-between items-center'>
                  <p className='text-base sm:w-full grow'>
                    You are viewing this page as an admin, Logged in as{' '}
                    <b>{personalData?.name + ' ' + personalData?.sur_name}</b>
                  </p>
                  <div className='flex-shrink'>
                  <Button colour='outlineWhite' onClick={logout}><div className='flex px-2'>Exit <ArrowRightIcon className='w-4 h-4 mt-[3px] ms-2' /></div> </Button>
                  </div>
                </div>
              </div>
            </>
          )}
          <main className='pb-10 overflow-x-hidden'>
            <div className='pb-[100px]' key={modalParam}>
              <Outlet />
            </div>
            <div className='block w-full lg:hidden'>
              <Navigation />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export {MasterLayout}

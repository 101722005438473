import {Suspense, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {BaseRoutes} from './app/routing/BaseRoutes'
import ReactGA from 'react-ga4'
import initializeAmplify from './setup/amplify/setup-amplify'
import { toAbsoluteUrl } from './app/content/helpers'
import is from 'is_js'
import { I18nProvider } from './app/config/i18n/i18nProvider'

ReactGA.initialize(`${process.env.REACT_APP_GA4_ID}`)
type Props = {
  basename: string
}

/*mac 8.0.1*/
is.not.mac()

initializeAmplify();

// Refer a friend SORETO config
window.SORETO = {
  Client: {id: '644ab5137008b30ff92276c6'},
  User: {},
  Widget: {
    showWidget: true,
    widgetType: 'sharestaticpage',
  },
  testMode: process.env.REACT_APP_ENV !== 'production',
  sourceTag: 'STATIC_PAGE_ON_CLIENT',
}

const App: React.FC<Props> = ({basename}) => {
  useEffect(() => {
    const isSessionOpened = sessionStorage.getItem('isSessionOpened')
    if (!isSessionOpened && localStorage.getItem('impersonator-token')) {
      sessionStorage.setItem('isSessionOpened', 'true')
      localStorage.clear()
    }
  }, [])

  // load refer a friend SORETO script
  useEffect(() => {
    if (window && document && window.SORETO) {
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]
      script.src = 'https://api.soreto.com/scripts/soreto.min.js'
      body.appendChild(script)
    }
  }, [window.SORETO])

  return (
    <>
      <Suspense>
        <BrowserRouter basename={basename}>
          <I18nProvider>
              <BaseRoutes />
          </I18nProvider>
        </BrowserRouter>

        {is.mac() && is.chrome('<81') && (
          <>
            <div className='blocked-os'>
              <div className='d-flex flex-column align-items-center h-100 justify-content-center'>
                <img
                loading="lazy"
                  src={toAbsoluteUrl('/assets/media/logos/logo-2.svg')}
                  alt='Passmefast logo'
                  className='h-50px'
                />
                <h2 className='fs-1 mt-5'>Sorry, your operating system is not supported</h2>
              </div>
            </div>
          </>
        )}
      </Suspense>
    </>
  )
}

export default App

import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {scrollToTop} from '../../helpers/ScrollHelper'
import {useCourse} from '../../providers/CourseProvider'
import {GetInTouchModel} from '../../models/get-in-touch-model'
import {SendEmailNotifications} from '../../models/send-email-notification'
import {sendNotification} from '../../services/notification.service'
import Modal from '../shared/overlays/Modal'
import TextArea from '../shared/forms/TextArea'
import Dropdown from '../shared/forms/Dropdown'
import toast from 'react-hot-toast'

type Props = {
  show: any
  onClose: any
  queryRelatedOptions: Array<any>
}

const GetInTouch: FC<Props> = ({show, onClose, queryRelatedOptions}) => {
  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    query_related_option_value: Yup.number().required('This field is required'),
    description: Yup.string().required('This field is required'),
  })
  const [showDescription, setShowDescription] = useState<boolean>(false)
  const {Course} = useCourse()

  const formik = useFormik({
    initialValues: {
      query_related_option_label: '',
      query_related_option_value: '',
      description: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      let getInTouchEmail: SendEmailNotifications<GetInTouchModel> = {
        action_name: 'getInTouchRequest',
        email: `${process.env.REACT_APP_PMF_SUPPORT_EMAIL}`,
        email_data: {
          deal_id: Course.deal_id,
          query_content: values.description == '' ? '-' : values.description,
          query_subject: values.query_related_option_label,
        },
      }
      let res = await sendNotification(getInTouchEmail).catch((error) => {
        setSubmitting(false)
        if (!error?.errorMessage) {
          toast.error('Notification not submitted')
        }
      })
      if (res) {
        handleClose()
        setSubmitting(false)
        scrollToTop()
      }
    },
  })

  const resetComponent = () => {
    setShowDescription(false)
    formik.resetForm()
  }

  const handleClose = () => {
    resetComponent()

    onClose(null)
  }

  return (
    <>
      <Modal
        title='Get in touch'
        open={show}
        onClose={handleClose}
        onSubmit={formik.handleSubmit}
        submitText={intl.formatMessage({id: 'ACTIONS.SUBMIT'})}
        closeText={intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        disableSubmit={formik.isSubmitting}
        disabled={formik.isSubmitting}
      >
        <>
          <Dropdown
            label={intl.formatMessage({id: 'GET_IN_TOUCH.QUERY_RELATED'})}
            name='query_related_option_value'
            id='query_related_option_value'
            options={queryRelatedOptions}
            disabled={formik.isSubmitting}
            onChange={(e) => {
              formik.setFieldValue('query_related_option_value', e?.value)
              formik.setFieldValue('query_related_option_label', e?.label)
              setShowDescription(true)
            }}
            value={{
              value: formik.values.query_related_option_value,
              label: formik.values.query_related_option_label,
            }}
            placeholder={intl.formatMessage({id: 'GET_IN_TOUCH.QUERY_RELATED_PLACEHOLDER'})}
            error={
              formik.touched.query_related_option_value && formik.errors.query_related_option_value
                ? true
                : false
            }
            errorMsg={formik.errors.query_related_option_value}
            required={true}
          />
          {showDescription && (
            <TextArea
              {...formik.getFieldProps('description')}
              className='form-control'
              name='description'
              id='description'
              onChange={(v) => {
                formik.setFieldValue('description', v.target.value)
              }}
              label={intl.formatMessage({id: 'GET_IN_TOUCH.DESCRIPTION'})}
              placeholder={intl.formatMessage({id: 'GET_IN_TOUCH.DESCRIPTION_PLACEHOLDER'})}
              disabled={formik.isSubmitting}
              rows={3}
              required={true}
              error={formik.touched.description && formik.errors.description ? true : false}
              errorMsg={formik.errors.description}
            ></TextArea>
          )}
        </>
      </Modal>
    </>
  )
}

export {GetInTouch}

import { FC } from "react";
import { useIntl } from "react-intl";
import { CourseUpgradeResponse } from "../../../models/order/course-upgrade-response";
import Modal from "../../shared/overlays/Modal";
import { formatPriceIntoTwoDigits } from "../../../helpers/PriceHelper";


type Props = {
    show: boolean
    handleClose: any,
    handleSubmit: any,
    submitting: boolean,
    courseUpgradeData?: CourseUpgradeResponse
    parentWrapper?: string
}

const CourseUpgradeModal: FC<Props> = (props) => {
    const intl = useIntl()
    return <>
        <Modal
            open={props.show}
            onClose={props?.handleClose}
            onSubmit={() => { props?.handleSubmit(false, props.parentWrapper !== 'questionnaire')}}
            submitText={intl.formatMessage({ id: 'UPGRADE_COURSE.CONTINUE' })}
            disableSubmit={props.submitting}
            title=""
        >
            <div className='p-4'>
                {props.courseUpgradeData?.is_course_upgrade ?
                    <p className='fw-normal text-gray-900'>
                        {props.courseUpgradeData?.has_accepted_practical || props.courseUpgradeData?.has_accepted_theory ?
                            <>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.QUESTIONNAIRE_PRICE_CHANGED_1' })}
                                <strong>£{formatPriceIntoTwoDigits(props.courseUpgradeData?.new_total_price)}</strong>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.PRICE_CHANGED_WITH_RESCHEDULE_1' })}
                                <strong>
                                    £{props.courseUpgradeData?.has_accepted_practical ?
                                        props.courseUpgradeData?.reschedule_pt_product?.price :
                                        props.courseUpgradeData?.reschedule_tt_product?.price
                                    }
                                </strong>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.PRICE_CHANGED_WITH_RESCHEDULE_2' })}
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.QUESTIONNAIRE_PRICE_CHANGED_2' })}
                            </>
                            :
                            <>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.QUESTIONNAIRE_PRICE_CHANGED_1' })}
                                <strong>£{formatPriceIntoTwoDigits(props.courseUpgradeData?.new_total_price)}</strong>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.QUESTIONNAIRE_PRICE_CHANGED_2' })}
                            </>
                        }
                    </p>
                    :
                    <p className='fw-normal text-gray-900'>
                        {props.courseUpgradeData?.has_accepted_practical || props.courseUpgradeData?.has_accepted_theory ?
                            <>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.QUESTIONNAIRE_PRICE_NOT_CHANGED_1' })}
                                <strong>{intl.formatMessage({ id: 'UPGRADE_COURSE.HAS_NOT' })}</strong>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.PRICE_NOT_CHANGED_WITH_RESCHEDULE_1' })}
                                <strong>
                                    £{props.courseUpgradeData?.has_accepted_practical ?
                                        props.courseUpgradeData?.reschedule_pt_product?.price :
                                        props.courseUpgradeData?.reschedule_tt_product?.price
                                    }
                                </strong>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.PRICE_NOT_CHANGED_WITH_RESCHEDULE_2' })}
                            </>
                            :
                            <>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.QUESTIONNAIRE_PRICE_NOT_CHANGED_1' })}
                                <strong>{intl.formatMessage({ id: 'UPGRADE_COURSE.HAS_NOT' })}</strong>
                                {intl.formatMessage({ id: 'UPGRADE_COURSE.QUESTIONNAIRE_PRICE_NOT_CHANGED_2' })}
                            </>
                        }
                    </p>
                }
            </div>
        </Modal></>
}

export default CourseUpgradeModal
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useCourse} from '../../providers/CourseProvider'
import {OrderPayment} from '../../models/payments/order-payment'
import {getOrderPayments} from '../../services/booking.service'
import Skeleton from '@mui/material/Skeleton'
import toast from 'react-hot-toast'

type Props = {}
const PaymentHistory: FC<Props> = () => {
  const intl = useIntl()
  const [loadingPayments, setLoadingPayments] = useState<boolean>(true)
  const [orderPayments, setOrderPayments] = useState<Array<OrderPayment>>([])
  const {Course} = useCourse()

  useEffect(() => {
    if (Course.deal_id) {
      setLoadingPayments(true)
      fetchData()
      setLoadingPayments(false)
    }
  }, [Course.deal_id])

  async function fetchData() {
    let res = await getOrderPayments(Course.deal_id).catch((error) => {
      if (!error?.errorMessage) toast.error('Failed to get order')
      setLoadingPayments(false)
    })
    setOrderPayments(res ?? [])
  }

  var orderPaymentsRows = orderPayments.map(function (orderPayment: OrderPayment, key: number) {
    return (
      <>
        <tr
          className='table-row bg-white item-card mb-5 justify-between w-100 rounded-lg my-50 text-center'
          key={key}
        >
          <td
            data-label={intl.formatMessage({id: 'PAYMENT_HISTORY.DESCRIPTION'})}
            className='rounded order-1 col-6 col-lg-3 px-1 px-lg-3 py-3'
          >
            <span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
              {orderPayment?.description?.trim()?.toLowerCase() == 'Zendesk Payment'.toLowerCase()
                ? '-'
                : orderPayment.description}
            </span>
          </td>

          <td
            data-label={intl.formatMessage({id: 'PAYMENT_HISTORY.PAYMENT_METHOD'})}
            className='order-1 col-6 col-lg-3 px-2 ps-1 ps-lg-0 py-3'
          >
            <span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
              {orderPayment.payment_method}
            </span>
          </td>

          <td
            data-label={intl.formatMessage({id: 'PAYMENT_HISTORY.AMOUNT'})}
            className='order-4 col-6 col-lg-2 px-2 ps-1 ps-lg-0 py-3'
          >
            <span className='font-weight-black d-block fs-3 text-green'>
              £{orderPayment.amount}
            </span>
          </td>

          <td
            data-label={intl.formatMessage({id: 'PAYMENT_HISTORY.ADDED_ON'})}
            className='rounded order-1 col-6 col-lg-3 px-2 ps-1 ps-lg-0 py-3'
          >
            <span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
              {orderPayment?.description?.trim()?.toLowerCase() == 'Zendesk Payment'.toLowerCase()
                ? '-'
                : orderPayment.created_at_human}
            </span>
          </td>
        </tr>
      </>
    )
  })

  return (
    <>
      <div className='responsive overflow-x-auto'>
        <table className='table-auto w-full shadow-md mt-5 rounded border-separate border-spacing-y-3'>
          <thead>
            <tr className='text-center'>
              <th>
                {loadingPayments ? (
                  <Skeleton width={150} />
                ) : (
                  intl.formatMessage({id: 'PAYMENT_HISTORY.DESCRIPTION'})
                )}
              </th>
              <th>
                {loadingPayments ? (
                  <Skeleton width={150} />
                ) : (
                  intl.formatMessage({id: 'PAYMENT_HISTORY.PAYMENT_METHOD'})
                )}
              </th>
              <th>
                {loadingPayments ? (
                  <Skeleton width={100} />
                ) : (
                  intl.formatMessage({id: 'PAYMENT_HISTORY.AMOUNT'})
                )}
              </th>
              <th>
                {loadingPayments ? (
                  <Skeleton width={100} />
                ) : (
                  intl.formatMessage({id: 'PAYMENT_HISTORY.ADDED_ON'})
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {loadingPayments ? (
              <tr className='d-flex flex-wrap table-row bg-white item-card mb-5 justify-between w-100 rounded-lg py-2 px-3 px-lg-0'>
                <td
                  data-label={intl.formatMessage({id: 'PAYMENT_HISTORY.DESCRIPTION'})}
                  className='rounded order-1 col-6 col-lg-3 px-1 px-lg-3'
                >
                  <Skeleton width={150} />
                </td>

                <td
                  data-label={intl.formatMessage({id: 'PAYMENT_HISTORY.PAYMENT_METHOD'})}
                  className='order-1 col-6 col-lg-3 px-2 ps-1 ps-lg-0'
                >
                  <Skeleton width={300} />
                </td>

                <td
                  data-label={intl.formatMessage({id: 'PAYMENT_HISTORY.AMOUNT'})}
                  className='order-4 col-6 col-lg-2 px-2 ps-1 ps-lg-0'
                >
                  <Skeleton width={150} />
                </td>

                <td
                  data-label={intl.formatMessage({id: 'PAYMENT_HISTORY.ADDED_ON'})}
                  className='rounded order-1 col-6 col-lg-3 px-2 ps-1 ps-lg-0'
                >
                  <Skeleton width={150} />
                </td>
              </tr>
            ) : (
              orderPaymentsRows
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export {PaymentHistory}

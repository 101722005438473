import {FC} from 'react'
import useScript from '../../../helpers/useScript'
import { toAbsoluteUrl } from '../../../helpers'
import PageHeader from '../../shared/elements/PageHeader'


const GoShorty: FC = () => {
  useScript('https://tyviso.com/rewards-page/loader.js')

  return (
    <>
      <div className="bg-white pt-6">
        <PageHeader title="GoShorty" backToURL="/more" isCenter />
      </div>
      <div className='goshorty-wrapper'>
        <a
          href='https://goshorty.co.uk/?goidst=PMFpartnershipstudentportal&utm_source=portal&utm_medium=student&utm_content=PMFpartnershipstudentportal'
          target='_blank'
          rel='noreferrer'
        >
          <img
            className='w-100'
            src={toAbsoluteUrl(`/assets/media/goshorty/Banner-1.png`)}
            alt='GoShorty: Learner driver practice insurance made simple'
          />
        </a>
        <h2 className='pt-8 text-center text-2xl font-bold secondary-highlight'>
          Practice makes a perfect pass
        </h2>
        <div className='px-12 py-0'>
          <p className='py-3'>
            Want to make sure you pass first time? It's important to get some practice outside your
            driving lessons. The DVSA recommends 22 hours of private practice as well as your
            driving lessons.
          </p>
          <p className='py-3'>
            But before you can get behind the wheel, you'll need to be insured…
          </p>
          <p className='py-3'>
            In fact, the DVSA recommends 22 hours of private practice on top of professional driving
            lessons.
          </p>
          <p className='py-3 text-pink font-semibold'>
            But before you can get behind the wheel, you'll need to be insured…
          </p>

          
          <a
            href='https://goshorty.co.uk/?goidst=PMFpartnershipstudentportal&utm_source=portal&utm_medium=student&utm_content=PMFpartnershipstudentportal'
            target='_blank'
            className='bg-pink w-fit min-w-[250px] rounded-full relative items-center justify-center py-3 px-2 text-base flex mx-auto justify-content-center my-4 border-[0.8px] border-pink text-white hover:brightness-75'
            rel='noreferrer'
          >
            GET A QUOTE
          </a>
        </div>
        <div className='px-12 pt-6 pb-10 font-normal'>
          <h2 className='text-center text-2xl font-bold secondary-highlight'>
            Why do private practice
          </h2>

          <p className='py-3'>
            Lessons with your instructor are crucial, but a bit of extra work can go a long way.
          </p>
          <p className='py-3'>
            Think of it like this — driving in your own time is your homework and revision. The more
            you do, the better your chances of posing for your pass pic 📸
          </p>
          <p className='py-3'>
            Parallel park to your heart's content, or get more experience in different conditions.
            You never know how the weather will hold up on the big day.
          </p>
          <p className='py-3'>
            And the best part? GoShorty can insure you for as little as one hour, so you're never
            paying for more than you need.
          </p>
          <p className='py-3'>
            Ready to get going? Avoid hold music and back-and-forth emails — just get a quote and
            buy online in minutes.
          </p>
          <p className='py-3'>
            In less time than it takes to make a cuppa, you'll be fully insured and one step closer
            to being a fully-fledged driver.
          </p>
          <a
            href='https://goshorty.co.uk/?goidst=PMFpartnershipstudentportal&utm_source=portal&utm_medium=student&utm_content=PMFpartnershipstudentportal'
            target='_blank'
            className='bg-pink w-fit min-w-[250px] rounded-full relative items-center justify-center py-3 px-2 text-base flex mx-auto justify-content-center my-4 border-[0.8px] border-pink text-white hover:brightness-75'
            rel='noreferrer'
          >
            GET A QUOTE
          </a>
          <p className='py-3 small-print'>
            This page contains affiliate links. If you make a purchase using one of these links,
            PassMeFast will earn a small commission. Purchasing via one of these links will not cost
            you anything and you’ll pay the same price as if you bought directly from GoShorty.
          </p>
        </div>
      </div>
    </>
  )
}

export default GoShorty

import {FC, useEffect, useState} from 'react'
import {Tab} from '@headlessui/react'
import {LessonsProgress} from './LessonsProgress'
import {Skeleton} from '@mui/material'
import {Lesson} from '../../models/lessons/lesson'
import LessonCard from '../shared/cards/LessonCard'
import UpdateLessonStatusModal from './_modal/UpdateLessonStatusModal'
import CancelLessonModal from './_modal/CancelLessonModal'
import DisputeLessonModal from './_modal/DisputeLessonModal'
import {useIntl} from 'react-intl'
import EmptyState from '../shared/elements/EmptyState'
import {ExclamationCircleIcon} from '@heroicons/react/24/outline'

type IProps = {
  hasPrevLessons: any
  progress: any
  loading: boolean
  upcomingLessons: Array<Lesson>
  allLessons: Array<Lesson>
  dealId: string
  showReferFriend: any
  rejectionReasons: Array<any>
  getLessons: any
  disableAction: boolean
}

const ListView: FC<IProps> = ({
  hasPrevLessons,
  progress,
  loading,
  upcomingLessons,
  allLessons,
  dealId,
  getLessons,
  rejectionReasons,
  showReferFriend,
  disableAction,
}) => {
  const intl = useIntl()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [currentLessons, setCurrentLessons] = useState<Array<any>>([])
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null)
  const [showUpdateLessonStatus, setShowUpdateLessonStatus] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDisputeModal, setShowDisputeModal] = useState(false)

  const tabs: Array<any> = [
    {
      key: 'all',
      title: `All Lessons (${allLessons.length})`,
    },
    {
      key: 'upcoming',
      title: `Upcoming Lessons (${upcomingLessons.length})`,
    },
  ]

  useEffect(() => {
    if (selectedIndex === 0) {
      setCurrentLessons(allLessons)
    } else {
      setCurrentLessons(upcomingLessons)
    }
  }, [allLessons, selectedIndex, upcomingLessons])

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <div className='px-5 py-6'>
        {((hasPrevLessons === true && progress.taken_hours > 0) || loading) && (
          <>
            <LessonsProgress
              loading={loading}
              courseHours={progress.adi_hours}
              progress={progress.percentage_used}
              remainingHours={progress.remaining_hours}
              scheduled={progress.remaining_hours}
            />
          </>
        )}
        <div>
          <Tab.Group
            selectedIndex={selectedIndex}
            onChange={(index) => {
              setSelectedIndex(index)
            }}
          >
            <Tab.List className='flex justify-start mt-8 mb-5'>
              {tabs.map((tab) => (
                <Tab
                  key={tab.key}
                  className={({selected}) =>
                    classNames(
                      ' w-fit py-1.5 px-5 text-sm font-semibold leading-1 rounded-[20px] me-2',
                      'ring-white ring-opacity-60 ring-offset-1 ring-offset-transparent focus:outline-none focus:ring-none',
                      selected
                        ? ' bg-darkBlue text-white'
                        : ' border-2 border-darkBlue text-darkBlue hover:brightness-75'
                    )
                  }
                >
                  {loading ? (
                    <div className='flex justify-center'>
                      <Skeleton width={80} />
                    </div>
                  ) : (
                    <>{tab.title}</>
                  )}
                </Tab>
              ))}
            </Tab.List>

            {!loading && currentLessons.length === 0 && (
              <div className='flex flex-col items-center justify-center text-center mx-auto pt-0 pb-4 w-full'>
                <EmptyState
                  title={
                    selectedIndex === 1
                      ? intl.formatMessage({id: 'DASHBOARD.EMPTY_UPCOMING_LESSON'})
                      : intl.formatMessage({id: 'DASHBOARD.EMPTY_LESSON'})
                  }
                  Icon={ExclamationCircleIcon}
                />
              </div>
            )}
            <div className='grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 gap-4'>
              {currentLessons.map((lesson, index) => {
                return (
                  <LessonCard
                    loading={loading}
                    lesson={lesson}
                    key={index}
                    disableAction={disableAction}
                    setSelectedStatus={setSelectedStatus}
                    setSelectedLesson={setSelectedLesson}
                    setShowUpdateLessonStatus={setShowUpdateLessonStatus}
                    setShowCancelModal={setShowCancelModal}
                    setShowDisputeModal={setShowDisputeModal}
                  />
                )
              })}{' '}
            </div>
          </Tab.Group>
        </div>
      </div>

      <UpdateLessonStatusModal
        dealId={dealId}
        lesson={selectedLesson ?? new Lesson()}
        rejectionReasons={rejectionReasons ?? []}
        statusId={selectedStatus}
        show={showUpdateLessonStatus}
        onHide={() => {
          setShowUpdateLessonStatus(false)
        }}
        onStatusSubmit={getLessons}
        showReferFriend={showReferFriend}
      />

      <CancelLessonModal
        lesson={selectedLesson ?? new Lesson()}
        open={showCancelModal}
        onClose={(reloadLesson?: boolean) => {
          setShowCancelModal(false)
          if (reloadLesson) {
            getLessons()
          }
        }}
      />

      <DisputeLessonModal
        open={showDisputeModal}
        lesson={selectedLesson ?? new Lesson()}
        onClose={(reloadLesson?: boolean) => {
          setShowDisputeModal(false)
          if (reloadLesson) {
            getLessons()
          }
        }}
      />
    </>
  )
}

export default ListView

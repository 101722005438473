import React, {FC, useRef, useEffect, useState} from 'react'
import {connect, useDispatch, ConnectedProps} from 'react-redux'
import * as auth from './AuthRedux'
import {Auth} from 'aws-amplify'
import {RootState} from './RootReducer'
import {getUserByToken} from '../../app/content/services/auth.service'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux & {children: React.ReactNode}> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken()
          const redirectKey = 'amplify-redirected-from-hosted-ui'
          const isRedirected = localStorage.getItem(redirectKey)
          if (isRedirected) {
            localStorage.removeItem(redirectKey)
          }
          dispatch(props.fulfillUser(user))
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    const getCurrentSession = async () => {
      let success = true
      await Auth.currentSession().catch(() => {
        success = false
        dispatch(props.logout())
        setShowSplashScreen(false)
      })
      if (!success) requestUser()
    }
    getCurrentSession()
    // eslint-disable-next-line
  }, [])

  return <>{props.children}</>
}

export default connector(AuthInit)

import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import {Intensity} from '../models/student/intensity'

const API_URL = process.env.REACT_APP_BASE_URL

export const GET_INTENSITY = `${API_URL}/intensity`

export async function getIntensity(): Promise<BaseResponse<Array<Intensity>>> {
  return await modifiedFetch({
    url: GET_INTENSITY,
    method: 'GET',
  })
}

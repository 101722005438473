import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {BaseResponse} from '../models/base-response'
import {MedicalCondition} from '../models/student/medical-condition'

const API_URL = process.env.REACT_APP_BASE_URL

export const GET_MEDICAL_CONDITIONS = `${API_URL}/medicalCondition`

export async function getMedicalConditions(): Promise<any> {
  return await modifiedFetch({
    url: GET_MEDICAL_CONDITIONS,
    method: 'GET',
  })
}

import React, { FC, useEffect, useState } from "react";
// import { getAdiPicture } from "../../core/services/adi-service";
import { User } from "../models/auth/User";
import LinkItem from "../components/shared/elements/LinkItem";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";

type Props = {
  personalData: User
  date: any
}
// { personalData,date }
const UserMenu: React.FC<Props> = ({ personalData, date }) => {
  const [adiPicture, setAdiPicture] = useState<any>(null);
  const [currentDate, setCurrentDate] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(false)
    // getAdiPicture().then((data) => {
    //   setAdiPicture(data.data.results);
    //   setIsLoading(false)
    // });
  }, []);

  useEffect(() => {
    setCurrentDate(moment(new Date(date)).format('dddd D MMMM'))
  }, [date]);


  // const fullName = "Nahla";
  const fullName = personalData?.name;
  let initials: any;
  if (fullName) initials = fullName.charAt(0);
  // return initials.toUpperCase();

  return (
    <>
      <div className="flex w-full items-center justify-between space-x-6 pt-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-xs font-normal text-white">
              {isLoading ? <><Skeleton width={150} className="me-2" /></> : <>Hi 
              {" "}{personalData?.name}
              ,</>}
            </h3>
          </div>
          <p className="text-base font-normalf font-bold text-white">
          {isLoading ? <><Skeleton width={150} className="me-2" /></> : <>It’s  {currentDate}</>}
          </p>
        </div>
        <LinkItem url="/profile/overview">
          {isLoading ? <>
            <Skeleton variant="circular" width={38} height={38} />
          </> : <Link to='/profile'>
            {adiPicture != null ? (
              <>
              <img
                  alt="Logo"
                  className="h-[38px] w-[38px] flex-shrink-0 rounded-full bg-gray-200 border-[3px] border-gray-100"
                  src={adiPicture}
                />
              </>
            ) : (
              <div className="h-[38px] w-[38px] flex-shrink-0 flex items-center justify-center rounded-full bg-gray-200 border-[3px] border-gray-100">
                <span className="initials">{initials}</span>
              </div>
            )}
          </Link>}
        
         </LinkItem>
      </div>
    </>
  );
};

export { UserMenu };

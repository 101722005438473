import React from 'react'

export interface EmptyStateProps {
  Icon?: any
  ImgPath?: any
  title?: string | React.ReactNode
  description?: string
}

export default function EmptyState({
  title = 'Nothing To See Here',
  description = 'Sorry, we have nothing to show you right now.',
  Icon,
  ImgPath,
  ...props
}: EmptyStateProps) {
  return (
    <div className={`flex flex-col items-center justify-center text-center py-10 px-5`} {...props}>
      {ImgPath && <img src={ImgPath} alt='' className='mb-3 w-90px' />}
      {Icon && <Icon className='h-10 w-10 text-darkBlue' />}
      <div className='text-darkBlue text-base font-bold mt-5 mb-2'>{title}</div>
      <p className='text-darkBlue text-sm'>{description}</p>
    </div>
  )
}

import React from 'react'
import {
  AdjustmentsHorizontalIcon,
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronUpDownIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import {Link} from 'react-router-dom'
import Button from './Button'
import LinkItem from './LinkItem'

export interface PageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  backToURL?: string
  description?: string
  isCenter?: boolean
  buttons?: {
    text: React.ReactNode
    notification?: React.ReactNode
    Icon?: 'sort' | 'filter'
    onClick: () => void
  }[]
  storageKey?: string
}

export default function PageHeader({
  title,
  backToURL,
  buttons,
  isCenter = false,
  storageKey,
}: // navigation,
PageHeaderProps) {
  return (
    <>
      <div className='lg:flex lg:items-center lg:justify-between mb-0 rounded-md transition-all flex items-center p-5'>
        {backToURL && (
          <div className={'min-w-0 flex mb-0'}>
            <Link
              to={
                storageKey
                  ? sessionStorage.getItem(storageKey) != null
                    ? backToURL + sessionStorage.getItem(storageKey)
                    : backToURL
                  : backToURL
              }
              className='flex items-center'
            >
              <ArrowLeftIcon className='w-5 h-5 me-[10px]' />
            </Link>
          </div>
        )}
        <div
          className={
            isCenter ? 'min-w-0 flex-1 flex justify-center mb-3' : 'min-w-0 flex-1 flex mb-0'
          }
        >
          <h2 className="text-2xl font-normalf font-bold text-darkBlue">
            {title}
          </h2>
        </div>
        <div className={'min-w-0 flex mb-0'}>
          <LinkItem url='/profile'>
            <div className='h-[34px] w-[34px] flex-shrink-0 flex items-center justify-center rounded-full bg-gray-200'>
              {/* <span className="initials">{initials}</span> */}
              <UserIcon className='w-5 h-5' />
            </div>
            <>
              {/* <img
          alt="Logo"
          className="h-[38px] w-[38px] flex-shrink-0 rounded-full bg-gray-200 border-[3px] border-gray-100"
          src={adiPicture}
        /> */}
            </>
          </LinkItem>
        </div>

        {buttons && buttons.length > 0 ? (
          <div className='flex space-x-4 items-center'>
            {buttons.map((button, i) => (
              <Button onClick={() => button.onClick()} key={i} colour='transparent' size='fit'>
                <span className='flex text-sm text-darkBlue items-center'>
                  {button.Icon === 'sort' && (
                    <ChevronUpDownIcon className='h-4 w-4 mr-1 text-darkBlue' />
                  )}
                  {button.Icon === 'filter' && (
                    <AdjustmentsHorizontalIcon className='h-4 w-4 mr-1 text-darkBlue' />
                  )}
                  {button.text} {button.notification && <>{button.notification}</>}{' '}
                </span>
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </>
  )
}

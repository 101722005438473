import React, {Fragment, useEffect} from 'react'
import Button from '../elements/Button'
import {Dialog, Transition} from '@headlessui/react'
// import { BottomSheet } from 'react-spring-bottom-sheet'
// import 'react-spring-bottom-sheet/dist/style.css';
import {ArrowsUpDownIcon} from '@heroicons/react/20/solid'
import {ArrowLeftIcon} from '@heroicons/react/24/outline'

export type ModalProps = {
  open?: boolean
  title?: string
  className?: string
  titleIcon?: string
  icon?: 'sort' | 'money' | 'receiptPercent' | null
  description?: string
  onSubmit?: () => void
  onClose?: () => void
  onDismiss?: () => void
  submitText?: string
  closeText?: string
  children?: React.ReactNode
  hideClose?: boolean
  hideSubmit?: boolean
  disabled?: boolean
  disableSubmit?: boolean
  showActionLink?: boolean
  actionLinkText?: string
  actionLinkColor?: 'dangerLink' | 'link'
  onActionLink?: () => void
  setMinHeight?: boolean
}

export default function Modal({
  open = false,
  title = 'Modal Title',
  className = '',
  titleIcon,
  icon,
  description,
  onClose = () => {},
  onSubmit = () => {},
  onDismiss,
  submitText = 'Submit',
  closeText = 'Close',
  children,
  hideClose = false,
  hideSubmit = false,
  disabled = false,
  disableSubmit = false,
  showActionLink = false,
  actionLinkText,
  actionLinkColor = 'link',
  onActionLink,
  setMinHeight = false,
}: ModalProps) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  const Header: React.ReactNode = (
    <div className='lg:flex lg:items-center lg:justify-between mb-0 rounded-md transition-all flex items-center py-5'>
      <div className={'min-w-0 flex mb-0'}>
        <div onClick={onClose} className='flex items-center'>
          <ArrowLeftIcon className='w-5 h-5 me-[10px]' />
        </div>
      </div>
      <div className='min-w-0 flex-1 flex justify-center'>
        <h3 className='text-2xl font-bold py-0 px-1 text-darkBlue'>{title}</h3>
      </div>

      <div className='w-5 h-5 ms-[10px]'></div>
    </div>
  )

  const Footer: React.ReactNode = (
    <>
      {(!hideSubmit || !hideClose) && (
        <div className='py-4 bg-gray-100  px-5'>
          <div className='flex items-center justify-between space-x-2'>
            {!hideSubmit && (
              <Button
                colour='gradient'
                type='submit'
                className={hideClose ? 'w-full' : 'w-1/2'}
                onClick={onSubmit}
                size='large'
                disabled={disabled || disableSubmit}
              >
                {submitText}
              </Button>
            )}
            {!hideClose && (
              <Button
                colour='outline'
                type='button'
                className={hideSubmit ? 'w-full' : 'w-1/2'}
                onClick={onClose}
                size='large'
                disabled={disabled}
              >
                {closeText}
              </Button>
            )}
          </div>

          {showActionLink && (
            <Button
              colour={actionLinkColor}
              type='button'
              className='w-full mt-2'
              onClick={onActionLink}
              size='small'
              disabled={disabled}
            >
              {actionLinkText}
            </Button>
          )}
        </div>
      )}
    </>
  )

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as='div' className='relative z-[100]' onClose={onDismiss ?? onClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='w-full fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-25 h-full' />
          </Transition.Child>
          <div className='fixed  top-0 right-0 left-0 bottom-0'>
            <div className='flex h-full items-end sm:items-center justify-center text-center'>
              <Transition.Child
                as={Fragment}
                enter='transition-all ease-in-out duration-500'
                enterFrom='opacity-0 translate-y-[10%]'
                enterTo='opacity-100'
                leave='transition-all ease-in-out duration-500'
                leaveFrom='opacity-100 '
                leaveTo='opacity-0 translate-y-[10%]'
              >
                <Dialog.Panel
                  className={`
                    ${className} 
                    flex flex-col px-0
                    w-full md:max-w-lg transform overflow-y-hidden rounded-none md:rounded-t-2xl overflow-x-hidden md:rounded-b-2xl 
                     !pt-0 !pb-0 text-left align-middle shadow-xl transition-all h-full md:h-fit md:min-h-[20vh] 
                  `}
                >
                  <div className='pt-1 -mx-1 bg-white sticky top-0 z-[11] px-5'>{Header}</div>
                  <div className='bg-gray-100 px-5 pt-6 grow md:max-h-[80vh] max-h-unset overflow-y-auto '>
                    <div>
                      <p className='text-xl font-normal mb-2'>{description}</p>
                      <div className=' grow'>{children}</div>
                    </div>
                    

                    
                  </div>
                  {(!hideClose || !hideSubmit) && (
                      <div className='sticky bottom-0 z-[11]'>{Footer}</div>
                    )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

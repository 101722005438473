import {BaseResponse} from '../models/base-response'

import store from '../../../setup/redux/Store'
import {Lesson} from '../models/lessons/lesson'
import modifiedFetch from '../../../setup/fetch/ModifiedFetch'
import {UpdateLessonStatusRequest} from '../models/student/update-lesson-status-request'
import {LessonRejectionReason} from '../models/lessons/lesson-rejection-reason'
import {TakenHours} from '../models/student/taken-hours'

const LESSON_URL = process.env.REACT_APP_LESSONS_BASE_URL

const GET_LESSONS = `${LESSON_URL}/lessons/student-lessons`
const GET_CHECK_HAS_PREV_LESSONS = `${LESSON_URL}/get-student-has-prev-lessons`
const GET_UPCOMING_DASHBOARD_LESSONS = `${LESSON_URL}/lessons/dashboard/student-upcoming-lessons`

export async function getStudentLessons(
  data: any,
  dealId: any
): Promise<BaseResponse<Array<Lesson>>> {
  return await modifiedFetch({
    url: `${GET_LESSONS}/${dealId}`,
    method: 'POST',
    data: data,
  })
}

export async function checkHasPrevLessons(dealId: any): Promise<BaseResponse<any>> {
  return await modifiedFetch({
    url: `${GET_CHECK_HAS_PREV_LESSONS}/${dealId}`,
    method: 'GET',
  })
}

export async function updateLessonStatus(data: UpdateLessonStatusRequest): Promise<any> {
  const state = store.getState()
  data.action_by = `Student - ${state.auth.personalData?.name} ${state.auth.personalData?.sur_name}`
  return await modifiedFetch({
    url: `${LESSON_URL}/students/lessons/lesson-status`,
    method: 'PUT',
    data: data,
  })
}

export async function getRejectionReasons(): Promise<BaseResponse<Array<LessonRejectionReason>>> {
  return await modifiedFetch({
    url: `${LESSON_URL}/rejection-reasons`,
    method: 'GET',
  })
}

export async function getTakenHours(data: any): Promise<BaseResponse<TakenHours>> {
  return await modifiedFetch({
    url: `${LESSON_URL}/lessons/taken-hours`,
    method: 'POST',
    data: data,
  })
}

export async function cancelLesson(data: any, lessonId: string): Promise<any> {
  const state = store.getState()
  data.action_by = `Student - ${state.auth.personalData?.name} ${state.auth.personalData?.sur_name}`
  return await modifiedFetch({
    url: `${LESSON_URL}/students/lessons/${lessonId}/cancel-lesson`,
    method: 'PUT',
    data: data,
  })
}

export enum TestStatusTypeEnum {
  InitialBooking = 'student confirmation',
  Confirmed = 'accepted',
  Rejected = 'rejected',
  Reschedule = 'rescheduled & swapped',
  Passed = 'passed',
  Failed = 'failed',
  Rebooked = 'rebooked',
  PendingReschedule = 'rescheduling',
  PendingAdiApproval = 'adi confirmation',
  PendingResult = 'pending result',
  Cancelled = 'cancelled',
  NotApplicable = 'N/A',
}

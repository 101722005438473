import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import Button from '../../../shared/elements/Button'
import { TestStatusTypeEnum } from '../../../../models/enums/test-status-type'
import { useCourse } from '../../../../providers/CourseProvider'
import { Card } from '../../Card'
import { Test } from '../../../../models/tests/test'

type Props = {
  test: Test | undefined
  getStudentBookingList: any
  testRejectionReason: any
  theoryTestCenters: any
  practicalTestCenters: any
  disableAction?: any
  canAddPassProtect: any
  isPto?: boolean
  leadTime?: number
  passProtectOption: any
}

const PracticalTest: FC<Props> = ({
  test,
  getStudentBookingList,
  practicalTestCenters,
  testRejectionReason,
  theoryTestCenters,
  disableAction,
  canAddPassProtect,
  isPto,
  leadTime,
  passProtectOption,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {Course, getCurrentCourse} = useCourse()
  return (
    <>
      {test &&
        (test.status === TestStatusTypeEnum.Confirmed ||
          test.status === TestStatusTypeEnum.PendingResult ||
          test.status === TestStatusTypeEnum.Failed ||
          test.status === TestStatusTypeEnum.Passed) && (
          <>
            <div>
              <Card
                parentPage='journey'
                test={test}
                getStudentBookingList={getStudentBookingList}
                practicalTestCenters={practicalTestCenters}
                testRejectionReason={testRejectionReason}
                theoryTestCenters={theoryTestCenters}
                disableAction={disableAction}
                leadTime={leadTime}
                IsPto={isPto}
                passProtectOption={passProtectOption}
              />
            </div>
            {canAddPassProtect && !Course?.currentCourse?.inActiveStage && (
              
              <div className=' mt-3'>
              <Button
                type='button'
                colour='outline'
                onClick={() => {
                  navigate('/addons')
                }}
                fullWidth
                className='mt-4'
              >
                {intl.formatMessage({id: 'STUDENT_TEST.ADDPASSPROTECT'})}
              </Button>
              </div>
            )}
          </>
        )}
    </>
  )
}

export {PracticalTest}

import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import DisclosureItem from '../../../shared/elements/DisclosureItem'
import Skeleton from '@mui/material/Skeleton'

type Props = {
  title: string
  children?: React.ReactNode
  status: 'complete' | 'current' | 'pending' | 'upcoming' | string
  id: number
  imgPath: string
  hasContent: boolean
  expanded: boolean
  stepIdx: number
  loading: boolean
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const JourneyItem: FC<Props> = ({
  title,
  status = 'upcoming',
  id,
  children,
  imgPath,
  hasContent,
  expanded,
  stepIdx,
  loading,
}) => {
  const [sectionOneToggler, switchSectionOneToggler] = useState(true)

  useEffect(() => {
    if (expanded) switchSectionOneToggler(false)
    else switchSectionOneToggler(true)
  }, [title, expanded])

  return (
    <>
      <li key={id}>
        <div className='relative pb-7'>
          {/* {stepIdx} */}
          {stepIdx !== 7 ? (
            <span
              className={`
              ${
                {
                  pending: 'bg-gray-200',
                  complete: 'bg-green',
                  upcoming: 'bg-gray-200',
                  current: 'bg-gradient-to-b from-green to-gray-200',
                }[status]
              }
              absolute left-5 top-4 h-full w-1 -ml-px`}
              aria-hidden='true'
            />
          ) : null}
          <div className='relative flex space-x-3'>
            <div className=' flex-shrink'>
            {loading ?
              <> 
                <div className='flex  h-10 w-10'>
                  <Skeleton variant="circular" width={40} height={40} />
                </div>
              </> 
              : <>
              <span
                className={`
              ${
                {
                  pending: 'bg-white border-4 border-gray-600',
                  complete: 'bg-green text-white',
                  upcoming: 'bg-white border-4 border-gray-600',
                  current: 'bg-gradient-to-b from-green to-blue !p-1',
                }[status]
              }
              h-10 w-10 rounded-full flex items-center justify-center`}
              >
                {/* <event.icon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      /> */}
                {status === 'complete' ? <i className='icon-check'></i> : null}
                {status === 'current' ? <span className='bg-darkBlue text-white font-bold text-lg rounded-full w-8 h-8 leading-none flex z-10 items-center justify-center'>0{stepIdx+1}</span> : null}
                {(status !== 'complete'&& status !== 'current') ? <span className='text-gray-600 font-bold text-lg w-8 h-8 leading-none flex items-center justify-center'>0{stepIdx+1}</span> : null}
              </span>
             </>
            }
            </div>
            <div className='w-full flex-grow'>
              {loading ?
                <> 
                  <DisclosureItem loading={loading} />
                </> 
                : <>
                <DisclosureItem key={stepIdx} title={title} disabled={!hasContent}>
                  <div className='pb-2'>{children}</div>
                </DisclosureItem>
                {/* <div className='whitespace-nowrap text-right text-sm text-gray-500'>
                  {status}
                </div> */}
                </>
              }
            </div>
          </div>
        </div>
      </li>

      {/* <Accordion key={stepIdx} defaultActiveKey={expanded ? '1' : undefined}>
        <div
          className={clsx('pb-10 position-relative', {
            'accordion-opened': !sectionOneToggler
          })}
        >
          <div
            aria-hidden='true'
            className={classNames(
              status === 'complete' ? 'stepper-line-done' : '',
              'stepper-line position-absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600'
            )}
          />
          <div className='group z-3 position-relative d-flex grow-1 align-items-start'>

            <span className='flex h-50px items-center'>
          
            <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800'>
            {loading ?
              <> 
                <div className='flex h-50px items-center w-50px flex-shrink mt-n1'>
                  <Skeleton circle height='100%' containerClassName='avatar-skeleton' />
                </div>
              </> 
              : <>
                {status === 'complete' ? (
                      <>
                        <img
                          className='w-50px'
                          src={toAbsoluteUrl('/media/svg/journey/completed.svg')}
                          alt={title + 'completed'}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className='w-50px'
                          src={toAbsoluteUrl('/media/svg/journey/' + imgPath)}
                          alt={title}
                        />
                      </>
                    )}
                </> 
              }
              </span>
           
            
              
            </span>

            <span className='ps-4 d-flex flex-column flex-grow-1 ms-4 flex min-w-0 flex-col'>
              <span className='d-flex justify-content-between align-items-center h-50px'>
                <span className='fw-bolder mb-0 font-size-18 lh-1'>
                  {loading ?
                    <> <Skeleton count={1} width={200} /></> 
                    :<> {title} </>
                  }
                </span>
                {loading ? 
                    <></> 
                    :<> 
                      {hasContent && (
                        <Accordion.Toggle
                          eventKey='1'
                          className='btn btn-link py-1 px-0 w-50px text-center rounded flex-shrink'
                          onClick={() => { switchSectionOneToggler(!sectionOneToggler) }}
                        >
                          {sectionOneToggler ? (
                            <i className='fas fa-angle-right fs-1'></i>
                          ) : (
                            <i className='fas fa-angle-up fs-1'></i>
                          )}
                        </Accordion.Toggle>
                      )}
                    </>
                  }
                

              </span>
              <Accordion.Collapse className='accordion-collapse' eventKey='1'>
                <>{children}</>
              </Accordion.Collapse>
            </span>
          </div>
        </div>
      </Accordion> */}
    </>
  )
}

export {JourneyItem}

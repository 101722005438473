import moment from 'moment'

export function getYears(
  yearsCount: number,
  yearDiff: number,
  isIncrementing: boolean
): Array<any> {
  let years: Array<any> = []
  const currentYear: number = Number(new Date().getFullYear())
  let startYear: number = currentYear - yearDiff
  let endYear: number
  if (isIncrementing) {
    endYear = startYear + yearsCount
    for (let i = startYear; i <= endYear; i++) {
      years.push({label: i.toString(), value: i.toString()})
    }
  } else {
    endYear = startYear - yearsCount
    for (let i = startYear; i >= endYear; i--) {
      years.push({label: i.toString(), value: i.toString()})
    }
  }
  return years
}
export function getMonths(): Array<any> {
  return moment.months().map((month) => {
    return {
      label: month,
      value: month,
    }
  })
}
export function getDays(yearStr: string, month: number): Array<string> {
  const year = parseInt(yearStr)
  var days: Array<string> = []
  var firstDayInMonth: number = new Date(year, month, 1).getDate()
  var lastDayInMonth: number = new Date(year, month + 1, 0).getDate()
  for (let i = firstDayInMonth; i <= lastDayInMonth; i++) {
    days.push(i.toString())
  }
  return days
}

export function getAllDays(maxDay?: number, minDay: number = 1): Array<any> {
  var days: Array<string> = []
  maxDay = maxDay || 31
  for (let i = minDay; i <= maxDay; i++) {
    days.push(i.toString())
  }
  return days.map((day) => {
    return {
      label: day,
      value: day,
    }
  })
}
export function getTimes(): Array<any> {
  var times = []
  var start = 6*60
  for (var i = 0; start < 24 * 60; i++) {
    var hh = Math.floor(start / 60)
    var mm = start % 60
    if(start > 21*60) {
      return times
    }
    times[i] = {
      value: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
      label: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
    }
    start = start + 15
  }
  return times
}
export function dateCompare(time1: any, time2: Date) {
  var t1 = new Date()
  var parts = time1.split(':')
  if (parts.length > 0) {
    t1.setHours(parts[0], parts[1], 0, 0)
  }
  var t2 = time2
  // returns 1 if greater, -1 if less and 0 if the same
  if (t1.getTime() > t2.getTime()) return 1
  if (t1.getTime() < t2.getTime()) return -1
  return 0
}
